import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteZoneRequest } from '../../../actions/zones';
import { Button } from '../../../components/form';
import { SvgTrash } from '../../../components/svg';
import { deletingZoneSelector } from '../../../selectors/zones';
import Header from '../header';
import styles from './index.module.css';

type DeleteZoneModalProps = {
  close: () => void;
  zone: {
    id: number;
    name: string;
  };
};

function DeleteZoneModal(props: DeleteZoneModalProps) {
  const { close, zone } = props;

  const { t } = useTranslation('modals', { keyPrefix: 'deleteZone' });

  const dispatch = useDispatch();

  const deleting = useSelector(deletingZoneSelector);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteZoneRequest(zone.id));
  }, [dispatch, zone]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgTrash color="#fff" height={17} width={19} />}
        title={t('title')}
      />
      <div className={styles.description}>
        <Trans
          components={{
            div: <div />,
            p: <p />,
          }}
          i18nKey="description"
          t={t}
          values={{ name: zone.name }}
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button color="primaryNegative" onClick={close} type="button">
          {t('cancel')}
        </Button>
        <Button
          className={styles.submit}
          color="negative"
          containerClassName={styles.submitContainer}
          disabled={false}
          loading={deleting}
          onClick={handleDeleteClick}
          type="button"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default DeleteZoneModal;
