import { Reducer, combineReducers } from 'redux';
import { RouterState } from 'redux-first-history';

import app, { AppState } from './app';
import auth, { AuthState } from './auth';
import cameras, { CamerasState } from './cameras';
import categories, { CategoriesState } from './categories';
import filters, { FiltersState } from './filters';
import foldouts, { FoldoutsState } from './foldouts';
import imports, { ImportsState } from './imports';
import industries, { IndustriesState } from './industries';
import insights from './insights';
import lines, { LinesState } from './lines';
import locations, { LocationsState } from './locations';
import modalToasts, { ModalToastsState } from './modal-toasts';
import modals, { ModalsState } from './modals';
import organizations, { OrganizationsState } from './organizations';
import permissions from './permissions';
import routes from './routes';
import segmentation, { SegmentationState } from './segmentation';
import toasts from './toasts';
import users from './users';
import validations from './validations';
import visitorJourney, { VisitorJourneyState } from './visitor-journey';
import zones from './zones';

export interface RootState {
  // other reducers
  [key: string]: any;
  app: AppState;
  auth: AuthState;
  cameras: CamerasState;
  categories: CategoriesState;
  filters: FiltersState;
  foldouts: FoldoutsState;
  imports: ImportsState;
  industries: IndustriesState;
  lines: LinesState;
  locations: LocationsState;
  modalToasts: ModalToastsState;
  modals: ModalsState;
  organizations: OrganizationsState;
  segmentation: SegmentationState;
  visitorJourney: VisitorJourneyState;
}

const createRootReducer = (routerReducer: Reducer<RouterState>) =>
  combineReducers({
    app,
    auth,
    cameras,
    categories,
    filters,
    foldouts,
    imports,
    industries,
    insights,
    lines,
    locations,
    modalToasts,
    modals,
    organizations,
    permissions,
    router: routerReducer,
    routes,
    segmentation,
    toasts,
    users,
    validations,
    visitorJourney,
    zones,
  });

export default createRootReducer;
