import { RootActions } from '../actions';
import * as visitorJourneyActions from '../actions/visitor-journey';
import {
  RESET_TOKEN,
  VISITOR_JOURNEY_FAILURE,
  VISITOR_JOURNEY_REQUEST,
  VISITOR_JOURNEY_SUCCESS,
} from '../constants/action-types';
import lineTypes from '../constants/line-types';

type LinkType = typeof lineTypes[keyof typeof lineTypes];

type Link = {
  color: string;
  source: string;
  target: string;
  type: LinkType;
  value: number;
};

type Node = {
  color: string;
  name: string;
  type: LinkType;
};

export type VisitorJourneyData = {
  links: Link[];
  nodes: Node[];
};

export type VisitorJourneyState = {
  data: VisitorJourneyData;
  dataDate: Nullable<Option>;
  dataDisplayLevel: Option;
  dataEndDate: Nullable<Date | string>;
  dataLocation: Nullable<Option>;
  dataOrganization: Nullable<Option>;
  dataStartDate: Nullable<Date | string>;
  failed: boolean;
  loading: boolean;
};

export const initialState: VisitorJourneyState = {
  data: {
    links: [],
    nodes: [],
  },
  dataDate: null,
  dataDisplayLevel: null,
  dataEndDate: null,
  dataLocation: null,
  dataOrganization: null,
  dataStartDate: null,
  failed: false,
  loading: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case VISITOR_JOURNEY_REQUEST:
      return {
        ...state,
        failed: false,
        loading: true,
      };
    case VISITOR_JOURNEY_SUCCESS: {
      const {
        data,
        date,
        displayLevel,
        endDate,
        location,
        organization,
        startDate,
      } = action as ReturnType<
        typeof visitorJourneyActions.visitorJourneySuccess
      >;

      return {
        ...state,
        data,
        dataDate: date,
        dataDisplayLevel: displayLevel,
        dataEndDate: endDate,
        dataLocation: location,
        dataOrganization: organization,
        dataStartDate: startDate,
        failed: false,
        loading: false,
      };
    }
    case VISITOR_JOURNEY_FAILURE:
      return {
        ...state,
        data: structuredClone(initialState.data),
        failed: true,
        loading: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
