function SvgTickLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 8.061 14.707"
      {...props}
    >
      <path d="M7.707.353l-7 7 7 7" fill="none" stroke="#999" />
    </svg>
  );
}

export default SvgTickLeft;
