import i18next from 'i18next';

type ServerError = {
  response: {
    body: {
      code: null | string;
      errors: {
        code: number;
        field: null | string;
        message: string;
      }[];
      message: string;
      status_code: number;
    };
  };
  status: number;
};

export function parseError(
  error: Error | ServerError | ValidationError | unknown,
  context?: string
): Validation[] {
  // const { response } = error as ServerError;

  const { validation } = error as ValidationError;

  if (validation) {
    return [validation];
  }

  const { response } = error as ServerError;

  if (response?.body?.errors) {
    return response.body.errors.map((e) => ({
      field: e.field,
      message: i18next.t([`error.${e.code}`, 'error.unknown'], { context }),
    }));
  }

  if (response?.body?.code) {
    return [
      {
        field: '',
        message: i18next.t([`error.${response?.body?.code}`, 'error.unknown'], {
          context,
        }),
      },
    ];
  }

  // if (response) {
  //   const {
  //     body: { responseCode, invalidParam, paymentGatewayErrorCode },
  //   } = response;

  //   if (paymentGatewayErrorCode) {
  //     return {
  //       message: i18next.t([
  //         `paymentGatewayError.${paymentGatewayErrorCode}`,
  //         'paymentGatewayError.unknown',
  //       ]),
  //       field: '',
  //     };
  //   }

  //   return {
  //     message: i18next.t([`error.${responseCode}`, 'error.unknown'], {
  //       context,
  //       invalidParam: invalidParam ? i18next.t(`violator.${invalidParam}`) : '',
  //     }),
  //     field: relatedField(responseCode, invalidParam),
  //   };
  // }

  return [
    {
      field: '',
      message: (error as Error).message?.includes('the network is offline')
        ? i18next.t('error.offline')
        : i18next.t('error.unknown'),
    },
  ];
}
