import clsx from 'clsx';
import { MouseEvent, useCallback } from 'react';

import styles from './canvas-button.module.css';

type CanvasButtonProps = {
  active?: boolean;
  disabled?: boolean;
  icon: JSX.Element;
  label?: string;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
};

function CanvasButton(props: CanvasButtonProps) {
  const {
    active = false,
    disabled = false,
    icon,
    label = null,
    onClick,
    ...rest
  } = props;

  const handleClick = useCallback(
    (e) => {
      if (e.type === 'keydown' && e.key !== 'Enter') return;

      if (!disabled) {
        onClick(e);
      }
    },
    [disabled, onClick]
  );

  return (
    <div
      className={clsx([
        styles.button,
        { [styles.active]: active, [styles.disabled]: disabled },
      ])}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      {...rest}
    >
      {icon}
      {label}
    </div>
  );
}

export default CanvasButton;
