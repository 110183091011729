import { createSelector } from 'reselect';

import { LINE_HEIGHT } from '../constants/canvas';
import { RootState } from '../reducers';
import { ICamera } from '../types/api/cameras';

export const listingCamerasSelector = (state: RootState) =>
  state.cameras.listing;

export const listingSingleCamerasSelector = (state: RootState) =>
  state.cameras.listingSingleCameras;

export const allCamerasSelector = (state: RootState) => state.cameras.cameras;

export const camerasSelector = createSelector([allCamerasSelector], (cameras) =>
  cameras.filter((c) => c.deleted_at == null)
);

export const singleCamerasSelector = (state: RootState) =>
  state.cameras.singleCameras;

export const deactivatingSelector = (state: RootState) =>
  state.cameras.deactivating;

export const activatingSelector = (state: RootState) =>
  state.cameras.activating;

export const cameraCountSelector = (state: RootState) =>
  state.cameras.cameraCount;

export const creatingCameraSelector = (state: RootState) =>
  state.cameras.creating;

export const deletingCameraSelector = (state: RootState) =>
  state.cameras.deleting;

export const listingSingleCameraSelector = createSelector(
  [listingSingleCamerasSelector, (_, id: ICamera['id']) => id],
  (cameras, id) => cameras[id]
);

export const singleCameraSelector = createSelector(
  [singleCamerasSelector, (_, id) => id],
  (cameras, id) => {
    const camera = cameras[id];

    if (!camera) return null;

    // Should be edited as the API updated.
    const zones = camera.zones.map((z) => {
      const x = parseFloat(z.x1);
      const y = parseFloat(z.y1);

      return {
        category: z.category?.id || null,
        fill: 'white',
        id: z.id,
        lineZones:
          z.line_zones?.map((zf) => ({
            FromZone: zf.FromZone.id,
            ToZone: zf.ToZone.id,
          })) || [],
        markerId: z.marker_id,
        name: z.name,
        points: [0, 0, parseFloat(z.x2) - x, parseFloat(z.y2) - y],
        stroke: z.colour,
        strokeWidth: LINE_HEIGHT,
        type: z.type || null,
        x,
        y,
        zoneFrom: z.zone_from?.id ?? null,
        zoneTo: z.zone_to?.id ?? null,
      };
    });

    return { ...camera, zones };
  }
);

export const camerasLengthSelector = createSelector(
  camerasSelector,
  (cameras) => cameras.length
);

export const selectedCamerasSelector = (state: RootState) =>
  state.cameras.selectedCameras;

export const updatingCameraSelector = (state: RootState) =>
  state.cameras.updating;

export const batchUpdatingCamerasSelector = (state: RootState) =>
  state.cameras.batchUpdating;

export const cameraByIdSelector = createSelector(
  [allCamerasSelector, (_, id) => id],
  (cameras, id) => {
    const camera = cameras.find((c) => c.id === id);

    // Should be edited as the API updated.
    const zones = camera.zones.map((z) => {
      const x = parseFloat(z.x1);
      const y = parseFloat(z.y1);

      return {
        category: z.category?.id || null,
        fill: 'white',
        id: z.id,
        lineZones:
          z.line_zones?.map((zf) => ({
            FromZone: zf.FromZone.id,
            ToZone: zf.ToZone.id,
          })) || [],
        markerId: z.marker_id,
        name: z.name,
        parentId: z.parent_marker_id,
        points: [0, 0, parseFloat(z.x2) - x, parseFloat(z.y2) - y],
        stroke: z.colour,
        strokeWidth: LINE_HEIGHT,
        type: z.type || null,
        x,
        y,
        zoneFrom: z.zone_from?.id ?? null,
        zoneTo: z.zone_to?.id ?? null,
      };
    });

    return { ...camera, zones };
  }
);
