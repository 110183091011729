import { ChangeEvent, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { batchUpdateUsersRequest } from '../../../actions/users';
import { Button, Input, Label, Select } from '../../../components/form';
import { SvgEdit } from '../../../components/svg';
import { EntityCount } from '../../../components/text';
import wr from '../../../constants/workrole';
import {
  selectedUsersSelector,
  updatingUsersSelector,
} from '../../../selectors/users';
import { setInitInputValue } from '../../../utils/user';
import Header from '../header';
import styles from './index.module.css';

type BulkEditUserProps = {
  close: () => void;
};

function BulkEditUser(props: BulkEditUserProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const userIds = useSelector(selectedUsersSelector);

  const updatingUsers = useSelector(updatingUsersSelector);

  const [t] = useTranslation('modals', { keyPrefix: 'bulkEditUser' });

  const [t2] = useTranslation();

  const [propertyName, setPropertyName] = useState<string>('');

  const [propertyValue, setPropertyValue] = useState<string>('');

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPropertyValue(e.target.value);
  }, []);

  const setSelectedPropertyName = useCallback((option: Option) => {
    setPropertyName(option?.value || null);
    setPropertyValue(setInitInputValue(option?.value));
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(batchUpdateUsersRequest(userIds, propertyName, propertyValue));
  }, [dispatch, propertyValue, propertyName, userIds]);

  const inputs = {
    first_name: (
      <Label className={styles.label}>
        {t('label.first_name')}
        <Input
          dashed={false}
          name="first_name"
          onChange={handleInputChange}
          placeholder={t('placeholder.first_name')}
          type="text"
          value={propertyValue}
        />
      </Label>
    ),
    last_name: (
      <Label className={styles.label}>
        {t('label.last_name')}
        <Input
          dashed={false}
          name="last_name"
          onChange={handleInputChange}
          placeholder={t('placeholder.last_name')}
          type="text"
          value={propertyValue}
        />
      </Label>
    ),
    work_role: (
      <Label className={styles.label}>
        {t('label.work_role')}
        <Select
          contentEditable={false}
          dashed={false}
          options={Object.keys(wr).map((key) => ({
            label: t2(`workrole.${wr[key]}`),
            value: key,
          }))}
          placeholder={t('placeholder.work_role')}
          selected={
            propertyValue
              ? {
                  label: t2(`workrole.${wr[propertyValue]}`),
                  value: propertyValue,
                }
              : null
          }
          setSelected={(option: Option) =>
            setPropertyValue(option?.value || null)
          }
          zIndex={1}
        />
      </Label>
    ),
  };

  return (
    <form className={styles.container}>
      <Header icon={<SvgEdit height={19} width={19} />} title={t('title')}>
        <EntityCount>
          <Trans
            components={{ bold: <b /> }}
            i18nKey="total"
            t={t}
            values={{ count: userIds.length }}
          />
        </EntityCount>
      </Header>
      <Label className={styles.label}>
        {t('editProperties')}
        <Select
          dashed={false}
          options={Object.keys(inputs).map((key) => ({
            label: t(`label.${key}`),
            value: key,
          }))}
          placeholder={t('placeholder.editProperties')}
          selected={
            propertyName && {
              label: propertyName && t(`label.${propertyName}`),
              value: propertyName,
            }
          }
          setSelected={setSelectedPropertyName}
          zIndex={30}
        />
      </Label>
      {inputs[propertyName]}
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          containerClassName={styles.buttonContainer}
          onClick={close}
          type="button"
        >
          {t('cancel')}
        </Button>
        <Button
          className={styles.button}
          color="secondary"
          containerClassName={styles.buttonContainer}
          disabled={!propertyValue}
          loading={updatingUsers}
          onClick={handleSubmit}
          type="button"
        >
          {t('update')}
        </Button>
      </div>
    </form>
  );
}

export default BulkEditUser;
