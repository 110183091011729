import { useCallback, useState } from 'react';
import { Input } from '../form';
import styles from './custom-time-input.module.css';

function isValid(val) {
  const regexp = /^\d{0,2}?\:?\d{0,2}$/;

  const [hoursStr, minutesStr] = val.split(':');

  if (!regexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour) =>
    Number.isInteger(hour) && hour >= 0 && hour < 24;
  const isValidMinutes = (mins) =>
    (Number.isInteger(mins) && hours >= 0 && hours < 24) || Number.isNaN(mins);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

  // Check MM and HH.
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
}

type CustomTimeInputProps = {
  label: string;
  value?: any;
};

function CustomTimeInput(props: CustomTimeInputProps) {
  const { value, label } = props;

  const [time, setTime] = useState(value);
  const [last, setLast] = useState('');

  const handleChange = useCallback(
    (e) => {
      let { value: val } = e.target;

      if (val === time) {
        return;
      }
      if (isValid(val)) {
        if (val.length === 2 && last.length !== 3 && val.indexOf(':') === -1) {
          val = `${val}:`;
        }

        if (val.length === 2 && last.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length > 5) {
          return;
        }

        setLast(val);
        setTime(val);
      }
    },
    [last.length, time]
  );

  return (
    <div className={styles.timeInput}>
      <div className={styles.label}>{label}</div>
      <Input
        className={styles.input}
        autoComplete="off"
        id={`${label}-time`}
        type="text"
        name="time"
        value={time}
        onChange={handleChange}
        placeholder="00:00"
      />
    </div>
  );
}

export default CustomTimeInput;
