import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import styles from './index.module.css';
import {
  Button,
  CategorizedMultiSelect,
  Input,
  TextArea,
  Label,
} from '../../../components/form';
import {
  inviteesSelector,
  invitingUserSelector,
  locationTreeSelector,
  partnerInviteesSelector,
} from '../../../selectors/users';
import {
  declineInvitationRequest,
  inviteUserRequest,
} from '../../../actions/users';
import { inviteUserValidationSelector } from '../../../selectors/validations';
import {
  systemRoles as sr,
  systemRoleIds as srids,
} from '../../../constants/system-role';
import { toIsoString } from '../../../utils/helper';
import { SvgCross, SvgUserPlus } from '../../../components/svg';
import Header from '../header';
import { DatepickerSelect } from '../../../components/date';

function InvitePartner() {
  const [t] = useTranslation('modals', { keyPrefix: 'invitePartner' });

  const [t2] = useTranslation();

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const [message, setMessage] = useState('');

  const [selectedLocation, setSelectedLocation] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
  }, []);

  const handleEndDateChange = useCallback((date) => {
    setEndDate(date);
  }, []);

  const handleTodayAndForwardChange = useCallback(() => {
    handleStartDateChange(new Date());
    handleEndDateChange(null);
  }, [handleEndDateChange, handleStartDateChange]);

  const loading = useSelector(invitingUserSelector);

  const validation = useSelector(inviteUserValidationSelector);

  const organizations = useSelector(locationTreeSelector);

  const invitees = useSelector(inviteesSelector);

  const partnerInvitees = useSelector(partnerInviteesSelector);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(
      inviteUserRequest({
        inviteEmail: email,
        locations: selectedLocation
          .filter((l) => l.indent === 3)
          .map((l) => l.value),
        startAt: toIsoString(startDate),
        expireAt: endDate ? toIsoString(endDate) : null,
        systemRole: sr.partner,
      })
    );
  }, [dispatch, email, endDate, selectedLocation, startDate]);

  const handleDeclineClick = useCallback(
    (id) => {
      dispatch(declineInvitationRequest([id]));
    },
    [dispatch]
  );

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgUserPlus width={24} height={19} />}
        title={t('title')}
      />
      <Label id="user-email" className={styles.label}>
        {t('email')}
        <Input
          dashed={false}
          id="user-email"
          type="text"
          name="email"
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={handleEmailChange}
          validation={validation}
          info={t('emailInfo')}
        />
      </Label>
      <Label id="message" className={styles.label}>
        {t('message')}
        <TextArea
          dashed={false}
          rows={2}
          cols={60}
          name="message"
          placeholder={t('messagePlaceholder')}
          value={message}
          onChange={handleMessageChange}
          validation={validation}
        />
      </Label>
      <Label id="location-access" className={styles.label}>
        {t('locationAccess')}
        <CategorizedMultiSelect
          dashed={false}
          placeholder={t('locationAccessPlaceholder')}
          options={organizations}
          selected={selectedLocation}
          setSelected={setSelectedLocation}
          zIndex={2}
        />
      </Label>
      <Label id="access-period" className={styles.label}>
        {t('accessPeriod')}
        <DatepickerSelect
          dashed={false}
          placeholder={t('accessPeriodPlaceholder')}
          zIndex={1}
          startDate={startDate}
          endDate={endDate}
          setStartDate={handleStartDateChange}
          setEndDate={handleEndDateChange}
          handleTodayAndForwardChange={handleTodayAndForwardChange}
        />
      </Label>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.submit}
          color="secondary"
          containerClassName={styles.submitContainer}
          onClick={handleSubmit}
          type="button"
          loading={loading}
          disabled={false}
        >
          {t('submit')}
        </Button>
      </div>
      {partnerInvitees.length > 0 && (
        <div className={styles.invitees}>
          <div className={styles.title}>{t('invitees')}</div>
          <div className={styles.list}>
            {partnerInvitees.map((invitee, i) => {
              const {
                id,
                invite_email: inviteEmail,
                system_role: systemRole,
                locations,
              } = invitee;

              return (
                <div
                  key={id}
                  className={clsx([
                    styles.invitee,
                    { [styles.odd]: i % 2 === 0 },
                  ])}
                >
                  <div className={styles.email}>{inviteEmail}</div>
                  <div className={styles.details}>
                    <div className={styles.detail}>
                      <Trans
                        t={t}
                        i18nKey="invited"
                        components={{ bold: <b /> }}
                        values={{
                          role: t2(`systemrole.${srids[systemRole]}`),
                          location: locations.map((l) => l.name).join(', '),
                        }}
                      />
                    </div>
                    <SvgCross
                      width={16}
                      height={16}
                      onClick={() => handleDeclineClick(id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </form>
  );
}

export default InvitePartner;
