import { call, put, takeLatest } from 'redux-saga/effects';

import {
  listCategoriesFailure,
  listCategoriesSuccess,
} from '../actions/categories';
import { Categories } from '../api';
import { LIST_CATEGORIES_REQUEST } from '../constants/action-types';

export function* listCategories() {
  try {
    const { body: categories } = yield call(Categories.List);
    yield put(listCategoriesSuccess(categories));
  } catch (e) {
    yield put(listCategoriesFailure(e));
  }
}

export function* watchCategories() {
  yield takeLatest(LIST_CATEGORIES_REQUEST, listCategories);
}
