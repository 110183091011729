import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createZoneRequest } from '../../../actions/zones';
import { Button, ButtonGroup, Input, Label } from '../../../components/form';
import { SvgEdit } from '../../../components/svg';
import { createZoneValidationSelector } from '../../../selectors/validations';
import { creatingZoneSelector } from '../../../selectors/zones';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

type CreateZoneProps = {
  close: () => void;
};

function CreateZone(props: CreateZoneProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'createZone' });

  const submitting = useSelector(creatingZoneSelector);

  const validation = useSelector(createZoneValidationSelector);

  const [name, setName] = useState('');

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    const payload = {
      name,
    };

    dispatch(createZoneRequest(payload));
  }, [dispatch, name]);

  return (
    <>
      <Header icon={<SvgEdit height={20} width={20} />} title={t('title')} />
      <Main className={styles.foldout}>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.name')}
            <Input
              dashed={false}
              name="name"
              onChange={handleNameChange}
              placeholder={t('placeholder.name')}
              type="text"
              validation={validation}
              validationKey="createZone"
              value={name}
            />
          </Label>
        </div>
      </Main>
      <Footer>
        <ButtonGroup>
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default CreateZone;
