import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedLineType } from '../../../../../../actions/segmentation';
import {
  Checkbox,
  Label,
  MultiSelect,
} from '../../../../../../components/form';
import lineTypes from '../../../../../../constants/line-types';
import {
  latestUpdateTimeSelector,
  selectedLineTypeSelector,
} from '../../../../../../selectors/segmentation';

type TypeFilterProps = {
  label: string;
  placeholder?: string;
};

function TypeFilter(props: TypeFilterProps) {
  const { label, placeholder = '' } = props;

  const [t2] = useTranslation();

  const dispatch = useDispatch();

  const latestUpdate = useSelector(latestUpdateTimeSelector);

  const selected = useSelector(selectedLineTypeSelector);

  const options = useMemo(() => {
    return Object.entries(lineTypes).map(([key, value]) => ({
      label: t2(`lineTypes.${value}`),
      value: key,
    }));
  }, [t2]);

  const [filterEnabled, setFilterEnabled] = useState(selected.length > 0);

  const handleChange = useCallback(
    (option: Option) => {
      dispatch(setSelectedLineType(option));
    },
    [dispatch]
  );

  const handleToggleFilter = useCallback(() => {
    if (filterEnabled) {
      dispatch(setSelectedLineType(null));
    }

    setFilterEnabled(!filterEnabled);
  }, [dispatch, filterEnabled]);

  useEffect(() => {
    if (latestUpdate) {
      setFilterEnabled(selected.length > 0);
    }
  }, [latestUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Checkbox
        borderColor="black"
        checked={filterEnabled}
        label={label}
        onClick={handleToggleFilter}
      />
      {filterEnabled && (
        <Label>
          <MultiSelect
            options={options}
            placeholder={placeholder}
            selected={selected}
            setSelected={handleChange}
            zIndex={2}
          />
        </Label>
      )}
    </>
  );
}

export default TypeFilter;
