import {
  HIDE_MODAL,
  SET_CAMERA_BEHAVIOR_SECTION,
  SET_INVITE_COWORKER_SECTION,
  SHOW_MODAL,
} from '../constants/action-types';
import {
  CameraBehaviorSection,
  InviteCoworkerSection,
} from '../reducers/modals';

export const showModal = (
  modalType: string,
  modalProps: Record<string, unknown>
) => ({
  modalProps,
  modalType,
  type: SHOW_MODAL,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const setCameraBehaviorSection = (section: CameraBehaviorSection) => ({
  section,
  type: SET_CAMERA_BEHAVIOR_SECTION,
});

export const setInviteCoworkerSection = (section: InviteCoworkerSection) => ({
  section,
  type: SET_INVITE_COWORKER_SECTION,
});
