import i18next from 'i18next';

import {
  ACTIVATE_CAMERA_SUCCESS,
  ACTIVATE_INVITED_USER_FAILURE,
  ACTIVATE_INVITED_USER_SUCCESS,
  BATCH_UPDATE_CAMERAS_SUCCESS,
  BATCH_UPDATE_USERS_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_CAMERA_SUCCESS,
  CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_ZONE_SUCCESS,
  DEACTIVATE_CAMERA_SUCCESS,
  DELETE_CAMERA_SUCCESS,
  DELETE_FILTER_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_ZONE_SUCCESS,
  GET_USER_FAILURE,
  IMPORT_ACTIVITY_FAILURE,
  IMPORT_ACTIVITY_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_SUCCESS,
  LIST_CAMERAS_FAILURE,
  LIST_LOCATIONS_FAILURE,
  LIST_PERMISSIONS_FAILURE,
  LIST_USER_GROUPS_FAILURE,
  LOCATION_TREE_FAILURE,
  PATCH_USER_INFO_SUCCESS,
  REMOVE_USER_SUCCESS,
  REPLACE_FILTER_SUCCESS,
  SAVE_FILTER_SUCCESS,
  SEND_RESET_PASSWORD_FAILURE,
  UPDATE_CAMERA_SUCCESS,
  UPDATE_FILTER_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_ZONE_SUCCESS,
} from '../constants/action-types';

const i18n = i18next.cloneInstance({
  defaultNS: 'toasts',
});

export function parseMessage(action, context?) {
  switch (action.type) {
    case DELETE_USER_SUCCESS:
      return i18n.t('delete.user', { context });
    case CREATE_USER_SUCCESS:
      return i18n.t('create.user', { context });
    case IMPORT_ACTIVITY_SUCCESS:
      return i18n.t('create.import', { context });
    case SAVE_FILTER_SUCCESS:
      return i18n.t('create.filter', { context });
    case REPLACE_FILTER_SUCCESS:
    case UPDATE_FILTER_SUCCESS:
      return i18n.t('update.filter', { context });
    case DELETE_FILTER_SUCCESS:
      return i18n.t('delete.filter', { context });
    case BATCH_UPDATE_USERS_SUCCESS:
      return i18n.t('update.users', { context });
    case CREATE_ORGANIZATION_SUCCESS:
      return i18n.t('create.organization', { context });
    case DELETE_ORGANIZATION_SUCCESS:
      return i18n.t('delete.organization', { context });
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return i18n.t('cancel.subscription', { context });
    case UPDATE_ORGANIZATION_SUCCESS:
      return i18n.t('patch.organization', { context });
    case DELETE_CAMERA_SUCCESS:
      return i18n.t('delete.camera', { context });
    case UPDATE_CAMERA_SUCCESS:
      return i18n.t('patch.camera', { context });
    case ACTIVATE_CAMERA_SUCCESS:
      return i18n.t('activate.camera', { context });
    case DEACTIVATE_CAMERA_SUCCESS:
      return i18n.t('deactivate.camera', { context });
    case REMOVE_USER_SUCCESS:
      return i18n.t('remove.removed', { email: action.email });
    case INVITE_USER_SUCCESS:
      return i18n.t('invite.invited', { email: action.user.email });
    case ACTIVATE_INVITED_USER_SUCCESS:
      return i18n.t('activate.activated', { context });
    case PATCH_USER_INFO_SUCCESS:
      return i18n.t('update.profile', { context });
    case UPDATE_USER_SUCCESS:
      return i18n.t('update.user', { context });
    case CREATE_CAMERA_SUCCESS:
      return i18n.t('create.camera', { context });
    case CREATE_LOCATION_SUCCESS:
      return i18n.t('create.location', { context });
    case UPDATE_LOCATION_SUCCESS:
      return i18n.t('update.location', { context });
    case DELETE_LOCATION_SUCCESS:
      return i18n.t('delete.location', { context });
    case BATCH_UPDATE_CAMERAS_SUCCESS:
      return i18n.t('batchUpdate.cameras', { context });
    case UPDATE_ZONE_SUCCESS:
      return i18n.t('update.zone', { context });
    case CREATE_ZONE_SUCCESS:
      return i18n.t('create.zone', { context });
    case DELETE_ZONE_SUCCESS:
      return i18n.t('remove.zone', { context });
    case LOCATION_TREE_FAILURE:
    case LIST_CAMERAS_FAILURE:
    case LIST_PERMISSIONS_FAILURE:
    case GET_USER_FAILURE:
    case LIST_USER_GROUPS_FAILURE:
      return i18n.t('refresh', { context });
    // All validation errors should be placed here:
    // case SEND_RESET_PASSWORD_FAILURE: {
    //   const { error, validation } = action;

    //   if (!error.validation) {
    //     return i18n.t('unknown');
    //   }

    //   return '';
    // }
    case IMPORT_ACTIVITY_FAILURE:
    case SEND_RESET_PASSWORD_FAILURE:
    case LIST_LOCATIONS_FAILURE:
    case UPDATE_LOCATION_FAILURE:
    case CREATE_LOCATION_FAILURE:
    case INVITE_USER_FAILURE:
    case ACTIVATE_INVITED_USER_FAILURE: {
      const { error } = action;
      if (error.validation) {
        return !error.validation.field ? action.error.validation.message : '';
      }
      return i18n.t('unknown');
    }

    default:
      return i18n.t('unknown');
  }
}

export function parseLevel(action) {
  if (action.type.endsWith('_SUCCESS')) {
    return 'success';
  }
  if (action.type.endsWith('_FAILURE')) {
    return 'failure';
  }
  return 'warning';
}
