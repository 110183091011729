import clsx from 'clsx';

import { SvgFilter } from '../../components/svg';
import styles from './filter-wrapper.module.css';

type FilterWrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  footer?: JSX.Element;
  icon?: JSX.Element;
  title: string;
};

function FilterWrapper(props: FilterWrapperProps) {
  const {
    children,
    className,
    footer,
    icon = <SvgFilter color="var(--black-40-)" height={16} width={16} />,
    title,
    ...rest
  } = props;

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.title}>
        {icon}
        {title}
      </div>
      {children}
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
}

export default FilterWrapper;
