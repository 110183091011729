import { RootActions } from '../actions';
import {
  IMPORT_ACTIVITY_FAILURE,
  IMPORT_ACTIVITY_REQUEST,
  IMPORT_ACTIVITY_SUCCESS,
  RESET_TOKEN,
} from '../constants/action-types';

export type ImportsState = {
  importingActivity: boolean;
};

export const initialState: ImportsState = {
  importingActivity: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case IMPORT_ACTIVITY_REQUEST:
      return {
        ...state,
        importingActivity: true,
      };
    case IMPORT_ACTIVITY_SUCCESS:
      return {
        ...state,
        importingActivity: false,
      };
    case IMPORT_ACTIVITY_FAILURE:
      return {
        ...state,
        importingActivity: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
