import { createTransform } from 'redux-persist';

import { initialState as appInit } from '../reducers/app';
import { initialState as authInit } from '../reducers/auth';
import { FiltersState } from '../reducers/filters';
import { initialState as organizationsInit } from '../reducers/organizations';
import { initialState as routesInit } from '../reducers/routes';
import { initialState as segmentationInit } from '../reducers/segmentation';

export const appTransform = createTransform(
  (inboundState: any, key) => {
    const { version: skip, ...rest } = appInit;

    return {
      ...inboundState,
      ...rest,
    };
  },

  (outboundState, key) => outboundState,

  { whitelist: ['app'] }
);

export const authTransform = createTransform(
  (inboundState: any, key) => {
    const { token: skip, user: skip2, usergroups: skip3, ...rest } = authInit;

    return {
      ...inboundState,
      ...rest,
    };
  },

  (outboundState, key) => outboundState,

  { whitelist: ['auth'] }
);

export const routesTransform = createTransform(
  (inboundState: any, key) => {
    const {
      lastBehavior: skip2,
      lastOrganization: skip4,
      lastProfile: skip,
      lastSettings: skip3,
      ...rest
    } = routesInit;

    return {
      ...inboundState,
      ...rest,
    };
  },

  (outboundState, key) => outboundState,

  { whitelist: ['routes'] }
);

export const segmentationTransform = createTransform(
  (inboundState: any, key) => {
    const {
      date: skip2,
      endDate: skip10,
      floor: skip5,
      line: skip3,
      lineType: skip12,
      location: skip,
      organization: skip4,
      property: skip11,
      startDate: skip9,
      zoneFrom: skip7,
      zoneTo: skip6,
      ...rest
    } = segmentationInit;

    return {
      ...inboundState,
      ...rest,
    };
  },

  (outboundState, key) => outboundState,

  { whitelist: ['segmentation'] }
);

export const organizationsTransform = createTransform(
  (inboundState: any, key) => {
    const { activeOrganization: skip, ...rest } = organizationsInit;

    return {
      ...inboundState,
      ...rest,
    };
  },

  (outboundState, key) => outboundState,

  { whitelist: ['organizations'] }
);

export const filtersTransform = createTransform(
  (inboundState: FiltersState) => {
    const { filters: skip, selected: skip2, ...rest } = inboundState;

    return {
      ...inboundState,
      ...rest,
    };
  }
);
