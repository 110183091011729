import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip } from '../../../../../../components/miscellaneous';
import SvgImage from '../../../../../../components/svg/image';
import { RootState } from '../../../../../../reducers';
import { singleCameraSelector } from '../../../../../../selectors/cameras';
import { ICamera } from '../../../../../../types/api/cameras';
import { ILine } from '../../../../../../types/api/lines';
import styles from './line-preview.module.css';
import VirtualCanvas from './virtual-canvas';

type LinePreviewProps = {
  id: ILine['id'];
  peopleCounterId: ICamera['id'];
};

function LinePreview(props: LinePreviewProps) {
  const { id, peopleCounterId } = props;

  const camera = useSelector((state: RootState) =>
    singleCameraSelector(state, peopleCounterId)
  );

  const [visible, setVisible] = useState<boolean | false>(false);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const handleMouseOver = useCallback(() => {
    setVisible(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <Tooltip mainAxis={44} placement="right">
      <Tooltip.Trigger asChild>
        <div
          className={styles.container}
          onMouseDown={handleMouseDown}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
        >
          <SvgImage />
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        <VirtualCanvas
          camera={camera}
          lineId={id}
          peopleCounterId={peopleCounterId}
          visible={visible}
        />
      </Tooltip.Content>
    </Tooltip>
  );
}

export default React.memo(LinePreview);
