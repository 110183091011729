import React, { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import clsx from 'clsx';
import styles from './sections.module.css';

type SectionProps = {
  text: string;
  name: string;
  active?: boolean;
  saveActive: (to: string) => void;
  disabled?: boolean;
};

const Section = React.forwardRef(
  (props: SectionProps, ref: Ref<HTMLDivElement>) => {
    const { text, name, active = false, saveActive, disabled = false } = props;

    const handleClick = useCallback(() => {
      saveActive(name);
    }, [saveActive, name]);

    return (
      <div
        className={clsx([
          styles.section,
          {
            [styles.active]: active,
            [styles.disabled]: disabled,
          },
        ])}
        onClick={handleClick}
        ref={ref}
      >
        {text}
      </div>
    );
  }
);

type SectionsProps = {
  items: Omit<SectionProps, 'saveActive'>[];
  saveActive: (to: string) => void;
  className?: string;
  activeName: string;
};

function Sections(props: SectionsProps) {
  const { items, saveActive, className, activeName } = props;

  const refs = [];
  for (let i = 0; i < items.length; i += 1) {
    refs.push(useRef(null));
  }

  // The active route's index.
  const activeIndex = useMemo(() => {
    for (let i = 0; i < items.length; i += 1) {
      const { name } = items[i];

      if (name === activeName) return i;
    }

    return -1;
  }, [activeName, items]);

  const [animIndicator, setIndicator] = useSpring(() => ({
    config: { duration: 200 },
    left: 0,
    opacity: 0,
  }));

  const animText = useSpring({
    config: { duration: 200 },
    to: async (next) => {
      await next({ color: 'black' });
      await next({ color: 'white' });
    },
    from: { color: 'black' },
  });

  const inRanges = activeIndex < items.length && activeIndex > -1;

  useEffect(
    () => {
      if (inRanges) {
        const { offsetLeft } = refs[activeIndex].current;
        setIndicator({ left: offsetLeft, opacity: 1 });
      } else {
        setIndicator({ opacity: 0, config: { duration: 0 } });
      }
    },
    [refs, activeIndex] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className={clsx([styles.sections, className])}>
      <animated.div
        className={styles.indicator}
        style={{
          ...animIndicator,
          pointerEvents: activeIndex === -1 ? 'none' : 'all',
        }}
      >
        <animated.div className={styles.text} style={animText}>
          {inRanges && items[activeIndex].text}
        </animated.div>
      </animated.div>
      {items.map((item, i) => (
        <Section
          disabled={item.disabled}
          saveActive={saveActive}
          ref={refs[i]}
          key={item.name}
          active={activeIndex === i}
          {...item}
        />
      ))}
    </div>
  );
}

export default Sections;
