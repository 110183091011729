import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from '../../selectors/auth';
import { firstLetter } from '../../utils/helper';
import styles from './profile.module.css';
import ProfileDropdown from './profile-dropdown';

function Profile() {
  const [visible, setVisible] = useState<boolean | false>(false);

  const user = useSelector(userSelector);

  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <Tippy
      appendTo={document.body}
      arrow={false}
      content={<ProfileDropdown onClose={() => setVisible(false)} />}
      interactive
      maxWidth="calc(100vw - 10px)"
      onClickOutside={() => setVisible(false)}
      placement="bottom"
      theme="light-border"
      visible={visible}
    >
      <div
        className={clsx(styles.dp, { [styles.active]: visible })}
        onClick={toggleVisibility}
      >
        {firstLetter(user.first_name || user.email)}
      </div>
    </Tippy>
  );
}

export default Profile;
