import { put, takeLatest } from 'redux-saga/effects';

import { showModalToast } from '../actions/modal-toasts';
import {
  ACTIVATE_CAMERA_FAILURE,
  BATCH_UPDATE_CAMERAS_FAILURE,
  BATCH_UPDATE_USERS_FAILURE,
  DEACTIVATE_CAMERA_FAILURE,
  DECLINE_INVITATION_FAILURE,
  INVITE_USER_FAILURE,
  LIST_LOCATIONS_FAILURE,
  REMOVE_USER_FAILURE,
  SEND_INVITATION_FAILURE,
} from '../constants/action-types';
import { MODAL_TOAST_TIMEOUT } from '../constants/timeouts';
import { uid } from '../utils/helper';
import { parseLevel, parseMessage } from '../utils/toasts';

export function* showModalToastWithMessage(action) {
  const id = uid();
  const message = parseMessage(action);
  const level = parseLevel(action);
  const timeout = MODAL_TOAST_TIMEOUT;

  if (!message) return;

  yield put(
    showModalToast({
      id,
      level,
      message,
      timeout,
    })
  );
}

export function* watchModalToasts() {
  yield takeLatest(BATCH_UPDATE_USERS_FAILURE, showModalToastWithMessage);
  yield takeLatest(BATCH_UPDATE_CAMERAS_FAILURE, showModalToastWithMessage);
  yield takeLatest(SEND_INVITATION_FAILURE, showModalToastWithMessage);
  yield takeLatest(DECLINE_INVITATION_FAILURE, showModalToastWithMessage);

  yield takeLatest(INVITE_USER_FAILURE, showModalToastWithMessage);
  yield takeLatest(REMOVE_USER_FAILURE, showModalToastWithMessage);
  yield takeLatest(DEACTIVATE_CAMERA_FAILURE, showModalToastWithMessage);
  yield takeLatest(ACTIVATE_CAMERA_FAILURE, showModalToastWithMessage);
  yield takeLatest(LIST_LOCATIONS_FAILURE, showModalToastWithMessage);
}
