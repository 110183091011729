function SvgSortAsc(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={18}
      height={18}
      {...props}
    >
      <path d="M20 4v12h3l-4 5-4-5h3V4h2Zm-8 14v2H3v-2h9Zm2-7v2H3v-2h11Zm0-7v2H3V4h11Z" />
    </svg>
  );
}
export default SvgSortAsc;
