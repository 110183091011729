import { replace } from 'redux-first-history';
import { call, put, takeLatest } from 'redux-saga/effects';

import pjson from '../../package.json';
import { setAppVersion } from '../actions/app';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  logoutFailure,
  logoutSuccess,
  registerFailure,
  registerRequest,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetToken,
  sendResetPasswordFailure,
  sendResetPasswordRequest,
  sendResetPasswordSuccess,
} from '../actions/auth';
import { Auth } from '../api';
import {
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  REGISTER_REQUEST,
  RESET_PASSWORD_REQUEST,
  SEND_RESET_PASSWORD_REQUEST,
} from '../constants/action-types';
import storeRegistry from '../store/store-registry';
import { parseError } from '../utils/error';
import { validateLogin, validateRegister, validateReset } from '../validations';

export function* login({ email, password }: ReturnType<typeof loginRequest>) {
  try {
    validateLogin(email, password);
    const {
      body: { token, user, usergroups },
    } = yield call(Auth.Login, email, password);
    yield put(loginSuccess(email, token, user, usergroups));
  } catch (e) {
    yield put(loginFailure(e, parseError(e, 'login')));
  }
}

export function* logout() {
  try {
    yield call(Auth.Logout);
    yield put(logoutSuccess());
  } catch (e) {
    yield put(logoutFailure(e));
  } finally {
    yield put(resetToken());
    storeRegistry.persistor.purge();
    yield put(setAppVersion(pjson.version));
  }
}

export function* sendResetPassword({
  email,
}: ReturnType<typeof sendResetPasswordRequest>) {
  try {
    validateReset(email);
    yield call(Auth.SendResetPassword, email);
    // const loggedIn = yield select(loggedInSelector);
    // if (loggedIn) yield put(logoutRequest());
    yield put(sendResetPasswordSuccess(email));
    yield put(replace('/reset-password', { email }));
  } catch (e) {
    yield put(sendResetPasswordFailure(e, parseError(e, 'reset')));
  }
}

export function* resetPassword({
  password,
  post,
  pre,
}: ReturnType<typeof resetPasswordRequest>) {
  try {
    const {
      body: { token, user, usergroups },
    } = yield call(Auth.ResetPassword, pre, post, password);
    yield put(resetPasswordSuccess(token, user, usergroups));
    yield put(replace('/'));
  } catch (e) {
    yield put(resetPasswordFailure(e, parseError(e, 'reset')));
  }
}

export function* register({
  email,
  password,
}: ReturnType<typeof registerRequest>) {
  try {
    validateRegister();
    yield call(Auth.Register, email, password);
    yield put(registerSuccess(email, password));
  } catch (e) {
    yield put(registerFailure(e, parseError(e, 'register')));
  }
}

export function* watchAuth() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(SEND_RESET_PASSWORD_REQUEST, sendResetPassword);
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(REGISTER_REQUEST, register);
}
