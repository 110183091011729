import {
  SET_LAST_BEHAVIOR,
  SET_LAST_ORGANIZATION,
  SET_LAST_PROFILE,
  SET_LAST_SETTINGS,
} from '../constants/action-types';

export const setLastProfile = (path) => ({
  type: SET_LAST_PROFILE,
  path,
});

export const setLastBehavior = (path) => ({
  type: SET_LAST_BEHAVIOR,
  path,
});

export const setLastSettings = (path) => ({
  type: SET_LAST_SETTINGS,
  path,
});

export const setLastOrganization = (path) => ({
  type: SET_LAST_ORGANIZATION,
  path,
});
