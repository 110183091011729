import dayjs from 'dayjs';

import {
  CAMERA_LINE_MULTILINE_FAILURE,
  CAMERA_LINE_MULTILINE_REQUEST,
  CAMERA_LINE_MULTILINE_SUCCESS,
  EXPORT_CHART_AS_IMAGE_FAILURE,
  EXPORT_CHART_AS_IMAGE_REQUEST,
  EXPORT_CHART_AS_IMAGE_SUCCESS,
  LAST_ENTERINGS_FAILURE,
  LAST_ENTERINGS_REQUEST,
  LAST_ENTERINGS_SUCCESS,
  LINE_TYPE_MULTILINE_FAILURE,
  LINE_TYPE_MULTILINE_REQUEST,
  LINE_TYPE_MULTILINE_SUCCESS,
  LOCATION_MULTILINE_FAILURE,
  LOCATION_MULTILINE_REQUEST,
  LOCATION_MULTILINE_SUCCESS,
  RESET_TOKEN,
  TOTAL_ENTERINGS_FAILURE,
  TOTAL_ENTERINGS_REQUEST,
  TOTAL_ENTERINGS_SUCCESS,
  TOTAL_VISITORS_FAILURE,
  TOTAL_VISITORS_REQUEST,
  TOTAL_VISITORS_SUCCESS,
  TOTAL_VISITS_FAILURE,
  TOTAL_VISITS_PREVIOUS_FAILURE,
  TOTAL_VISITS_PREVIOUS_REQUEST,
  TOTAL_VISITS_PREVIOUS_SUCCESS,
  TOTAL_VISITS_REQUEST,
  TOTAL_VISITS_SUCCESS,
  ZONE_FROM_MULTILINE_FAILURE,
  ZONE_FROM_MULTILINE_REQUEST,
  ZONE_FROM_MULTILINE_SUCCESS,
  ZONE_TO_MULTILINE_FAILURE,
  ZONE_TO_MULTILINE_REQUEST,
  ZONE_TO_MULTILINE_SUCCESS,
} from '../constants/action-types';

export const initialState = {
  cameraLineMultilineData: [],
  cameraLineMultilineFailed: false,
  // Camera line multiline
  cameraLineMultilineLoading: false,
  categoryMultilineData: [],
  categoryMultilineFailed: false,
  // Category multiline
  categoryMultilineLoading: false,
  dataCategory: [],
  // Last fetched data's props
  dataDate: null,
  dataEndDate: null,
  dataFloor: [],
  dataLine: [],
  dataLocation: null,

  dataOrganization: null,
  dataProperty: null,

  dataStartDate: null,
  dataType: [],

  dataZoneFrom: [],
  dataZoneTo: [],
  exportingAsImage: false,
  // Last enterings
  lastEnterings: [],
  lastEnteringsLoading: false,
  locationMultilineData: [],

  locationMultilineFailed: false,
  // Location multiline
  locationMultilineLoading: false,
  // Total enterings
  totalEnterings: [],

  totalEnteringsLoading: false,
  // Total visitors
  totalVisitors: 0,
  totalVisitorsLastUpdated: null,

  totalVisitorsLoading: false,
  totalVisitorsPrevious: 0,
  visitorLinePrv: [],

  visitorLinePrvFailed: false,
  visitorLinePrvLoading: false,
  // Visitor line
  visitorLineStd: [],

  visitorLineStdFailed: false,
  visitorLineStdLoading: false,
  zoneFromMultilineData: [],

  zoneFromMultilineFailed: false,
  // Zone from multiline
  zoneFromMultilineLoading: false,
  zoneToMultilineData: [],
  zoneToMultilineFailed: false,

  // Zone to multiline
  zoneToMultilineLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPORT_CHART_AS_IMAGE_REQUEST:
      return {
        ...state,
        exportingAsImage: true,
      };
    case EXPORT_CHART_AS_IMAGE_SUCCESS:
      return {
        ...state,
        exportingAsImage: false,
      };
    case EXPORT_CHART_AS_IMAGE_FAILURE:
      return {
        ...state,
        exportingAsImage: false,
      };
    case LOCATION_MULTILINE_REQUEST:
      return {
        ...state,
        locationMultilineFailed: false,
        locationMultilineLoading: true,
      };
    case LOCATION_MULTILINE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        locationMultilineData: data,
        locationMultilineFailed: false,
        locationMultilineLoading: false,
      };
    }
    case LOCATION_MULTILINE_FAILURE:
      return {
        ...state,
        locationMultilineFailed: true,
        locationMultilineLoading: false,
      };
    case ZONE_FROM_MULTILINE_REQUEST:
      return {
        ...state,
        zoneFromMultilineFailed: false,
        zoneFromMultilineLoading: true,
      };
    case ZONE_FROM_MULTILINE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        zoneFromMultilineData: data,
        zoneFromMultilineFailed: false,
        zoneFromMultilineLoading: false,
      };
    }
    case ZONE_FROM_MULTILINE_FAILURE:
      return {
        ...state,
        zoneFromMultilineFailed: true,
        zoneFromMultilineLoading: false,
      };
    case ZONE_TO_MULTILINE_REQUEST:
      return {
        ...state,
        zoneToMultilineFailed: false,
        zoneToMultilineLoading: true,
      };
    case ZONE_TO_MULTILINE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        zoneToMultilineData: data,
        zoneToMultilineFailed: false,
        zoneToMultilineLoading: false,
      };
    }
    case ZONE_TO_MULTILINE_FAILURE:
      return {
        ...state,
        zoneToMultilineFailed: true,
        zoneToMultilineLoading: false,
      };
    case CAMERA_LINE_MULTILINE_REQUEST:
      return {
        ...state,
        cameraLineMultilineFailed: false,
        cameraLineMultilineLoading: true,
      };
    case CAMERA_LINE_MULTILINE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        cameraLineMultilineData: data,
        cameraLineMultilineFailed: false,
        cameraLineMultilineLoading: false,
      };
    }
    case CAMERA_LINE_MULTILINE_FAILURE:
      return {
        ...state,
        cameraLineMultilineFailed: true,
        cameraLineMultilineLoading: false,
      };
    case TOTAL_VISITORS_REQUEST:
      return {
        ...state,
        totalVisitorsLoading: true,
      };
    case TOTAL_VISITORS_SUCCESS: {
      const { data, previousData } = action;

      return {
        ...state,
        totalVisitors: data,
        totalVisitorsLastUpdated: dayjs().format('HH:mm'),
        totalVisitorsLoading: false,
        totalVisitorsPrevious: previousData,
      };
    }
    case TOTAL_VISITORS_FAILURE:
      return {
        ...state,
        totalVisitorsLoading: false,
      };
    case TOTAL_ENTERINGS_REQUEST:
      return {
        ...state,
        totalEnteringsLoading: true,
      };
    case TOTAL_ENTERINGS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        totalEnterings: data,
        totalEnteringsLoading: false,
      };
    }
    case TOTAL_ENTERINGS_FAILURE:
      return {
        ...state,
        totalEnteringsLoading: false,
      };
    case LAST_ENTERINGS_REQUEST:
      return {
        ...state,
        lastEnteringsLoading: true,
      };
    case LAST_ENTERINGS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        lastEnterings: data,
        lastEnteringsLoading: false,
      };
    }
    case LAST_ENTERINGS_FAILURE:
      return {
        ...state,
        lastEnteringsLoading: false,
      };
    case TOTAL_VISITS_PREVIOUS_REQUEST:
      return {
        ...state,
        visitorLinePrvFailed: false,
        visitorLinePrvLoading: true,
      };
    case TOTAL_VISITS_PREVIOUS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        visitorLinePrv: data,
        visitorLinePrvLoading: false,
      };
    }
    case TOTAL_VISITS_PREVIOUS_FAILURE:
      return {
        ...state,
        visitorLinePrvFailed: true,
        visitorLinePrvLoading: false,
      };
    case LINE_TYPE_MULTILINE_REQUEST:
      return {
        ...state,
        categoryMultilineFailed: false,
        categoryMultilineLoading: true,
      };
    case LINE_TYPE_MULTILINE_SUCCESS: {
      const { data, date, endDate, property, startDate } = action;

      return {
        ...state,
        categoryMultilineData: data,
        categoryMultilineFailed: false,
        categoryMultilineLoading: false,
      };
    }
    case LINE_TYPE_MULTILINE_FAILURE:
      return {
        ...state,
        categoryMultilineFailed: true,
        categoryMultilineLoading: false,
      };
    case TOTAL_VISITS_REQUEST:
      return {
        ...state,
        visitorLinePrv: [],
        visitorLineStdFailed: false,
        visitorLineStdLoading: true,
      };
    case TOTAL_VISITS_SUCCESS: {
      const {
        data,
        date,
        endDate,
        floor,
        line,
        lineType,
        location,
        organization,
        property,
        startDate,
        zoneFrom,
        zoneTo,
      } = action;

      return {
        ...state,
        // (except the ones that we alway show independent of the filters).
        dataDate: date,
        dataEndDate: endDate,

        // This normally is not belong to TOTAL_VISITS but is a global for all charts
        dataFloor: floor,
        dataLine: line,
        dataLocation: location,
        dataOrganization: organization,
        dataProperty: property,
        dataStartDate: startDate,
        dataType: lineType,
        dataZoneFrom: zoneFrom,
        dataZoneTo: zoneTo,
        visitorLineStd: data,
        visitorLineStdFailed: false,
        visitorLineStdLoading: false,
      };
    }
    case TOTAL_VISITS_FAILURE:
      return {
        ...state,
        visitorLineStdFailed: true,
        visitorLineStdLoading: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
