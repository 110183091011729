import clsx from 'clsx';
import styles from './separator.module.css';

type SeparatorProps = {
  className?: string;
  width?: string | number;
};

function Separator(props: SeparatorProps) {
  const { width = 1.5, className } = props;

  return (
    <div
      className={clsx([styles.separator, className])}
      style={{ borderBottomWidth: width }}
    />
  );
}

export default Separator;
