import { RootActions } from '../actions';
import * as industriesActions from '../actions/industries';
import {
  LIST_INDUSTRIES_FAILURE,
  LIST_INDUSTRIES_REQUEST,
  LIST_INDUSTRIES_SUCCESS,
  RESET_TOKEN,
} from '../constants/action-types';
import { IIndustry } from '../types/api/industry';

export type IndustriesState = {
  industries: IIndustry[];
  listingIndustries: boolean;
};

export const initialState: IndustriesState = {
  industries: [],
  listingIndustries: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case LIST_INDUSTRIES_REQUEST:
      return {
        ...state,
        listingIndustries: true,
      };
    case LIST_INDUSTRIES_SUCCESS: {
      const { industries } = action as ReturnType<
        typeof industriesActions.listIndustriesSuccess
      >;

      return {
        ...state,
        industries,
        listingIndustries: false,
      };
    }
    case LIST_INDUSTRIES_FAILURE:
      return {
        ...state,
        listingIndustries: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
