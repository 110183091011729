import { put, takeLatest } from 'redux-saga/effects';

import { showToast } from '../actions/toasts';
import {
  ACTIVATE_CAMERA_SUCCESS,
  ACTIVATE_INVITED_USER_FAILURE,
  ACTIVATE_INVITED_USER_SUCCESS,
  BATCH_UPDATE_CAMERAS_SUCCESS,
  BATCH_UPDATE_USERS_SUCCESS,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_CAMERA_SUCCESS,
  CREATE_LOCATION_SUCCESS,
  CREATE_ORGANIZATION_SUCCESS,
  CREATE_USER_SUCCESS,
  CREATE_ZONE_SUCCESS,
  DEACTIVATE_CAMERA_SUCCESS,
  DELETE_CAMERA_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  DELETE_ZONE_SUCCESS,
  GET_USER_FAILURE,
  IMPORT_ACTIVITY_FAILURE,
  IMPORT_ACTIVITY_SUCCESS,
  INVITE_USER_SUCCESS,
  LIST_CAMERAS_FAILURE,
  LIST_PERMISSIONS_FAILURE,
  LIST_USER_GROUPS_FAILURE,
  LOCATION_TREE_FAILURE,
  PATCH_USER_INFO_FAILURE,
  PATCH_USER_INFO_SUCCESS,
  REMOVE_USER_SUCCESS,
  REPLACE_FILTER_SUCCESS,
  SAVE_FILTER_SUCCESS,
  SEND_RESET_PASSWORD_FAILURE,
  UPDATE_CAMERA_SUCCESS,
  UPDATE_FILTER_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_ZONE_SUCCESS,
} from '../constants/action-types';
import { TOAST_TIMEOUT } from '../constants/timeouts';
import { uid } from '../utils/helper';
import { parseLevel, parseMessage } from '../utils/toasts';

export function* showToastWithMessage(action) {
  const id = uid();
  const message = parseMessage(action);
  const level = parseLevel(action);
  const timeout = TOAST_TIMEOUT;

  if (!message) return;

  yield put(
    showToast({
      id,
      level,
      message,
      timeout,
    })
  );
}

export function* watchToasts() {
  yield takeLatest(CREATE_USER_SUCCESS, showToastWithMessage);
  yield takeLatest(PATCH_USER_INFO_SUCCESS, showToastWithMessage);
  yield takeLatest(UPDATE_USER_SUCCESS, showToastWithMessage);
  yield takeLatest(PATCH_USER_INFO_FAILURE, showToastWithMessage);
  yield takeLatest(SEND_RESET_PASSWORD_FAILURE, showToastWithMessage);
  yield takeLatest(ACTIVATE_INVITED_USER_SUCCESS, showToastWithMessage);
  yield takeLatest(ACTIVATE_INVITED_USER_FAILURE, showToastWithMessage);
  yield takeLatest(INVITE_USER_SUCCESS, showToastWithMessage);
  yield takeLatest(REMOVE_USER_SUCCESS, showToastWithMessage);
  yield takeLatest(ACTIVATE_CAMERA_SUCCESS, showToastWithMessage);
  yield takeLatest(DEACTIVATE_CAMERA_SUCCESS, showToastWithMessage);
  yield takeLatest(UPDATE_CAMERA_SUCCESS, showToastWithMessage);
  yield takeLatest(IMPORT_ACTIVITY_SUCCESS, showToastWithMessage);
  yield takeLatest(UPDATE_FILTER_SUCCESS, showToastWithMessage);
  yield takeLatest(REPLACE_FILTER_SUCCESS, showToastWithMessage);
  yield takeLatest(SAVE_FILTER_SUCCESS, showToastWithMessage);
  // yield takeLatest(UPDATE_ORGANIZATION_FAILURE, showToastWithMessage);
  yield takeLatest(UPDATE_ORGANIZATION_SUCCESS, showToastWithMessage);
  yield takeLatest(CANCEL_SUBSCRIPTION_SUCCESS, showToastWithMessage);
  yield takeLatest(CREATE_ORGANIZATION_SUCCESS, showToastWithMessage);
  yield takeLatest(CREATE_LOCATION_SUCCESS, showToastWithMessage);
  yield takeLatest(UPDATE_LOCATION_SUCCESS, showToastWithMessage);
  yield takeLatest(DELETE_LOCATION_SUCCESS, showToastWithMessage);
  yield takeLatest(BATCH_UPDATE_USERS_SUCCESS, showToastWithMessage);
  yield takeLatest(BATCH_UPDATE_CAMERAS_SUCCESS, showToastWithMessage);
  yield takeLatest(UPDATE_ZONE_SUCCESS, showToastWithMessage);
  yield takeLatest(CREATE_ZONE_SUCCESS, showToastWithMessage);
  yield takeLatest(DELETE_ZONE_SUCCESS, showToastWithMessage);
  yield takeLatest(CREATE_CAMERA_SUCCESS, showToastWithMessage);
  yield takeLatest(DELETE_CAMERA_SUCCESS, showToastWithMessage);
  /* Page could not be loaded. */
  yield takeLatest(LOCATION_TREE_FAILURE, showToastWithMessage);
  yield takeLatest(LIST_CAMERAS_FAILURE, showToastWithMessage);
  yield takeLatest(LIST_PERMISSIONS_FAILURE, showToastWithMessage);
  yield takeLatest(GET_USER_FAILURE, showToastWithMessage);
  yield takeLatest(LIST_USER_GROUPS_FAILURE, showToastWithMessage);
  yield takeLatest(IMPORT_ACTIVITY_FAILURE, showToastWithMessage);
  /* * * * * * * * * * * * * * */
}
