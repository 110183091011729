import { ChangeEvent, useCallback, useRef, useState } from 'react';
import styles from './upload-button.module.css';
import Button, { ButtonProps } from './button';
import clsx from 'clsx';

type UploadButtonProps = ButtonProps & {
  onFileChange: (file: File) => void;
};

function UploadButton(props: UploadButtonProps) {
  const { onFileChange, children, className, ...rest } = props;

  const [fileName, setFileName] = useState(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      setFileName(file.name);
      onFileChange(file);
    },
    [onFileChange]
  );

  const handleButtonClick = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  return (
    <div>
      {fileName && <p className={styles.fileName}>{fileName}</p>}
      <Button
        {...rest}
        className={clsx(className, styles.button)}
        onClick={handleButtonClick}
      >
        {children}
      </Button>
      <input
        accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
        type="file"
      />
    </div>
  );
}

export default UploadButton;
