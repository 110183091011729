import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedDisplayLevel } from '../../../../../actions/segmentation';
import { Label, Select } from '../../../../../components/form';
import { selectedDisplayLevelSelector } from '../../../../../selectors/segmentation';

type SecondVariableProps = {
  label: string;
  placeholder?: string;
};

const secondVariables = [
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
];

function SecondVariable(props: SecondVariableProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const selected = useSelector(selectedDisplayLevelSelector);

  const handleChange = useCallback(
    (displayLevel: Option) => {
      dispatch(setSelectedDisplayLevel(displayLevel));
    },
    [dispatch]
  );

  return (
    <Label>
      {label}
      <Select
        contentEditable={false}
        options={secondVariables}
        placeholder={placeholder}
        selected={selected}
        setSelected={handleChange}
        zIndex={16}
      />
    </Label>
  );
}

export default SecondVariable;
