import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedDate } from '../../../../../../actions/segmentation';
import { Checkbox, Label, Select } from '../../../../../../components/form';
import { dates } from '../../../../../../constants/segments';
import { selectedDateSelector } from '../../../../../../selectors/segmentation';

type DateFilterProps = {
  label: string;
  placeholder?: string;
};

function DateFilter(props: DateFilterProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const selected = useSelector(selectedDateSelector);

  const handleChange = useCallback(
    (organization: Option) => {
      dispatch(setSelectedDate(organization));
    },
    [dispatch]
  );

  return (
    <>
      <Checkbox borderColor="black" checked disabled label={label} />
      <Label>
        <Select
          contentEditable={false}
          options={dates}
          placeholder={placeholder}
          selected={selected}
          setSelected={handleChange}
          zIndex={15}
        />
      </Label>
    </>
  );
}

export default DateFilter;
