import {
  LIST_PERMISSIONS_FAILURE,
  LIST_PERMISSIONS_REQUEST,
  LIST_PERMISSIONS_SUCCESS,
} from '../constants/action-types';

export const listPermissionsRequest = () => ({
  type: LIST_PERMISSIONS_REQUEST,
});

export const listPermissionsSuccess = (permissions) => ({
  type: LIST_PERMISSIONS_SUCCESS,
  permissions,
});

export const listPermissionsFailure = (error) => ({
  type: LIST_PERMISSIONS_FAILURE,
  error,
});
