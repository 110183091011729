import { CSSProperties } from 'react';
import { LOCATION_CHANGE } from 'redux-first-history';

import { RootActions } from '../actions';
import * as modalsActions from '../actions/modals';
import {
  HIDE_MODAL,
  LOGOUT_SUCCESS,
  RESET_TOKEN,
  SET_CAMERA_BEHAVIOR_SECTION,
  SET_INVITE_COWORKER_SECTION,
  SHOW_MODAL,
} from '../constants/action-types';
import { FoldoutSize } from './foldouts';

export type CameraBehaviorSection = 'behavior' | 'premium-brands' | 'settings';

export type InviteCoworkerSection = 'by-email' | 'by-link';

export type ModalsState = {
  cameraBehaviorSection: CameraBehaviorSection;
  inviteCoworkerSection: InviteCoworkerSection;
  isShown: boolean;
  modalProps: CSSProperties & { size: FoldoutSize };
  modalType: string;
};

export const initialState: ModalsState = {
  cameraBehaviorSection: 'behavior',
  inviteCoworkerSection: 'by-email',
  isShown: false,
  modalProps: {} as CSSProperties & { size: FoldoutSize },
  modalType: null,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case SET_INVITE_COWORKER_SECTION: {
      const { section } = action as ReturnType<
        typeof modalsActions.setInviteCoworkerSection
      >;

      return {
        ...state,
        inviteCoworkerSection: section,
      };
    }
    case SET_CAMERA_BEHAVIOR_SECTION: {
      const { section } = action as ReturnType<
        typeof modalsActions.setCameraBehaviorSection
      >;

      return {
        ...state,
        cameraBehaviorSection: section,
      };
    }
    case SHOW_MODAL: {
      const { modalProps, modalType } = action as ReturnType<
        typeof modalsActions.showModal
      >;

      return {
        ...state,
        isShown: true,
        modalProps: modalProps || {},
        modalType,
      };
    }
    case LOGOUT_SUCCESS:
    case LOCATION_CHANGE:
    case HIDE_MODAL:
      return {
        ...state,
        isShown: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
