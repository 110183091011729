import {
  RESET_FILTERS,
  SET_ALL_SELECTED,
  SET_SELECTED_DATE,
  SET_SELECTED_DISPLAY_LEVEL,
  SET_SELECTED_END_DATE,
  SET_SELECTED_FLOOR,
  SET_SELECTED_LINE,
  SET_SELECTED_LINE_TYPE,
  SET_SELECTED_LOCATION,
  SET_SELECTED_ORGANIZATION,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_START_DATE,
  SET_SELECTED_ZONE_FROM,
  SET_SELECTED_ZONE_TO,
} from '../constants/action-types';
import { SegmentationState } from '../reducers/segmentation';

export const setSelectedProperty = (property: Option) => ({
  property,
  type: SET_SELECTED_PROPERTY,
});

export const setSelectedDisplayLevel = (displayLevel: Option) => ({
  displayLevel,
  type: SET_SELECTED_DISPLAY_LEVEL,
});

export const setSelectedDate = (date: Option) => ({
  date,
  type: SET_SELECTED_DATE,
});

export const setSelectedStartDate = (startDate: Date | string) => ({
  startDate,
  type: SET_SELECTED_START_DATE,
});

export const setSelectedEndDate = (endDate: Date | string) => ({
  endDate,
  type: SET_SELECTED_END_DATE,
});

export const setSelectedOrganization = (organization: Option) => ({
  organization,
  type: SET_SELECTED_ORGANIZATION,
});

export const setSelectedLocation = (location: Nullable<Option>) => ({
  location,
  type: SET_SELECTED_LOCATION,
});

export const setSelectedLine = (line: Option) => ({
  line,
  type: SET_SELECTED_LINE,
});

export const setSelectedFloor = (floor: Option) => ({
  floor,
  type: SET_SELECTED_FLOOR,
});

export const setSelectedZoneTo = (zoneTo: Option) => ({
  type: SET_SELECTED_ZONE_TO,
  zoneTo,
});

export const setSelectedZoneFrom = (zoneFrom: Option) => ({
  type: SET_SELECTED_ZONE_FROM,
  zoneFrom,
});

export const setSelectedLineType = (lineType: Option) => ({
  lineType,
  type: SET_SELECTED_LINE_TYPE,
});

export const setAllSelected = (selectedFilters: SegmentationState) => ({
  selectedFilters,
  type: SET_ALL_SELECTED,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});
