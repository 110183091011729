import { RootActions } from '../actions';
import * as linesActions from '../actions/lines';
import {
  LIST_LINES_FAILURE,
  LIST_LINES_REQUEST,
  LIST_LINES_SUCCESS,
  RESET_TOKEN,
} from '../constants/action-types';
import { ILine } from '../types/api/lines';

export type LinesState = {
  lines: ILine[];
  listing: boolean;
};

export const initialState = {
  lines: [],
  listing: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case LIST_LINES_REQUEST:
      return {
        ...state,
        listing: true,
      };
    case LIST_LINES_SUCCESS: {
      const { lines } = action as ReturnType<
        typeof linesActions.listLinesSuccess
      >;

      return {
        ...state,
        lines,
        listing: false,
      };
    }
    case LIST_LINES_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
