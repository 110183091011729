import { t } from 'i18next';

import { vt } from '.';
import { isIpAddress, isPort } from './regex';

export function validateUpdateCamera(payload) {
  const { floor, ip, model, name, port, stream_location: url } = payload;

  if (!name) {
    vt({
      field: 'name',
      message: t('validations.updateCamera.C003'),
    });
  }

  if (!model) {
    vt({
      field: 'model',
      message: t('validations.updateCamera.C007'),
    });
  }

  if (!floor) {
    vt({
      field: 'floor',
      message: t('validations.updateCamera.C008'),
    });
  }

  if (!ip) {
    vt({
      field: 'ip',
      message: t('validations.updateCamera.C004'),
    });
  }

  if (!isIpAddress(ip)) {
    vt({
      field: 'ip',
      message: t('validations.updateCamera.C005'),
    });
  }

  if (!port) {
    vt({
      field: 'port',
      message: t('validations.updateCamera.C009'),
    });
  }

  if (!isPort(port)) {
    vt({
      field: 'port',
      message: t('validations.updateCamera.C010'),
    });
  }

  if (!url) {
    vt({
      field: 'stream_location',
      message: t('validations.updateCamera.C006'),
    });
  }

  // if (!login) {
  //   vt({
  //     field: 'camera-login',
  //     message: t('validations.updateCamera.C001'),
  //   });
  // }

  // if (!password) {
  //   vt({
  //     field: 'camera-password',
  //     message: t('validations.updateCamera.C002'),
  //   });
  // }
}

export function validateCreateCamera(payload) {
  const {
    floor,
    ip,
    location,
    model,
    name,
    port,
    stream_location: url,
  } = payload;

  if (!location) {
    vt({
      field: 'location',
      message: t('validations.createCamera.C001'),
    });
  }

  if (!name) {
    vt({
      field: 'name',
      message: t('validations.createCamera.C003'),
    });
  }

  if (!floor) {
    vt({
      field: 'floor',
      message: t('validations.createCamera.C002'),
    });
  }

  if (!model) {
    vt({
      field: 'model',
      message: t('validations.createCamera.C007'),
    });
  }

  if (!ip) {
    vt({
      field: 'ip',
      message: t('validations.createCamera.C004'),
    });
  }

  if (!isIpAddress(ip)) {
    vt({
      field: 'ip',
      message: t('validations.createCamera.C005'),
    });
  }

  if (!port) {
    vt({
      field: 'port',
      message: t('validations.createCamera.C008'),
    });
  }

  if (!isPort(port)) {
    vt({
      field: 'port',
      message: t('validations.createCamera.C009'),
    });
  }

  if (!url) {
    vt({
      field: 'stream_location',
      message: t('validations.createCamera.C006'),
    });
  }
}
