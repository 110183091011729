import { animated } from 'react-spring';
import styles from './inner-content-loader.module.css';
import { ProgressSlider } from '../miscellaneous';

function InnerContentLoader(
  props: React.HTMLProps<HTMLDivElement> & React.RefAttributes<HTMLDivElement>
) {
  const { ...rest } = props;

  return (
    <animated.div className={styles.container} {...rest}>
      <div className={styles.loader}>
        <ProgressSlider />
        <div className={styles.squares}>
          <div className={styles.title} />
          <div className={styles.subtitle} />
          <div className={styles.content} />
          <div className={styles.twins}>
            <div className={styles.twin} />
            <div className={styles.twin} />
          </div>
        </div>
      </div>
    </animated.div>
  );
}

export default InnerContentLoader;
