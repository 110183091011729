import { dates } from '../constants/segments';

export function dateOptionsByProperty(property: string) {
  switch (property) {
    case '1m':
    case '10m':
    case '30m':
      return dates.slice(0, 3);
    case '1h':
      return dates.slice(0, 3);
    case '1d':
      return dates.slice(0, -2);
    case '1w':
      return [dates[0], ...dates.slice(3)];
    case '1mo':
      return [dates[0], ...dates.slice(5)];
    default:
      return dates;
  }
}
