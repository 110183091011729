import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedFilter } from '../../../../../actions/filters';
import { SvgOptions } from '../../../../../components/svg';
import { filtersSelectedSelector } from '../../../../../selectors/filters';
import { IFilter } from '../../../../../types/api/filters';
import styles from './filter.module.css';
import FilterDropdown from './filter-dropdown';

type FilterProps = {
  filter: IFilter;
};

function Filter(props: FilterProps) {
  const { filter } = props;

  const dispatch = useDispatch();

  const selected = useSelector(filtersSelectedSelector);

  const [visible, setVisible] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleClick = useCallback(() => {
    dispatch(setSelectedFilter(selected !== filter.id ? filter.id : null));
  }, [dispatch, filter.id, selected]);

  return (
    <div
      className={clsx(styles.filter, {
        [styles.active]: selected === filter.id,
        [styles.hover]: hovered,
      })}
      key={filter.id}
    >
      <div
        className={styles.text}
        onClick={handleClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {filter.name}
      </div>
      <Tippy
        // appendTo={document.body}
        arrow={false}
        content={
          <FilterDropdown filter={filter} onClose={() => setVisible(false)} />
        }
        interactive
        offset={[0, 0]}
        // maxWidth="calc(100vw - 10px)"
        onClickOutside={() => setVisible(false)}
        placement="bottom"
        theme="light-border"
        visible={visible}
      >
        <SvgOptions
          className={clsx(styles.options, {
            // [styles.loading]: loading,
            // [styles.visible]: visible,
            [styles.expanded]: visible,
          })}
          onClick={() => setVisible(!visible)}
        />
      </Tippy>
    </div>
  );
}

export default Filter;
