import styles from './nav-wrapper.module.css';

type NavWrapperProps = {
  children: JSX.Element | JSX.Element[];
};

function NavWrapper(props: NavWrapperProps) {
  const { children } = props;

  return <nav className={styles.wrapper}>{children}</nav>;
}

export default NavWrapper;
