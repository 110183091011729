import { LOCATION_CHANGE } from 'redux-first-history';

import {
  ACTIVATE_INVITED_USER_FAILURE,
  BATCH_UPDATE_CAMERAS_FAILURE,
  CREATE_CAMERA_FAILURE,
  CREATE_LOCATION_FAILURE,
  CREATE_USER_FAILURE,
  CREATE_ZONE_FAILURE,
  HIDE_FOLDOUT,
  HIDE_MODAL,
  INVITE_BYLINK_FAILURE,
  INVITE_BYLINK_REQUEST,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  PATCH_USER_INFO_FAILURE,
  REGISTER_FAILURE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_TOKEN,
  RESET_VALIDATION,
  SEND_RESET_PASSWORD_FAILURE,
  SEND_RESET_PASSWORD_REQUEST,
  UPDATE_CAMERA_FAILURE,
  UPDATE_LOCATION_FAILURE,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_USER_FAILURE,
  UPDATE_ZONE_FAILURE,
} from '../constants/action-types';
import { cloneObjectWithEmptyArrayReferences } from '../utils/helper';

export const initialState = {
  activateInvitedUserValidation: [],
  batchUpdateCamerasValidation: [],
  createCameraValidation: [],
  createLocationValidation: [],
  createUserValidation: [],
  createZoneValidation: [],
  inviteUserValidation: [],
  loginValidation: [],
  patchUserInfoValidation: [],
  patchUserValidation: [],
  registerValidation: [],
  resetPasswordValidation: [],
  sendResetValidation: [],
  updateCameraValidation: [],
  updateLocationValidation: [],
  updateOrganizationValidation: [],
  updateUserValidation: [],
  updateZoneValidation: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_VALIDATION: {
      const { validationKey } = action;

      return {
        ...state,
        [`${validationKey}Validation`]: [],
      };
    }
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        updateLocationValidation: action.validation,
      };
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        createLocationValidation: action.validation,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        createUserValidation: action.validation,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserValidation: action.validation,
      };
    case CREATE_ZONE_FAILURE:
      return {
        ...state,
        createZoneValidation: action.validation,
      };
    case UPDATE_ZONE_FAILURE:
      return {
        ...state,
        updateZoneValidation: action.validation,
      };
    case UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        updateOrganizationValidation: action.validation,
      };
    case CREATE_CAMERA_FAILURE:
      return {
        ...state,
        createCameraValidation: action.validation,
      };
    case UPDATE_CAMERA_FAILURE:
      return {
        ...state,
        updateCameraValidation: action.validation,
      };
    case BATCH_UPDATE_CAMERAS_FAILURE:
      return {
        ...state,
        batchUpdateCamerasValidation: action.validation,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordValidation: [],
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordValidation: action.validation,
      };
    case PATCH_USER_INFO_FAILURE:
      return {
        ...state,
        patchUserInfoValidation: action.validation,
      };
    case INVITE_BYLINK_REQUEST:
    case INVITE_USER_REQUEST:
      return {
        ...state,
        inviteUserValidation: [],
      };
    case INVITE_BYLINK_FAILURE:
    case INVITE_USER_FAILURE:
      return {
        ...state,
        inviteUserValidation: action.validation,
      };
    case ACTIVATE_INVITED_USER_FAILURE:
      return {
        ...state,
        activateInvitedUserValidation: action.validation,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registerValidation: action.validation,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginValidation: [],
        sendResetValidation: [],
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginValidation: action.validation,
      };
    case SEND_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        sendResetValidation: [],
      };
    case SEND_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        sendResetValidation: action.validation,
      };
    case LOCATION_CHANGE:
      return cloneObjectWithEmptyArrayReferences(initialState);
    case HIDE_FOLDOUT:
    case HIDE_MODAL:
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
