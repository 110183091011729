function SvgWhy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 457.73 57.136"
      {...props}
    >
      <text
        transform="translate(0 32)"
        fontSize={30}
        fontFamily="Poppins-SemiBold, Poppins"
        fontWeight={600}
      >
        <tspan x={0} y={0}>
          Why Indivd collects Your data
        </tspan>
      </text>
      <path d="M310.304 39.732l151.267.213-.038 2.215-152.926 1.658z" />
    </svg>
  );
}

export default SvgWhy;
