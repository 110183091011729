import { t } from 'i18next';

import { vt } from '.';
import { isHour } from './regex';

export function validateCreateLocation(payload) {
  const {
    city,
    country,
    employees,
    legal_address: address,
    opening_year: openingYear,
    organization,
    placement,
    postcode,
    size,
    timezone_id: timezone,
    type,
    weekdays,
  } = payload;

  if (!type) {
    vt({
      field: 'type',
      message: t('validations.createLocation.C001'),
    });
  }

  if (!organization) {
    vt({
      field: 'organization',
      message: t('validations.createLocation.C002'),
    });
  }

  if (!placement) {
    vt({
      field: 'placement',
      message: t('validations.createLocation.C003'),
    });
  }

  if (!address) {
    vt({
      field: 'legal_address',
      message: t('validations.createLocation.C009'),
    });
  }

  if (!city) {
    vt({
      field: 'city',
      message: t('validations.createLocation.C004'),
    });
  }

  if (!country) {
    vt({
      field: 'country',
      message: t('validations.createLocation.C005'),
    });
  }

  if (!postcode) {
    vt({
      field: 'postcode',
      message: t('validations.createLocation.C006'),
    });
  }

  weekdays.forEach((w) => {
    if (w.is_open) {
      if (!w.opens_at || !isHour(w.opens_at)) {
        vt({
          field: `opening_time_${w.weekday}`,
          message: t('validations.createLocation.C007'),
        });
      }
      if (!w.closes_at || !isHour(w.closes_at)) {
        vt({
          field: `closing_time_${w.weekday}`,
          message: t('validations.createLocation.C008'),
        });
      }
    }
  });
}
