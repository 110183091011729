import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedZoneFrom } from '../../../../../../actions/segmentation';
import {
  Checkbox,
  Label,
  MultiSelect,
} from '../../../../../../components/form';
import {
  latestUpdateTimeSelector,
  selectedZoneFromSelector,
  zoneFilterOptionsSelector,
} from '../../../../../../selectors/segmentation';
import { listingZonesSelector } from '../../../../../../selectors/zones';

type ZoneFromFilterProps = {
  label: string;
  placeholder?: string;
};

function ZoneFromFilter(props: ZoneFromFilterProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const latestUpdate = useSelector(latestUpdateTimeSelector);

  const selected = useSelector(selectedZoneFromSelector);

  const loading = useSelector(listingZonesSelector);

  const options = useSelector(zoneFilterOptionsSelector);

  const [filterEnabled, setFilterEnabled] = useState(selected.length > 0);

  const handleChange = useCallback(
    (option: Option) => {
      dispatch(setSelectedZoneFrom(option));
    },
    [dispatch]
  );

  const handleToggleFilter = useCallback(() => {
    if (filterEnabled) {
      dispatch(setSelectedZoneFrom(null));
    }

    setFilterEnabled(!filterEnabled);
  }, [dispatch, filterEnabled]);

  useEffect(() => {
    if (latestUpdate) {
      setFilterEnabled(selected.length > 0);
    }
  }, [latestUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Checkbox
        borderColor="black"
        checked={filterEnabled}
        label={label}
        onClick={handleToggleFilter}
      />
      {filterEnabled && (
        <Label>
          <MultiSelect
            loading={loading}
            options={options}
            placeholder={placeholder}
            selected={selected}
            setSelected={handleChange}
            zIndex={4}
          />
        </Label>
      )}
    </>
  );
}

export default ZoneFromFilter;
