function SvgGear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={21}
      viewBox="0 0 21 21"
      width={21}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-.5 -.5)"
      >
        <path d="M17.727 13.727a1.5 1.5 0 0 0 .3 1.655l.055.055a1.82 1.82 0 1 1-2.573 2.573l-.055-.055a1.512 1.512 0 0 0-2.564 1.073v.155a1.818 1.818 0 1 1-3.636 0V19.1a1.5 1.5 0 0 0-.982-1.373 1.5 1.5 0 0 0-1.655.3l-.055.055a1.82 1.82 0 1 1-2.573-2.573l.055-.055a1.512 1.512 0 0 0-1.073-2.564h-.153a1.818 1.818 0 0 1 0-3.636H2.9a1.5 1.5 0 0 0 1.373-.982 1.5 1.5 0 0 0-.3-1.655l-.055-.055a1.819 1.819 0 1 1 2.573-2.571l.055.055a1.5 1.5 0 0 0 1.655.3h.073a1.5 1.5 0 0 0 .909-1.373v-.155a1.818 1.818 0 0 1 3.636 0V2.9a1.512 1.512 0 0 0 2.564 1.073l.055-.055a1.82 1.82 0 1 1 2.573 2.573l-.055.055a1.5 1.5 0 0 0-.3 1.655v.073a1.5 1.5 0 0 0 1.373.909h.155a1.818 1.818 0 1 1 0 3.636H19.1a1.5 1.5 0 0 0-1.373.908Z" />
        <circle
          cx={2.727}
          cy={2.727}
          r={2.727}
          transform="translate(8.273 8.273)"
        />
      </g>
    </svg>
  );
}

export default SvgGear;
