import { useSelector } from 'react-redux';

import { HollowDotsSpinner } from '../components/loader';
import { SvgLogo } from '../components/svg';
import {
  fetchingLocationTreeSelector,
  gettingUserSelector,
} from '../selectors/users';
import styles from './page-loader.module.css';

function PageLoader() {
  const loadingUser = useSelector(gettingUserSelector);
  const loadingLocationTree = useSelector(fetchingLocationTreeSelector);

  const loading = loadingUser || loadingLocationTree;

  return (
    loading && (
      <>
        <div className={styles.loader}>
          <SvgLogo color="var(--indivd-orange)" height={150} width={180} />
          <HollowDotsSpinner className={styles.spinner} />
        </div>
      </>
    )
  );
}

export default PageLoader;
