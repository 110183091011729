import { put, takeLatest } from 'redux-saga/effects';

import { hideFoldout as hideFoldoutAction } from '../actions/foldouts';
import {
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/action-types';

export function* hideFoldout() {
  yield put(hideFoldoutAction());
}

export function* watchFoldouts() {
  yield takeLatest(CREATE_LOCATION_SUCCESS, hideFoldout);
  yield takeLatest(UPDATE_LOCATION_SUCCESS, hideFoldout);
  yield takeLatest(DELETE_LOCATION_SUCCESS, hideFoldout);
}
