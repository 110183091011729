import { t } from 'i18next';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setSelectedFilter as setSelectedFilterAction } from '../actions/filters';
import { setAllSelected } from '../actions/segmentation';
import { Lines, Zones } from '../api';
import { SET_SELECTED_FILTER } from '../constants/action-types';
import lineTypes from '../constants/line-types';
import { secondVariables } from '../constants/segments';
import { SegmentationState } from '../reducers/segmentation';
import { filtersSelector } from '../selectors/filters';
import { allLocationsSelector } from '../selectors/locations';
import { organizationsSelector } from '../selectors/organizations';
import { IFilter } from '../types/api/filters';
import { ILine } from '../types/api/lines';
import { ILocation } from '../types/api/locations';
import { IOrganization } from '../types/api/organization';
import { IZone } from '../types/api/zones';
import { dateOptionsByProperty } from '../utils/segmentation';

export function* setSelectedFilter({
  id,
}: ReturnType<typeof setSelectedFilterAction>) {
  const selectedFilters: SegmentationState = {
    date: { label: 'Today', value: 'today' },
    displayLevel: { label: '1', value: '0' },
    endDate: null,
    floor: [],
    latestResetTime: null,
    latestUpdateTime: null,
    line: [],
    lineType: [],
    location: null,
    organization: null,
    property: { label: '1 hour interval', value: '1h' },
    startDate: null,
    zoneFrom: [],
    zoneTo: [],
  };

  if (!id) {
    yield put(setAllSelected(selectedFilters));
    return;
  }

  const filters: IFilter[] = yield select(filtersSelector);

  const filter = filters.find((f) => f.id === id);

  if (filter) {
    const category = filter.field_val.category as string[];
    const lineType = filter.field_val.lineType as string[];
    const date = filter.field_val.date as string;
    const endDate = filter.field_val.endDate as string;
    const floor = filter.field_val.floor as string[];
    const line = filter.field_val.line as string[];
    const location = filter.field_val.location as string;
    const organization = filter.field_val.organization as string;
    const property = filter.field_val.property as string;
    const startDate = filter.field_val.startDate as string;
    const zoneFrom = filter.field_val.zoneFrom as string[];
    const zoneTo = filter.field_val.zoneTo as string[];

    if (property) {
      try {
        selectedFilters.property = secondVariables.find(
          (v) => v.value === property
        );
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (date && property) {
      try {
        const dates = dateOptionsByProperty(property);
        selectedFilters.date = dates.find((d) => d.value === date);
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (startDate) {
      try {
        selectedFilters.startDate = startDate;
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (endDate) {
      try {
        selectedFilters.endDate = endDate;
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (organization) {
      try {
        const organizations: IOrganization[] = yield select(
          organizationsSelector
        );

        selectedFilters.organization = {
          label: organizations.find((o) => o.id.toString() === organization)
            .short_name,
          value: organization,
        };
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (location) {
      try {
        const locations: ILocation[] = yield select(allLocationsSelector);

        selectedFilters.location = {
          label: locations.find((l) => l.id.toString() === location).name,
          value: location,
        };
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (floor) {
      try {
        selectedFilters.floor = floor.map((f) => ({
          label: t(`floors.${f}`),
          value: f.toString(),
        }));
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (zoneFrom || zoneTo) {
      try {
        const { body: zones } = yield call(
          Zones.ListByOrganizationId,
          organization
        );

        if (zoneFrom) {
          selectedFilters.zoneFrom = zoneFrom.map((zf) => ({
            label: (zones as IZone[]).find((z) => String(z.id) === String(zf))
              .name,
            value: zf,
          }));
        }

        if (zoneTo) {
          selectedFilters.zoneTo = zoneTo.map((zt) => ({
            label: (zones as IZone[]).find((z) => String(z.id) === String(zt))
              .name,
            value: zt,
          }));
        }
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (lineType) {
      try {
        selectedFilters.lineType = lineType.map((lt) => ({
          label: t(`lineTypes.${lineTypes[lt]}`),
          value: lt,
        }));
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    if (line) {
      try {
        const payload = {
          category: category?.join(','),
          floor: floor?.join(','),
          location,
          organization,
          zone_from: zoneFrom?.join(','),
          zone_to: zoneTo?.join(','),
        };

        Object.keys(payload).forEach((p) => {
          if (!payload[p]) {
            delete payload[p];
          }
        });

        const { body: lines } = yield call(Lines.List, payload);

        selectedFilters.line = line.map((l) => ({
          label: (lines as ILine[]).find((ln) => String(ln.id) === String(l))
            .name,
          value: l,
        }));
      } catch (e) {
        // TODO: Show warning and ask user to update the filter.
      }
    }

    yield put(setAllSelected(selectedFilters));
  }
}

export function* watchSegmentation() {
  yield takeLatest(SET_SELECTED_FILTER, setSelectedFilter);
}
