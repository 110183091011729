import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import { truncateSentenceEnd } from '../utils/helper';

export const locationsSelector = (state: RootState) =>
  state.locations.locations;

export const selectLocationById = createSelector(
  [locationsSelector, (_, id) => id],
  (locations, id) => locations.find((location) => location.id === id)
);

export const listingLocationsSelector = (state: RootState) =>
  state.locations.listing;

export const listingAllLocationsSelector = (state: RootState) =>
  state.locations.listingAll;

export const allLocationsSelector = (state: RootState) =>
  state.locations.allLocations;

export const locationOptionsSelector = createSelector(
  [allLocationsSelector],
  (locations) =>
    locations.map((location) => ({
      label: `${location.organization.short_name} ${truncateSentenceEnd(
        location.legal_address
      )}`,
      value: location.id.toString(),
    }))
);

export const locationCountSelector = (state: RootState) =>
  state.locations.count;

export const selectedLocationsSelector = (state: RootState) =>
  state.locations.selectedLocations;

export const updatingLocationSelector = (state: RootState) =>
  state.locations.updatingLocation;

export const deletingLocationSelector = (state: RootState) =>
  state.locations.deletingLocation;

export const updatingLocationsSelector = (state: RootState) =>
  state.locations.updatingLocations;

export const creatingLocationSelector = (state: RootState) =>
  state.locations.creating;
