import {
  VISITOR_JOURNEY_FAILURE,
  VISITOR_JOURNEY_REQUEST,
  VISITOR_JOURNEY_SUCCESS,
} from '../constants/action-types';
import { SegmentationState } from '../reducers/segmentation';
import { VisitorJourneyData } from '../reducers/visitor-journey';

export const visitorJourneyRequest = () => ({
  type: VISITOR_JOURNEY_REQUEST,
});

export const visitorJourneySuccess = ({
  data,
  date,
  displayLevel,
  endDate,
  location,
  organization,
  startDate,
}: {
  data: VisitorJourneyData;
  date: SegmentationState['date'];
  displayLevel: SegmentationState['displayLevel'];
  endDate: SegmentationState['endDate'];
  location: SegmentationState['location'];
  organization: SegmentationState['organization'];
  startDate: SegmentationState['startDate'];
}) => ({
  data,
  date,
  displayLevel,
  endDate,
  location,
  organization,
  startDate,
  type: VISITOR_JOURNEY_SUCCESS,
});

export const visitorJourneyFailure = (error: unknown) => ({
  error,
  type: VISITOR_JOURNEY_FAILURE,
});
