import React from 'react';

function SvgCopy(
  props: React.SVGProps<SVGSVGElement>,
  ref: React.LegacyRef<SVGSVGElement>
) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 17.44 18.8" ref={ref} {...props}>
      <path
        d="M14.616 18.8H6.824A2.823 2.823 0 014 15.984v-9.96A2.827 2.827 0 016.824 3.2h7.792a2.823 2.823 0 012.824 2.816v9.96a2.827 2.827 0 01-2.824 2.824zM6.824 4.4A1.626 1.626 0 005.2 6.024v9.96A1.622 1.622 0 006.824 17.6h7.792a1.626 1.626 0 001.624-1.624v-9.96A1.622 1.622 0 0014.616 4.4z"
        fill="var(--black-40-)"
      />
      <path
        d="M5.2 15.6H2.824A2.823 2.823 0 010 12.784v-9.96A2.827 2.827 0 012.824 0h7.792a2.823 2.823 0 012.824 2.816V4.4H6.824A1.626 1.626 0 005.2 6.024zM2.824 1.2A1.626 1.626 0 001.2 2.824v9.96A1.622 1.622 0 002.824 14.4H4V6.024A2.827 2.827 0 016.824 3.2h5.416v-.384A1.622 1.622 0 0010.616 1.2z"
        fill="var(--black-40-)"
      />
    </svg>
  );
}

export default React.forwardRef(SvgCopy);
