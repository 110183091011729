import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { listCategoriesRequest } from '../actions/categories';
import { listFiltersRequest } from '../actions/filters';
import { listIndustriesRequest } from '../actions/industries';
import { listAllLocationsRequest } from '../actions/locations';
import { getUserRequest, locationTreeRequest } from '../actions/users';
import { listZonesRequest } from '../actions/zones';

function Initialize() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserRequest());
    dispatch(listCategoriesRequest());
    dispatch(listZonesRequest());
    dispatch(locationTreeRequest());
    dispatch(listIndustriesRequest());
    dispatch(listAllLocationsRequest());
    dispatch(listFiltersRequest());
  }, [dispatch]);

  return null;
}

export default Initialize;
