import clsx from 'clsx';
import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedEndDate,
  setSelectedStartDate,
} from '../../../../../../actions/segmentation';
import { CustomHeader } from '../../../../../../components/date';
import {
  DateRangePicker,
  Input,
  Label,
} from '../../../../../../components/form';
import i18n from '../../../../../../i18next';
import {
  selectedDateSelector,
  selectedEndDateSelector,
  selectedStartDateSelector,
} from '../../../../../../selectors/segmentation';
import { noop } from '../../../../../../utils/helper';
import styles from './index.module.css';

type CustomDateFilterProps = {
  labelEndDate: string;
  labelStartDate: string;
  placeholderEndDate: string;
  placeholderStartDate: string;
};

function CustomDateFilter(props: CustomDateFilterProps) {
  const {
    labelEndDate,
    labelStartDate,
    placeholderEndDate,
    placeholderStartDate,
  } = props;

  const dispatch = useDispatch();

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const startDateDropdownRef = useRef(null);
  const endDateDropdownRef = useRef(null);

  const selected = useSelector(selectedDateSelector);

  const startDate = useSelector(selectedStartDateSelector);

  const endDate = useSelector(selectedEndDateSelector);

  const [startDateVisible, setStartDateVisible] = useState(false);
  const [endDateVisible, setEndDateVisible] = useState(false);

  const handleStartDateChange = useCallback(
    (date: Date) => {
      dispatch(setSelectedStartDate(date));

      setStartDateVisible(false);

      endDateRef.current.focus();
    },
    [dispatch]
  );

  const handleStartDateKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();

        endDateRef.current.focus();
      }
    },
    []
  );

  const handleStartDateFocus = useCallback(() => {
    setStartDateVisible(true);
  }, []);

  const handleEndDateChange = useCallback(
    (date: Date) => {
      dispatch(setSelectedEndDate(date));

      setEndDateVisible(false);
    },
    [dispatch]
  );

  const handleEndDateFocus = useCallback(() => {
    setEndDateVisible(true);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        startDateRef.current &&
        startDateDropdownRef.current &&
        !startDateRef.current.contains(event.target) &&
        !startDateDropdownRef.current.contains(event.target)
      ) {
        setStartDateVisible(false);
      }

      if (
        endDateRef.current &&
        endDateDropdownRef.current &&
        !endDateRef.current.contains(event.target) &&
        !endDateDropdownRef.current.contains(event.target)
      ) {
        setEndDateVisible(false);
      }
    };
    document.addEventListener('focus', handleClickOutside, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('focus', handleClickOutside, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    selected?.value === 'custom' && (
      <div className={styles.container}>
        <Label className={styles.label}>
          {labelStartDate}
          <div>
            <Input
              autoComplete="off"
              onChange={noop}
              onFocus={handleStartDateFocus}
              onKeyDown={handleStartDateKeyDown}
              placeholder={placeholderStartDate}
              ref={startDateRef}
              type="text"
              value={startDate ? new Date(startDate).toLocaleDateString() : ''}
            />
            <div
              className={clsx(styles.dropdown, styles.start, {
                [styles.visible]: startDateVisible,
              })}
              ref={startDateDropdownRef}
            >
              <DateRangePicker
                disabledKeyboardNavigation
                inline
                locale={i18n.language}
                maxDate={endDate ? new Date(endDate) : new Date()}
                minDate={new Date('2021')}
                onChange={handleStartDateChange}
                openToDate={
                  startDate
                    ? new Date(startDate)
                    : endDate
                    ? new Date(endDate)
                    : new Date()
                }
                renderCustomHeader={(headerProps) => (
                  <CustomHeader label={labelStartDate} {...headerProps} />
                )}
                selected={new Date(startDate)}
                shouldCloseOnSelect={true}
                showTimeInput={false}
              />
            </div>
          </div>
        </Label>

        <Label className={styles.label}>
          {labelEndDate}
          <div>
            <Input
              autoComplete="off"
              onChange={noop}
              onFocus={handleEndDateFocus}
              placeholder={placeholderEndDate}
              ref={endDateRef}
              type="text"
              value={endDate ? new Date(endDate).toLocaleDateString() : ''}
            />
            <div
              className={clsx(styles.dropdown, styles.end, {
                [styles.visible]: endDateVisible,
              })}
              ref={endDateDropdownRef}
            >
              <DateRangePicker
                disabledKeyboardNavigation
                inline
                locale={i18n.language}
                maxDate={new Date()}
                minDate={new Date(startDate) || new Date('2021')}
                onChange={handleEndDateChange}
                openToDate={
                  endDate
                    ? new Date(endDate)
                    : startDate
                    ? new Date(startDate)
                    : new Date()
                }
                renderCustomHeader={(headerProps) => (
                  <CustomHeader label={labelEndDate} {...headerProps} />
                )}
                selected={new Date(endDate)}
                shouldCloseOnSelect={true}
                showTimeInput={false}
              />
            </div>
          </div>
        </Label>
      </div>
    )
  );
}

export default CustomDateFilter;
