import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/form';
import { SvgPeopleCounter } from '../../../components/svg';
import Header from '../header';
import styles from './index.module.css';

type ActivateCameraModalProps = {
  close: () => void;
  deactivate: () => void;
};

function ActivateCameraModal(props: ActivateCameraModalProps) {
  const { close, deactivate } = props;

  const { t } = useTranslation('modals', { keyPrefix: 'activateCamera' });

  const handleDeactivate = useCallback(() => {
    deactivate();
    close();
  }, [close, deactivate]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgPeopleCounter color="#fff" height={17} width={19} />}
        title={t('title')}
      />
      <div className={styles.description}>{t('description')}</div>
      <div className={styles.buttonGroup}>
        <Button
          color="primaryPositive"
          onClick={handleDeactivate}
          type="button"
        >
          {t('cancel')}
        </Button>
        <Button
          className={styles.submit}
          color="positive"
          containerClassName={styles.submitContainer}
          disabled={false}
          onClick={close}
          type="button"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default ActivateCameraModal;
