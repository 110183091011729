function SvgTip(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      {...props}
    >
      <g transform="translate(-915 -168)">
        <circle
          cx={15}
          cy={15}
          r={15}
          transform="translate(915 168)"
          fill="#64c2eb"
        />
        <g
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M924 188.625a1.875 1.875 0 0 1 1.875-1.875H936" />
          <path d="M925.875 175.5H936v15h-10.125a1.875 1.875 0 0 1-1.875-1.875v-11.25a1.875 1.875 0 0 1 1.875-1.875Z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgTip;
