import { call, put, takeLatest } from 'redux-saga/effects';

import {
  createLocationFailure,
  createLocationRequest,
  createLocationSuccess,
  deleteLocationFailure,
  deleteLocationRequest,
  deleteLocationSuccess,
  listAllLocationsFailure,
  listAllLocationsSuccess,
  listLocationsFailure,
  listLocationsRequest,
  listLocationsSuccess,
  updateLocationFailure,
  updateLocationRequest,
  updateLocationSuccess,
} from '../actions/locations';
import { setLastSettings } from '../actions/routes';
import { locationTreeRequest } from '../actions/users';
import { Locations } from '../api';
import {
  CREATE_LOCATION_REQUEST,
  DELETE_LOCATION_REQUEST,
  LIST_ALL_LOCATIONS_REQUEST,
  LIST_LOCATIONS_REQUEST,
  UPDATE_LOCATION_REQUEST,
} from '../constants/action-types';
import { parseError } from '../utils/error';
import { pruneEmptyValues } from '../utils/helper';
import { validateCreateLocation } from '../validations/locations';

export function* listAllLocations() {
  try {
    const { body: locations } = yield call(Locations.ListAll);
    yield put(listAllLocationsSuccess(locations));
  } catch (e) {
    yield put(listAllLocationsFailure(e));
  }
}

export function* listLocations({
  limit,
  offset,
}: ReturnType<typeof listLocationsRequest>) {
  try {
    const {
      body: { count, results },
    } = yield call(Locations.List, limit, offset);
    yield put(listLocationsSuccess(results, count));

    const url = new URL(window.location.href);

    if (url.pathname.startsWith('/settings/locations')) {
      window.history.replaceState(
        null,
        '',
        `/settings/locations/${limit}/${offset}`
      );

      yield put(setLastSettings(`/settings/locations/${limit}/${offset}`));
    }
  } catch (e) {
    yield put(listLocationsFailure(e));
  }
}

export function* updateLocation({
  id,
  location: payload,
}: ReturnType<typeof updateLocationRequest>) {
  try {
    pruneEmptyValues(payload);
    validateCreateLocation(payload);
    const { body: location } = yield call(Locations.Update, id, payload);
    yield put(updateLocationSuccess(location));
    yield put(locationTreeRequest());
  } catch (e) {
    yield put(updateLocationFailure(e, parseError(e)));
  }
}

export function* createLocation({
  location: payload,
}: ReturnType<typeof createLocationRequest>) {
  try {
    pruneEmptyValues(payload);
    validateCreateLocation(payload);
    const { body: location } = yield call(Locations.Create, payload);
    yield put(createLocationSuccess(location));
    yield put(locationTreeRequest());
  } catch (e) {
    yield put(createLocationFailure(e, parseError(e)));
  }
}

export function* deleteLocation({
  id,
}: ReturnType<typeof deleteLocationRequest>) {
  try {
    yield call(Locations.DeleteLocation, id);
    yield put(deleteLocationSuccess(id));
    yield put(locationTreeRequest());
  } catch (e) {
    yield put(deleteLocationFailure(e));
  }
}

export function* watchLocations() {
  yield takeLatest(LIST_LOCATIONS_REQUEST, listLocations);
  yield takeLatest(UPDATE_LOCATION_REQUEST, updateLocation);
  yield takeLatest(CREATE_LOCATION_REQUEST, createLocation);
  yield takeLatest(DELETE_LOCATION_REQUEST, deleteLocation);
  yield takeLatest(LIST_ALL_LOCATIONS_REQUEST, listAllLocations);
}
