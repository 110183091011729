import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import clsx from 'clsx';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateOrganizationRequest } from '../../../actions/organizations';
import {
  Button,
  Checkbox,
  Input,
  Label,
  Select,
} from '../../../components/form';
import { HollowDotsSpinner } from '../../../components/loader';
import iso3166 from '../../../constants/iso3166-1.alpha2';
import { ADMIN, SUPERUSER, VIEWER } from '../../../constants/roles';
import {
  activeOrganizationSelector,
  updatingOrganizationSelector,
} from '../../../selectors/organizations';
import { userRoleSelector } from '../../../selectors/users';
import { updateOrganizationValidationSelector } from '../../../selectors/validations';
import styles from './billing.module.css';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

function Billing() {
  const [t] = useTranslation('organization', { keyPrefix: 'billing' });

  const dispatch = useDispatch();

  const role = useSelector(userRoleSelector);

  const autocomplete = useRef(null);

  const autocompleteCity = useRef(null);

  const addressRef = useRef<HTMLInputElement>(null);

  const organization = useSelector(activeOrganizationSelector);

  const validation = useSelector(updateOrganizationValidationSelector);

  const updating = useSelector(updatingOrganizationSelector);

  const [country, setCountry] = useState(
    organization.country
      ? {
          label: iso3166[organization.country],
          value: organization.country,
        }
      : null
  );

  const [email, setEmail] = useState(organization.email);

  const [refname, setRefname] = useState(organization.ref);

  const [postcode, setPostcode] = useState(organization.postcode);

  const [address, setAddress] = useState(organization.legal_address);

  const [city, setCity] = useState(organization.city);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries,
  });

  const onLoad = (_autocomplete) => {
    autocomplete.current = _autocomplete;
  };

  const onLoadCity = (_autocomplete) => {
    autocompleteCity.current = _autocomplete;
  };

  const onPlaceChanged = useCallback(() => {
    if (autocomplete.current !== null) {
      const { address_components: comps } = autocomplete.current.getPlace();

      let countryIndex = 0;
      let placeCountry = '';
      let placeCity = '';
      let placePostcode = '';
      comps.forEach((comp, i) => {
        const type = comp.types.join(';');
        if (type === 'country;political') {
          placeCountry = comp.short_name;
          countryIndex = i;
        }
        if (type === 'postal_code') {
          placePostcode = comp.short_name;
        }
        if (type === 'postal_town') {
          placeCity = comp.short_name;
        }
        if (!placeCity) {
          if (type === 'locality;political') {
            placeCity = comp.short_name;
          }
          if (type === 'administrative_area_level_1;political') {
            placeCity = comp.short_name;
          }
        }
      });

      let streetAddress = '';
      for (let i = 0; i < countryIndex; i += 1) {
        if (!streetAddress) {
          streetAddress += comps[i].short_name;
        } else {
          streetAddress = `${streetAddress}, ${comps[i].short_name}`;
        }
      }

      if (placeCountry) {
        setCountry({
          label: iso3166[placeCountry],
          value: placeCountry,
        });
      }

      if (placeCity) {
        setCity(placeCity);
      }

      if (placePostcode) {
        setPostcode(placePostcode);
      }

      if (streetAddress) {
        setAddress(streetAddress);
      }
    }
  }, []);

  const onCityChange = useCallback(() => {
    if (autocompleteCity.current !== null) {
      const { address_components: comps } = autocompleteCity.current.getPlace();

      let placeCity = '';
      comps.forEach((comp) => {
        const type = comp.types.join(';');
        if (type === 'locality;political') {
          placeCity = comp.short_name;
        }
        if (!placeCity && type === 'administrative_area_level_1;political') {
          placeCity = comp.short_name;
        }
      });

      if (placeCity) {
        setCity(placeCity);
      }
    }
  }, []);

  const handleAddressChange = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleCityChange = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleRefnameChange = useCallback((e) => {
    setRefname(e.target.value);
  }, []);

  const handlePostcodeChange = useCallback((e) => {
    setPostcode(e.target.value);
  }, []);

  // const handleCancelSubscriptionClick = useCallback(() => {
  //   dispatch(
  //     showModal(CANCEL_SUBSCRIPTION_MODAL, {
  //       size: 'sm',
  //       backgroundColor: 'var(--error)',
  //       organization,
  //     })
  //   );
  // }, [dispatch, organization]);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      const payload = {
        city,
        country: country?.value,
        email,
        legal_address: address,
        postcode,
        ref: refname,
      };

      dispatch(updateOrganizationRequest(payload));
    },
    [address, city, country, dispatch, email, postcode, refname]
  );

  useEffect(() => {
    setCountry(
      organization.country
        ? {
            label: iso3166[organization.country],
            value: organization.country,
          }
        : null
    );
    setEmail(organization.email);
    setRefname(organization.ref);
    setPostcode(organization.postcode);
    setAddress(organization.legal_address);
    setCity(organization.city);
  }, [organization]);

  if (!isLoaded) {
    return (
      <div className={styles.loader}>
        <HollowDotsSpinner className={styles.spinner} />
      </div>
    );
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <section className={styles.section}>
        <div className={styles.column}>
          <div className={styles.pro}>
            <Checkbox
              borderColor="white"
              checked
              className={styles.checkbox}
              disabled
            />
            {t('pro')}
          </div>
          <Label className={clsx(styles.label, styles.disabled)}>
            {t('activatedCameras')}
            <Input
              containerClass={styles.disabledInput}
              dashed={false}
              disabled
              name="activatedCameras"
              type="text"
              value={organization.active_camera_count}
            />
          </Label>
          {/* <Label className={clsx(styles.label, styles.disabled)}>
            {t('monthlyPrice')}
            <Input
              containerClass={styles.disabledInput}
              type="text"
              name="monthlyPrice"
              value={`${organization.monthly_price} ${organization.currency}`}
              disabled
              dashed={false}
            />
          </Label> */}
          <Label className={styles.label}>
            {t('billingEmail')}
            <Input
              disabled={role === VIEWER}
              name="email"
              onChange={handleEmailChange}
              placeholder={t('billingEmailPlaceholder')}
              type="email"
              validation={validation}
              validationKey="updateOrganization"
              value={email}
            />
          </Label>
          <Label className={styles.label}>
            {t('refname')}
            <Input
              disabled={role === VIEWER}
              name="ref"
              onChange={handleRefnameChange}
              placeholder={t('refnamePlaceholder')}
              type="text"
              validation={validation}
              validationKey="updateOrganization"
              value={refname}
            />
          </Label>
        </div>
        <div className={styles.column}>
          <Label className={styles.label}>
            {t('address')}
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              // options={streeAddressOptions}
            >
              <Input
                disabled={role === VIEWER}
                name="legal_address"
                onChange={handleAddressChange}
                placeholder={t('addressPlaceholder')}
                ref={addressRef}
                type="text"
                validation={validation}
                validationKey="updateOrganization"
                value={address}
              />
            </Autocomplete>
          </Label>
          <Label className={styles.label}>
            {t('city')}
            <Autocomplete
              onLoad={onLoadCity}
              onPlaceChanged={onCityChange}
              options={{
                types: ['(cities)'],
                // componentRestrictions: country
                //   ? { country: country.value }
                //   : {},
              }}
            >
              <Input
                disabled={role === VIEWER}
                name="city"
                onChange={handleCityChange}
                placeholder={t('cityPlaceholder')}
                type="text"
                validation={validation}
                validationKey="updateOrganization"
                value={city}
              />
            </Autocomplete>
          </Label>
          <div className={styles.row}>
            <Label className={styles.label}>
              {t('country')}
              <Select
                disabled={role === VIEWER}
                name="country"
                options={Object.entries(iso3166).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))}
                placeholder={t('countryPlaceholder')}
                selected={country}
                setSelected={setCountry}
                validation={validation}
                validationKey="updateOrganization"
                zIndex={1}
              />
            </Label>
            <Label className={styles.label}>
              {t('postcode')}
              <Input
                disabled={role === VIEWER}
                name="postcode"
                onChange={handlePostcodeChange}
                placeholder={t('postcodePlaceholder')}
                type="text"
                validation={validation}
                validationKey="updateOrganization"
                value={postcode}
              />
            </Label>
          </div>
          {/* <div className={styles.cancel}>
          <SvgTrash width={14} height={16} />
          <span
            className={styles.action}
            onClick={handleCancelSubscriptionClick}
          >
            {t('cancel')}
          </span>
        </div> */}
        </div>
      </section>
      {(role === SUPERUSER || role === ADMIN) && (
        <div className={styles.footer}>
          <Button color="secondary" loading={updating} type="submit">
            {t('apply')}
          </Button>
        </div>
      )}
    </form>
  );
}

export default Billing;
