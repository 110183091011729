import { LOCATION_CHANGE } from 'redux-first-history';

import { RootActions } from '../actions';
import * as foldoutsActions from '../actions/foldouts';
import {
  FOLDOUT_STATE_CHANGED,
  HIDE_FOLDOUT,
  LOGOUT_SUCCESS,
  RESET_TOKEN,
  SHOW_FOLDOUT,
} from '../constants/action-types';

export type FoldoutSize = 'lg' | 'md' | 'md-lg' | 'sm' | 'tn';

export type FoldoutsState = {
  changed: boolean;
  foldoutProps: Record<string, unknown>;
  foldoutShown: boolean;
  foldoutSize: FoldoutSize;
  foldoutType: string;
};

export const initialState = {
  changed: false,
  foldoutProps: {},
  foldoutShown: false,
  foldoutSize: 'md',
  foldoutType: null,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case SHOW_FOLDOUT: {
      const { foldoutProps, foldoutSize, foldoutType } = action as ReturnType<
        typeof foldoutsActions.showFoldout
      >;

      return {
        ...state,
        foldoutProps: foldoutProps || {},
        foldoutShown: true,
        foldoutSize,
        foldoutType,
      };
    }
    case LOGOUT_SUCCESS:
    case LOCATION_CHANGE:
    case HIDE_FOLDOUT:
      return {
        ...state,
        changed: false,
        foldoutShown: false,
      };
    case FOLDOUT_STATE_CHANGED: {
      const { changed } = action as ReturnType<
        typeof foldoutsActions.foldoutStateChanged
      >;

      return {
        ...state,
        changed,
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
