import { HIDE_MODAL_TOAST, SHOW_MODAL_TOAST } from '../constants/action-types';
import { MODAL_TOAST_TIMEOUT } from '../constants/timeouts';
import { ModalLevel } from '../reducers/modal-toasts';
import { uid } from '../utils/helper';

export const hideModalToast = () => ({
  type: HIDE_MODAL_TOAST,
});

export const showModalToast = ({
  id = uid(),
  level,
  message,
  reloadable = false,
  timeout = MODAL_TOAST_TIMEOUT,
}: {
  id?: string;
  level: ModalLevel;
  message: string;
  reloadable?: boolean;
  timeout?: number;
}) => ({
  id,
  level,
  message,
  reloadable,
  timeout,
  type: SHOW_MODAL_TOAST,
});
