import {
  ACTIVATE_CAMERA_FAILURE,
  ACTIVATE_CAMERA_REQUEST,
  ACTIVATE_CAMERA_SUCCESS,
  BATCH_UPDATE_CAMERAS_FAILURE,
  BATCH_UPDATE_CAMERAS_REQUEST,
  BATCH_UPDATE_CAMERAS_SUCCESS,
  CLEAR_CAMERA_SELECTIONS,
  CREATE_CAMERA_FAILURE,
  CREATE_CAMERA_REQUEST,
  CREATE_CAMERA_SUCCESS,
  DEACTIVATE_CAMERA_FAILURE,
  DEACTIVATE_CAMERA_REQUEST,
  DEACTIVATE_CAMERA_SUCCESS,
  DELETE_CAMERA_FAILURE,
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_SUCCESS,
  GET_CAMERA_FAILURE,
  GET_CAMERA_REQUEST,
  GET_CAMERA_SUCCESS,
  LIST_CAMERAS_FAILURE,
  LIST_CAMERAS_REQUEST,
  LIST_CAMERAS_SUCCESS,
  SELECT_CAMERA_ALL,
  SELECT_CAMERA_WITH_CTRL,
  SELECT_CAMERA_WITH_SHIFT,
  SELECT_CAMERA_WITHOUT,
  UPDATE_CAMERA_FAILURE,
  UPDATE_CAMERA_REQUEST,
  UPDATE_CAMERA_SUCCESS,
  UPDATE_CAMERAS_FAILURE,
  UPDATE_CAMERAS_REQUEST,
  UPDATE_CAMERAS_SUCCESS,
} from '../constants/action-types';
import { ICamera } from '../types/api/cameras';
import { ILineWithZoneIds } from '../types/api/lines';

export const listCamerasRequest = (
  limit: number,
  offset: number,
  order?: string,
  search?: string
) => ({
  limit,
  offset,
  order,
  search,
  type: LIST_CAMERAS_REQUEST,
});

export const listCamerasSuccess = (cameras: ICamera[], count: number) => ({
  cameras,
  count,
  type: LIST_CAMERAS_SUCCESS,
});

export const listCamerasFailure = (error: unknown) => ({
  error,
  type: LIST_CAMERAS_FAILURE,
});

export const getCameraRequest = (id: ICamera['id']) => ({
  id,
  type: GET_CAMERA_REQUEST,
});

export const getCameraSuccess = (camera: ICamera) => ({
  camera,
  type: GET_CAMERA_SUCCESS,
});

export const getCameraFailure = (id: ICamera['id'], error: unknown) => ({
  error,
  id,
  type: GET_CAMERA_FAILURE,
});

export const deactivateCameraRequest = (id: ICamera['id']) => ({
  id,
  type: DEACTIVATE_CAMERA_REQUEST,
});

export const deactivateCameraSuccess = (id: ICamera['id']) => ({
  id,
  type: DEACTIVATE_CAMERA_SUCCESS,
});

export const deactivateCameraFailure = (error: unknown) => ({
  error,
  type: DEACTIVATE_CAMERA_FAILURE,
});

export const activateCameraRequest = (id: ICamera['id']) => ({
  id,
  type: ACTIVATE_CAMERA_REQUEST,
});

export const activateCameraSuccess = (id: ICamera['id']) => ({
  id,
  type: ACTIVATE_CAMERA_SUCCESS,
});

export const activateCameraFailure = (error: unknown) => ({
  error,
  type: ACTIVATE_CAMERA_FAILURE,
});

export const updateCameraRequest = (
  id: ICamera['id'],
  camera: Partial<Omit<ICamera, 'zones'>> & {
    zones: ILineWithZoneIds[];
  }
) => ({
  camera,
  id,
  type: UPDATE_CAMERA_REQUEST,
});

export const updateCameraSuccess = (
  id: ICamera['id'],
  camera: Partial<ICamera>
) => ({
  camera,
  id,
  type: UPDATE_CAMERA_SUCCESS,
});

export const updateCameraFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: UPDATE_CAMERA_FAILURE,
  validation,
});

export const batchUpdateCamerasRequest = (
  ids: ICamera['id'][],
  fieldName: string,
  value: string
) => ({
  fieldName,
  ids,
  type: BATCH_UPDATE_CAMERAS_REQUEST,
  value,
});

export const batchUpdateCamerasSuccess = (
  ids: ICamera['id'][],
  fieldName: string,
  value: string
) => ({
  fieldName,
  ids,
  type: BATCH_UPDATE_CAMERAS_SUCCESS,
  value,
});

export const batchUpdateCamerasFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: BATCH_UPDATE_CAMERAS_FAILURE,
  validation,
});

export const selectCameraWithCtrl = (id: ICamera['id']) => ({
  id,
  type: SELECT_CAMERA_WITH_CTRL,
});

export const selectCameraWithShift = (id: ICamera['id']) => ({
  id,
  type: SELECT_CAMERA_WITH_SHIFT,
});

export const selectCameraWithout = (id: ICamera['id']) => ({
  id,
  type: SELECT_CAMERA_WITHOUT,
});

export const selectCameraAll = () => ({
  type: SELECT_CAMERA_ALL,
});

export const clearCameraSelections = () => ({
  type: CLEAR_CAMERA_SELECTIONS,
});

export const updateCamerasRequest = (data: Partial<ICamera>) => ({
  data,
  type: UPDATE_CAMERAS_REQUEST,
});

export const updateCamerasSuccess = (data: Partial<ICamera>) => ({
  data,
  type: UPDATE_CAMERAS_SUCCESS,
});

export const updateCamerasFailure = (error: unknown) => ({
  error,
  type: UPDATE_CAMERAS_FAILURE,
});

export const createCameraRequest = (
  payload: Partial<Omit<ICamera, 'zones'>> & {
    zones?: ILineWithZoneIds[];
  }
) => ({
  payload,
  type: CREATE_CAMERA_REQUEST,
});

export const createCameraSuccess = (camera: Partial<ICamera>) => ({
  camera,
  type: CREATE_CAMERA_SUCCESS,
});

export const createCameraFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: CREATE_CAMERA_FAILURE,
  validation,
});

export const deleteCameraRequest = (id: ICamera['id']) => ({
  id,
  type: DELETE_CAMERA_REQUEST,
});

export const deleteCameraSuccess = (id: ICamera['id']) => ({
  id,
  type: DELETE_CAMERA_SUCCESS,
});

export const deleteCameraFailure = (error: unknown) => ({
  error,
  type: DELETE_CAMERA_FAILURE,
});
