export const sendResetValidationSelector = (state) =>
  state.validations.sendResetValidation;

export const resetPasswordValidationSelector = (state) =>
  state.validations.resetPasswordValidation;

export const loginValidationSelector = (state) =>
  state.validations.loginValidation;

export const activateInvitedUserValidationSelector = (state) =>
  state.validations.activateInvitedUserValidation;

export const patchUserValidationSelector = (state) =>
  state.validations.patchUserValidation;

export const inviteUserValidationSelector = (state) =>
  state.validations.inviteUserValidation;

export const batchUpdateCamerasValidationSelector = (state) =>
  state.validations.batchUpdateCamerasValidation;

export const updateCameraValidationSelector = (state) =>
  state.validations.updateCameraValidation;

export const createCameraValidationSelector = (state) =>
  state.validations.createCameraValidation;

export const updateZoneValidationSelector = (state) =>
  state.validations.updateZoneValidation;

export const updateUserValidationSelector = (state) =>
  state.validations.updateUserValidation;

export const createUserValidationSelector = (state) =>
  state.validations.createUserValidation;

export const createLocationValidationSelector = (state) =>
  state.validations.createLocationValidation;

export const updateLocationValidationSelector = (state) =>
  state.validations.updateLocationValidation;

export const createZoneValidationSelector = (state) =>
  state.validations.createZoneValidation;

export const patchUserInfoValidationSelector = (state) =>
  state.validations.patchUserInfoValidation;

export const updateOrganizationValidationSelector = (state) =>
  state.validations.updateOrganizationValidation;
