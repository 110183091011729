import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setInviteCoworkerSection } from '../../../actions/modals';
import {
  declineInvitationRequest,
  inviteBylinkRequest,
  inviteUserRequest,
} from '../../../actions/users';
import {
  Button,
  CategorizedMultiSelect,
  Input,
  Label,
  RadioButton,
  TextArea,
} from '../../../components/form';
import { SvgCross, SvgUserPlus } from '../../../components/svg';
import {
  systemRoles as sr,
  systemRoleIds as srids,
} from '../../../constants/system-role';
import { inviteCoworkerSectionSelector } from '../../../selectors/modals';
import {
  coWorkerInviteesSelector,
  invitingUserSelector,
  locationTreeSelector,
} from '../../../selectors/users';
import { inviteUserValidationSelector } from '../../../selectors/validations';
import { toIsoString } from '../../../utils/helper';
import Header from '../header';
import styles from './index.module.css';

const ALLOWED = {
  ANY_EMAIL: 0,
  ONLY_EMAIL: 1,
};

function InviteCoworker() {
  const { t } = useTranslation('modals', { keyPrefix: 'inviteCoworker' });

  const { t: t2 } = useTranslation();

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const [message, setMessage] = useState('');

  const [selectedLocation, setSelectedLocation] = useState([]);

  const loading = useSelector(invitingUserSelector);

  const validation = useSelector(inviteUserValidationSelector);

  const organizations = useSelector(locationTreeSelector);

  const ics = useSelector(inviteCoworkerSectionSelector);

  // const invitees = useSelector(inviteesSelector);

  const coWorkerInvitees = useSelector(coWorkerInviteesSelector);

  const [allowedEmail, setAllowedEmail] = useState('');

  const [allowed, setAllowed] = useState(ALLOWED.ANY_EMAIL);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleAllowedEmailChange = useCallback((e) => {
    setAllowedEmail(e.target.value);
  }, []);

  const handleAllowedChange = useCallback((e, target) => {
    switch (target) {
      case ALLOWED.ANY_EMAIL:
        setAllowed(ALLOWED.ANY_EMAIL);
        break;
      case ALLOWED.ONLY_EMAIL:
        setAllowed(ALLOWED.ONLY_EMAIL);
        break;
      default:
    }
  }, []);

  const handleSubmit = useCallback(() => {
    switch (ics) {
      case 'by-link':
        dispatch(
          inviteBylinkRequest({
            expireAt: null,
            inviteEmail: allowed === ALLOWED.ANY_EMAIL ? null : allowedEmail,
            locations: selectedLocation
              .filter((l) => l.indent === 3)
              .map((l) => l.value),
            startAt: toIsoString(new Date()),
            systemRole: sr.coWorker,
          })
        );
        break;
      case 'by-email':
      default:
        dispatch(
          inviteUserRequest({
            expireAt: null,
            inviteEmail: email,
            locations: selectedLocation
              .filter((l) => l.indent === 3)
              .map((l) => l.value),
            startAt: toIsoString(new Date()),
            systemRole: sr.coWorker,
          })
        );
    }
  }, [ics, dispatch, allowed, allowedEmail, selectedLocation, email]);

  const handleDeclineClick = useCallback(
    (id) => {
      dispatch(declineInvitationRequest([id]));
    },
    [dispatch]
  );

  const setInviteSection = useCallback(
    (section) => {
      dispatch(setInviteCoworkerSection(section));
    },
    [dispatch]
  );

  return (
    <form className={styles.container}>
      <Header icon={<SvgUserPlus height={19} width={24} />} title={t('title')}>
        <div className={styles.sections}>
          <div
            className={clsx([
              styles.section,
              {
                [styles.active]: ics === 'by-email',
              },
            ])}
            onClick={() => setInviteSection('by-email')}
            role="link"
            tabIndex={0}
          >
            {t('byEmail')}
          </div>
          <div
            className={clsx([
              styles.section,
              { [styles.active]: ics === 'by-link' },
            ])}
            onClick={() => setInviteSection('by-link')}
            role="link"
            tabIndex={0}
          >
            {t('byLink')}
          </div>
        </div>
      </Header>
      {
        {
          'by-email': (
            <>
              <Label className={styles.label}>
                {t('email')}
                <Input
                  dashed={false}
                  info={t('emailInfo')}
                  name="email"
                  onChange={handleEmailChange}
                  placeholder={t('emailPlaceholder')}
                  type="text"
                  validation={validation}
                  value={email}
                />
              </Label>
              <Label className={styles.label}>
                {t('message')}
                <TextArea
                  cols={60}
                  dashed={false}
                  name="message"
                  onChange={handleMessageChange}
                  placeholder={t('messagePlaceholder')}
                  rows={2}
                  validation={validation}
                  value={message}
                />
              </Label>
              <Label className={styles.label}>
                {t('locationAccess')}
                <CategorizedMultiSelect
                  dashed={false}
                  options={organizations}
                  placeholder={t('locationAccessPlaceholder')}
                  selected={selectedLocation}
                  setSelected={setSelectedLocation}
                  zIndex={2}
                />
              </Label>
            </>
          ),
          'by-link': (
            <>
              <div className={styles.info}>{t('info')}</div>
              <RadioButton
                checked={allowed === ALLOWED.ANY_EMAIL}
                className={clsx([styles.label, styles.heightless])}
                label={t('allowEmail')}
                onChange={(e) => handleAllowedChange(e, ALLOWED.ANY_EMAIL)}
              />
              <RadioButton
                checked={allowed === ALLOWED.ONLY_EMAIL}
                className={clsx([styles.label, styles.heightless])}
                label={
                  <>
                    {t('onlyAllow')}
                    <Input
                      containerClass={styles.onlyEmail}
                      dashed={false}
                      onChange={handleAllowedEmailChange}
                      placeholder={t('onlyAllowPlaceholder')}
                      value={allowedEmail}
                    />
                    {t('emailAddress')}
                  </>
                }
                onChange={(e) => handleAllowedChange(e, ALLOWED.ONLY_EMAIL)}
              />
              <Label className={styles.label}>
                {t('locationAccess')}
                <CategorizedMultiSelect
                  dashed={false}
                  options={organizations}
                  placeholder={t('locationAccessPlaceholder')}
                  selected={selectedLocation}
                  setSelected={setSelectedLocation}
                  zIndex={2}
                />
              </Label>
            </>
          ),
        }[ics]
      }
      <div className={styles.buttonContainer}>
        <Button
          className={styles.submit}
          color="secondary"
          containerClassName={styles.submitContainer}
          disabled={false}
          loading={loading}
          onClick={handleSubmit}
          type="button"
        >
          {t('submit', { context: ics })}
        </Button>
      </div>
      {coWorkerInvitees.length > 0 && (
        <div className={styles.invitees}>
          <div className={styles.title}>{t('invitees')}</div>
          <div className={styles.list}>
            {coWorkerInvitees.map((invitee, i) => {
              const {
                id,
                invite_email: inviteEmail,
                locations,
                system_role: systemRole,
              } = invitee;

              return (
                <div
                  className={clsx([
                    styles.invitee,
                    { [styles.odd]: i % 2 === 0 },
                  ])}
                  key={id}
                >
                  <div className={styles.email}>{inviteEmail}</div>
                  <div className={styles.details}>
                    <div className={styles.detail}>
                      <Trans
                        components={{ bold: <b /> }}
                        i18nKey="invited"
                        t={t}
                        values={{
                          location: locations.map((l) => l.name).join(', '),
                          role: t2(`systemrole.${srids[systemRole]}`),
                        }}
                      />
                    </div>
                    <SvgCross
                      height={16}
                      onClick={() => handleDeclineClick(id)}
                      width={16}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </form>
  );
}

export default InviteCoworker;
