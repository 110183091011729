import enGB from 'date-fns/locale/en-GB';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

registerLocale('en-GB', enGB);

function DateRangePicker(props: ReactDatePickerProps) {
  const { ...rest } = props;

  return <DatePicker {...rest} />;
}

export default DateRangePicker;
