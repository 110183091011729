function SvgTrashLight(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#fff' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14.217 15.686"
      {...props}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 3.437h13.217M12.249 3.437v10.28a1.469 1.469 0 01-1.469 1.469H3.438a1.469 1.469 0 01-1.469-1.469V3.437m2.2 0V1.969A1.469 1.469 0 015.64.5h2.937a1.469 1.469 0 011.469 1.469v1.468" />
      </g>
    </svg>
  );
}

export default SvgTrashLight;
