import dayjs from 'dayjs';

import { FULL_HEIGHT, FULL_WIDTH } from '../constants/canvas';

export function uid() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

// Based on https://abhishekdutta.org/blog/standalone_uuid_generator_in_javascript.html
// IE11 and Modern Browsers Only
export function uuid4() {
  const tempUrl = URL.createObjectURL(new Blob());
  const uuid = tempUrl.toString();
  URL.revokeObjectURL(tempUrl);
  return uuid.split(/[:\/]/g).pop().toLowerCase(); // remove prefixes
}

export function randomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function compareValues(
  key: number | string,
  order: 'asc' | 'desc' = 'asc',
  prioritized = false
) {
  return function innerSort(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a, key)) {
      return 0;
    }
    if (!Object.prototype.hasOwnProperty.call(b, key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    if (prioritized) {
      if (varA === prioritized && varB === prioritized) {
        return 0;
      }
      if (varA === prioritized) {
        return -1;
      }
      if (varB === prioritized) {
        return 1;
      }
    }

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export function ceil(value: number, precision = 2) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.ceil(value * multiplier) / multiplier;
}

/**
 * Formats 1000 as 1k, 900 as 900, -23414 as -23k and, so on.
 *
 * @export
 * @param {number} num
 * @returns {string|number}
 */
export function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? `${ceil(Math.sign(num) * (Math.abs(num) / 1000), 1)}k`
    : Math.sign(num) * Math.abs(num);
}

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  // outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export function hexToRgbA(hex, alpha = '1') {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )},${alpha})`; // eslint-disable-line no-bitwise
  }
  throw new Error('Bad Hex');
}

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

export function toIsoString(date) {
  // const tzo = -date.getTimezoneOffset();
  // const dif = tzo >= 0 ? '+' : '-';
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${
    pad(date.getSeconds())
    // }${dif}${pad(tzo / 60)}:${pad(tzo % 60)}`;
    // TODO: set above solution for dynamic timezone and remove the +02:00 below
  }+01:00`;
}

export function areArraysEqual(a, b) {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function percentageDifference(a, b) {
  if (a === 0 && b === 0) {
    return 0;
  }

  if (a === 0 && b > 0) {
    return -100;
  }

  if (b === 0 && a > 0) {
    return 100;
  }

  const diff = a - b;
  const percentageDiff = (diff * 100) / b;
  return percentageDiff;
}

export function noop() {}

/**
 *
 * @param {['today', 'yesterday', 'last7days']} date
 */
export function extractStartEndDates(date, includeToday = false) {
  switch (date) {
    case 'yesterday': {
      const start = new Date();
      start.setDate(start.getDate() - 1);
      start.setHours(0, 0, 0, 0);

      let end;
      if (includeToday) {
        end = new Date();
        end.setHours(23, 59, 59, 999);
      } else {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(23, 59, 59, 999);
        end = yesterday;
      }

      return {
        endDate: toIsoString(end),
        startDate: toIsoString(start),
      };
    }
    case 'last7days': {
      const start = new Date();
      start.setDate(start.getDate() - 7);
      start.setHours(0, 0, 0, 0);

      let end;
      if (includeToday) {
        end = new Date();
        end.setHours(23, 59, 59, 999);
      } else {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(23, 59, 59, 999);
        end = yesterday;
      }

      return {
        endDate: toIsoString(end),
        startDate: toIsoString(start),
      };
    }
    case 'last30days': {
      const start = new Date();
      start.setDate(start.getDate() - 30);
      start.setHours(0, 0, 0, 0);

      const end = new Date();

      return {
        endDate: toIsoString(end),
        startDate: toIsoString(start),
      };
    }
    case 'today':
    default: {
      const start = new Date();
      start.setHours(0, 0, 0, 0);

      const end = new Date();
      end.setHours(23, 59, 59, 999);

      return {
        endDate: toIsoString(end),
        startDate: toIsoString(start),
      };
    }
  }
}

export function setNodataDays(groups) {
  const dist = {};
  groups.forEach((group) => {
    dist[group] = 0;
  });
  return dist;
}

export function calculatePercentOf(x, y) {
  if (x === 0 || (x > 0 && y === 0)) {
    return 0;
  }
  return Math.abs(100 - (y * 100) / x);
}

// TODO: this should be removed when the open close time is become dynamic.
export function isStoreClosed(data) {
  switch (data.variable.toLowerCase()) {
    case 'sun':
      return data.group > 16 || data.group < 11;
    case 'sat':
      return data.group > 17;
    default:
      return false;
  }
}

export function copy(o) {
  const out = Array.isArray(o) ? [] : {};
  for (const key of o) {
    const v = o[key];
    out[key] = typeof v === 'object' && v !== null ? copy(v) : v;
  }
  return out;
}

export function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function calculateDaysBetweenDates(startDate: Date, endDate: Date) {
  if (startDate && endDate) {
    const diff = startDate.getTime() - endDate.getTime();
    const totalDaysBetweenDates = Math.ceil(
      Math.abs(diff / (1000 * 3600 * 24))
    );

    return totalDaysBetweenDates;
  }

  return 0;
}

type Chunk = '1' | '5' | '10' | '30';

type AggregateBy =
  | 'dayofmonth,month,year'
  | 'hour,dayofmonth,month,year'
  | 'minute,hour,dayofmonth,month,year'
  | 'month,year'
  | 'week,month,year';

// Returns [startDate, endDate]
export function calculatePreviousPeriod(
  startDate: string,
  endDate: string
): [string, string] {
  const diff =
    dayjs(endDate).toDate().getTime() - dayjs(startDate).toDate().getTime();
  const days = diff / (1000 * 3600 * 24) || 1;

  return [
    dayjs(startDate).subtract(days, 'days').format('YYYY-MM-DD'),
    dayjs(endDate).subtract(days, 'days').format('YYYY-MM-DD'),
  ];
}

export function segmentationPropertyToAggregatedBy(
  selectedProperty: SelectedProperty
): [AggregateBy, Chunk] {
  switch (selectedProperty) {
    case '1m':
      return ['minute,hour,dayofmonth,month,year', '1'];
    case '10m':
      return ['minute,hour,dayofmonth,month,year', '10'];
    case '30m':
      return ['minute,hour,dayofmonth,month,year', '30'];
    case '1h':
      return ['hour,dayofmonth,month,year', null];
    case '1d':
      return ['dayofmonth,month,year', null];
    case '1w':
      return ['week,month,year', null];
    case '1mo':
      return ['month,year', null];
  }
}

// Returns [startDate, endDate]
export function segmentationDateToRndDate(
  selectedDate: SelectedDate,
  startDate?: Date | string,
  endDate?: Date | string
): [string, string] {
  switch (selectedDate) {
    case 'custom': {
      const start = dayjs(startDate).format('YYYY-MM-DD');

      const end = dayjs(endDate).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'today': {
      const start = dayjs().tz('Europe/Stockholm', false).format('YYYY-MM-DD');
      const end = dayjs().tz('Europe/Stockholm', false).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'yesterday': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      now.setDate(now.getDate() - 1);

      const start = dayjs(now)
        .tz('Europe/Stockholm', false)
        .format('YYYY-MM-DD');
      const end = dayjs(now).tz('Europe/Stockholm', false).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisWeek': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
      const diff = now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1);

      const firstDay = new Date(now.setDate(diff));
      const lastDay = new Date(now.setDate(now.getDate() - now.getDay() + 7));

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisMonth': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastWeek': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const weekAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );

      const firstDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 1)
      );
      const lastDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 7)
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastMonth': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const monthAgo = new Date(now.getFullYear(), now.getMonth() - 1, 1);

      const firstDay = new Date(monthAgo.getFullYear(), monthAgo.getMonth(), 1);
      const lastDay = new Date(
        monthAgo.getFullYear(),
        monthAgo.getMonth() + 1,
        0
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisYear': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const firstDay = new Date(now.getFullYear(), 0, 1);
      const lastDay = new Date(now.getFullYear(), 11, 31);

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastYear': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const firstDay = new Date(now.getFullYear() - 1, 0, 1);
      const lastDay = new Date(now.getFullYear() - 1, 12, 0);

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    default:
      return ['', ''];
  }
}

// Returns [startDate, endDate]
export function segmentationDateToPreviousRndDate(
  selectedDate: SelectedDate,
  startDate?: Date,
  endDate?: Date
): [string, string] {
  switch (selectedDate) {
    case 'custom': {
      const diff =
        dayjs(endDate).toDate().getTime() - dayjs(startDate).toDate().getTime();
      const days = diff / (1000 * 3600 * 24) || 1;

      return [
        dayjs(startDate).subtract(days, 'days').format('YYYY-MM-DD'),
        dayjs(endDate).subtract(days, 'days').format('YYYY-MM-DD'),
      ];
    }
    case 'today': {
      const start = dayjs()
        .subtract(1, 'days')
        .tz('Europe/Stockholm', false)
        .format('YYYY-MM-DD');
      const end = dayjs()
        .subtract(1, 'days')
        .tz('Europe/Stockholm', false)
        .format('YYYY-MM-DD');

      return [start, end];
    }
    case 'yesterday': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      now.setDate(now.getDate() - 2);

      const start = dayjs(now)
        .tz('Europe/Stockholm', false)
        .format('YYYY-MM-DD');
      const end = dayjs(now).tz('Europe/Stockholm', false).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisWeek': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const weekAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );

      const firstDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 1)
      );
      const lastDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 7)
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisMonth': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const monthAgo = new Date(now.getFullYear(), now.getMonth() - 1, 1);

      const firstDay = new Date(monthAgo.getFullYear(), monthAgo.getMonth(), 1);
      const lastDay = new Date(
        monthAgo.getFullYear(),
        monthAgo.getMonth() + 1,
        0
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastWeek': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const weekAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 14
      );

      const firstDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 1)
      );
      const lastDay = new Date(
        weekAgo.setDate(weekAgo.getDate() - weekAgo.getDay() + 7)
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastMonth': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const monthAgo = new Date(now.getFullYear(), now.getMonth() - 2, 1);

      const firstDay = new Date(monthAgo.getFullYear(), monthAgo.getMonth(), 1);
      const lastDay = new Date(
        monthAgo.getFullYear(),
        monthAgo.getMonth() + 1,
        0
      );

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'thisYear': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const firstDay = new Date(now.getFullYear() - 1, 0, 1);
      const lastDay = new Date(now.getFullYear() - 1, 12, 0);

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    case 'lastYear': {
      const now = dayjs().tz('Europe/Stockholm', false).toDate();

      const firstDay = new Date(now.getFullYear() - 2, 0, 1);
      const lastDay = new Date(now.getFullYear() - 2, 12, 0);

      const start = dayjs(firstDay).format('YYYY-MM-DD');
      const end = dayjs(lastDay).format('YYYY-MM-DD');

      return [start, end];
    }
    default:
      return ['', ''];
  }
}

export function segmentationDateToFormat(
  selectedProperty: SelectedProperty
): string {
  switch (selectedProperty) {
    case '1m':
    case '10m':
    case '30m':
      return 'HH:mm';
    case '1h':
      return 'HH';
    case '1d':
      return 'DD';
    case '1w':
      return 'ww';
    case '1mo':
      return 'MMM';
  }
}

export function segmentationDateToTooltipFormat(
  selectedProperty: SelectedProperty
) {
  switch (selectedProperty) {
    case '1m':
    case '10m':
    case '30m':
      return 'DD MMM YYYY HH:mm';
    case '1h':
      return 'DD MMM YYYY HH:mm';
    case '1d':
      return 'DD MMM YYYY';
    case '1w':
      return 'DD MMM YYYY';
    case '1mo':
      return 'MMMM YYYY';
    default:
      return 'DD-MM-YYYY';
  }
}

/**
 * Truncates "text" from the end of the string. Algorithm looks
 * for a dot and a space (". ") after the "from"th and before
 * the "to"th characters to determine end of the sentence. If
 * dot does not exist between the range then sentence will be
 * truncated after the comma and if comma is not exist as well as
 * then it will be truncated from a space (" ") and dot will
 * be added to the end.
 */
export const truncateSentenceEnd = (
  text: string,
  from = 150,
  to = 200,
  rate = 10
) => {
  if (text && text.length > from) {
    let truncIndex = -1;

    let i = 0;
    let isComma = false;
    while (truncIndex === -1) {
      if (from === 50 && to === text.length) {
        return text;
      }

      from = from - rate * i > 50 ? from - rate * i : 50;
      to = to + rate * i < text.length ? to + rate * i : text.length;

      const range = text.slice(from, to);

      // Search for a dot at the end.
      truncIndex = range.indexOf('. ');

      // Start checking for a comma after 3rd iteration.
      if (truncIndex === -1 && i >= 2) {
        truncIndex = range.indexOf(', ');

        if (truncIndex !== -1) {
          isComma = true;
        }
      }

      i++;
    }

    return `${text.slice(0, from + truncIndex)}`;
  }

  return text;
};

export function firstLetter(username: string): string {
  if (username.length <= 2) {
    return username;
  }

  const words = username.split(' ').slice(0, 2);

  return words.reduce((acc, word) => acc + word[0].toUpperCase(), '');
}

export function limitDraggibilityWithPoints(points: number[]): number[] {
  return points.map((point, index) => {
    const isX = index % 2 === 0;

    if (isX) {
      if (point <= 0) return 0;
      if (point >= FULL_WIDTH) return FULL_WIDTH;
    } else {
      if (point <= 0) return 0;
      if (point >= FULL_HEIGHT) return FULL_HEIGHT;
    }
  });
}

export function limitDraggibilityByPosition(
  {
    x,
    y,
  }: {
    x: number;
    y: number;
  },
  x2?: number,
  y2?: number
): {
  x: number;
  y: number;
} {
  let minX = 0;
  let maxX = FULL_WIDTH;

  let minY = 0;
  let maxY = FULL_HEIGHT;

  if (x2 < 0) minX += Math.abs(x2);
  if (x2 > 0) maxX -= Math.abs(x2);

  if (y2 < 0) minY += Math.abs(y2);
  if (y2 > 0) maxY -= Math.abs(y2);

  if (x <= minX) x = minX;
  if (x >= maxX) x = maxX;
  if (y <= minY) y = minY;
  if (y >= maxY) y = maxY;

  return { x, y };
}

export function dateToWeekNumberOfYear(date: Date) {
  const currentdate = new Date(date);
  const oneJan = new Date(currentdate.getFullYear(), 0, 1).getTime();
  const numberOfDays = Math.floor(
    (currentdate.getTime() - oneJan) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
}

export function calculateEstimatedElementWidth(text: string, sortable = false) {
  const temporary = document.getElementById('temporary');
  const span = document.createElement('span');

  span.innerHTML = text;
  span.style.font = "'Poppins', sans-serif";
  span.style.fontSize = '14px';
  span.style.fontWeight = '700';
  span.style.visibility = 'hidden';

  temporary.appendChild(span);

  const width = span.offsetWidth;

  temporary.removeChild(span);

  return sortable ? width + 22 : width;
}

export function removeEmptyValues(obj: { [key: string]: unknown }) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] == null) {
      delete obj[key];
    }
  });
  return obj;
}

export function cloneObjectWithEmptyArrayReferences(obj: {
  [key: string]: unknown;
}) {
  const result = {};
  for (const key in obj) {
    if (Array.isArray(obj[key]) && (obj[key] as unknown[]).length === 0) {
      result[key] = obj[key];
    } else if (typeof obj[key] === 'object') {
      result[key] = cloneObjectWithEmptyArrayReferences(
        obj[key] as { [key: string]: unknown }
      );
    } else {
      result[key] = obj[key];
    }
  }
  return result;
}

export function throttle(func, limit) {
  let lastCall;
  return function (...args) {
    const now = Date.now();
    if (!lastCall || now - lastCall >= limit) {
      lastCall = now;
      func(...args);
    }
  };
}

export type TStrength =
  | 'Acceptable'
  | 'Secure'
  | 'Strong'
  | 'Unavailable'
  | 'Weak';

export function calculateStrength(password: string): [number, TStrength] {
  let count = 0;

  if (/[a-z]/.test(password)) count++; // check for lowercase letters
  if (/[A-Z]/.test(password)) count++; // check for uppercase letters
  if (/\d/.test(password)) count++; // check for numbers
  if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) count++; // check for special characters

  switch (count) {
    case 0:
      return [count, 'Unavailable'];
    case 1:
      return [count, 'Weak'];
    case 2:
      return [count, 'Acceptable'];
    case 3:
      return [count, 'Strong'];
    case 4:
      return [count, 'Secure'];
    default:
      return [count, 'Weak'];
  }
}

export function hasUppercaseLowercase(str: string): boolean {
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  if (str.match(lowerCaseLetters) && str.match(upperCaseLetters)) {
    return true;
  }
  return false;
}

export function trimStartDash(str: string): string {
  if (str && str.startsWith('-')) {
    return str.substring(1);
  }
  return str;
}

export function pruneEmptyValues(payload) {
  Object.keys(payload).forEach((key) => {
    if (!payload[key]) {
      delete payload[key];
    }
  });
}

export function weekdayByName(day) {
  switch (day) {
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
    case 'sunday':
      return 7;
  }
}

export function weekdayByNumber(day) {
  switch (day) {
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    case 7:
      return 'sunday';
  }
}
