import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listLinesRequest } from '../../../../../../actions/lines';
import { setSelectedLine } from '../../../../../../actions/segmentation';
import {
  Checkbox,
  Label,
  MultiSelect,
} from '../../../../../../components/form';
import { listingLinesSelector } from '../../../../../../selectors/lines';
import {
  latestUpdateTimeSelector,
  lineFilterOptionsWithoutBufferLinesSelector,
  selectedFloorSelector,
  selectedLineSelector,
  selectedLineTypeSelector,
  selectedLocationSelector,
  selectedOrganizationSelector,
  selectedZoneFromSelector,
  selectedZoneToSelector,
} from '../../../../../../selectors/segmentation';
import LinePreview from './line-preview';

type LineFilterProps = {
  label: string;
  placeholder?: string;
};

function LineFilter(props: LineFilterProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const latestUpdate = useSelector(latestUpdateTimeSelector);

  const selected = useSelector(selectedLineSelector);

  const loading = useSelector(listingLinesSelector);

  const options = useSelector(lineFilterOptionsWithoutBufferLinesSelector);

  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const selectedLocation = useSelector(selectedLocationSelector);

  const selectedFloor = useSelector(selectedFloorSelector);

  const selectedZoneTo = useSelector(selectedZoneToSelector);

  const selectedZoneFrom = useSelector(selectedZoneFromSelector);

  const selectedLineType = useSelector(selectedLineTypeSelector);

  const [filterEnabled, setFilterEnabled] = useState(selected.length > 0);

  const handleChange = useCallback(
    (option: Option) => {
      dispatch(setSelectedLine({ label: option.label, value: option.value }));
    },
    [dispatch]
  );

  const handleToggleFilter = useCallback(() => {
    if (filterEnabled) {
      dispatch(setSelectedLine(null));
    }

    setFilterEnabled(!filterEnabled);
  }, [dispatch, filterEnabled]);

  useEffect(() => {
    dispatch(listLinesRequest());
  }, [
    dispatch,
    selectedOrganization,
    selectedLocation,
    selectedFloor,
    selectedZoneTo,
    selectedZoneFrom,
    selectedLineType,
  ]);

  useEffect(() => {
    if (latestUpdate) {
      setFilterEnabled(selected.length > 0);
    }
  }, [latestUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Checkbox
        borderColor="black"
        checked={filterEnabled}
        label={label}
        onClick={handleToggleFilter}
      />
      {filterEnabled && (
        <Label>
          <MultiSelect
            loading={loading}
            options={options.map((option) => ({
              ...option,
              children: (
                <LinePreview
                  id={option.value}
                  peopleCounterId={option.peopleCounterId}
                />
              ),
              value: option.value.toString(),
            }))}
            placeholder={placeholder}
            selected={selected}
            setSelected={handleChange}
            zIndex={1}
          />
        </Label>
      )}
    </>
  );
}

export default LineFilter;
