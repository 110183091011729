function SvgRadio(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 30 20"
      {...props}
    >
      <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
        <path d="M17.321 10.48a3 3 0 11-3-3 3 3 0 013 3zM20.182 4.619a8.294 8.294 0 010 11.736M8.46 16.341a8.294 8.294 0 010-11.736M24.097.707a13.823 13.823 0 010 19.546m-19.546 0a13.823 13.823 0 010-19.546" />
      </g>
    </svg>
  );
}

export default SvgRadio;
