function SvgCross(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#fff' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20.707 20.707"
      {...props}
    >
      <path
        d="M10.354 10.353l-10 10 10-10-10-10 10 10 10-10-10 10 10 10z"
        fill="none"
        stroke={color}
      />
    </svg>
  );
}

export default SvgCross;
