function SvgGrid(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#000' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21 21"
      {...props}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5.5h7.778v7.778H.5zM12.722.5H20.5v7.778h-7.778zM12.722 12.722H20.5V20.5h-7.778zM.5 12.722h7.778V20.5H.5z" />
      </g>
    </svg>
  );
}

export default SvgGrid;
