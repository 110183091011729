import { replace } from 'redux-first-history';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { hideFoldout } from '../actions/foldouts';
import { hideModal } from '../actions/modals';
import { setLastSettings } from '../actions/routes';
import {
  activateInvitedUserFailure,
  activateInvitedUserRequest,
  activateInvitedUserSuccess,
  batchUpdateUsersFailure,
  batchUpdateUsersRequest,
  batchUpdateUsersSuccess,
  createUserFailure,
  createUserSuccess,
  declineInvitationFailure,
  declineInvitationRequest,
  declineInvitationSuccess,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  getInviteesFailure,
  getInviteesSuccess,
  getUserFailure,
  getUserSuccess,
  inviteBylinkFailure,
  inviteBylinkRequest,
  inviteBylinkSuccess,
  inviteUserFailure,
  inviteUserRequest,
  inviteUserSuccess,
  listUserGroupsFailure,
  listUserGroupsSuccess,
  listUsersFailure,
  listUsersRequest,
  listUsersSuccess,
  locationTreeFailure,
  locationTreeSuccess,
  patchUserInfoFailure,
  patchUserInfoRequest,
  patchUserInfoSuccess,
  removeUserFailure,
  removeUserRequest,
  removeUserSuccess,
  sendInvitationFailure,
  sendInvitationRequest,
  sendInvitationSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
} from '../actions/users';
import { Users } from '../api';
import {
  ACTIVATE_INVITED_USER_REQUEST,
  BATCH_UPDATE_USERS_REQUEST,
  CREATE_USER_REQUEST,
  DECLINE_INVITATION_REQUEST,
  DELETE_USER_REQUEST,
  GET_INVITEES_REQUEST,
  GET_USER_REQUEST,
  INVITE_BYLINK_REQUEST,
  INVITE_USER_REQUEST,
  LIST_USER_GROUPS_REQUEST,
  LIST_USERS_REQUEST,
  LOCATION_TREE_REQUEST,
  PATCH_USER_INFO_REQUEST,
  REMOVE_USER_REQUEST,
  SEND_INVITATION_REQUEST,
  UPDATE_USER_REQUEST,
} from '../constants/action-types';
import { uidSelector } from '../selectors/auth';
import { usersSelector } from '../selectors/users';
import { parseError } from '../utils/error';
import { pruneEmptyValues } from '../utils/helper';
import {
  validateActivateInvitedUser,
  validateInviteByLink,
  validateInviteUser,
} from '../validations';
import { validateCreateUser, validateUpdateUser } from '../validations/users';

export function* locationTree() {
  try {
    const uid = yield select(uidSelector);
    const { body: organizations } = yield call(Users.ListOrganizations, uid);

    const organizationSet = new Set();
    const countrySet = new Set();
    const citySet = new Set();
    const locationSet = new Set();

    if (organizations.length) {
      for (const organization of organizations) {
        const {
          fields: { countries },
        } = organization;

        organizationSet.add(organization.pk);

        for (const country of countries) {
          const { cities } = country;

          countrySet.add(country.name);

          for (const city of cities) {
            const { locations } = city;

            citySet.add(city.name);

            for (const location of locations) {
              locationSet.add(location.pk);
            }
          }
        }
      }
    }

    // let resetLocations = false;

    // const location = yield select(locationSelector);

    // if (location.locations.length) {
    //   for (const oid of location.organizations) {
    //     if (!organizationSet.has(oid)) {
    //       resetLocations = true;
    //       break;
    //     }
    //   }
    //   if (!resetLocations) {
    //     for (const cname of location.countries) {
    //       if (!countrySet.has(cname)) {
    //         resetLocations = true;
    //         break;
    //       }
    //     }
    //     if (!resetLocations) {
    //       for (const ciname of location.cities) {
    //         if (!citySet.has(ciname)) {
    //           resetLocations = true;
    //           break;
    //         }
    //       }
    //       if (!resetLocations) {
    //         for (const lid of location.locations) {
    //           if (!locationSet.has(lid)) {
    //             resetLocations = true;
    //             break;
    //           }
    //         }
    //       }
    //     }
    //   }
    // } else {
    //   resetLocations = true;
    // }

    yield put(locationTreeSuccess(organizations));
  } catch (e) {
    yield put(locationTreeFailure(e));
  }
}

export function* listUsers({
  limit,
  offset,
}: ReturnType<typeof listUsersRequest>) {
  try {
    const {
      body: { count, results },
    } = yield call(Users.List, limit, offset);
    yield put(listUsersSuccess(results, count));

    const url = new URL(window.location.href);

    if (url.pathname.startsWith('/settings/users')) {
      window.history.replaceState(
        null,
        '',
        `/settings/users/${limit}/${offset}`
      );

      yield put(setLastSettings(`/settings/users/${limit}/${offset}`));
    }
  } catch (e) {
    yield put(listUsersFailure(e));
  }
}

export function* listUserGroups() {
  try {
    const uid = yield select(uidSelector);
    const { body: groups } = yield call(Users.ListUserGroups, uid);
    yield put(listUserGroupsSuccess(groups));
  } catch (e) {
    yield put(listUserGroupsFailure(e));
  }
}

export function* patchUserInfo({
  payload,
}: ReturnType<typeof patchUserInfoRequest>) {
  try {
    const uid = yield select(uidSelector);
    const { body: user } = yield call(Users.PatchUserInfo, uid, payload);
    yield put(patchUserInfoSuccess(user));
  } catch (e) {
    yield put(patchUserInfoFailure(e, parseError(e, 'update')));
  }
}

export function* createUser({
  user: payload,
}: ReturnType<typeof updateUserRequest>) {
  try {
    pruneEmptyValues(payload);
    validateCreateUser(payload);
    const { body: user } = yield call(Users.Create, payload);
    yield put(createUserSuccess(user));
    yield put(hideFoldout());
  } catch (e) {
    yield put(createUserFailure(e, parseError(e)));
  }
}

export function* updateUser({
  id,
  user: payload,
}: ReturnType<typeof updateUserRequest>) {
  try {
    pruneEmptyValues(payload);
    validateUpdateUser(payload);
    const { body: user } = yield call(Users.Update, id, payload);
    yield put(updateUserSuccess(user));
    yield put(hideFoldout());
  } catch (e) {
    yield put(updateUserFailure(e, parseError(e)));
  }
}

export function* getUser() {
  try {
    const uid = yield select(uidSelector);
    const { body: user } = yield call(Users.Get, uid);
    yield put(getUserSuccess(user));
  } catch (e) {
    yield put(getUserFailure(e));
  }
}

export function* deleteUser({ id }: ReturnType<typeof deleteUserRequest>) {
  try {
    yield call(Users.DeleteUser, id);
    yield put(deleteUserSuccess(id));
    yield put(hideModal());
    yield put(hideFoldout());
  } catch (e) {
    yield put(deleteUserFailure(e));
  }
}

// export function* deleteAccount() {
//   try {
//     const uid = yield select(uidSelector);
//     yield call(Users.DeleteUser, uid);
//     yield put(deleteAccountSuccess());
//   } catch (e) {
//     yield put(deleteAccountFailure(e));
//   } finally {
//     yield put(resetToken());
//     storeRegistry.persistor.purge();
//   }
// }

export function* activateInvitedUser({
  password,
  password2,
  post,
  pre,
}: ReturnType<typeof activateInvitedUserRequest>) {
  try {
    validateActivateInvitedUser(password, password2);
    yield call(Users.ActivateInvitedUser, pre, post, password);
    yield put(replace('/login'));
    yield put(activateInvitedUserSuccess());
  } catch (e) {
    yield put(activateInvitedUserFailure(e, parseError(e, 'activate')));
  }
}

export function* inviteUser({ payload }: ReturnType<typeof inviteUserRequest>) {
  try {
    const { expireAt, inviteEmail, locations, startAt, systemRole } = payload;
    validateInviteUser(inviteEmail);
    const { body: invitee } = yield call(
      Users.Invite,
      inviteEmail,
      locations,
      startAt,
      expireAt,
      systemRole
    );

    yield put(inviteUserSuccess(invitee));
    yield put(hideModal());
  } catch (e) {
    yield put(inviteUserFailure(e, parseError(e, 'invite')));
  }
}

export function* inviteBylink({
  payload,
}: ReturnType<typeof inviteBylinkRequest>) {
  try {
    const { expireAt, inviteEmail, locations, startAt, systemRole } = payload;
    validateInviteByLink(inviteEmail);
    const { body: invitee } = yield call(
      Users.Invite,
      inviteEmail,
      locations,
      startAt,
      expireAt,
      systemRole,
      true
    );

    yield put(inviteBylinkSuccess(invitee));
  } catch (e) {
    yield put(inviteBylinkFailure(e, parseError(e, 'invite')));
  }
}

export function* removeUser({ uid }: ReturnType<typeof removeUserRequest>) {
  try {
    const users = yield select(usersSelector);
    yield call(Users.RemoveUser, uid);
    const { email } = users.find((u) => u.id === uid);
    yield put(removeUserSuccess(uid, email));
    yield put(hideModal());
  } catch (e) {
    yield put(removeUserFailure(e));
  }
}

export function* getInvitees() {
  try {
    const { body: invitees } = yield call(Users.Invitees);
    yield put(getInviteesSuccess(invitees));
  } catch (e) {
    yield put(getInviteesFailure(e));
  }
}

export function* sendInvitation({
  ids,
}: ReturnType<typeof sendInvitationRequest>) {
  try {
    yield call(Users.SendInvitation, ids);
    yield put(sendInvitationSuccess(ids));
    yield put(hideModal());
  } catch (e) {
    yield put(sendInvitationFailure(e));
  }
}

export function* declineInvitation({
  ids,
}: ReturnType<typeof declineInvitationRequest>) {
  try {
    yield call(Users.DeclineInvitation, ids);
    yield put(declineInvitationSuccess(ids));
    yield put(hideModal());
  } catch (e) {
    yield put(declineInvitationFailure(e));
  }
}

export function* updateUsers({
  fieldName,
  ids,
  value,
}: ReturnType<typeof batchUpdateUsersRequest>) {
  try {
    yield call(Users.BatchUpdate, ids, fieldName, value);
    yield put(batchUpdateUsersSuccess(ids, fieldName, value));
    yield put(hideModal());
  } catch (e) {
    yield put(batchUpdateUsersFailure(e, parseError(e, 'update')));
  }
}

export function* watchUsers() {
  yield takeLatest(LOCATION_TREE_REQUEST, locationTree);
  yield takeLatest(LIST_USERS_REQUEST, listUsers);
  yield takeLatest(LIST_USER_GROUPS_REQUEST, listUserGroups);
  yield takeLatest(PATCH_USER_INFO_REQUEST, patchUserInfo);
  yield takeLatest(GET_USER_REQUEST, getUser);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
  yield takeLatest(ACTIVATE_INVITED_USER_REQUEST, activateInvitedUser);
  yield takeLatest(INVITE_USER_REQUEST, inviteUser);
  yield takeLatest(REMOVE_USER_REQUEST, removeUser);
  yield takeLatest(GET_INVITEES_REQUEST, getInvitees);
  yield takeLatest(SEND_INVITATION_REQUEST, sendInvitation);
  yield takeLatest(DECLINE_INVITATION_REQUEST, declineInvitation);
  yield takeLatest(INVITE_BYLINK_REQUEST, inviteBylink);
  yield takeLatest(BATCH_UPDATE_USERS_REQUEST, updateUsers);
  yield takeLatest(UPDATE_USER_REQUEST, updateUser);
  yield takeLatest(CREATE_USER_REQUEST, createUser);
}
