function SvgCross2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.707}
      height={10.707}
      role="img"
      viewBox="0 0 10.707 10.707"
      {...props}
    >
      <path
        d="m5.354 5.353-5 5 5-5-5-5 5 5 5-5-5 5 5 5Z"
        fill="none"
        stroke="#999"
      />
    </svg>
  );
}

export default SvgCross2;
