import { HIDE_TOAST, SHOW_TOAST } from '../constants/action-types';
import { TOAST_TIMEOUT } from '../constants/timeouts';
import { uid } from '../utils/helper';

export const hideToast = () => ({
  type: HIDE_TOAST,
});

type ShowToastProps = {
  id?: string;
  message: string;
  reloadable?: boolean;
  timeout?: number;
  level: 'success' | 'warning' | 'failure';
};

export const showToast = ({
  id = uid(),
  message,
  reloadable = false,
  timeout = TOAST_TIMEOUT,
  level,
}: ShowToastProps) => ({
  type: SHOW_TOAST,
  id,
  message,
  reloadable,
  timeout,
  level,
});
