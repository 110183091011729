import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import { compareValues } from '../utils/helper';

export const exportingAsImageSelector = (state) =>
  state.insights.exportingAsImage;

export const categoryMultilineLoadingSelector = (state) =>
  state.insights.categoryMultilineLoading;
export const categoryMultilineDataSelector = (state) =>
  state.insights.categoryMultilineData;
export const categoryMultilineFailedSelector = (state) =>
  state.insights.categoryMultilineFailed;
export const categoryMultilineCategorySelector = (state) =>
  state.insights.categoryMultilineCategory;

export const lastEnteringsLoadingSelector = (state) =>
  state.insights.lastEnteringsLoading;
export const lastEnteringsSelector = (state) => state.insights.lastEnterings;

export const visitorLineStdLoadingSelector = (state) =>
  state.insights.visitorLineStdLoading;
export const visitorLinePrvLoadingSelector = (state) =>
  state.insights.visitorLinePrvLoading;
export const visitorLineStdSelector = (state) => state.insights.visitorLineStd;
export const visitorLinePrvSelector = (state) => state.insights.visitorLinePrv;

export const totalEnteringsSelector = (state) => state.insights.totalEnterings;
export const totalEnteringsLoadingSelector = (state) =>
  state.insights.totalEnteringsLoading;

export const dataTypeSelector = (state) => state.insights.dataType;
export const dataPropertySelector = (state) => state.insights.dataProperty;
export const dataDateSelector = (state) => state.insights.dataDate;
// export const dataCategorySelector = (state) => state.insights.dataCategory;
export const dataZoneFromSelector = (state) => state.insights.dataZoneFrom;
export const dataZoneToSelector = (state) => state.insights.dataZoneTo;
export const dataOrganizationSelector = (state) =>
  state.insights.dataOrganization;
export const dataLocationSelector = (state) => state.insights.dataLocation;
export const dataFloorSelector = (state) => state.insights.dataFloor;
export const dataLineSelector = (state) => state.insights.dataLine;
export const dataStartDateSelector = (state) => state.insights.dataStartDate;
export const dataEndDateSelector = (state) => state.insights.dataEndDate;

export const totalVisitorsLoadingSelector = (state) =>
  state.insights.totalVisitorsLoading;
export const totalVisitorsSelector = (state) => state.insights.totalVisitors;
export const totalVisitorsPreviousSelector = (state) =>
  state.insights.totalVisitorsPrevious;
export const totalVisitorsLastUpdatedSelector = (state) =>
  state.insights.totalVisitorsLastUpdated;

export const visitorLineStdFailedSelector = (state) =>
  state.insights.visitorLineStdFailed;
export const visitorLinePrvFailedSelector = (state) =>
  state.insights.visitorLinePrvFailed;

export const cameraLineMultilineLoadingSelector = (state) =>
  state.insights.cameraLineMultilineLoading;
export const cameraLineMultilineDataSelector = (state) =>
  state.insights.cameraLineMultilineData;
export const cameraLineMultilineFailedSelector = (state) =>
  state.insights.cameraLineMultilineFailed;

export const locationMultilineLoadingSelector = (state) =>
  state.insights.locationMultilineLoading;
export const locationMultilineDataSelector = (state) =>
  state.insights.locationMultilineData;
export const locationMultilineFailedSelector = (state) =>
  state.insights.locationMultilineFailed;

export const zoneFromMultilineLoadingSelector = (state) =>
  state.insights.zoneFromMultilineLoading;
export const zoneFromMultilineDataSelector = (state) =>
  state.insights.zoneFromMultilineData;
export const zoneFromMultilineFailedSelector = (state) =>
  state.insights.zoneFromMultilineFailed;

export const zoneToMultilineLoadingSelector = (state) =>
  state.insights.zoneToMultilineLoading;
export const zoneToMultilineDataSelector = (state) =>
  state.insights.zoneToMultilineData;
export const zoneToMultilineFailedSelector = (state) =>
  state.insights.zoneToMultilineFailed;

export const totalEnteringsDataSelector = createSelector(
  [totalEnteringsSelector],
  (data) => {
    if (data.length === 0) {
      return [];
    }

    return data
      .map((d) => {
        return {
          id: d._id.line.id,
          name: d._id.line.name,
          value: d.count,
        };
      })
      .sort(compareValues('value', 'desc'));
  }
);

export const realtimeDataSelector = createSelector(
  lastEnteringsSelector,
  (lastEnterings) => {
    const now = dayjs().tz('Europe/Stockholm', false);
    const thisDay = parseInt(now.format('D'), 10);
    const thisHour = parseInt(now.format('HH'), 10);
    const thisMinute = parseInt(now.format('mm'), 10);

    const index = lastEnterings.findIndex(
      (d) =>
        d._id.dayofmonth === thisDay &&
        d._id.hour === thisHour &&
        d._id.minute === thisMinute
    );

    if (lastEnterings.length === 0 || index === -1) {
      let i = 0;
      const dummyArray = Array.from({ length: 30 }, () => {
        const ago = now.subtract(30 - (i + 1), 'minutes');
        const yyyy = ago.format('YYYY');
        const MM = ago.format('MM');
        const dd = ago.format('DD');
        const hh = ago.format('HH');
        const mm = ago.format('mm');

        const date = dayjs(`${yyyy}-${MM}-${dd} ${hh}:${mm}`).toDate();
        i++;
        return { count: 0, date };
      });
      return dummyArray;
    }

    return lastEnterings
      .slice(index - 30 < 0 ? 0 : index - 30, index || 1)
      .map((d) => {
        return {
          count: d.count,
          date: dayjs(
            `${d._id.year}-${d._id.month}-${d._id.dayofmonth} ${d._id.hour}:${d._id.minute}`
          ).toDate(),
        };
      });
  }
);

export const avgRealtimeDataSelector = createSelector(
  [realtimeDataSelector],
  (data) => {
    const total = data.reduce((acc, curr) => acc + curr.count, 0);

    return total === 0 ? 0 : Math.round(total / data.length);
  }
);

export const categoryMultilineDataByDateSelector = createSelector(
  [categoryMultilineDataSelector, dataPropertySelector],
  (data, selectedProperty) => {
    switch (selectedProperty?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            category: d.category,
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:${d.minute}`
            ).toDate(),
            value: d.count,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            category: d.category,
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:00`
            ).toDate(),
            value: d.count,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            category: d.category,
            date: dayjs(`${d.year}-${d.month}-${d.dayofmonth}`).toDate(),
            value: d.count,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d.year, 0, 1 + (d.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            category: d.category,
            date: week,
            value: d.count,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            category: d.category,
            date: dayjs(`${d.year}-${d.month}-01`).toDate(),
            value: d.count,
          };
        });
      default:
        return [];
    }
  }
);

export const locationMultilineDataByDateSelector = createSelector(
  [locationMultilineDataSelector, dataPropertySelector],
  (data, property) => {
    switch (property?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:${d.minute}`
            ).toDate(),
            location: {
              custom_id: d.custom_id,
              id: d.id,
              legal_address: d.legal_address,
              organization: {
                id: d.organization.id,
                short_name: d.organization.short_name,
              },
            },
            value: d.count,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:00`
            ).toDate(),
            location: {
              custom_id: d.custom_id,
              id: d.id,
              legal_address: d.legal_address,
              organization: {
                id: d.organization.id,
                short_name: d.organization.short_name,
              },
            },
            value: d.count,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-${d.dayofmonth}`).toDate(),
            location: {
              custom_id: d.custom_id,
              id: d.id,
              legal_address: d.legal_address,
              organization: {
                id: d.organization.id,
                short_name: d.organization.short_name,
              },
            },
            value: d.count,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d.year, 0, 1 + (d.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            location: {
              custom_id: d.custom_id,
              id: d.id,
              legal_address: d.legal_address,
              organization: {
                id: d.organization.id,
                short_name: d.organization.short_name,
              },
            },
            value: d.count,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-01`).toDate(),
            location: {
              custom_id: d.custom_id,
              id: d.id,
              legal_address: d.legal_address,
              organization: {
                id: d.organization.id,
                short_name: d.organization.short_name,
              },
            },
            value: d.count,
          };
        });
      default:
        return [];
    }
  }
);

export const zoneToMultilineDataByDateSelector = createSelector(
  [zoneToMultilineDataSelector, dataPropertySelector],
  (data, property) => {
    switch (property?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:${d.minute}`
            ).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:00`
            ).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-${d.dayofmonth}`).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d.year, 0, 1 + (d.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            value: d.count,
            zone: d.zone,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-01`).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      default:
        return [];
    }
  }
);

export const zoneFromMultilineDataByDateSelector = createSelector(
  [zoneFromMultilineDataSelector, dataPropertySelector],
  (data, property) => {
    switch (property?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:${d.minute}`
            ).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:00`
            ).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-${d.dayofmonth}`).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d.year, 0, 1 + (d.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            value: d.count,
            zone: d.zone,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-01`).toDate(),
            value: d.count,
            zone: d.zone,
          };
        });
      default:
        return [];
    }
  }
);

export const cameraLineMultilineDataByDateSelector = createSelector(
  [cameraLineMultilineDataSelector, dataPropertySelector],
  (data, property) => {
    switch (property?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:${d.minute}`
            ).toDate(),
            line: d.line,
            value: d.count,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d.year}-${d.month}-${d.dayofmonth} ${d.hour}:00`
            ).toDate(),
            line: d.line,
            value: d.count,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-${d.dayofmonth}`).toDate(),
            line: d.line,
            value: d.count,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d.year, 0, 1 + (d.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            line: d.line,
            value: d.count,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d.year}-${d.month}-01`).toDate(),
            line: d.line,
            value: d.count,
          };
        });
      default:
        return [];
    }
  }
);

export const locationMultilineDataHighestSelector = createSelector(
  [locationMultilineDataByDateSelector],
  (data) => {
    return data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value;
  }
);

export const locationMultilineDataLowestSelector = createSelector(
  [locationMultilineDataByDateSelector],
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const locationMultilineDataTotalSelector = createSelector(
  [locationMultilineDataByDateSelector],
  (data) => {
    let total = 0;
    const lineTotals = {};

    for (const d of data) {
      total += d.value;
      const id = d.location.id;

      if (lineTotals.hasOwnProperty(id)) {
        lineTotals[id] += d.value;
      } else {
        lineTotals[id] = d.value;
      }
    }

    return [total, lineTotals] as const;
  }
);

export const zoneToMultilineDataHighestSelector = createSelector(
  [zoneToMultilineDataByDateSelector],
  (data) => {
    return data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value;
  }
);

export const zoneFromMultilineDataHighestSelector = createSelector(
  [zoneFromMultilineDataByDateSelector],
  (data) => {
    return data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value;
  }
);

export const cameraLineMultilineDataHighestSelector = createSelector(
  [cameraLineMultilineDataByDateSelector],
  (data) => {
    return data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value;
  }
);

export const categoryMultilineDataHighestSelector = createSelector(
  [categoryMultilineDataByDateSelector],
  (data) => {
    return data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value;
  }
);

export const zoneToMultilineDataLowestSelector = createSelector(
  [zoneToMultilineDataByDateSelector],
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const zoneFromMultilineDataLowestSelector = createSelector(
  [zoneFromMultilineDataByDateSelector],
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const cameraLineMultilineDataLowestSelector = createSelector(
  [cameraLineMultilineDataByDateSelector],
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const categoryMultilineDataLowestSelector = createSelector(
  [categoryMultilineDataByDateSelector],
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const categoryMultilineDataTotalSelector = createSelector(
  [categoryMultilineDataByDateSelector],
  (data) => {
    let total = 0;
    const lineTotals = {};

    for (const d of data) {
      total += d.value;
      const id = d.category.id;

      if (lineTotals.hasOwnProperty(id)) {
        lineTotals[id] += d.value;
      } else {
        lineTotals[id] = d.value;
      }
    }

    return [total, lineTotals] as const;
  }
);

export const zoneToMultilineDataTotalSelector = createSelector(
  [zoneToMultilineDataByDateSelector],
  (data) => {
    let total = 0;
    const lineTotals = {};

    for (const d of data) {
      total += d.value;
      const id = d.zone.id;

      if (lineTotals.hasOwnProperty(id)) {
        lineTotals[id] += d.value;
      } else {
        lineTotals[id] = d.value;
      }
    }

    return [total, lineTotals] as const;
  }
);

export const zoneFromMultilineDataTotalSelector = createSelector(
  [zoneFromMultilineDataByDateSelector],
  (data) => {
    let total = 0;
    const lineTotals = {};

    for (const d of data) {
      total += d.value;
      const id = d.zone.id;

      if (lineTotals.hasOwnProperty(id)) {
        lineTotals[id] += d.value;
      } else {
        lineTotals[id] = d.value;
      }
    }

    return [total, lineTotals] as const;
  }
);

export const cameraLineMultilineDataTotalSelector = createSelector(
  [cameraLineMultilineDataByDateSelector],
  (data) => {
    let total = 0;
    const lineTotals = {};

    for (const d of data) {
      total += d.value;
      const id = d.line.id;

      if (lineTotals.hasOwnProperty(id)) {
        lineTotals[id] += d.value;
      } else {
        lineTotals[id] = d.value;
      }
    }

    return [total, lineTotals] as const;
  }
);

export const visitorsDataByDateSelector = createSelector(
  [visitorLineStdSelector, visitorLinePrvSelector, dataPropertySelector],
  (std, prv, selectedProperty) => {
    const data =
      prv.length > 0 && std.length > prv.length
        ? std.slice(std.length - prv.length, std.length)
        : std;

    switch (selectedProperty?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth} ${d._id.hour}:${d._id.minute}`
            ).toDate(),
            type: 'std',
            value: d.count,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth} ${d._id.hour}:00`
            ).toDate(),
            type: 'std',
            value: d.count,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth}`
            ).toDate(),
            type: 'std',
            value: d.count,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d._id.year, 0, 1 + (d._id.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            type: 'std',
            value: d.count,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d._id.year}-${d._id.month}-01`).toDate(),
            type: 'std',
            value: d.count,
          };
        });
      default:
        return [];
    }
  }
);

export const visitorsDataPreviousByDateSelector = createSelector(
  [visitorLinePrvSelector, visitorLineStdSelector, dataPropertySelector],
  (prv, std, selectedProperty) => {
    if (std.length === 0) return [];

    const data = prv.slice(0, std.length);

    switch (selectedProperty?.value) {
      case '1m':
      case '10m':
      case '30m':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth} ${d._id.hour}:${d._id.minute}`
            ).toDate(),
            type: 'prv',
            value: d.count,
          };
        });
      case '1h':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth} ${d._id.hour}:00`
            ).toDate(),
            type: 'prv',
            value: d.count,
          };
        });
      case '1d':
        return data.map((d) => {
          return {
            date: dayjs(
              `${d._id.year}-${d._id.month}-${d._id.dayofmonth}`
            ).toDate(),
            type: 'prv',
            value: d.count,
          };
        });
      case '1w':
        return data.map((d) => {
          const simple = new Date(d._id.year, 0, 1 + (d._id.week - 1) * 7);
          const dow = simple.getDay();
          const week = simple;

          if (dow <= 4) {
            week.setDate(simple.getDate() - simple.getDay() + 1);
          } else {
            week.setDate(simple.getDate() + 8 - simple.getDay());
          }

          return {
            date: week,
            type: 'prv',
            value: d.count,
          };
        });
      case '1mo':
        return data.map((d) => {
          return {
            date: dayjs(`${d._id.year}-${d._id.month}-01`).toDate(),
            type: 'prv',
            value: d.count,
          };
        });
      default:
        return [];
    }
  }
);

export const visitorsAverageDataSelector = createSelector(
  visitorsDataByDateSelector,
  (data) => {
    const now = dayjs().tz('Europe/Stockholm', false);
    const index = data.findIndex((d) => {
      const dd = dayjs.tz(
        `${d.date.getFullYear()}-${
          d.date.getMonth() + 1
        }-${d.date.getDate()} ${d.date.getHours()}:${d.date.getMinutes()}`,
        'Europe/Stockholm'
      );

      return dd.isAfter(now);
    });

    const pastData = index > -1 ? data.slice(0, index) : data;

    const avg =
      pastData.length === 0
        ? 0
        : pastData.reduce((acc, curr) => acc + curr.value, 0) / pastData.length;

    return data.map((d) => {
      return {
        date: d.date,
        type: 'avg',
        value: avg,
      };
    });
  }
);

export const visitorsRgrDataSelector = createSelector(
  [visitorsDataByDateSelector],
  (data) => {
    const now = dayjs().tz('Europe/Stockholm', false);
    const index = data.findIndex((d) => {
      const dd = dayjs.tz(
        `${d.date.getFullYear()}-${
          d.date.getMonth() + 1
        }-${d.date.getDate()} ${d.date.getHours()}:${d.date.getMinutes()}`,
        'Europe/Stockholm'
      );

      return dd.isAfter(now);
    });

    return (index > -1 ? data.slice(0, index) : data).map((d, i) => ({
      date: d.date,
      type: 'rgr',
      value: d.value,
    }));
  }
);

export const visitorsStdPrvDataSelector = createSelector(
  [visitorsDataByDateSelector, visitorsDataPreviousByDateSelector],
  (std, prv) => {
    const now = dayjs().tz('Europe/Stockholm', false);
    const stdIndex = std.findIndex((d) => {
      const dd = dayjs.tz(
        `${d.date.getFullYear()}-${
          d.date.getMonth() + 1
        }-${d.date.getDate()} ${d.date.getHours()}:${d.date.getMinutes()}`,
        'Europe/Stockholm'
      );

      return dd.isAfter(now);
    });
    const prvIndex = prv.findIndex((d) => {
      const dd = dayjs.tz(
        `${d.date.getFullYear()}-${
          d.date.getMonth() + 1
        }-${d.date.getDate()} ${d.date.getHours()}:${d.date.getMinutes()}`,
        'Europe/Stockholm'
      );

      return dd.isAfter(now);
    });

    return [
      ...(stdIndex > -1 ? std.slice(0, stdIndex) : std),
      ...(prvIndex > -1 ? prv.slice(0, prvIndex) : prv),
    ];
  }
);

// export const visitorsDataPreviousLineOfBestFitSelector = createSelector(
//   [visitorsDataPreviousByDateSelector],
//   (data) => {
//     const { slope, intercept } = linearRegression(data);

//     const result = data.map((obj) => {
//       const x = Date.parse(obj.date);
//       const y = slope * x + intercept;

//       return {
//         date: obj.date,
//         value: y,
//         type: 'regression',
//       };
//     });

//     return result;
//   }
// );

export const totalVisitorsDataSelector = createSelector(
  visitorsDataByDateSelector,
  (data) => data.reduce((acc, curr) => acc + curr.value, 0)
);

export const previousTotalVisitorsSelector = createSelector(
  visitorsDataPreviousByDateSelector,
  (data) => data.reduce((acc, curr) => acc + curr.value, 0)
);

export const allVisitorsDataByDateSelector = createSelector(
  [visitorsDataByDateSelector, visitorsDataPreviousByDateSelector],
  (std, prv) => {
    return [...prv, ...std];
  }
);

export const highestValueSelector = createSelector(
  visitorsDataByDateSelector,
  (data) =>
    data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value
);

export const previousHighestValueSelector = createSelector(
  visitorsDataPreviousByDateSelector,
  (data) =>
    data.reduce(
      (prev, current) => (prev.value > current.value ? prev : current),
      { value: 0 }
    ).value
);

export const lowestValueSelector = createSelector(
  visitorsDataByDateSelector,
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);

export const previousLowestValueSelector = createSelector(
  visitorsDataPreviousByDateSelector,
  (data) => {
    const res = data.reduce(
      (prev, current) => (prev.value < current.value ? prev : current),
      { value: Infinity }
    ).value;

    return res === Infinity ? 0 : res;
  }
);
