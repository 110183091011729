import { configureStore as createStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { unstable_batchedUpdates } from 'react-dom';
import { applyMiddleware, compose } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import {
  CAMERA_LINE_MULTILINE_FAILURE,
  CAMERA_LINE_MULTILINE_REQUEST,
  CAMERA_LINE_MULTILINE_SUCCESS,
  LINE_TYPE_MULTILINE_FAILURE,
  LINE_TYPE_MULTILINE_REQUEST,
  LINE_TYPE_MULTILINE_SUCCESS,
  LAST_ENTERINGS_FAILURE,
  LAST_ENTERINGS_REQUEST,
  LAST_ENTERINGS_SUCCESS,
  LOCATION_MULTILINE_FAILURE,
  LOCATION_MULTILINE_REQUEST,
  LOCATION_MULTILINE_SUCCESS,
  TOTAL_ENTERINGS_FAILURE,
  TOTAL_ENTERINGS_REQUEST,
  TOTAL_ENTERINGS_SUCCESS,
  TOTAL_VISITS_FAILURE,
  TOTAL_VISITS_PREVIOUS_FAILURE,
  TOTAL_VISITS_PREVIOUS_REQUEST,
  TOTAL_VISITS_PREVIOUS_SUCCESS,
  TOTAL_VISITS_REQUEST,
  TOTAL_VISITS_SUCCESS,
  ZONE_FROM_MULTILINE_FAILURE,
  ZONE_FROM_MULTILINE_REQUEST,
  ZONE_FROM_MULTILINE_SUCCESS,
  ZONE_TO_MULTILINE_FAILURE,
  ZONE_TO_MULTILINE_REQUEST,
  ZONE_TO_MULTILINE_SUCCESS,
} from '../constants/action-types';
import createRootReducer from '../reducers';
import {
  appTransform,
  authTransform,
  filtersTransform,
  organizationsTransform,
  routesTransform,
  segmentationTransform,
} from './transforms';

const excludedActions = new Set([
  CAMERA_LINE_MULTILINE_FAILURE,
  CAMERA_LINE_MULTILINE_REQUEST,
  CAMERA_LINE_MULTILINE_SUCCESS,
  LINE_TYPE_MULTILINE_FAILURE,
  LINE_TYPE_MULTILINE_REQUEST,
  LINE_TYPE_MULTILINE_SUCCESS,
  LAST_ENTERINGS_FAILURE,
  LAST_ENTERINGS_REQUEST,
  LAST_ENTERINGS_SUCCESS,
  LOCATION_MULTILINE_FAILURE,
  LOCATION_MULTILINE_REQUEST,
  LOCATION_MULTILINE_SUCCESS,
  TOTAL_ENTERINGS_FAILURE,
  TOTAL_ENTERINGS_REQUEST,
  TOTAL_ENTERINGS_SUCCESS,
  TOTAL_VISITS_FAILURE,
  TOTAL_VISITS_PREVIOUS_FAILURE,
  TOTAL_VISITS_PREVIOUS_REQUEST,
  TOTAL_VISITS_PREVIOUS_SUCCESS,
  TOTAL_VISITS_REQUEST,
  TOTAL_VISITS_SUCCESS,
  ZONE_FROM_MULTILINE_FAILURE,
  ZONE_FROM_MULTILINE_REQUEST,
  ZONE_FROM_MULTILINE_SUCCESS,
  ZONE_TO_MULTILINE_FAILURE,
  ZONE_TO_MULTILINE_REQUEST,
  ZONE_TO_MULTILINE_SUCCESS,
]);

function configureStore({ initialState, rootSaga }) {
  const history = createBrowserHistory();

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      batch: unstable_batchedUpdates,
      history,
    });

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routerMiddleware];
  const enhancers: typeof compose[] = [];

  const persistConfig = {
    key: 'indivd',
    storage,
    transforms: [
      appTransform,
      authTransform,
      filtersTransform,
      organizationsTransform,
      routesTransform,
      segmentationTransform,
    ],
    whitelist: [
      'app',
      'auth',
      'components',
      'filters',
      'organizations',
      'routes',
      'segmentation',
    ],
  };
  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(routerReducer)
  );

  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
      const logger = createLogger({
        predicate: (_getState, action) => !excludedActions.has(action.type),
      });

      middleware.push(logger);
    }

    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_ || compose;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const store = createStore({
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [applyMiddleware(...middleware), ...enhancers],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    preloadedState: initialState,
    reducer: persistedReducer,
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga, history);

  return { history: createReduxHistory(store), persistor, store };
}

export default configureStore;
