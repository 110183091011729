import { InputHTMLAttributes, useMemo } from 'react';
import clsx from 'clsx';
import styles from './text-area.module.css';

type TextAreaProps = {
  blackDisabled?: boolean;
  className?: string;
  dashed?: boolean;
  disabled?: boolean;
  info?: string;
  name?: string;
  rightIcon?: JSX.Element;
  rows?: number;
  cols?: number;
  validation: {
    field: string;
    message: string;
  };
};

function TextArea(
  props: TextAreaProps & InputHTMLAttributes<HTMLTextAreaElement>
) {
  const {
    className,
    dashed = true,
    disabled = false,
    validation,
    name,
    rightIcon,
    blackDisabled = false,
    info,
    ...rest
  } = props;

  const invalid = useMemo(() => {
    if (!validation) {
      return false;
    }
    return name === validation.field;
  }, [name, validation]);

  return (
    <div>
      <div className={styles.inputContainer}>
        <textarea
          name={name}
          className={clsx([
            styles.input,
            className,
            {
              [styles.dashed]: dashed,
              [styles.disabled]: disabled,
              [styles.blackDisabled]: blackDisabled,
              [styles.invalid]: invalid,
            },
          ])}
          disabled={disabled}
          {...rest}
        />
        <div className={styles.rightIcon}>{rightIcon}</div>
      </div>
      {invalid ? (
        <div className={styles.validation}>{validation.message}</div>
      ) : (
        info && <div className={styles.info}>{info}</div>
      )}
    </div>
  );
}

export default TextArea;
