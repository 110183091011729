import clsx from 'clsx';
import styles from './counter-url.module.css';
import { HTMLAttributes } from 'react';

type CounterUrlProps = HTMLAttributes<HTMLDivElement> & {
  url: string;
  port: string;
  ip: string;
  username: string;
  password: string;
};

function CounterUrl(props: CounterUrlProps) {
  const { url, port, ip, username, password, className, ...rest } = props;

  return (
    <div className={clsx(styles.container, className)} {...rest}>
      {`rtsp://${username || 'username'}:${password || 'password'}@${
        ip || 'ip'
      }:${port || 'port'}${url || '/camera_stream_url'}`}
    </div>
  );
}

export default CounterUrl;
