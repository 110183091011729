import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedLocation } from '../../../../../../actions/segmentation';
import { Checkbox, Label, Select } from '../../../../../../components/form';
import { listingLocationsSelector } from '../../../../../../selectors/locations';
import {
  latestUpdateTimeSelector,
  locationFilterOptionsForVisitorJourneySelector,
  selectedLocationSelector,
} from '../../../../../../selectors/segmentation';

type LocationFilterProps = {
  label: string;
  placeholder?: string;
};

function LocationFilter(props: LocationFilterProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const latestUpdate = useSelector(latestUpdateTimeSelector);

  const selected = useSelector(selectedLocationSelector);

  const loading = useSelector(listingLocationsSelector);

  const options = useSelector(locationFilterOptionsForVisitorJourneySelector);

  const [filterEnabled, setFilterEnabled] = useState(!!selected);

  const handleChange = useCallback(
    (option: Option) => {
      dispatch(setSelectedLocation(option));
    },
    [dispatch]
  );

  const handleToggleFilter = useCallback(() => {
    if (filterEnabled) {
      dispatch(setSelectedLocation(null));
    }

    setFilterEnabled(!filterEnabled);
  }, [dispatch, filterEnabled]);

  useEffect(() => {
    if (latestUpdate) {
      setFilterEnabled(!!selected);
    }
  }, [latestUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Checkbox
        borderColor="black"
        checked={filterEnabled}
        label={label}
        onClick={handleToggleFilter}
      />
      {filterEnabled && (
        <Label>
          <Select
            loading={loading}
            options={options}
            placeholder={placeholder}
            selected={selected}
            setSelected={handleChange}
            zIndex={6}
          />
        </Label>
      )}
    </>
  );
}

export default LocationFilter;
