function SvgLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      role="img"
      viewBox="0 0 16 16"
      {...props}
    >
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M5.5 15.5H2.167A1.667 1.667 0 0 1 .5 13.833V2.167A1.667 1.667 0 0 1 2.167.5H5.5M11.333 12.166 15.5 7.999l-4.167-4.166M15.5 8h-10" />
      </g>
    </svg>
  );
}
export default SvgLogout;
