import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './password-strength.module.css';
import { SvgError, SvgSuccess } from '../svg';
import { hasUppercaseLowercase } from '../../utils/helper';

type PasswordStrengthProps = {
  password: string;
};

function PasswordStrength(props: PasswordStrengthProps) {
  const { password } = props;

  const [t] = useTranslation('translation', { keyPrefix: 'passwordStrength' });

  const isMoreThan8Chart = useMemo(() => password.length >= 8, [password]);
  const isUppercaseLowercase = useMemo(
    () => hasUppercaseLowercase(password),
    [password]
  );
  const isAtLeastOneNumber = useMemo(() => password.match(/\d/), [password]);
  const isAtLeastOneSpecial = useMemo(
    () => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password),
    [password]
  );

  return (
    <div className={styles.tooltip}>
      <div className={styles.title}>{t('title')}</div>
      <ul>
        <li className={clsx({ [styles.success]: isMoreThan8Chart })}>
          {isMoreThan8Chart ? <SvgSuccess /> : <SvgError />}
          {t('8char')}
        </li>
        <li
          className={clsx({
            [styles.success]: isUppercaseLowercase,
          })}
        >
          {isUppercaseLowercase ? <SvgSuccess /> : <SvgError />}
          {t('upperLower')}
        </li>
        <li
          className={clsx({
            [styles.success]: isAtLeastOneNumber,
          })}
        >
          {isAtLeastOneNumber ? <SvgSuccess /> : <SvgError />}
          {t('digit')}
        </li>
        <li
          className={clsx({
            [styles.success]: isAtLeastOneSpecial,
          })}
        >
          {isAtLeastOneSpecial ? <SvgSuccess /> : <SvgError />}
          {t('special')}
        </li>
      </ul>
    </div>
  );
}

export default PasswordStrength;
