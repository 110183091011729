import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import styles from './hollow-dots-spinner.module.css';

type HollowDotsSpinnerProps = {
  size?: number;
};

const HollowDotsSpinner = forwardRef(function HollowDotsSpinner(
  props: React.HTMLProps<HTMLDivElement> & HollowDotsSpinnerProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { size = 15, className, ...rest } = props;

  const dotStyle = useMemo(
    () => ({
      width: size,
      height: size,
      margin: `0 ${size / 2}px`,
      border: `${size / 5}px solid var(--indivd-orange)`,
    }),
    [size]
  );

  return (
    <div
      className={clsx([styles.hollowDotsSpinner, className])}
      {...rest}
      style={{ height: size, width: size * 2 * 3 }}
      ref={ref}
    >
      <div className={styles.dot} style={dotStyle}></div>
      <div className={styles.dot} style={dotStyle}></div>
      <div className={styles.dot} style={dotStyle}></div>
    </div>
  );
});

export default HollowDotsSpinner;
