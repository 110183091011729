import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteLocationRequest } from '../../../actions/locations';
import { Button } from '../../../components/form';
import { SvgTrash } from '../../../components/svg';
import { deletingLocationSelector } from '../../../selectors/locations';
import { truncateSentenceEnd } from '../../../utils/helper';
import Header from '../header';
import styles from './index.module.css';

type DeleteLocationModalProps = {
  close: () => void;
  location: {
    id: number;
    legal_address: string;
    organization: {
      id: number;
      short_name: string;
    };
  };
};

function DeleteLocationModal(props: DeleteLocationModalProps) {
  const { close, location } = props;

  const [t] = useTranslation('modals', { keyPrefix: 'deleteLocation' });

  const dispatch = useDispatch();

  const deleting = useSelector(deletingLocationSelector);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteLocationRequest(location.id));
  }, [dispatch, location]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgTrash color="#fff" height={17} width={19} />}
        title={t('title')}
      />
      <div className={styles.description}>
        <Trans
          components={{
            div: <div />,
            p: <p />,
            u: <u />,
          }}
          i18nKey="description"
          t={t}
          values={{
            name: `${location.organization.short_name} ${truncateSentenceEnd(
              location.legal_address
            )}`,
          }}
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button color="primaryNegative" onClick={close} type="button">
          {t('cancel')}
        </Button>
        <Button
          className={styles.submit}
          color="negative"
          containerClassName={styles.submitContainer}
          disabled={false}
          loading={deleting}
          onClick={handleDeleteClick}
          type="button"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default DeleteLocationModal;
