import Konva from 'konva';
import React, { ForwardedRef, useCallback, useMemo } from 'react';
import { Layer, Rect, Stage } from 'react-konva';

import { CircleSpinner } from '../../../components/loader';
import { SvgFallbackImage } from '../../../components/svg';
import { FULL_HEIGHT, FULL_WIDTH } from '../../../constants/canvas';
import { useImage } from '../../../hooks';
import styles from './canvas.module.css';
import Mark, { IMark } from './mark';

type CanvasProps = {
  marks: IMark[];
  onMarkChange: (mark: IMark) => void;
  selectedShape: null | string;
  setSelectedShape: (marking: IMark | string) => void; // mark or mark.markerId
  snapshot: string;
};

function Canvas(props: CanvasProps, ref: ForwardedRef<Konva.Layer>) {
  const { marks, onMarkChange, selectedShape, setSelectedShape, snapshot } =
    props;

  const cameraImage = useMemo(
    () => `${process.env.REACT_APP_API_URL}${snapshot}`,
    [snapshot]
  );

  const [image, status] = useImage(cameraImage);
  // TODO: Remove when API is fixed
  // const [image, status] = useImage('https://api.indivd.com/media/camera_images/2023/07/snapshot_FecbSOP.jpg');

  const selectedMark = useMemo(
    () => marks.find((mark) => mark.markerId === selectedShape),
    [marks, selectedShape]
  );

  const proportion = useMemo(() => {
    const { width = FULL_WIDTH } = (image as HTMLImageElement) || {};

    return FULL_WIDTH / width;
  }, [image]);

  const checkDeselect = useCallback(
    (
      e: Konva.KonvaEventObject<MouseEvent> | Konva.KonvaEventObject<TouchEvent>
    ) => {
      const clickedOnEmpty = e.target === e.target.getStage();
      if (clickedOnEmpty) {
        if (selectedMark && selectedMark.parentId) {
          setSelectedShape(selectedMark.parentId);
        } else {
          setSelectedShape(null);
        }
      }
    },
    [selectedMark, setSelectedShape]
  );

  return (
    <>
      <div style={{ height: 0, overflow: 'hidden', width: 0 }}>
        <img src={cameraImage} />
      </div>
      {status === 'loading' ? (
        <div
          className={styles.spinnerContainer}
          style={{ height: FULL_HEIGHT, width: FULL_WIDTH }}
        >
          <CircleSpinner className={styles.spinner} />
        </div>
      ) : (
        <>
          {status === 'failed' ? (
            <SvgFallbackImage height={FULL_HEIGHT} width={FULL_WIDTH} />
          ) : (
            <Stage
              height={FULL_HEIGHT}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              width={FULL_WIDTH}
            >
              <Layer>
                <Rect
                  fillPatternImage={image as HTMLImageElement}
                  fillPatternScaleX={proportion}
                  fillPatternScaleY={proportion}
                  height={FULL_HEIGHT}
                  listening={false}
                  width={FULL_WIDTH}
                />
                {marks
                  .filter((mark) => {
                    if (mark.parentId) {
                      const parentId = selectedMark?.parentId;
                      const markerId = selectedMark?.markerId;

                      if (parentId) {
                        return (
                          mark.parentId === parentId ||
                          mark.markerId === parentId
                        );
                      }

                      return markerId && mark.parentId === markerId;
                    }

                    return true;
                  })
                  .map((mark) => (
                    <Mark
                      isSelected={mark.markerId === selectedShape}
                      key={mark.markerId}
                      listening={
                        !selectedShape ||
                        selectedShape === mark.markerId ||
                        (!mark.parentId &&
                          selectedMark?.parentId === mark.markerId) ||
                        (!!mark.parentId &&
                          (mark.parentId === selectedMark?.markerId ||
                            mark.parentId === selectedMark?.parentId ||
                            mark.markerId === selectedMark?.parentId))
                      }
                      onChange={onMarkChange}
                      onSelect={() => setSelectedShape(mark.markerId)}
                      shapeProps={mark}
                    />
                  ))}
              </Layer>
              <Layer ref={ref}></Layer>
            </Stage>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(React.forwardRef(Canvas));
