import { RootState } from '../reducers';

export const modalTypeSelector = (state: RootState) => state.modals.modalType;

export const modalPropsSelector = (state: RootState) => state.modals.modalProps;

export const modalIsShownSelector = (state: RootState) => state.modals.isShown;

export const cameraBehaviorSectionSelector = (state: RootState) =>
  state.modals.cameraBehaviorSection;

export const inviteCoworkerSectionSelector = (state: RootState) =>
  state.modals.inviteCoworkerSection;
