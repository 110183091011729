import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { go } from 'redux-first-history';

import { hideModalToast } from '../../actions/modal-toasts';
import { ModalToast } from '../../components/miscellaneous';
import { modalToastSelector } from '../../selectors/modal-toasts';
import styles from './modal-toast-container.module.css';

const DURATION = 250;

type ModalToastContainerProps = {
  left?: number;
};

function ModalToastContainer(props: ModalToastContainerProps) {
  const { left = 0 } = props;

  const toast = useSelector(modalToastSelector);

  const [anim, set] = useSpring(() => ({
    config: { duration: DURATION },
    opacity: 0,
  }));

  const dispatch = useDispatch();

  const hide = useCallback(() => {
    set({ opacity: 0 });

    setTimeout(() => {
      dispatch(hideModalToast());
    }, DURATION);
  }, [dispatch, set]);

  const reload = useCallback(() => {
    dispatch(go(0));
  }, [dispatch]);

  const child = useMemo(
    () =>
      toast && (
        <ModalToast hide={hide} reload={reload} {...toast} key={toast.id} />
      ),
    [hide, reload, toast]
  );

  useEffect(() => {
    if (toast) {
      set({ opacity: 1 });
    }
  }, [set, toast]);

  return (
    <div className={styles.container} style={{ left, right: 0 }}>
      <animated.div style={anim}>{child}</animated.div>
    </div>
  );
}

export default ModalToastContainer;
