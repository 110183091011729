import { ChangeEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import Header from '../header';
import { SvgEdit } from '../../../components/svg';
import { Button, Input, Label } from '../../../components/form';
import { filtersUpdatingSelector } from '../../../selectors/filters';
import { updateFilterRequest } from '../../../actions/filters';
import { IFilter } from '../../../types/api/filters';

type EditFilterProps = {
  close: () => void;
  filter: IFilter;
};

function EditFilter(props: EditFilterProps) {
  const { filter, close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('modals', { keyPrefix: 'editFilter' });

  const [name, setName] = useState(filter.name);

  const loading = useSelector(filtersUpdatingSelector);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      dispatch(
        updateFilterRequest(filter.id, {
          field_key: filter.field_key,
          field_val: filter.field_val,
          name,
        })
      );
    },
    [dispatch, filter, name]
  );

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Header icon={<SvgEdit width={19} height={19} />} title={t('title')} />
      <Label className={styles.label}>
        {t('label.name')}
        <Input
          dashed={false}
          name="name"
          onChange={handleInputChange}
          placeholder={t('placeholder.name')}
          required
          type="text"
          value={name}
        />
      </Label>
      <div className={styles.buttons}>
        <Button
          containerClassName={styles.buttonContainer}
          className={styles.button}
          type="button"
          onClick={close}
        >
          {t('cancel')}
        </Button>
        <Button
          className={styles.button}
          color="secondary"
          containerClassName={styles.buttonContainer}
          disabled={!name}
          loading={loading}
          type="submit"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default EditFilter;
