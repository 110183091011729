function SvgLogo(props: React.SVGProps<SVGSVGElement>) {
  const { color, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 175.03 40"
      {...rest}
    >
      <path
        fill={color}
        d="M0 9.932h7.477v29.586H0zM31.24 9.504a11.855 11.855 0 00-5.1 1.095 10.3 10.3 0 00-3.814 3.018l-3.525 4.914-3.953 5.5v15.488h7.478V23.175a7.592 7.592 0 011.789-5.42 6.4 6.4 0 014.886-1.9 6.243 6.243 0 014.8 1.9 7.672 7.672 0 011.764 5.42v16.343h7.477V22.163q0-5.982-3.258-9.32a11.4 11.4 0 00-8.544-3.339zM72.521 14.045a11.018 11.018 0 00-4.166-3.339 12.609 12.609 0 00-5.5-1.254 12.9 12.9 0 00-11.778 7.209 17.127 17.127 0 00-1.789 7.958 17.374 17.374 0 001.789 8.036 13.384 13.384 0 004.886 5.421A12.707 12.707 0 0062.8 40a11.811 11.811 0 005.474-1.123 13.654 13.654 0 004.248-3.739l3.685-4.754 3.9-5.071V0h-7.583v14.045zm-1.015 15.353a7.524 7.524 0 01-2.883 2.99 7.706 7.706 0 01-3.9 1.044 7.3 7.3 0 01-3.816-1.069 7.881 7.881 0 01-2.886-3.072 9.575 9.575 0 01-1.095-4.672 9.27 9.27 0 011.095-4.618 7.642 7.642 0 012.858-2.965 7.564 7.564 0 013.846-1.016 7.7 7.7 0 013.9 1.044 7.516 7.516 0 012.883 2.99 10.742 10.742 0 010 9.344zM87.422 9.932h7.477v29.586h-7.477zM115.672 32.63l-7.477-22.7h-8.011l10.894 29.586h9.081l10.945-29.584h-7.958zM157.7 14.045a11 11 0 00-4.162-3.339 12.625 12.625 0 00-5.5-1.254 12.9 12.9 0 00-11.774 7.209 17.114 17.114 0 00-1.789 7.958 17.374 17.374 0 001.789 8.036 13.336 13.336 0 004.886 5.421 12.7 12.7 0 006.835 1.925 11.832 11.832 0 005.474-1.123 13.607 13.607 0 004.244-3.739l3.685-4.754 3.9-5.071V0h-7.583v14.045zm-1.012 15.353a7.508 7.508 0 01-2.886 2.99 7.693 7.693 0 01-3.9 1.044 7.332 7.332 0 01-3.821-1.069 7.871 7.871 0 01-2.883-3.072 9.574 9.574 0 01-1.095-4.672 9.269 9.269 0 011.095-4.618 7.64 7.64 0 012.858-2.965 7.554 7.554 0 013.845-1.016 7.689 7.689 0 013.9 1.044 7.5 7.5 0 012.886 2.99 10.778 10.778 0 010 9.344z"
      />
      <circle
        cx={4.818}
        cy={4.818}
        r={4.818}
        transform="translate(165.394 30.363)"
        fill={color}
      />
    </svg>
  );
}

SvgLogo.defaultProps = {
  color: 'white',
};

export default SvgLogo;
