function SvgRedo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.17}
      height={19.122}
      viewBox="0 0 21.17 19.122"
      {...props}
    >
      <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
        <path d="M20.463 1.71v5.965h-5.965" />
        <path d="M17.968 12.647a8.948 8.948 0 1 1-2.108-9.306l4.6 4.335" />
      </g>
    </svg>
  );
}

export default SvgRedo;
