function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 9.664 13"
      {...props}
    >
      <g
        fill="none"
        stroke="#a4322b"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4.832.5v12M8.957 8.388L4.832 12.5.707 8.388" />
      </g>
    </svg>
  );
}

export default SvgArrowDown;
