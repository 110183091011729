import { ChangeEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { saveFilterRequest } from '../../../actions/filters';
import { Button, Input, Label } from '../../../components/form';
import { SvgEdit } from '../../../components/svg';
import { filtersSavingSelector } from '../../../selectors/filters';
import Header from '../header';
import styles from './index.module.css';

type SaveFilterProps = {
  close: () => void;
};

function SaveFilter(props: SaveFilterProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('modals', { keyPrefix: 'saveFilter' });

  const [name, setName] = useState('');

  const loading = useSelector(filtersSavingSelector);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      dispatch(saveFilterRequest(name));
    },
    [dispatch, name]
  );

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Header icon={<SvgEdit height={19} width={19} />} title={t('title')} />
      <Label className={styles.label}>
        {t('label.name')}
        <Input
          dashed={false}
          name="name"
          onChange={handleInputChange}
          placeholder={t('placeholder.name')}
          required
          type="text"
          value={name}
        />
      </Label>
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          containerClassName={styles.buttonContainer}
          onClick={close}
          type="button"
        >
          {t('cancel')}
        </Button>
        <Button
          className={styles.button}
          color="secondary"
          containerClassName={styles.buttonContainer}
          disabled={!name}
          loading={loading}
          type="submit"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default SaveFilter;
