import {
  ACTIVATE_INVITED_USER_FAILURE,
  ACTIVATE_INVITED_USER_REQUEST,
  ACTIVATE_INVITED_USER_SUCCESS,
  BATCH_UPDATE_USERS_FAILURE,
  BATCH_UPDATE_USERS_REQUEST,
  BATCH_UPDATE_USERS_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DECLINE_INVITATION_FAILURE,
  DECLINE_INVITATION_REQUEST,
  DECLINE_INVITATION_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_INVITEES_FAILURE,
  GET_INVITEES_REQUEST,
  GET_INVITEES_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  LIST_USER_GROUPS_FAILURE,
  LIST_USER_GROUPS_REQUEST,
  LIST_USER_GROUPS_SUCCESS,
  LIST_USERS_FAILURE,
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LOCATION_TREE_FAILURE,
  LOCATION_TREE_REQUEST,
  LOCATION_TREE_SUCCESS,
  PATCH_USER_INFO_FAILURE,
  PATCH_USER_INFO_REQUEST,
  PATCH_USER_INFO_SUCCESS,
  REMOVE_USER_FAILURE,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  RESET_TOKEN,
  SELECT_USER_ALL,
  SELECT_USER_WITH_CTRL,
  SELECT_USER_WITH_SHIFT,
  SELECT_USER_WITHOUT,
  SEND_INVITATION_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../constants/action-types';

export const initialState = {
  activatingInvitedUser: false,
  count: 0,
  creating: false,
  decliningInvitation: false,
  deletingUser: false,
  fetchingLocationTree: false,
  gettingInvitees: false,
  gettingUser: false,
  invitees: [],
  invitingUser: false,
  lastDeletedAt: null,
  lastSelected: '',
  listing: false,
  listingUserGroups: false,
  locationTree: [],
  patching: false,
  patchingInfo: false,
  removing: false,
  selected: [],
  sendingInvitation: false,
  updating: false,
  user: {},
  users: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_USER_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        creating: false,
        users: [user, ...state.users],
      };
    }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        creating: false,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_USER_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        updating: false,
        user:
          // @ts-ignore
          user.id === state.user.id
            ? {
                ...state.user,
                ...user,
              }
            : state.user,
        users: state.users.map((u) => {
          if (u.id === user.id) {
            return user;
          }
          return u;
        }),
      };
    }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case BATCH_UPDATE_USERS_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case BATCH_UPDATE_USERS_SUCCESS: {
      const { fieldName, ids, value } = action;
      const { users } = state;

      return {
        ...state,
        updating: false,
        users: users.map((user) => {
          if (ids.includes(user.id)) {
            return {
              ...user,
              [fieldName]: value,
            };
          }
          return user;
        }),
      };
    }
    case BATCH_UPDATE_USERS_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case DECLINE_INVITATION_REQUEST:
      return {
        ...state,
        decliningInvitation: true,
      };
    case DECLINE_INVITATION_SUCCESS: {
      const { ids } = action;
      const { invitees } = state;

      return {
        ...state,
        decliningInvitation: false,
        invitees: invitees.filter((invitee) => !ids.includes(invitee.id)),
      };
    }
    case DECLINE_INVITATION_FAILURE:
      return {
        ...state,
        decliningInvitation: false,
      };
    case SEND_INVITATION_REQUEST:
      return {
        ...state,
        sendingInvitation: true,
      };
    case SEND_INVITATION_SUCCESS: {
      const { invitees } = state;
      const { ids } = action;

      return {
        ...state,
        invitees: invitees.filter((invitee) => !ids.includes(invitee.id)),
        sendingInvitation: false,
      };
    }
    case SEND_INVITATION_FAILURE:
      return {
        ...state,
        sendingInvitation: false,
      };
    case GET_INVITEES_REQUEST:
      return {
        ...state,
        gettingInvitees: true,
      };
    case GET_INVITEES_SUCCESS: {
      const { invitees } = action;

      return {
        ...state,
        gettingInvitees: false,
        invitees,
      };
    }
    case GET_INVITEES_FAILURE:
      return {
        ...state,
        gettingInvitees: false,
      };
    case REMOVE_USER_REQUEST:
      return {
        ...state,
        removing: true,
      };
    case REMOVE_USER_SUCCESS: {
      const { users } = state;
      const { uid } = action;

      const index = users.findIndex((u) => u.id === uid);

      return {
        ...state,
        removing: false,
        users: [...users.slice(0, index), ...users.slice(index + 1)],
      };
    }
    case REMOVE_USER_FAILURE:
      return {
        ...state,
        removing: false,
      };
    case INVITE_USER_REQUEST:
      return {
        ...state,
        invitingUser: true,
      };
    case INVITE_USER_SUCCESS: {
      const { invitee } = action;
      const { invitees } = state;

      return {
        ...state,
        invitees: [...invitees, invitee],
        invitingUser: false,
      };
    }
    case INVITE_USER_FAILURE:
      return {
        ...state,
        invitingUser: false,
      };
    case ACTIVATE_INVITED_USER_REQUEST:
      return {
        ...state,
        activatingInvitedUser: true,
      };
    case ACTIVATE_INVITED_USER_SUCCESS: {
      return {
        ...state,
        activatingInvitedUser: false,
      };
    }
    case ACTIVATE_INVITED_USER_FAILURE:
      return {
        ...state,
        activatingInvitedUser: false,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        deletingUser: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deletingUser: false,
        lastDeletedAt: new Date().toJSON(),
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        deletingUser: false,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        gettingUser: true,
      };
    case GET_USER_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        gettingUser: false,
        user,
      };
    }
    case GET_USER_FAILURE:
      return {
        ...state,
        gettingUser: false,
      };
    case PATCH_USER_INFO_REQUEST:
      return {
        ...state,
        patchingInfo: true,
      };
    case PATCH_USER_INFO_SUCCESS: {
      const { user } = action;

      return {
        ...state,
        patchingInfo: false,
        user: {
          ...state.user,
          ...user,
        },
      };
    }
    case PATCH_USER_INFO_FAILURE:
      return {
        ...state,
        patchingInfo: false,
      };
    case LIST_USER_GROUPS_REQUEST:
      return {
        ...state,
        listingUserGroups: true,
      };
    case LIST_USER_GROUPS_SUCCESS: {
      const { groups } = action;

      return {
        ...state,
        groups,
      };
    }
    case LIST_USER_GROUPS_FAILURE:
      return {
        ...state,
        listingUserGroups: false,
      };
    case LIST_USERS_REQUEST:
      return {
        ...state,
        listing: true,
      };
    case LIST_USERS_SUCCESS: {
      const { count, users } = action;

      return {
        ...state,
        count,
        listing: false,
        users,
      };
    }
    case LIST_USERS_FAILURE:
      return {
        ...state,
        listingUsers: false,
      };
    case LOCATION_TREE_REQUEST:
      return {
        ...state,
        fetchingLocationTree: true,
      };
    case LOCATION_TREE_SUCCESS: {
      const { locationTree } = action;

      return {
        ...state,
        fetchingLocationTree: false,
        locationTree,
      };
    }
    case LOCATION_TREE_FAILURE:
      return {
        ...state,
        fetchingLocationTree: false,
      };
    case SELECT_USER_WITH_SHIFT: {
      const { id } = action;
      const { lastSelected, users } = state;

      if (!lastSelected || id === lastSelected) {
        return {
          ...state,
          lastSelected: id,
          selected: [id],
        };
      }

      const index = users.findIndex((u) => id === u.id);
      const indexLastSelected = users.findIndex((u) => lastSelected === u.id);

      let selected: number[];
      if (index > indexLastSelected) {
        selected = users.slice(indexLastSelected, index + 1).map((u) => u.id);
      } else {
        selected = users.slice(index, indexLastSelected + 1).map((u) => u.id);
      }

      return {
        ...state,
        selected,
      };
    }
    case SELECT_USER_WITH_CTRL: {
      const { selected } = state;
      const { id } = action;
      const index = state.selected.indexOf(id);

      if (index === -1) {
        const newSelected = [...selected, id];

        return {
          ...state,
          lastSelected: id,
          selected: newSelected,
        };
      }

      const newSelected = [
        ...selected.slice(0, index),
        ...selected.slice(index + 1),
      ];
      let previousSelection = 0;
      if (newSelected.length) {
        previousSelection = newSelected[newSelected.length - 1];
      }

      return {
        ...state,
        lastSelected: previousSelection,
        selected: newSelected,
      };
    }
    case SELECT_USER_WITHOUT: {
      const { selected } = state;
      const { id } = action;
      const isThisOne = selected[0] === id;
      const isOnlyOne = selected.length === 1;

      const newSelected = isOnlyOne && isThisOne ? [] : [action.id];

      return {
        ...state,
        lastSelected: isOnlyOne && isThisOne ? 0 : action.id,
        selected: newSelected,
      };
    }
    case SELECT_USER_ALL: {
      const { selected, users } = state;

      return {
        ...state,
        selected: selected.length > 0 ? [] : users.map((u) => u.id),
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
