import {
  FOLDOUT_STATE_CHANGED,
  HIDE_FOLDOUT,
  SHOW_FOLDOUT,
} from '../constants/action-types';
import { FoldoutSize } from '../reducers/foldouts';

export const showFoldout = (
  foldoutType: string,
  foldoutProps?: Record<string, unknown>,
  foldoutSize?: FoldoutSize
) => ({
  foldoutProps,
  foldoutSize,
  foldoutType,
  type: SHOW_FOLDOUT,
});

export const hideFoldout = () => ({
  type: HIDE_FOLDOUT,
});

export const foldoutStateChanged = (changed: boolean) => ({
  changed,
  type: FOLDOUT_STATE_CHANGED,
});
