import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { foldoutStateChanged } from '../../../actions/foldouts';
import { createUserRequest } from '../../../actions/users';
import {
  Button,
  ButtonGroup,
  CategorizedMultiSelect,
  Checkbox,
  Input,
  Label,
  Select,
} from '../../../components/form';
import { FourStepProgress } from '../../../components/miscellaneous';
import { SvgUserPlus } from '../../../components/svg';
import { PasswordStrengthTooltip } from '../../../components/tooltip';
import wr from '../../../constants/workrole';
import {
  creatingUserSelector,
  locationTreeSelector,
} from '../../../selectors/users';
import { createUserValidationSelector } from '../../../selectors/validations';
import { TStrength, calculateStrength } from '../../../utils/helper';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

type CreateUserProps = {
  close: () => void;
};

function CreateUser(props: CreateUserProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'createUser' });

  const [t2] = useTranslation();

  const submitting = useSelector(creatingUserSelector);
  const validation = useSelector(createUserValidationSelector);
  const organizations = useSelector(locationTreeSelector);

  const [strength, setStrength] = useState<[number, TStrength]>([
    0,
    'Unavailable',
  ]);
  const [showPassword, setShowPassword] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedWorkRole, setSelectedWorkRole] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  }, []);

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.trim();
      setPassword(val);
      setStrength(calculateStrength(val));
    },
    []
  );

  const handleTipOver = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleTipOut = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const handleFirstNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    },
    []
  );

  const handleLastNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    const payload = {
      email,
      first_name: firstName,
      group_name: selectedRole?.value,
      last_name: lastName,
      locations: selectedLocation
        .filter((l) => l.indent === 3)
        .map((l) => l.value),
      password,
      work_role: selectedWorkRole?.value,
    };
    dispatch(createUserRequest(payload));
  }, [
    dispatch,
    email,
    firstName,
    lastName,
    password,
    selectedLocation,
    selectedRole,
    selectedWorkRole,
  ]);

  const isUpdated = useMemo(
    () =>
      email !== '' ||
      password !== '' ||
      firstName !== '' ||
      lastName !== '' ||
      selectedWorkRole !== null ||
      selectedRole !== null ||
      selectedLocation.length !== 0,
    [
      email,
      firstName,
      lastName,
      password,
      selectedLocation.length,
      selectedRole,
      selectedWorkRole,
    ]
  );

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  return (
    <>
      <Header
        icon={<SvgUserPlus height={20} width={20} />}
        title={t('title')}
      />
      <Main>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.email')}
            <Input
              autoComplete="off"
              dashed={false}
              name="email"
              onChange={handleEmailChange}
              placeholder={t('placeholder.email')}
              type="email"
              validation={validation}
              validationKey="createUser"
              value={email}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.password')}
            <Input
              autoComplete="off"
              blackDisabled
              dashed={false}
              name="password"
              onChange={handlePasswordChange}
              placeholder={t('placeholder.password')}
              type={showPassword ? 'text' : 'password'}
              validation={validation}
              validationKey="createUser"
              value={password}
            />
            <FourStepProgress
              className={styles.fourStepProgress}
              onTipOut={handleTipOut}
              onTipOver={handleTipOver}
              showTooltip={showTooltip}
              strength={strength}
              tooltip={<PasswordStrengthTooltip password={password} />}
            />
            <Checkbox
              checked={showPassword}
              className={styles.checkbox}
              label={t('label.showPassword')}
              labelClass={styles.checkboxLabel}
              onClick={() => setShowPassword(!showPassword)}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.role')}
            <Select
              dashed={false}
              name="group_name"
              options={['0', '1'].map((key) => ({
                label: t2(`role.${key}`),
                value: key,
              }))}
              placeholder={t('placeholder.role')}
              selected={selectedRole}
              setSelected={setSelectedRole}
              validation={validation}
              validationKey="createUser"
              zIndex={27}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.workRole')}
            <Select
              dashed={false}
              name="work_role"
              options={Object.keys(wr).map((key) => ({
                label: t2(`workrole.${wr[key]}`),
                value: key,
              }))}
              placeholder={t('placeholder.workRole')}
              selected={selectedWorkRole}
              setSelected={setSelectedWorkRole}
              validation={validation}
              validationKey="createUser"
              zIndex={26}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.locationAccess')}
            <CategorizedMultiSelect
              dashed={false}
              name="locations"
              options={organizations}
              placeholder={t('placeholder.locationAccess')}
              selected={selectedLocation}
              setSelected={setSelectedLocation}
              validation={validation}
              validationKey="createUser"
              zIndex={25}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.firstName')}
            <Input
              dashed={false}
              name="first_name"
              onChange={handleFirstNameChange}
              placeholder={t('placeholder.firstName')}
              type="text"
              validation={validation}
              validationKey="createUser"
              value={firstName}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.lastName')}
            <Input
              dashed={false}
              name="last_name"
              onChange={handleLastNameChange}
              placeholder={t('placeholder.lastName')}
              type="text"
              validation={validation}
              validationKey="createUser"
              value={lastName}
            />
          </Label>
        </div>
      </Main>
      <Footer>
        <ButtonGroup>
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default CreateUser;
