import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectedZonesSelector } from '../../../selectors/zones';
import styles from './options-bar.module.css';

function OptionsBar() {
  const [t] = useTranslation('organization', { keyPrefix: 'zones' });

  const selected = useSelector(selectedZonesSelector);

  return (
    <div className={styles.optionsBar}>
      <div className={styles.selected}>
        {t('selected', { count: selected.length })}
      </div>
    </div>
  );
}

export default OptionsBar;
