import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import Header from '../header';
import { Input, Label, Button, Select } from '../../../components/form';
import { SvgEdit } from '../../../components/svg';
import { EntityCount } from '../../../components/text';
import { batchUpdateCamerasRequest } from '../../../actions/cameras';
import {
  batchUpdatingCamerasSelector,
  selectedCamerasSelector,
} from '../../../selectors/cameras';
import { setInitInputValue } from '../../../utils/camera';
import floors from '../../../constants/floors';
import { batchUpdateCamerasValidationSelector } from '../../../selectors/validations';
import { showModalToast } from '../../../actions/modal-toasts';

type BulkEditCameraProps = {
  close: () => void;
};

function BulkEditCamera(props: BulkEditCameraProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('modals', { keyPrefix: 'bulkEditCamera' });

  const [t2] = useTranslation();

  const [propertyName, setPropertyName] = useState<string>('');

  const [propertyValue, setPropertyValue] = useState<string>('');

  const cameraIds = useSelector(selectedCamerasSelector);

  const updating = useSelector(batchUpdatingCamerasSelector);

  const validation = useSelector(batchUpdateCamerasValidationSelector);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPropertyValue(e.target.value);
  }, []);

  const setSelectedPropertyName = useCallback((option: Option) => {
    setPropertyName(option?.value || null);
    setPropertyValue(setInitInputValue(option?.value));
  }, []);

  const handleSubmit = useCallback(() => {
    dispatch(batchUpdateCamerasRequest(cameraIds, propertyName, propertyValue));
  }, [cameraIds, dispatch, propertyName, propertyValue]);

  const inputs = useMemo(
    () => ({
      name: (
        <Label className={styles.label}>
          {t('label.name')}
          <Input
            dashed={false}
            name="name"
            onChange={handleInputChange}
            placeholder={t('placeholder.name')}
            type="text"
            value={propertyValue}
          />
        </Label>
      ),
      floor: (
        <Label className={styles.label}>
          {t('label.floor')}
          <Select
            dashed={false}
            zIndex={1}
            placeholder={t('placeholder.floor')}
            options={floors.map((floor) => ({
              label: t2(`floors.${floor}`),
              value: floor.toString(),
            }))}
            selected={
              propertyValue
                ? { label: t2(`floors.${propertyValue}`), value: propertyValue }
                : null
            }
            setSelected={(option: Option) =>
              setPropertyValue(option?.value || null)
            }
          />
        </Label>
      ),
    }),
    [handleInputChange, propertyValue, t, t2]
  );

  useEffect(() => {
    const val = validation.find((v) => !v.field);

    if (val) {
      dispatch(showModalToast({ message: val.message, level: 'failure' }));
    }
  }, [dispatch, validation]);

  return (
    <div className={styles.container}>
      <Header icon={<SvgEdit width={19} height={19} />} title={t('title')}>
        <EntityCount>
          <Trans
            t={t}
            i18nKey="total"
            components={{
              bold: <b />,
            }}
            values={{
              count: cameraIds.length,
            }}
          />
        </EntityCount>
      </Header>
      <Label className={styles.label}>
        {t('editProperties')}
        <Select
          dashed={false}
          placeholder={t('placeholder.editProperties')}
          zIndex={2}
          selected={
            propertyName && {
              label: propertyName && t(`label.${propertyName}`),
              value: propertyName,
            }
          }
          setSelected={setSelectedPropertyName}
          options={Object.keys(inputs).map((key) => ({
            label: t(`label.${key}`),
            value: key,
          }))}
        />
      </Label>
      {inputs[propertyName]}
      <div className={styles.buttons}>
        <Button
          className={styles.button}
          containerClassName={styles.buttonContainer}
          onClick={close}
          type="button"
        >
          {t('cancel')}
        </Button>
        <Button
          className={styles.button}
          color="secondary"
          containerClassName={styles.buttonContainer}
          disabled={!propertyValue}
          loading={updating}
          onClick={handleSubmit}
          type="button"
        >
          {t('update')}
        </Button>
      </div>
    </div>
  );
}

export default BulkEditCamera;
