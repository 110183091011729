import { animated } from 'react-spring';

type SvgUncheckedProps = {
  disabled: boolean;
};

function SvgUnchecked(
  props: React.SVGProps<SVGSVGElement> &
    React.RefAttributes<SVGSVGElement> &
    SvgUncheckedProps
) {
  const { disabled = false, ...rest } = props;

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path d="M0 0h20v20H0z" fill={disabled ? '#CCCCCC' : '#fff'} />
    </animated.svg>
  );
}

export default SvgUnchecked;
