import styles from './entity-count.module.css';

type EntityCountProps = {
  children: JSX.Element;
};

function EntityCount(props: EntityCountProps) {
  const { children } = props;

  return <div className={styles.entityCount}>{children}</div>;
}

export default EntityCount;
