import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  cameraLineMultilineRequest,
  lastEnteringsRequest,
  lineTypeMultilineRequest,
  locationMultilineRequest,
  totalVisitorsRequest,
  totalVisitsRequest,
  zoneFromMultilineRequest,
  zoneToMultilineRequest,
} from '../../../../actions/insights';
import { resetFilters } from '../../../../actions/segmentation';
import { Button } from '../../../../components/form';
import { SvgLayers } from '../../../../components/svg';
import { filtersChangedSelector } from '../../../../selectors/filters';
import {
  insightsRequestsEnabledSelector,
  selectedDateSelector,
  selectedEndDateSelector,
  selectedLineTypeSelector,
  selectedOrganizationSelector,
  selectedPropertySelector,
  selectedStartDateSelector,
  selectedZoneFromSelector,
  selectedZoneToSelector,
} from '../../../../selectors/segmentation';
import FilterWrapper from '../../filter-wrapper';
import CustomDateFilter from './filters/custom-date-filter';
import DateFilter from './filters/date-filter';
import FloorFilter from './filters/floor-filter';
import LineFilter from './filters/line-filter';
import LocationFilter from './filters/location-filter';
import OrganizationFilter from './filters/organization-filter';
import TypeFilter from './filters/type-filter';
import ZoneFromFilter from './filters/zone-from-filter';
import ZoneToFilter from './filters/zone-to-filter';
import styles from './index.module.css';
import SecondVariable from './properties/second-variable';
import SavedFilters from './saved-filters';

function Dashboard() {
  const dispatch = useDispatch();

  const [t] = useTranslation('sidebar', { keyPrefix: 'dashboard' });

  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const selectedDate = useSelector(selectedDateSelector);
  const selectedStartDate = useSelector(selectedStartDateSelector);
  const selectedEndDate = useSelector(selectedEndDateSelector);
  const selectedProperty = useSelector(selectedPropertySelector);
  const selectedLineType = useSelector(selectedLineTypeSelector);
  const selectedZoneFrom = useSelector(selectedZoneFromSelector);
  const selectedZoneTo = useSelector(selectedZoneToSelector);
  const enabled = useSelector(insightsRequestsEnabledSelector);

  const filtersChanged = useSelector(filtersChangedSelector);

  const handleClearClick = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const handleApplyClick = useCallback(() => {
    if (selectedOrganization) {
      if (selectedDate) {
        if (selectedDate?.value === 'custom') {
          if (selectedStartDate && selectedEndDate) {
            dispatch(totalVisitsRequest());
            dispatch(locationMultilineRequest());

            // if (selectedZoneFrom.length === 0 && selectedZoneTo.length === 0) {
            dispatch(lineTypeMultilineRequest());
            // }

            if (selectedLineType.length === 0) {
              dispatch(zoneFromMultilineRequest());
              dispatch(zoneToMultilineRequest());
            }

            // Prevent tooltip issue for too many lines
            if (selectedProperty?.value !== '1m') {
              dispatch(cameraLineMultilineRequest());
            }
          }
        } else {
          dispatch(totalVisitsRequest());
          dispatch(locationMultilineRequest());

          // if (selectedZoneFrom.length === 0 && selectedZoneTo.length === 0) {
          dispatch(lineTypeMultilineRequest());
          // }

          // if (selectedLineType.length === 0) {
          dispatch(zoneFromMultilineRequest());
          dispatch(zoneToMultilineRequest());
          // }

          // Prevent tooltip issue for too many lines
          if (selectedProperty?.value !== '1m') {
            dispatch(cameraLineMultilineRequest());
          }
        }
      }
      dispatch(lastEnteringsRequest());

      if (String(selectedOrganization?.value) === String(17)) {
        dispatch(totalVisitorsRequest());
      }
    }
  }, [
    dispatch,
    selectedDate,
    selectedEndDate,
    selectedLineType,
    selectedOrganization,
    selectedProperty,
    selectedStartDate,
  ]);

  return (
    <div>
      <SavedFilters label={t('savedFilters.title')} />
      <FilterWrapper
        icon={<SvgLayers color="var(--black-40-)" height={16} width={16} />}
        title={t('propertiesTitle')}
      >
        <SecondVariable
          label={t('secondVariable.label')}
          placeholder={t('secondVariable.placeholder')}
        />
      </FilterWrapper>
      <FilterWrapper
        className={styles.filterWrapper}
        footer={
          <>
            <Button color="primary" onClick={handleClearClick} type="button">
              {t('clear')}
            </Button>
            <Button
              className={styles.apply}
              color="secondary"
              disabled={!enabled || !filtersChanged}
              onClick={handleApplyClick}
              type="button"
            >
              {t('apply')}
            </Button>
          </>
        }
        title={t('filterTitle')}
      >
        <DateFilter
          label={t('date.label')}
          placeholder={t('date.placeholder')}
        />

        <CustomDateFilter
          labelEndDate={t('endDate.label')}
          labelStartDate={t('startDate.label')}
          placeholderEndDate={t('endDate.placeholder')}
          placeholderStartDate={t('startDate.placeholder')}
        />

        <OrganizationFilter
          label={t('organization.label')}
          placeholder={t('organization.placeholder')}
        />

        <LocationFilter
          label={t('location.label')}
          placeholder={t('location.placeholder')}
        />

        <FloorFilter
          label={t('floor.label')}
          placeholder={t('floor.placeholder')}
        />

        <ZoneFromFilter
          label={t('zoneFrom.label')}
          placeholder={t('zoneFrom.placeholder')}
        />

        <ZoneToFilter
          label={t('zoneTo.label')}
          placeholder={t('zoneTo.placeholder')}
        />

        <TypeFilter
          label={t('type.label')}
          placeholder={t('type.placeholder')}
        />

        <LineFilter
          label={t('line.label')}
          placeholder={t('line.placeholder')}
        />
      </FilterWrapper>
    </div>
  );
}

export default Dashboard;
