import { CSSProperties, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring';
import { bindActionCreators } from 'redux';

import { hideModalToast as hideModalToastAction } from '../../actions/modal-toasts';
import { hideModal as hideModalAction } from '../../actions/modals';
import {
  ACTIVATE_CAMERA_MODAL,
  BULK_EDIT_CAMERA_MODAL,
  BULK_EDIT_USER_MODAL,
  DELETE_CAMERA_MODAL,
  DELETE_FILTER_MODAL,
  DELETE_LOCATION_MODAL,
  DELETE_USER_MODAL,
  DELETE_ZONE_MODAL,
  EDIT_FILTER_MODAL,
  INVITE_COWORKER_MODAL,
  INVITE_PARTNER_MODAL,
  NEW_DASHBOARD_MODAL,
  REQUESTS_MODAL,
  SAVE_FILTER_MODAL,
  UNSAVED_CHANGES_MODAL,
  UPGRADE_WARNING_MODAL,
} from '../../constants/modal-types';
import { FoldoutSize } from '../../reducers/foldouts';
import {
  modalIsShownSelector,
  modalPropsSelector,
  modalTypeSelector,
} from '../../selectors/modals';
import ActivateCameraModal from './activate-camera';
import BulkEditCamera from './bulk-edit-camera';
import BulkEditUser from './bulk-edit-user';
import DeleteCameraModal from './delete-camera';
import DeleteFilterModal from './delete-filter';
import DeleteLocationModal from './delete-location';
import DeleteUserModal from './delete-user';
import DeleteZoneModal from './delete-zone';
import EditFilter from './edit-filter';
import styles from './index.module.css';
import InviteCoworker from './invite-coworker';
import InvitePartner from './invite-partner';
import ModalToastContainer from './modal-toast-container';
import NewDashboardModal from './new-dashboard';
import Requests from './requests';
import SaveFilter from './save-filter';
import UnsavedChangesModal from './unsaved-changes';
import UpgradeWarningModal from './upgrade-warning';

const modalRoot = document.getElementById('modal');

const MODAL_COMPONENTS = {
  [ACTIVATE_CAMERA_MODAL]: ActivateCameraModal,
  [BULK_EDIT_CAMERA_MODAL]: BulkEditCamera,
  [BULK_EDIT_USER_MODAL]: BulkEditUser,
  [DELETE_CAMERA_MODAL]: DeleteCameraModal,
  [DELETE_FILTER_MODAL]: DeleteFilterModal,
  [DELETE_LOCATION_MODAL]: DeleteLocationModal,
  [DELETE_USER_MODAL]: DeleteUserModal,
  [DELETE_ZONE_MODAL]: DeleteZoneModal,
  [EDIT_FILTER_MODAL]: EditFilter,
  [INVITE_COWORKER_MODAL]: InviteCoworker,
  [INVITE_PARTNER_MODAL]: InvitePartner,
  [NEW_DASHBOARD_MODAL]: NewDashboardModal,
  [REQUESTS_MODAL]: Requests,
  [SAVE_FILTER_MODAL]: SaveFilter,
  [UNSAVED_CHANGES_MODAL]: UnsavedChangesModal,
  [UPGRADE_WARNING_MODAL]: UpgradeWarningModal,
};

function sizeToStyle(size: FoldoutSize) {
  switch (size) {
    case 'tn':
      return {
        marginBottom: '7.5%',
        minHeight: '200px',
        width: '36.5234375%',
      };
    case 'md':
      return {
        // height: '77.8vh',
        marginBottom: '2%',
        // minHeight: '700px',
        width: '700px',
      };
    case 'md-lg':
      return {
        marginBottom: '2%',
        width: '1000px',
      };
    case 'lg':
      return {
        marginBottom: '1%',
        minHeight: '650px',
        width: '93.75%',
      };
    case 'sm':
    default:
      return {
        // minHeight: '340px',
        maxHeight: '655px',
        // height: '77.8vh',
        width: '445px',
        // marginBottom: '2%',
      };
  }
}

type ModalProps = {
  hideModal: () => void;
  hideModalToast: () => void;
  isShown?: boolean;
  modalProps?: CSSProperties & { size: FoldoutSize };
  modalType?: string;
};

class Modal extends PureComponent<ModalProps> {
  constructor(props: ModalProps) {
    super(props);

    this.handleKeydownClose = this.handleKeydownClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps: ModalProps) {
    const { isShown } = this.props;

    if (isShown !== prevProps.isShown) {
      if (isShown) {
        window.addEventListener('keydown', this.handleKeydownClose, false);
      } else {
        window.removeEventListener('keydown', this.handleKeydownClose, false);
      }
    }
  }

  handleClose() {
    const { hideModal, hideModalToast } = this.props;

    hideModal();
    hideModalToast();
  }

  handleKeydownClose(e: WindowEventMap['keydown']) {
    const { hideModal, hideModalToast } = this.props;

    if (e.code === 'Escape') {
      hideModal();
      hideModalToast();
    }
  }

  render() {
    const {
      hideModal,
      isShown = false,
      modalProps: { backgroundColor, backgroundImage, size, ...rest } = {},
      modalType = null,
    } = this.props;

    const SpecificModal = MODAL_COMPONENTS[modalType];

    return ReactDOM.createPortal(
      <Transition
        config={{ duration: 250 }}
        enter={{ opacity: 1 }}
        from={{ opacity: 0 }}
        items={isShown}
        leave={{ opacity: 0 }}
      >
        {(props, show) =>
          show && (
            <>
              <animated.div className={styles.overlay} style={props} />
              <animated.div
                aria-modal="true"
                className={styles.modal}
                onMouseDown={this.handleClose}
                role="dialog"
                style={props}
              >
                <div
                  className={styles.window}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  role="dialog"
                  style={{
                    ...sizeToStyle(size),
                    backgroundColor,
                    backgroundImage:
                      backgroundImage ||
                      (!backgroundColor
                        ? 'linear-gradient(to bottom, var(--black-80-), var(--black-80-))'
                        : backgroundImage),
                  }}
                >
                  <div className={styles.content}>
                    {SpecificModal && (
                      <SpecificModal close={hideModal} {...rest} />
                    )}
                    <ModalToastContainer />
                  </div>
                </div>
              </animated.div>
            </>
          )
        }
      </Transition>,
      modalRoot
    );
  }
}

const mapStateToProps = (state) => ({
  isShown: modalIsShownSelector(state),
  modalProps: modalPropsSelector(state),
  modalType: modalTypeSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      hideModal: hideModalAction,
      hideModalToast: hideModalToastAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
