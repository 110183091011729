import { put, takeLatest } from 'redux-saga/effects';

import { hideModal as hideModalAction } from '../actions/modals';
import { DELETE_LOCATION_SUCCESS } from '../constants/action-types';

export function* hideModal() {
  yield put(hideModalAction());
}

export function* watchModals() {
  yield takeLatest(DELETE_LOCATION_SUCCESS, hideModal);
}
