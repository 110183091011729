type SvgEditProps = {
  color?: string;
};

function SvgEdit(props: SvgEditProps & React.SVGProps<SVGSVGElement>) {
  const { color = '#fff' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15.686 15.81"
      {...props}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M7.069 2.172H1.96A1.46 1.46 0 00.5 3.632V13.85a1.46 1.46 0 001.46 1.46h10.218a1.46 1.46 0 001.46-1.46V8.741" />
        <path d="M12.543 1.077a1.549 1.549 0 012.19 2.19l-6.934 6.934-2.92.73.73-2.919z" />
      </g>
    </svg>
  );
}

export default SvgEdit;
