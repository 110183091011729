function SvgSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={800}
      height={800}
      fill="none"
      viewBox="0 0 15 15"
      {...props}
    >
      <path
        fill="#000"
        d="M2.5 6.5V6H2v.5h.5Zm8 4H10v.5h.5v-.5Zm2 0v.5h.5v-.5h-.5Zm1-7h.5v-.207l-.146-.147-.354.354Zm-3-3 .354-.354L10.707 0H10.5v.5Zm-4 6 .447-.224L6 6.5h.5Zm-.5 4v.5h1v-.5H6Zm2.5 0-.447.224A.5.5 0 0 0 9 10.5h-.5Zm.5-4V6H8v.5h1ZM2.5 7h1V6h-1v1Zm.5 4V8.5H2V11h1Zm0-2.5v-2H2v2h1Zm.5-.5h-1v1h1V8Zm.5-.5a.5.5 0 0 1-.5.5v1A1.5 1.5 0 0 0 5 7.5H4ZM3.5 7a.5.5 0 0 1 .5.5h1A1.5 1.5 0 0 0 3.5 6v1ZM10 6v4.5h1V6h-1Zm.5 5h2v-1h-2v1Zm2.5-.5v-2h-1v2h1ZM10.5 7H13V6h-2.5v1ZM2 5V1.5H1V5h1Zm11-1.5V5h1V3.5h-1ZM2.5 1h8V0h-8v1Zm7.646-.146 3 3 .708-.708-3-3-.708.708ZM2 1.5a.5.5 0 0 1 .5-.5V0A1.5 1.5 0 0 0 1 1.5h1ZM1 12v1.5h1V12H1Zm1.5 3h10v-1h-10v1ZM14 13.5V12h-1v1.5h1ZM12.5 15a1.5 1.5 0 0 0 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM1 13.5A1.5 1.5 0 0 0 2.5 15v-1a.5.5 0 0 1-.5-.5H1Zm5-7v4h1v-4H6Zm.053.224 2 4 .894-.448-2-4-.894.448ZM8 6.5v4h1v-4H8Z"
      />
    </svg>
  );
}

export default SvgSvg;
