import { t } from 'i18next';

import { vt } from '.';
import { isEmail, isPasswordStrong } from './regex';

export function validateCreateUser(payload) {
  const {
    email,
    first_name: firstName,
    group_name: role,
    last_name: lastName,
    locations,
    password,
    work_role: workRole,
  } = payload;

  if (!email) {
    vt({
      field: 'email',
      message: t('validations.createUser.C001'),
    });
  }

  if (!isEmail(email)) {
    vt({
      field: 'email',
      message: t('validations.createUser.C002'),
    });
  }

  if (!isPasswordStrong(password)) {
    vt({
      field: 'password',
      message: t('validations.createUser.C005'),
    });
  }

  if (!role) {
    vt({
      field: 'group_name',
      message: t('validations.createUser.C008'),
    });
  }

  if (!workRole) {
    vt({
      field: 'work_role',
      message: t('validations.createUser.C007'),
    });
  }

  if (!locations || locations.length === 0) {
    vt({
      field: 'locations',
      message: t('validations.createUser.C006'),
    });
  }

  if (!firstName) {
    vt({
      field: 'first_name',
      message: t('validations.createUser.C003'),
    });
  }

  if (!lastName) {
    vt({
      field: 'last_name',
      message: t('validations.createUser.C004'),
    });
  }
}

export function validateUpdateUser(payload) {
  const {
    first_name: firstName,
    group_name: role,
    last_name: lastName,
    locations,
    password,
    work_role: workRole,
  } = payload;

  if (password && !isPasswordStrong(password)) {
    vt({
      field: 'password',
      message: t('validations.createUser.C005'),
    });
  }

  if (!role) {
    vt({
      field: 'group_name',
      message: t('validations.createUser.C008'),
    });
  }

  if (!workRole) {
    vt({
      field: 'work_role',
      message: t('validations.createUser.C007'),
    });
  }

  if (!locations || locations.length === 0) {
    vt({
      field: 'locations',
      message: t('validations.createUser.C006'),
    });
  }

  if (!firstName) {
    vt({
      field: 'first_name',
      message: t('validations.createUser.C003'),
    });
  }

  if (!lastName) {
    vt({
      field: 'last_name',
      message: t('validations.createUser.C004'),
    });
  }
}
