import { createSelector } from 'reselect';

export const listingZonesSelector = (state) => state.zones.listing;

export const zonesSelector = (state) => state.zones.zones;

export const deletingZoneSelector = (state) => state.zones.deleting;

export const zonesByOrganizationListingSelector = (state) =>
  state.zones.zonesByOrganizationListing;

export const zonesByOrganizationSelector = (state) =>
  state.zones.zonesByOrganization;

export const selectedZonesSelector = (state) => state.zones.selectedZones;

export const updatingZoneSelector = (state) => state.zones.updatingZone;

export const zoneByIdSelector = createSelector(
  [zonesByOrganizationSelector, (_, id) => id],
  (zones, id) => {
    return zones.find((z) => z.id === id) || {};
  }
);

export const creatingZoneSelector = (state) => state.zones.creating;
