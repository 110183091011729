import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  deleteFilterFailure,
  deleteFilterRequest,
  deleteFilterSuccess,
  listFiltersFailure,
  listFiltersSuccess,
  replaceFilterFailure,
  replaceFilterSuccess,
  saveFilterFailure,
  saveFilterRequest,
  saveFilterSuccess,
  updateFilterFailure,
  updateFilterRequest,
  updateFilterSuccess,
} from '../actions/filters';
import { hideModal } from '../actions/modals';
import { Filters } from '../api';
import {
  DELETE_FILTER_REQUEST,
  LIST_FILTERS_REQUEST,
  REPLACE_FILTER_REQUEST,
  SAVE_FILTER_REQUEST,
  UPDATE_FILTER_REQUEST,
} from '../constants/action-types';
import { DASHBOARD_FILTERS } from '../constants/filters';
import { filtersAppliedSelector, filtersSelector } from '../selectors/filters';
import { IFilter } from '../types/api/filters';

export function* listFilters() {
  try {
    const { body: filters } = yield call(Filters.List);
    yield put(listFiltersSuccess(filters));
  } catch (e) {
    listFiltersFailure(e);
  }
}

export function* saveFilter({ name }: ReturnType<typeof saveFilterRequest>) {
  try {
    const applied = yield select(filtersAppliedSelector);

    const payload: Partial<IFilter> = {
      field_key: DASHBOARD_FILTERS,
      field_val: applied,
      name,
    };
    const { body: filter } = yield call(Filters.Save, payload);
    yield put(saveFilterSuccess(filter));
    yield put(hideModal());
  } catch (e) {
    yield put(saveFilterFailure(e));
  }
}

export function* deleteFilter({ id }: ReturnType<typeof deleteFilterRequest>) {
  try {
    yield call(Filters.Delete, id);
    yield put(deleteFilterSuccess(id));
    yield put(hideModal());
  } catch (e) {
    yield put(deleteFilterFailure(e));
  }
}

export function* updateFilter({
  id,
  payload,
}: ReturnType<typeof updateFilterRequest>) {
  try {
    const { body: filter } = yield call(Filters.Update, id, payload);
    yield put(updateFilterSuccess(filter));
    yield put(hideModal());
  } catch (e) {
    yield put(updateFilterFailure(e));
  }
}

export function* replaceFilter({ id }: ReturnType<typeof updateFilterRequest>) {
  try {
    const applied = yield select(filtersAppliedSelector);
    const filters: IFilter[] = yield select(filtersSelector);
    const oldFilter: IFilter = filters.find((f) => f.id === id);

    const { body: filter } = yield call(Filters.Update, id, {
      ...oldFilter,
      field_val: applied,
    });

    yield put(replaceFilterSuccess(filter));
    yield put(hideModal());
  } catch (e) {
    yield put(replaceFilterFailure(e));
  }
}

export function* watchFilters() {
  yield takeLatest(LIST_FILTERS_REQUEST, listFilters);
  yield takeLatest(SAVE_FILTER_REQUEST, saveFilter);
  yield takeLatest(DELETE_FILTER_REQUEST, deleteFilter);
  yield takeLatest(UPDATE_FILTER_REQUEST, updateFilter);
  yield takeLatest(REPLACE_FILTER_REQUEST, replaceFilter);
}
