import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  visitorJourneyFailure,
  visitorJourneySuccess,
} from '../actions/visitor-journey';
import { Insights } from '../api';
import { VISITOR_JOURNEY_REQUEST } from '../constants/action-types';
import { SegmentationState } from '../reducers/segmentation';
import { VisitorJourneyData } from '../reducers/visitor-journey';
import {
  selectedEndDateSelector,
  selectedStartDateSelector,
} from '../selectors/segmentation';
import { pruneEmptyValues, segmentationDateToRndDate } from '../utils/helper';

export function* visitorJourney() {
  try {
    const segmentation: SegmentationState = yield select(
      (state) => state.segmentation
    );
    const { date, displayLevel, location, organization } = segmentation;

    const startDate: string = yield select(selectedStartDateSelector);
    const endDate: string = yield select(selectedEndDateSelector);

    const [start, end] = segmentationDateToRndDate(
      date.value as SelectedDate,
      startDate,
      endDate
    );

    const params = {
      depth: displayLevel?.value,
      end_date: end,
      location: location?.value,
      start_date: start,
    };
    pruneEmptyValues(params);

    const { body } = yield call(Insights.VisitorJourney, params);
    const data: VisitorJourneyData = body;

    yield put(
      visitorJourneySuccess({
        data,
        date,
        displayLevel,
        endDate,
        location,
        organization,
        startDate,
      })
    );
  } catch (e) {
    yield put(visitorJourneyFailure(e));
  }
}

export function* watchVisitorJourney() {
  yield takeLatest(VISITOR_JOURNEY_REQUEST, visitorJourney);
}
