import { createSelector } from 'reselect';

import { RootState } from '../reducers';

export const listingOrganizationsSelector = (state: RootState) =>
  state.organizations.listingOrganizations;

export const organizationsSelector = (state: RootState) =>
  state.organizations.organizations;

export const activeOrganizationSelector = (state: RootState) =>
  state.organizations.activeOrganization;

export const deletingOrganizationSelector = (state: RootState) =>
  state.organizations.deletingOrganization;

export const cancellingSubscriptionSelector = (state: RootState) =>
  state.organizations.cancellingSubscription;

export const creatingOrganizationSelector = (state: RootState) =>
  state.organizations.creatingOrganization;

export const updatingOrganizationSelector = (state: RootState) =>
  state.organizations.updatingOrganization;

export const organizationCountSelector = createSelector(
  organizationsSelector,
  (organizations) => organizations.length
);
