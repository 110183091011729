function SvgLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19.898}
      height={19.9}
      viewBox="0 0 19.898 19.9"
      {...props}
    >
      <g fill="none" stroke="#d7512f">
        <g transform="rotate(-45 10.722 -13.146)">
          <circle cx={2.638} cy={2.638} r={2.638} stroke="none" />
          <circle cx={2.638} cy={2.638} r={2.138} />
        </g>
        <g transform="rotate(-45 19.518 8.085)">
          <circle cx={2.638} cy={2.638} r={2.638} stroke="none" />
          <circle cx={2.638} cy={2.638} r={2.138} />
        </g>
        <path d="m14.924 4.975-9.95 9.95" />
      </g>
    </svg>
  );
}

export default SvgLine;
