import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { getYear, getMonth } from 'date-fns';
import { SvgTickLeft } from '../../components/svg';
import styles from './custom-header.module.css';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

const years = Array(new Date().getFullYear() - 2020)
  .fill(2021, 0, new Date().getFullYear() - 2020)
  .map((x, y) => x + y);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// type CustomHeaderProps = {
//   date: Date;
//   changeYear: (value: string) => void;
//   changeMonth: (month: number) => void;
//   decreaseMonth: () => void;
//   increaseMonth: () => void;
//   prevMonthButtonDisabled: boolean;
//   nextMonthButtonDisabled: boolean;
//   label: string;
// };

type CustomHeaderProps = {
  label: string;
} & ReactDatePickerCustomHeaderProps;

function CustomHeader(props: CustomHeaderProps) {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    label,
  } = props;

  const hiddenMonthRef = useRef(null);
  const hiddenYearRef = useRef(null);

  const [selectedYear, setSelectedYear] = useState(getYear(date));
  const [selectedMonth, setSelectedMonth] = useState(months[getMonth(date)]);

  const [monthWidth, setMonthWidth] = useState('auto');
  const [yearWidth, setYearWidth] = useState('auto');

  const handleYearChange = useCallback(
    (e) => {
      const { value } = e.target;

      setSelectedYear(value);
      changeYear(value);
    },
    [changeYear]
  );

  const handleMonthChange = useCallback(
    (e) => {
      const { value } = e.target;

      setSelectedMonth(value);
      changeMonth(months.indexOf(value));
    },
    [changeMonth]
  );

  const handleIncreaseClick = useCallback(() => {
    increaseMonth();
    setSelectedMonth(months[getMonth(date)]);
    setSelectedYear(getYear(date));
  }, [date, increaseMonth]);

  const handleDecreaseClick = useCallback(() => {
    decreaseMonth();
    setSelectedMonth(months[getMonth(date)]);
    setSelectedYear(getYear(date));
  }, [date, decreaseMonth]);

  useEffect(() => {
    const newWidth = hiddenMonthRef.current.offsetWidth
      ? `${hiddenMonthRef.current.offsetWidth + 10}px`
      : 'auto';
    setMonthWidth(newWidth);
  }, [selectedMonth, date]);

  useEffect(() => {
    const newWidth = hiddenYearRef.current.offsetWidth
      ? `${hiddenYearRef.current.offsetWidth + 10}px`
      : 'auto';
    setYearWidth(newWidth);
  }, [selectedYear, date]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newMonthWidth = hiddenMonthRef.current.offsetWidth
        ? `${hiddenMonthRef.current.offsetWidth + 10}px`
        : 'auto';
      setMonthWidth(newMonthWidth);

      const newYearWidth = hiddenYearRef.current.offsetWidth
        ? `${hiddenYearRef.current.offsetWidth + 10}px`
        : 'auto';
      setYearWidth(newYearWidth);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.label}>{label}</div>
        <div className={styles.current}>
          <SvgTickLeft
            className={clsx([
              styles.arrow,
              {
                [styles.disabled]: prevMonthButtonDisabled,
              },
            ])}
            onClick={handleDecreaseClick}
          />
          <select
            className={styles.select}
            value={months[getMonth(date)]}
            onChange={handleMonthChange}
            style={{ width: monthWidth }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className={styles.select}
            value={getYear(date)}
            onChange={handleYearChange}
            style={{ width: yearWidth }}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <SvgTickLeft
            className={clsx([
              styles.arrow,
              styles.rotate,
              {
                [styles.disabled]: nextMonthButtonDisabled,
              },
            ])}
            onClick={handleIncreaseClick}
          />
        </div>
      </div>
      <span className={styles.hidden} ref={hiddenMonthRef}>
        {months[getMonth(date)]}
      </span>
      <span className={styles.hidden} ref={hiddenYearRef}>
        {getYear(date)}
      </span>
    </>
  );
}

export default CustomHeader;
