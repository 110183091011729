import { RootActions } from '../actions';
import * as appActions from '../actions/app';
import { SET_APP_VERSION } from '../constants/action-types';

export type AppState = {
  version: Nullable<string>;
};

export const initialState: AppState = {
  version: null,
};

export default (state = initialState, action: RootActions): AppState => {
  switch (action.type) {
    case SET_APP_VERSION:
      const { version } = action as ReturnType<typeof appActions.setAppVersion>;

      return {
        ...state,
        version,
      };
    default:
      return state;
  }
};
