import { HTMLAttributes, useCallback, useState } from 'react';
import clsx from 'clsx';
import styles from './accordion.module.css';
import { SvgSelectArrow } from '../svg';

type AccordionProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  initiallyOpen?: boolean;
};

function Accordion(props: AccordionProps) {
  const { title, children, className, initiallyOpen = false, ...rest } = props;

  const [expanded, setExpanded] = useState(initiallyOpen);

  const handleClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <div className={clsx(styles.accordion, className)} {...rest}>
      <div className={styles.trigger} onClick={handleClick}>
        <SvgSelectArrow
          className={clsx(styles.arrow, { [styles.revert]: expanded })}
          width={16}
          height={8}
        />
        {title}
      </div>
      <div className={clsx(styles.content, { [styles.expanded]: expanded })}>
        {children}
      </div>
    </div>
  );
}

export default Accordion;
