import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { showModal } from '../../../../../actions/modals';
import { SvgEdit, SvgTrashLight } from '../../../../../components/svg';
import {
  DELETE_FILTER_MODAL,
  EDIT_FILTER_MODAL,
} from '../../../../../constants/modal-types';
import { IFilter } from '../../../../../types/api/filters';
import styles from './filter-dropdown.module.css';

type FilterDropdownProps = {
  filter: IFilter;
  onClose(e: React.MouseEvent<HTMLDivElement | HTMLLIElement>): void;
};

function FilterDropdown(props: FilterDropdownProps) {
  const { filter, onClose } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('sidebar', { keyPrefix: 'dashboard' });

  const handleEditClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      dispatch(
        showModal(EDIT_FILTER_MODAL, {
          backgroundImage:
            'linear-gradient(to bottom, var(--black-80-), var(--black-60-))',
          filter,
          size: 'sm',
        })
      );
      onClose(e);
    },
    [dispatch, filter, onClose]
  );

  const handleDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      dispatch(
        showModal(DELETE_FILTER_MODAL, {
          backgroundColor: 'var(--error)',
          filter,
          size: 'sm',
        })
      );
      onClose(e);
    },
    [dispatch, filter, onClose]
  );

  return (
    <div className={styles.dropdown}>
      <ul className={styles.list}>
        <li className={styles.item} onClick={handleEditClick}>
          <label className={styles.label}>
            <SvgEdit color="#999" height={16} width={16} />
            {t('savedFilters.options.edit')}
          </label>
        </li>
        <li className={styles.item} onClick={handleDeleteClick}>
          <label className={styles.label}>
            <SvgTrashLight color="#999" height={18} width={18} />
            {t('savedFilters.options.delete')}
          </label>
        </li>
      </ul>
    </div>
  );
}

export default FilterDropdown;
