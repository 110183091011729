import { MutableRefObject } from 'react';

import {
  CAMERA_LINE_MULTILINE_FAILURE,
  CAMERA_LINE_MULTILINE_REQUEST,
  CAMERA_LINE_MULTILINE_SUCCESS,
  LINE_TYPE_MULTILINE_FAILURE,
  LINE_TYPE_MULTILINE_REQUEST,
  LINE_TYPE_MULTILINE_SUCCESS,
  EXPORT_CHART_AS_IMAGE_FAILURE,
  EXPORT_CHART_AS_IMAGE_REQUEST,
  EXPORT_CHART_AS_IMAGE_SUCCESS,
  LAST_ENTERINGS_FAILURE,
  LAST_ENTERINGS_REQUEST,
  LAST_ENTERINGS_SUCCESS,
  LOCATION_MULTILINE_FAILURE,
  LOCATION_MULTILINE_REQUEST,
  LOCATION_MULTILINE_SUCCESS,
  TOTAL_ENTERINGS_FAILURE,
  TOTAL_ENTERINGS_REQUEST,
  TOTAL_ENTERINGS_SUCCESS,
  TOTAL_VISITORS_FAILURE,
  TOTAL_VISITORS_REQUEST,
  TOTAL_VISITORS_SUCCESS,
  TOTAL_VISITS_FAILURE,
  TOTAL_VISITS_PREVIOUS_FAILURE,
  TOTAL_VISITS_PREVIOUS_REQUEST,
  TOTAL_VISITS_PREVIOUS_SUCCESS,
  TOTAL_VISITS_REQUEST,
  TOTAL_VISITS_SUCCESS,
  ZONE_FROM_MULTILINE_FAILURE,
  ZONE_FROM_MULTILINE_REQUEST,
  ZONE_FROM_MULTILINE_SUCCESS,
  ZONE_TO_MULTILINE_FAILURE,
  ZONE_TO_MULTILINE_REQUEST,
  ZONE_TO_MULTILINE_SUCCESS,
} from '../constants/action-types';

export const totalVisitsRequest = () => ({
  type: TOTAL_VISITS_REQUEST,
});

export const totalVisitsSuccess = (
  data,
  date: Option,
  property: Option,
  startDate,
  endDate,
  lineType,
  zoneTo,
  zoneFrom,
  organization,
  location,
  floor,
  line
) => ({
  data,
  date,
  endDate,
  floor,
  line,
  lineType,
  location,
  organization,
  property,
  startDate,
  type: TOTAL_VISITS_SUCCESS,
  zoneFrom,
  zoneTo,
});

export const totalVisitsFailure = (error: unknown) => ({
  error,
  type: TOTAL_VISITS_FAILURE,
});

export const lineTypeMultilineRequest = () => ({
  type: LINE_TYPE_MULTILINE_REQUEST,
});

export const lineTypeMultilineSuccess = (
  data,
  date: Option,
  property: Option,
  startDate,
  endDate
) => ({
  data,
  date,
  endDate,
  property,
  startDate,
  type: LINE_TYPE_MULTILINE_SUCCESS,
});

export const lineTypeMultilineFailure = (error: unknown) => ({
  error,
  type: LINE_TYPE_MULTILINE_FAILURE,
});

export const totalVisitsPreviousRequest = (params) => ({
  params,
  type: TOTAL_VISITS_PREVIOUS_REQUEST,
});

export const totalVisitsPreviousSuccess = (data) => ({
  data,
  type: TOTAL_VISITS_PREVIOUS_SUCCESS,
});

export const totalVisitsPreviousFailure = (error: unknown) => ({
  error,
  type: TOTAL_VISITS_PREVIOUS_FAILURE,
});

export const lastEnteringsRequest = () => ({
  type: LAST_ENTERINGS_REQUEST,
});

export const lastEnteringsSuccess = (data) => ({
  data,
  type: LAST_ENTERINGS_SUCCESS,
});

export const lastEnteringsFailure = (error: unknown) => ({
  error,
  type: LAST_ENTERINGS_FAILURE,
});

export const totalEnteringsRequest = () => ({
  type: TOTAL_ENTERINGS_REQUEST,
});

export const totalEnteringsSuccess = (data) => ({
  data,
  type: TOTAL_ENTERINGS_SUCCESS,
});

export const totalEnteringsFailure = (error: unknown) => ({
  error,
  type: TOTAL_ENTERINGS_FAILURE,
});

export const totalVisitorsRequest = () => ({
  type: TOTAL_VISITORS_REQUEST,
});

export const totalVisitorsSuccess = (data, previousData) => ({
  data,
  previousData,
  type: TOTAL_VISITORS_SUCCESS,
});

export const totalVisitorsFailure = (error: unknown) => ({
  error,
  type: TOTAL_VISITORS_FAILURE,
});

export const cameraLineMultilineRequest = () => ({
  type: CAMERA_LINE_MULTILINE_REQUEST,
});

export const cameraLineMultilineSuccess = (data) => ({
  data,
  type: CAMERA_LINE_MULTILINE_SUCCESS,
});

export const cameraLineMultilineFailure = (error: unknown) => ({
  error,
  type: CAMERA_LINE_MULTILINE_FAILURE,
});

export const zoneToMultilineRequest = () => ({
  type: ZONE_TO_MULTILINE_REQUEST,
});

export const zoneToMultilineSuccess = (data) => ({
  data,
  type: ZONE_TO_MULTILINE_SUCCESS,
});

export const zoneToMultilineFailure = (error: unknown) => ({
  error,
  type: ZONE_TO_MULTILINE_FAILURE,
});

export const zoneFromMultilineRequest = () => ({
  type: ZONE_FROM_MULTILINE_REQUEST,
});

export const zoneFromMultilineSuccess = (data) => ({
  data,
  type: ZONE_FROM_MULTILINE_SUCCESS,
});

export const zoneFromMultilineFailure = (error: unknown) => ({
  error,
  type: ZONE_FROM_MULTILINE_FAILURE,
});

export const locationMultilineRequest = () => ({
  type: LOCATION_MULTILINE_REQUEST,
});

export const locationMultilineSuccess = (data) => ({
  data,
  type: LOCATION_MULTILINE_SUCCESS,
});

export const locationMultilineFailure = (error: unknown) => ({
  error,
  type: LOCATION_MULTILINE_FAILURE,
});

export const exportChartAsImageRequest = (ref: MutableRefObject<any>) => ({
  ref,
  type: EXPORT_CHART_AS_IMAGE_REQUEST,
});

export const exportChartAsImageSuccess = () => ({
  type: EXPORT_CHART_AS_IMAGE_SUCCESS,
});

export const exportChartAsImageFailure = (error: unknown) => ({
  error,
  type: EXPORT_CHART_AS_IMAGE_FAILURE,
});
