import React, { Ref } from 'react';

function SvgOptions(
  props: React.SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) {
  const { color } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={24}
      role="img"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12} r={1} stroke={color} />
      <circle cx={12} cy={5} r={1} stroke={color} />
      <circle cx={12} cy={19} r={1} stroke={color} />
    </svg>
  );
}

export default React.forwardRef(SvgOptions);
