import clsx from 'clsx';
import { KeyboardEvent, PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { MODAL_TOAST_TIMEOUT } from '../../constants/timeouts';
import { ModalLevel } from '../../reducers/modal-toasts';
import { SvgCross } from '../svg';
import styles from './modal-toast.module.css';

type ModalToastProps = {
  hide: () => void;
  level: ModalLevel;
  message: string;
  rate?: number;
  reload?: () => void;
  reloadable?: boolean;
  timeout?: number;
  undoable?: boolean;
};

type ModalToastState = {
  strokeDashoffset: number;
};

class ModalToast extends PureComponent<ModalToastProps, ModalToastState> {
  private interval: number;

  private ticker: NodeJS.Timer;

  private timer: NodeJS.Timeout;

  constructor(props: ModalToastProps) {
    super(props);

    // Increase rate for higher fps.
    const { rate = 2, timeout = MODAL_TOAST_TIMEOUT } = this.props;
    this.interval = timeout / (80 * rate);

    this.state = { strokeDashoffset: 0 };
    this.handleTimeout = this.handleTimeout.bind(this);
    this.updateStrokeDashoffset = this.updateStrokeDashoffset.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleActionKeyDown = this.handleActionKeyDown.bind(this);
  }

  componentDidMount() {
    const { timeout = MODAL_TOAST_TIMEOUT } = this.props;

    this.timer = setTimeout(this.handleTimeout, timeout);
    this.ticker = setInterval(this.updateStrokeDashoffset, this.interval);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.ticker);
  }

  handleActionClick() {
    const { reload, reloadable = false } = this.props;

    switch (true) {
      case reloadable:
        reload();
        break;
      default:
    }
  }

  handleActionKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.handleActionClick();
    }
  }

  handleTimeout() {
    const { hide, reload, reloadable = false, undoable = false } = this.props;

    switch (true) {
      case reloadable:
        reload();
        break;
      case undoable:
      default:
        hide();
    }
  }

  render() {
    const { level, message } = this.props;

    return (
      <div className={clsx([styles.toast, styles[level]])}>
        <span className={styles.message}>{message}</span>
        <SvgCross
          className={styles.close}
          height={16}
          onClick={this.handleTimeout}
          width={16}
        />
      </div>
    );
  }

  updateStrokeDashoffset() {
    const { strokeDashoffset } = this.state;

    this.setState({ strokeDashoffset: strokeDashoffset + 0.5 });
  }
}

export default withTranslation('toasts')(ModalToast);
