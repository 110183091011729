function SvgArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 13 9.664"
      {...props}
    >
      <g fill="none" stroke="#999" strokeLinecap="round" strokeLinejoin="round">
        <path d="M.5 4.832h12M8.388.707L12.5 4.832 8.388 8.957" />
      </g>
    </svg>
  );
}

export default SvgArrowRight;
