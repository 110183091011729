import Konva from 'konva';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Arrow, Circle, Line, Text, Transformer } from 'react-konva';

import {
  ARROW_LENGTH,
  ARROW_OFFSET,
  CIRCLE_RADIUS,
  TEXT_OFFSET_Y,
  TEXT_WIDTH,
} from '../../../constants/canvas';
import lineTypes from '../../../constants/line-types';
import { ICategory } from '../../../types/api/categories';
import { ILine } from '../../../types/api/lines';
import { IZone } from '../../../types/api/zones';
import { calculateArrowPos } from '../../../utils/canvas';
import { limitDraggibilityByPosition } from '../../../utils/helper';

const OPACITY = 0.6;

export interface IMark {
  accuracyIndex?: string;
  category?: ICategory['id'];
  createdAt?: number;
  createdBy?: {
    email: string;
    id: string;
  };
  fill?: string;
  id?: ILine['id'];
  lineZones: {
    FromZone: IZone['id'];
    ToZone: IZone['id'];
  }[];
  markerId?: string;
  name: string;
  parentId: ILine['parent_marker_id'];
  points: number[];
  stroke?: string;
  strokeWidth?: number;
  totalVisitors?: number;
  type?: typeof lineTypes[keyof typeof lineTypes];
  x: number;
  y: number;
  zoneFrom: Nullable<IZone['id']>;
  zoneTo: Nullable<IZone['id']>;
}

type MarkProps = {
  isSelected: boolean;
  listening?: boolean;
  onChange?: (props: IMark) => void;
  onSelect?: (e: Konva.KonvaEventObject<DragEvent | MouseEvent>) => void;
  shapeProps: IMark;
};

function Mark(props: MarkProps) {
  const {
    isSelected,
    listening = true,
    onChange,
    onSelect,
    shapeProps,
  } = props;

  const transformer = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const line = useRef(null);
  const dash = useRef(null);
  const arrow = useRef(null);
  const text = useRef(null);

  const [draggable, setDraggable] = useState(true);
  const [transformerHovered, setTransformerHovered] = useState(false);
  const [circleHovered, setCircleHovered] = useState(false);
  const [dragging, setDragging] = useState(false);

  const handleClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      onSelect(e);
    },
    [onSelect]
  );

  const transformerVisible = useMemo(
    () => transformerHovered || isSelected || circleHovered,
    [circleHovered, isSelected, transformerHovered]
  );

  const handleLineDragEnd = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      onChange({
        ...shapeProps,
        points: (e.target as Konva.Line).points(),
        x: e.target.x(),
        y: e.target.y(),
      });

      setDragging(false);
    },
    [onChange, shapeProps]
  );

  const handleLineDragMove = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => {
      const points = line.current.getPoints();

      const [, , x2, y2] = points;
      const linePos = limitDraggibilityByPosition(
        line.current.getPosition(),
        x2,
        y2
      );

      line.current.position(linePos);
      arrow.current.position(linePos);

      circle1.current.position({
        x: linePos.x + points[0],
        y: linePos.y + points[1],
      });

      circle2.current.position({
        x: linePos.x + points[2],
        y: linePos.y + points[3],
      });

      if (transformer.current) {
        text.current.position({
          x:
            transformer.current.x() -
            TEXT_WIDTH / 2 +
            transformer.current.width() / 2,
          y: transformer.current.y() - TEXT_OFFSET_Y,
        });
      }

      if (!isSelected) {
        onSelect(e);
      }
    },
    [isSelected, onSelect]
  );

  const handleCircle1DragMove = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => {
      const circle1Pos = limitDraggibilityByPosition(
        circle1.current.getPosition()
      );
      const circle2Pos = circle2.current.getPosition();

      circle1.current.position(circle1Pos);

      const points = [
        0,
        0,
        circle2Pos.x - circle1Pos.x,
        circle2Pos.y - circle1Pos.y,
      ];

      const [arrowX2, arrowY2] = calculateArrowPos(points, ARROW_OFFSET);
      const [arrowX1, arrowY1] = calculateArrowPos(
        points,
        ARROW_LENGTH + ARROW_OFFSET
      );

      line.current.position(circle1Pos);
      line.current.points(points);

      arrow.current.position(circle1Pos);
      arrow.current.points([arrowX1, arrowY1, arrowX2, arrowY2]);

      if (transformer.current) {
        transformer.current.nodes([line.current, arrow.current]);
        transformer.current.getLayer().batchDraw();

        text.current.position({
          x:
            transformer.current.x() -
            TEXT_WIDTH / 2 +
            transformer.current.width() / 2,
          y: transformer.current.y() - TEXT_OFFSET_Y,
        });
      }

      if (!isSelected) {
        onSelect(e);
      }
    },
    [isSelected, onSelect]
  );

  const handleCircle2DragMove = useCallback(
    (e: Konva.KonvaEventObject<DragEvent>) => {
      const linePos = line.current.getPosition();
      const circle2Pos = limitDraggibilityByPosition(
        circle2.current.getPosition()
      );
      const circle1Pos = circle1.current.getPosition();

      circle2.current.position(circle2Pos);

      const points = [0, 0, circle2Pos.x - linePos.x, circle2Pos.y - linePos.y];

      const [arrowX2, arrowY2] = calculateArrowPos(points, ARROW_OFFSET);
      const [arrowX1, arrowY1] = calculateArrowPos(
        points,
        ARROW_LENGTH + ARROW_OFFSET
      );

      line.current.position(circle1Pos);
      line.current.points(points);

      arrow.current.position(circle1Pos);
      arrow.current.points([arrowX1, arrowY1, arrowX2, arrowY2]);

      if (transformer.current) {
        transformer.current.nodes([line.current, arrow.current]);
        transformer.current.getLayer().batchDraw();

        text.current.position({
          x:
            transformer.current.x() -
            TEXT_WIDTH / 2 +
            transformer.current.width() / 2,
          y: transformer.current.y() - TEXT_OFFSET_Y,
        });
      }

      if (!isSelected) {
        onSelect(e);
      }
    },
    [isSelected, onSelect]
  );

  const handleDragStart = () => {
    setDragging(true);
  };

  const handleCircleDragEnd = () => {
    onChange({
      ...shapeProps,
      points: line.current.getPoints(),
      x: line.current.x(),
      y: line.current.y(),
    });

    setDragging(false);
  };

  const handleTransformerMouseEnter = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const container = e.target.getStage().container();
      container.style.cursor = 'grab';

      setTransformerHovered(true);
    },
    []
  );

  const handleTransformerMouseLeave = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const container = e.target.getStage().container();
      container.style.cursor = 'default';

      setTransformerHovered(false);
    },
    []
  );

  const handleCircleMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'crosshair';

    setDraggable(false);

    setCircleHovered(true);
  };

  const handleCircleMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';

    setDraggable(true);

    setCircleHovered(false);
  };

  const [arrowX2, arrowY2] = useMemo(() => {
    const { points } = shapeProps;

    return calculateArrowPos(points, ARROW_OFFSET);
  }, [shapeProps]);

  const [arrowX1, arrowY1] = useMemo(() => {
    const { points } = shapeProps;

    return calculateArrowPos(points, ARROW_LENGTH + ARROW_OFFSET);
  }, [shapeProps]);

  useEffect(() => {
    text.current.position({
      x:
        transformer.current.x() -
        TEXT_WIDTH / 2 +
        transformer.current.width() / 2,
      y: transformer.current.y() - TEXT_OFFSET_Y,
    });
  }, [shapeProps]);

  useEffect(() => {
    transformer.current.nodes([line.current, arrow.current]);
    transformer.current.getLayer().batchDraw();

    text.current.position({
      x:
        transformer.current.x() -
        TEXT_WIDTH / 2 +
        transformer.current.width() / 2,
      y: transformer.current.y() - TEXT_OFFSET_Y,
    });

    // circle1.current.zIndex(transformer.current.zIndex() + 1);
    // circle2.current.zIndex(transformer.current.zIndex() + 1);
  }, []);

  return (
    <>
      <Line
        dash={[6, 6, 6, 6]}
        dashEnabled={!!shapeProps.parentId}
        draggable={draggable}
        listening={listening}
        // lineCap="round"
        onDragEnd={handleLineDragEnd}
        onDragMove={handleLineDragMove}
        onDragStart={handleDragStart}
        opacity={listening ? 1 : OPACITY}
        ref={line}
        {...shapeProps}
        id={shapeProps?.id?.toString()}
      />
      <Text
        align="center"
        fill={transformerVisible ? '#fff' : 'transparent'}
        fontFamily="'Poppins', sans-serif"
        fontSize={13}
        listening={false} // Prevents transformer from being selected when listening is true
        ref={text}
        text={shapeProps.name}
        verticalAlign="center"
        width={TEXT_WIDTH}
        wrap="char"
      />
      <Circle
        draggable
        listening={listening}
        // fill="white"
        onClick={handleClick}
        onDragEnd={handleCircleDragEnd}
        onDragMove={handleCircle1DragMove}
        onDragStart={handleDragStart}
        onMouseEnter={handleCircleMouseEnter}
        onMouseLeave={handleCircleMouseLeave}
        onTap={handleClick}
        opacity={listening ? 1 : OPACITY}
        radius={CIRCLE_RADIUS}
        ref={circle1}
        stroke={shapeProps.stroke}
        strokeWidth={2}
        x={shapeProps.x + shapeProps.points[0]}
        y={shapeProps.y + shapeProps.points[1]}
      />
      <Circle
        draggable
        listening={listening}
        // fill={shapeProps.stroke}
        onClick={handleClick}
        onDragEnd={handleCircleDragEnd}
        onDragMove={handleCircle2DragMove}
        onDragStart={handleDragStart}
        onMouseEnter={handleCircleMouseEnter}
        onMouseLeave={handleCircleMouseLeave}
        onTap={handleClick}
        opacity={listening ? 1 : OPACITY}
        radius={CIRCLE_RADIUS}
        ref={circle2}
        stroke={shapeProps.stroke}
        strokeWidth={3}
        x={shapeProps.points[2] + shapeProps.x}
        y={shapeProps.points[3] + shapeProps.y}
      />
      <Arrow
        fill={shapeProps.stroke}
        fillAfterStrokeEnabled
        lineCap="round"
        lineJoin="miter"
        listening={listening}
        opacity={listening ? 1 : OPACITY}
        pointerLength={10}
        pointerWidth={16}
        points={[arrowX1, arrowY1, arrowX2, arrowY2]}
        ref={arrow}
        stroke={shapeProps.stroke}
        strokeWidth={3}
        tension={100}
        x={shapeProps.x}
        y={shapeProps.y}
      />
      <Transformer
        anchorStroke={transformerVisible ? '#D6512F' : 'transparent'}
        borderStroke={transformerVisible ? '#D6512F' : 'transparent'}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
        listening={listening}
        onClick={handleClick}
        onMouseEnter={handleTransformerMouseEnter}
        onMouseLeave={handleTransformerMouseLeave}
        onTap={handleClick}
        ref={transformer}
        resizeEnabled={false}
        rotateEnabled={false}
        shouldOverdrawWholeArea
      />
    </>
  );
}

export default React.memo(Mark);
