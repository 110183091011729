import clsx from 'clsx';
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring';
import { Dispatch, bindActionCreators } from 'redux';

import { hideFoldout as hideFoldoutAction } from '../../actions/foldouts';
import { showModal as showModalAction } from '../../actions/modals';
import {
  CREATE_CAMERA_FOLDOUT,
  CREATE_LOCATION_FOLDOUT,
  CREATE_USER_FOLDOUT,
  CREATE_ZONE_FOLDOUT,
  EDIT_CAMERA_FOLDOUT,
  EDIT_LOCATION_FOLDOUT,
  EDIT_USER_FOLDOUT,
  EDIT_ZONE_FOLDOUT,
} from '../../constants/foldout-types';
import { UNSAVED_CHANGES_MODAL } from '../../constants/modal-types';
import { RootState } from '../../reducers';
import {
  foldoutChangedSelector,
  foldoutPropsSelector,
  foldoutShownSelector,
  foldoutSizeSelector,
  foldoutTypeSelector,
} from '../../selectors/foldouts';
import { noop } from '../../utils/helper';
import CreateCamera from './create-camera';
import CreateLocation from './create-location';
import CreateUser from './create-user';
import CreateZone from './create-zone';
import EditCamera from './edit-camera';
import EditLocation from './edit-location';
import EditUser from './edit-user';
import EditZone from './edit-zone';
import styles from './index.module.css';

const foldoutRoot = document.getElementById('foldout');

const FOLDOUT_COMPONENTS = {
  [CREATE_CAMERA_FOLDOUT]: CreateCamera,
  [CREATE_LOCATION_FOLDOUT]: CreateLocation,
  [CREATE_USER_FOLDOUT]: CreateUser,
  [CREATE_ZONE_FOLDOUT]: CreateZone,
  [EDIT_CAMERA_FOLDOUT]: EditCamera,
  [EDIT_LOCATION_FOLDOUT]: EditLocation,
  [EDIT_USER_FOLDOUT]: EditUser,
  [EDIT_ZONE_FOLDOUT]: EditZone,
};

type FoldoutProps = {
  changed?: boolean;
  foldoutProps: Record<string, unknown>;
  foldoutSize?: string;
  foldoutType: null | string;
  hideFoldout?: () => void;
  isShown?: boolean;
  showModal?: (modalType: string, modalProps: Record<string, unknown>) => void;
};

class Foldout extends PureComponent<FoldoutProps> {
  constructor(props: FoldoutProps) {
    super(props);

    this.handleKeydownClose = this.handleKeydownClose.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps: FoldoutProps) {
    const { isShown = false } = this.props;

    if (isShown !== prevProps.isShown) {
      if (isShown) {
        window.addEventListener('keydown', this.handleKeydownClose, false);
      } else {
        window.removeEventListener('keydown', this.handleKeydownClose, false);
      }
    }
  }

  handleClose() {
    const { changed = false, hideFoldout, showModal } = this.props;

    if (changed) {
      showModal(UNSAVED_CHANGES_MODAL, {
        backgroundColor: 'var(--error)',
        size: 'sm',
      });
    } else {
      hideFoldout();
    }
  }

  handleKeydownClose(e: WindowEventMap['keydown']) {
    const { hideFoldout = noop } = this.props;

    if (e.key === 'Escape') {
      hideFoldout();
    }
  }

  render() {
    const {
      foldoutProps = {},
      foldoutSize = 'md',
      foldoutType = null,
      isShown = false,
    } = this.props;

    const SpecificFoldout = FOLDOUT_COMPONENTS[foldoutType];

    return ReactDOM.createPortal(
      <>
        <Transition
          config={{ duration: 250 }}
          enter={{
            opacity: 1,
          }}
          from={{
            opacity: 0,
          }}
          items={isShown}
          leave={{
            opacity: 0,
          }}
        >
          {(props, show) =>
            show && (
              <animated.div
                className={styles.overlay}
                onMouseDown={this.handleClose}
                style={props}
              />
            )
          }
        </Transition>
        <Transition
          config={{ duration: 250 }}
          enter={{
            opacity: 1,
            transform: 'translateX(0%)',
          }}
          from={{
            opacity: 0,
            transform: 'translateX(100%)',
          }}
          items={isShown}
          leave={{
            opacity: 0,
            transform: 'translateX(100%)',
          }}
        >
          {(props, show) =>
            show && (
              <animated.div
                aria-modal="true"
                className={styles.foldout}
                role="dialog"
                style={props}
              >
                <div className={styles.window}>
                  {/* <Header modalType={modalType} modalProps={rest} /> */}
                  <div className={clsx([styles.content, styles[foldoutSize]])}>
                    {SpecificFoldout && (
                      <SpecificFoldout
                        close={this.handleClose}
                        {...foldoutProps}
                      />
                    )}
                  </div>
                </div>
              </animated.div>
            )
          }
        </Transition>
      </>,
      foldoutRoot
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  changed: foldoutChangedSelector(state),
  foldoutProps: foldoutPropsSelector(state),
  foldoutSize: foldoutSizeSelector(state),
  foldoutType: foldoutTypeSelector(state),
  isShown: foldoutShownSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      hideFoldout: hideFoldoutAction,
      showModal: showModalAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Foldout);
