import dayjs from 'dayjs';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { showFoldout } from '../../../actions/foldouts';
import {
  listZonesByOrganizationRequest,
  selectZoneAll,
  selectZoneWithCtrl,
  selectZoneWithShift,
} from '../../../actions/zones';
import { FullTable } from '../../../components/data';
import { Input } from '../../../components/form';
import { SvgSearch } from '../../../components/svg';
import { EDIT_ZONE_FOLDOUT } from '../../../constants/foldout-types';
import { VIEWER } from '../../../constants/roles';
import { activeOrganizationSelector } from '../../../selectors/organizations';
import { userRoleSelector } from '../../../selectors/users';
import {
  selectedZonesSelector,
  zonesByOrganizationListingSelector,
  zonesByOrganizationSelector,
} from '../../../selectors/zones';
import OptionsBar from './options-bar';
import styles from './zones.module.css';

function Zones() {
  const [t] = useTranslation('organization', { keyPrefix: 'zones' });

  const dispatch = useDispatch();

  const role = useSelector(userRoleSelector);

  const listing = useSelector(zonesByOrganizationListingSelector);
  const zones = useSelector(zonesByOrganizationSelector);
  const selected = useSelector(selectedZonesSelector);
  const activeOrganization = useSelector(activeOrganizationSelector);

  const [filter, setFilter] = useState('');

  const headers = useMemo(
    () => [t('header.name'), t('header.id'), t('header.createdAt')],
    [t]
  );

  const rows = useMemo(
    () =>
      zones
        .filter((zone) => {
          const f = filter.trim().toLowerCase();
          return (
            zone.name.toLowerCase().includes(f) ||
            zone.id.toString().includes(f)
          );
        })
        .map((zone) => {
          const { created_at: createdAt, id, name } = zone;

          const isSelected = selected.includes(id);

          return {
            cells: [name, id, dayjs(createdAt).format('DD MMM YYYY')],
            grayedOut: false,
            id,
            isSelected,
          };
        }),
    [zones, filter, selected]
  );

  const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  }, []);

  const handleCheck = useCallback(
    (e: MouseEvent<HTMLDivElement>, id: number | string) => {
      if (e.shiftKey) {
        dispatch(selectZoneWithShift(id));
      } else {
        dispatch(selectZoneWithCtrl(id));
      }
    },
    [dispatch]
  );

  const handleCheckAll = useCallback(() => {
    dispatch(selectZoneAll());
  }, [dispatch]);

  const handleEditClick = useCallback(
    (id: number | string) => {
      dispatch(showFoldout(EDIT_ZONE_FOLDOUT, { id }, 'md'));
    },
    [dispatch]
  );

  useEffect(() => {
    if (zones.length === 0) {
      dispatch(listZonesByOrganizationRequest());
    }
  }, [activeOrganization]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <Input
        blackBorder
        containerClass={styles.search}
        dashed={false}
        leftIcon={<SvgSearch />}
        onChange={handleFilterChange}
        placeholder={t('search')}
        value={filter}
      />
      <FullTable
        OptionsBar={<OptionsBar />}
        headers={headers}
        listing={listing}
        mode={role === VIEWER ? 'view' : 'edit'}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        onEditClick={handleEditClick}
        rows={rows}
      />
    </div>
  );
}

export default Zones;
