import clsx from 'clsx';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';

import { resetValidation } from '../../actions/validations';
import { PopupScrollContext } from '../../contexts';
import styles from './input.module.css';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  blackBorder?: boolean;
  blackDisabled?: boolean;
  className?: string;
  containerClass?: string;
  dashed?: boolean;
  disabled?: boolean;
  info?: string;
  leftIcon?: JSX.Element;
  name?: string;
  rightIcon?: JSX.Element;
  validation?: Validation[];
  validationKey?: string;
};

function Input(props: InputProps, ref: Ref<HTMLDivElement>) {
  const {
    blackBorder = false,
    blackDisabled = false,
    className = null,
    containerClass = null,
    dashed = true,
    disabled = false,
    info = '',
    leftIcon = null,
    name = null,
    onChange,
    rightIcon = null,
    validation = [],
    validationKey = null,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const innerRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => innerRef.current);

  const scrollTo = useContext(PopupScrollContext);

  const val: Validation = useMemo(() => {
    if (validation.length === 0) {
      return { field: '', message: '' };
    }

    const found = validation.find((v) => v.field === name);

    return found || { field: '', message: '' };
  }, [name, validation]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function') {
        onChange(e);

        if (validationKey) {
          dispatch(resetValidation(validationKey));
        }
      }
    },
    [dispatch, onChange, validationKey]
  );

  useEffect(() => {
    if (val.field === name && scrollTo) {
      // TODO: Remove commented lines if not used for 2 months (2022-10-22)
      // const input = document.querySelector(`input[name='${val.field}']`);
      // if (input) {
      //   scrollTo(0, input.getBoundingClientRect().top);
      // }

      if (innerRef.current) {
        scrollTo(0, innerRef.current.getBoundingClientRect().top);
        innerRef.current.focus({ preventScroll: true });
      }
    }
  }, [val.field]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={containerClass}>
      <div className={styles.inputContainer}>
        {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
        <input
          className={clsx([
            styles.input,
            className,
            {
              [styles.blackBorder]: blackBorder,
              [styles.blackDisabled]: blackDisabled,
              [styles.dashed]: dashed,
              [styles.disabled]: disabled,
              [styles.invalid]: !!val.message,
              [styles.leftIconExist]: leftIcon,
            },
          ])}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          ref={innerRef}
          {...rest}
        />
        <div className={styles.rightIcon}>{rightIcon}</div>
      </div>
      {!!val.message ? (
        <div className={styles.validation}>{val.message}</div>
      ) : (
        info && <div className={styles.info}>{info}</div>
      )}
    </div>
  );
}

export default React.forwardRef(Input);
