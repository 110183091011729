import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './tiny-circle-spinner.module.css';

type TinyCircleSpinnerProps = HTMLAttributes<HTMLDivElement>;

function TinyCircleSpinner(props: TinyCircleSpinnerProps) {
  const { className, ...rest } = props;

  return <div className={clsx(styles.spinner, className)} {...rest}></div>;
}

export default TinyCircleSpinner;
