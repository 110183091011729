import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Nav from '../nav';
import NavWrapper from '../nav-wrapper';

function Profile() {
  const [t] = useTranslation('sidebar', { keyPrefix: 'profile' });

  return (
    <>
      <NavWrapper>
        <Nav to="/profile/personal-information">{t('personalInformation')}</Nav>
        <Nav to="/profile/privacy-settings">{t('privacySettings')}</Nav>
      </NavWrapper>
      <Outlet />
    </>
  );
}

export default Profile;
