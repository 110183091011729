const SvgPeopleCounter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28.641}
    height={20.961}
    viewBox="0 0 28.641 20.961"
    {...props}
  >
    <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17.321 10.48a3 3 0 1 1-3-3 3 3 0 0 1 3 3ZM20.182 4.619a8.294 8.294 0 0 1 0 11.736M8.46 16.341a8.294 8.294 0 0 1 0-11.736M24.097.707a13.823 13.823 0 0 1 0 19.546m-19.546 0a13.823 13.823 0 0 1 0-19.546" />
    </g>
  </svg>
);

export default SvgPeopleCounter;
