function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#000' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 21.207 21.207"
      {...props}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-2.5 -2.5)"
      >
        <circle cx={8.889} cy={8.889} r={8.889} transform="translate(3 3)" />
        <path d="m23 23-4.833-4.833" />
      </g>
    </svg>
  );
}

export default SvgSearch;
