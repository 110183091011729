import { call, put, takeLatest } from 'redux-saga/effects';

import {
  importActivityFailure,
  importActivityRequest,
  importActivitySuccess,
} from '../actions/imports';
import { Imports } from '../api';
import { IMPORT_ACTIVITY_REQUEST } from '../constants/action-types';

function* importActivity({ file }: ReturnType<typeof importActivityRequest>) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    yield call(Imports.ImportActivity, formData);
    yield put(importActivitySuccess());
  } catch (e) {
    yield put(importActivityFailure(e));
  }
}

export function* watchImports() {
  yield takeLatest(IMPORT_ACTIVITY_REQUEST, importActivity);
}
