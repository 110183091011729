import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { showFoldout } from '../../../actions/foldouts';
import {
  listOrganizationsRequest,
  setActiveOrganization,
} from '../../../actions/organizations';
import { setLastOrganization, setLastSettings } from '../../../actions/routes';
import { showToast } from '../../../actions/toasts';
import { Button, Label, Select } from '../../../components/form';
import { InnerContentLoader } from '../../../components/loader';
import { Sections } from '../../../components/miscellaneous';
import { CREATE_ZONE_FOLDOUT } from '../../../constants/foldout-types';
import { ADMIN, SUPERUSER } from '../../../constants/roles';
import { listingIndustriesSelector } from '../../../selectors/industry';
import {
  activeOrganizationSelector,
  listingOrganizationsSelector,
  organizationsSelector,
} from '../../../selectors/organizations';
import { lastOrganizationSelector } from '../../../selectors/routes';
import { userRoleSelector } from '../../../selectors/users';
import { updateOrganizationValidationSelector } from '../../../selectors/validations';
import Billing from './billing';
import Header from './header';
import styles from './index.module.css';
import Information from './information';
import Zones from './zones';

function Organization() {
  const dispatch = useDispatch();

  const [t] = useTranslation('organization');

  const role = useSelector(userRoleSelector);

  const activeOrganization = useSelector(activeOrganizationSelector);

  const listingOrganization = useSelector(listingOrganizationsSelector);

  const listingIndustries = useSelector(listingIndustriesSelector);

  const organizations = useSelector(organizationsSelector);

  const lastOrganization = useSelector(lastOrganizationSelector);

  const validation = useSelector(updateOrganizationValidationSelector);

  const saveLastOrganization = useCallback(
    (path: string) => {
      dispatch(setLastOrganization(path));
    },
    [dispatch]
  );

  const handleOrganizationChange = useCallback(
    (option: Option) => {
      const organization = organizations.find(
        (o) => String(o.id) === option.value
      );

      dispatch(setActiveOrganization(organization));
    },
    [dispatch, organizations]
  );

  const handleCreateZoneClick = useCallback(() => {
    dispatch(showFoldout(CREATE_ZONE_FOLDOUT));
  }, [dispatch]);

  useEffect(
    () => {
      if (!activeOrganization && organizations.length > 0) {
        dispatch(setActiveOrganization(organizations[0]));
      }
    },
    [organizations] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (organizations.length === 0) {
        dispatch(listOrganizationsRequest());
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const val = validation.find((v) => !v.field);

    if (val) {
      dispatch(showToast({ level: 'failure', message: val.message }));
    }
  }, [dispatch, validation]);

  useEffect(() => {
    dispatch(setLastSettings('/settings/organizations'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (listingOrganization || !activeOrganization || listingIndustries) {
    return <InnerContentLoader />;
  }

  return (
    <>
      <Header />
      <Label className={styles.label}>
        {t('organization')}
        <Select
          contentEditable={false}
          options={organizations.map((organization) => ({
            label: organization.short_name,
            value: organization.id.toString(),
          }))}
          placeholder={t('organizationPlaceholder')}
          selected={{
            label: activeOrganization.short_name,
            value: activeOrganization.id.toString(),
          }}
          setSelected={handleOrganizationChange}
          zIndex={2}
        />
      </Label>
      <div className={styles.sections}>
        <Sections
          activeName={lastOrganization}
          items={[
            {
              active: lastOrganization === 'information',
              disabled: false,
              name: 'information',
              text: t('information.title'),
            },
            {
              active: lastOrganization === 'billing',
              disabled: false,
              name: 'billing',
              text: t('billing.title'),
            },
          ]}
          saveActive={saveLastOrganization}
        />
        <div
          className={clsx(styles.zones, {
            [styles.active]: lastOrganization === 'zones',
          })}
          onClick={() => saveLastOrganization('zones')}
        >
          {t('organizationZones')}
        </div>
        {lastOrganization === 'zones' &&
          (role === SUPERUSER || role === ADMIN) && (
            <Button
              color="secondary"
              containerClassName={styles.createZone}
              onClick={handleCreateZoneClick}
            >
              {t('createZone')}
            </Button>
          )}
      </div>
      {lastOrganization === 'information' && <Information />}
      {lastOrganization === 'billing' && <Billing />}
      {lastOrganization === 'zones' && <Zones />}
    </>
  );
}

export default Organization;
