import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import clsx from 'clsx';
import styles from './color-select.module.css';

type ColorSelectProps = {
  colors: string[];
  disabled?: boolean;
  selected?: string;
  setSelected: (color: string) => void;
};

function ColorSelect(props: ColorSelectProps) {
  const { colors, disabled = false, setSelected, selected = null } = props;

  const handleColorClick = useCallback(
    (_: MouseEvent<HTMLSpanElement>, color: string) => {
      setSelected(color);
    },
    [setSelected]
  );

  const handleColorKeyDown = useCallback(
    (e: KeyboardEvent<HTMLSpanElement>, color: string) => {
      if (e.key === 'Enter') {
        setSelected(color);
      }
    },
    [setSelected]
  );

  return (
    <div className={styles.colors}>
      {colors.map((color) => (
        <span
          className={clsx([
            styles.color,
            { [styles.selected]: color === selected },
            { [styles.disabled]: disabled },
          ])}
          id={color}
          key={color}
          onClick={(e) => handleColorClick(e, color)}
          onKeyDown={(e) => handleColorKeyDown(e, color)}
          role="button"
          style={{ background: color }}
          tabIndex={0}
          title={color}
        />
      ))}
    </div>
  );
}

export default ColorSelect;
