// app

export const SET_APP_VERSION = 'SET_APP_VERSION';

// auth

export const RESET_TOKEN = 'RESET_TOKEN';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SEND_RESET_PASSWORD_REQUEST = 'SEND_RESET_PASSWORD_REQUEST';
export const SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_FAILURE = 'SEND_RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// routes

export const SET_LAST_PROFILE = 'SET_LAST_PROFILE';

export const SET_LAST_BEHAVIOR = 'SET_LAST_BEHAVIOR';

export const SET_LAST_SETTINGS = 'SET_LAST_SETTINGS';

export const SET_LAST_ORGANIZATION = 'SET_LAST_ORGANIZATION';

// modals

export const SET_CAMERA_BEHAVIOR_SECTION = 'SET_CAMERA_BEHAVIOR_SECTION';

export const SET_INVITE_COWORKER_SECTION = 'SET_INVITE_COWORKER_SECTION';

export const SHOW_MODAL = 'SHOW_MODAL';

export const HIDE_MODAL = 'HIDE_MODAL';

// toasts

export const SHOW_TOAST = 'SHOW_TOAST';

export const HIDE_TOAST = 'HIDE_TOAST';

export const SHOW_MODAL_TOAST = 'SHOW_MODAL_TOAST';

export const HIDE_MODAL_TOAST = 'HIDE_MODAL_TOAST';

// foldout

export const SHOW_FOLDOUT = 'SHOW_FOLDOUT';

export const HIDE_FOLDOUT = 'HIDE_FOLDOUT';

export const FOLDOUT_STATE_CHANGED = 'FOLDOUT_STATE_CHANGED';

// permissions

export const LIST_PERMISSIONS_REQUEST = 'LIST_PERMISSIONS_REQUEST';
export const LIST_PERMISSIONS_SUCCESS = 'LIST_PERMISSIONS_SUCCESS';
export const LIST_PERMISSIONS_FAILURE = 'LIST_PERMISSIONS_FAILURE';

// users

export const LOCATION_TREE_REQUEST = 'LOCATION_TREE_REQUEST';
export const LOCATION_TREE_SUCCESS = 'LOCATION_TREE_SUCCESS';
export const LOCATION_TREE_FAILURE = 'LOCATION_TREE_FAILURE';

export const PATCH_USER_INFO_REQUEST = 'PATCH_USER_INFO_REQUEST';
export const PATCH_USER_INFO_SUCCESS = 'PATCH_USER_INFO_SUCCESS';
export const PATCH_USER_INFO_FAILURE = 'PATCH_USER_INFO_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const ACTIVATE_INVITED_USER_REQUEST = 'ACTIVATE_INVITED_USER_REQUEST';
export const ACTIVATE_INVITED_USER_SUCCESS = 'ACTIVATE_INVITED_USER_SUCCESS';
export const ACTIVATE_INVITED_USER_FAILURE = 'ACTIVATE_INVITED_USER_FAILURE';

export const INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';

export const INVITE_BYLINK_REQUEST = 'INVITE_BYLINK_REQUEST';
export const INVITE_BYLINK_SUCCESS = 'INVITE_BYLINK_SUCCESS';
export const INVITE_BYLINK_FAILURE = 'INVITE_BYLINK_FAILURE';

export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST';
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAILURE = 'REMOVE_USER_FAILURE';

export const SELECT_USER_WITH_CTRL = 'SELECT_USER_WITH_CTRL';
export const SELECT_USER_WITH_SHIFT = 'SELECT_USER_WITH_SHIFT';
export const SELECT_USER_WITHOUT = 'SELECT_USER_WITHOUT';
export const SELECT_USER_ALL = 'SELECT_USER_ALL';

export const LIST_USERS_REQUEST = 'LIST_USERS_REQUEST';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_FAILURE = 'LIST_USERS_FAILURE';

export const LIST_USER_GROUPS_REQUEST = 'LIST_USER_GROUPS_REQUEST';
export const LIST_USER_GROUPS_SUCCESS = 'LIST_USER_GROUPS_SUCCESS';
export const LIST_USER_GROUPS_FAILURE = 'LIST_USER_GROUPS_FAILURE';

export const GET_INVITEES_REQUEST = 'GET_INVITEES_REQUEST';
export const GET_INVITEES_SUCCESS = 'GET_INVITEES_SUCCESS';
export const GET_INVITEES_FAILURE = 'GET_INVITEES_FAILURE';

export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAILURE = 'SEND_INVITATION_FAILURE';

export const DECLINE_INVITATION_REQUEST = 'DECLINE_INVITATION_REQUEST';
export const DECLINE_INVITATION_SUCCESS = 'DECLINE_INVITATION_SUCCESS';
export const DECLINE_INVITATION_FAILURE = 'DECLINE_INVITATION_FAILURE';

export const BATCH_UPDATE_USERS_REQUEST = 'BATCH_UPDATE_USERS_REQUEST';
export const BATCH_UPDATE_USERS_SUCCESS = 'BATCH_UPDATE_USERS_SUCCESS';
export const BATCH_UPDATE_USERS_FAILURE = 'BATCH_UPDATE_USERS_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

// segmentation

export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';

export const SET_SELECTED_DISPLAY_LEVEL = 'SET_SELECTED_DISPLAY_LEVEL';

export const SET_SELECTED_LOCATION = 'SET_LOCATION';

export const SET_SELECTED_DATE = 'SET_DATE';
export const SET_SELECTED_START_DATE = 'SET_SELECTED_START_DATE';
export const SET_SELECTED_END_DATE = 'SET_SELECTED_END_DATE';

export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';

export const SET_SELECTED_LINE = 'SET_SELECTED_LINE';

export const SET_SELECTED_FLOOR = 'SET_SELECTED_FLOOR';

export const SET_SELECTED_ZONE_TO = 'SET_SELECTED_ZONE_TO';
export const SET_SELECTED_ZONE_FROM = 'SET_SELECTED_ZONE_FROM';

export const SET_SELECTED_LINE_TYPE = 'SET_SELECTED_LINE_TYPE';

export const SET_ALL_SELECTED = 'SET_ALL_SELECTED';

export const RESET_FILTERS = 'RESET_FILTERS';

// cameras

export const LIST_CAMERAS_REQUEST = 'LIST_CAMERAS_REQUEST';
export const LIST_CAMERAS_SUCCESS = 'LIST_CAMERAS_SUCCESS';
export const LIST_CAMERAS_FAILURE = 'LIST_CAMERAS_FAILURE';

export const GET_CAMERA_REQUEST = 'GET_CAMERA_REQUEST';
export const GET_CAMERA_SUCCESS = 'GET_CAMERA_SUCCESS';
export const GET_CAMERA_FAILURE = 'GET_CAMERA_FAILURE';

export const DEACTIVATE_CAMERA_REQUEST = 'DEACTIVATE_CAMERA_REQUEST';
export const DEACTIVATE_CAMERA_SUCCESS = 'DEACTIVATE_CAMERA_SUCCESS';
export const DEACTIVATE_CAMERA_FAILURE = 'DEACTIVATE_CAMERA_FAILURE';

export const ACTIVATE_CAMERA_REQUEST = 'ACTIVATE_CAMERA_REQUEST';
export const ACTIVATE_CAMERA_SUCCESS = 'ACTIVATE_CAMERA_SUCCESS';
export const ACTIVATE_CAMERA_FAILURE = 'ACTIVATE_CAMERA_FAILURE';

export const UPDATE_CAMERA_REQUEST = 'UPDATE_CAMERA_REQUEST';
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS';
export const UPDATE_CAMERA_FAILURE = 'UPDATE_CAMERA_FAILURE';

export const BATCH_UPDATE_CAMERAS_REQUEST = 'BATCH_UPDATE_CAMERAS_REQUEST';
export const BATCH_UPDATE_CAMERAS_SUCCESS = 'BATCH_UPDATE_CAMERAS_SUCCESS';
export const BATCH_UPDATE_CAMERAS_FAILURE = 'BATCH_UPDATE_CAMERAS_FAILURE';

export const CLEAR_CAMERA_SELECTIONS = 'CLEAR_CAMERA_SELECTIONS';

export const SELECT_CAMERA_WITH_CTRL = 'SELECT_CAMERA_WITH_CTRL';
export const SELECT_CAMERA_WITH_SHIFT = 'SELECT_CAMERA_WITH_SHIFT';
export const SELECT_CAMERA_WITHOUT = 'SELECT_CAMERA_WITHOUT';
export const SELECT_CAMERA_ALL = 'SELECT_CAMERA_ALL';

export const UPDATE_CAMERAS_REQUEST = 'UPDATE_CAMERAS_REQUEST';
export const UPDATE_CAMERAS_SUCCESS = 'UPDATE_CAMERAS_SUCCESS';
export const UPDATE_CAMERAS_FAILURE = 'UPDATE_CAMERAS_FAILURE';

export const CREATE_CAMERA_REQUEST = 'CREATE_CAMERA_REQUEST';
export const CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS';
export const CREATE_CAMERA_FAILURE = 'CREATE_CAMERA_FAILURE';

export const DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST';
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS';
export const DELETE_CAMERA_FAILURE = 'DELETE_CAMERA_FAILURE';

// insights

export const TOTAL_VISITS_REQUEST = 'TOTAL_VISITS_REQUEST';
export const TOTAL_VISITS_SUCCESS = 'TOTAL_VISITS_SUCCESS';
export const TOTAL_VISITS_FAILURE = 'TOTAL_VISITS_FAILURE';

export const LAST_ENTERINGS_REQUEST = 'LAST_ENTERINGS_REQUEST';
export const LAST_ENTERINGS_SUCCESS = 'LAST_ENTERINGS_SUCCESS';
export const LAST_ENTERINGS_FAILURE = 'LAST_ENTERINGS_FAILURE';

export const TOTAL_ENTERINGS_REQUEST = 'TOTAL_ENTERINGS_REQUEST';
export const TOTAL_ENTERINGS_SUCCESS = 'TOTAL_ENTERINGS_SUCCESS';
export const TOTAL_ENTERINGS_FAILURE = 'TOTAL_ENTERINGS_FAILURE';

export const TOTAL_VISITORS_REQUEST = 'TOTAL_VISITORS_REQUEST';
export const TOTAL_VISITORS_SUCCESS = 'TOTAL_VISITORS_SUCCESS';
export const TOTAL_VISITORS_FAILURE = 'TOTAL_VISITORS_FAILURE';

export const TOTAL_VISITS_PREVIOUS_REQUEST = 'TOTAL_VISITS_PREVIOUS_REQUEST';
export const TOTAL_VISITS_PREVIOUS_SUCCESS = 'TOTAL_VISITS_PREVIOUS_SUCCESS';
export const TOTAL_VISITS_PREVIOUS_FAILURE = 'TOTAL_VISITS_PREVIOUS_FAILURE';

export const LINE_TYPE_MULTILINE_REQUEST = 'LINE_TYPE_MULTILINE_REQUEST';
export const LINE_TYPE_MULTILINE_SUCCESS = 'LINE_TYPE_MULTILINE_SUCCESS';
export const LINE_TYPE_MULTILINE_FAILURE = 'LINE_TYPE_MULTILINE_FAILURE';

export const CAMERA_LINE_MULTILINE_REQUEST = 'CAMERA_LINE_MULTILINE_REQUEST';
export const CAMERA_LINE_MULTILINE_SUCCESS = 'CAMERA_LINE_MULTILINE_SUCCESS';
export const CAMERA_LINE_MULTILINE_FAILURE = 'CAMERA_LINE_MULTILINE_FAILURE';

export const ZONE_FROM_MULTILINE_REQUEST = 'ZONE_FROM_MULTILINE_REQUEST';
export const ZONE_FROM_MULTILINE_SUCCESS = 'ZONE_FROM_MULTILINE_SUCCESS';
export const ZONE_FROM_MULTILINE_FAILURE = 'ZONE_FROM_MULTILINE_FAILURE';

export const ZONE_TO_MULTILINE_REQUEST = 'ZONE_TO_MULTILINE_REQUEST';
export const ZONE_TO_MULTILINE_SUCCESS = 'ZONE_TO_MULTILINE_SUCCESS';
export const ZONE_TO_MULTILINE_FAILURE = 'ZONE_TO_MULTILINE_FAILURE';

export const LOCATION_MULTILINE_REQUEST = 'LOCATION_MULTILINE_REQUEST';
export const LOCATION_MULTILINE_SUCCESS = 'LOCATION_MULTILINE_SUCCESS';
export const LOCATION_MULTILINE_FAILURE = 'LOCATION_MULTILINE_FAILURE';

export const EXPORT_CHART_AS_IMAGE_REQUEST = 'EXPORT_CHART_AS_IMAGE_REQUEST';
export const EXPORT_CHART_AS_IMAGE_SUCCESS = 'EXPORT_CHART_AS_IMAGE_SUCCESS';
export const EXPORT_CHART_AS_IMAGE_FAILURE = 'EXPORT_CHART_AS_IMAGE_FAILURE';

// visitor journey

export const VISITOR_JOURNEY_REQUEST = 'VISITOR_JOURNEY_REQUEST';
export const VISITOR_JOURNEY_SUCCESS = 'VISITOR_JOURNEY_SUCCESS';
export const VISITOR_JOURNEY_FAILURE = 'VISITOR_JOURNEY_FAILURE';

// locations

export const LIST_LOCATIONS_REQUEST = 'LIST_LOCATIONS_REQUEST';
export const LIST_LOCATIONS_SUCCESS = 'LIST_LOCATIONS_SUCCESS';
export const LIST_LOCATIONS_FAILURE = 'LIST_LOCATIONS_FAILURE';

export const LIST_ALL_LOCATIONS_REQUEST = 'LIST_ALL_LOCATIONS_REQUEST';
export const LIST_ALL_LOCATIONS_SUCCESS = 'LIST_ALL_LOCATIONS_SUCCESS';
export const LIST_ALL_LOCATIONS_FAILURE = 'LIST_ALL_LOCATIONS_FAILURE';

export const SELECT_ALL_LOCATIONS = 'SELECT_ALL_LOCATIONS';
export const SELECT_LOCATION_WITH_SHIFT = 'SELECT_LOCATION_WITH_SHIFT';
export const SELECT_LOCATION_WITH_CTRL = 'SELECT_LOCATION_WITH_CTRL';

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

export const CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

// organizations

export const SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION';

export const LIST_ORGANIZATIONS_REQUEST = 'LIST_ORGANIZATIONS_REQUEST';
export const LIST_ORGANIZATIONS_SUCCESS = 'LIST_ORGANIZATIONS_SUCCESS';
export const LIST_ORGANIZATIONS_FAILURE = 'LIST_ORGANIZATIONS_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

// industries

export const LIST_INDUSTRIES_REQUEST = 'LIST_INDUSTRIES_REQUEST';
export const LIST_INDUSTRIES_SUCCESS = 'LIST_INDUSTRIES_SUCCESS';
export const LIST_INDUSTRIES_FAILURE = 'LIST_INDUSTRIES_FAILURE';

// categories

export const LIST_CATEGORIES_REQUEST = 'LIST_CATEGORIES_REQUEST';
export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_FAILURE = 'LIST_CATEGORIES_FAILURE';

// zones

export const LIST_ZONES_REQUEST = 'LIST_ZONES_REQUEST';
export const LIST_ZONES_SUCCESS = 'LIST_ZONES_SUCCESS';
export const LIST_ZONES_FAILURE = 'LIST_ZONES_FAILURE';

export const LIST_ZONES_BY_ORGANIZATION_REQUEST =
  'LIST_ZONES_BY_ORGANIZATION_REQUEST';
export const LIST_ZONES_BY_ORGANIZATION_SUCCESS =
  'LIST_ZONES_BY_ORGANIZATION_SUCCESS';
export const LIST_ZONES_BY_ORGANIZATION_FAILURE =
  'LIST_ZONES_BY_ORGANIZATION_FAILURE';

export const UPDATE_ZONE_REQUEST = 'UPDATE_ZONE_REQUEST';
export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';
export const UPDATE_ZONE_FAILURE = 'UPDATE_ZONE_FAILURE';

export const CREATE_ZONE_REQUEST = 'CREATE_ZONE_REQUEST';
export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILURE = 'CREATE_ZONE_FAILURE';

export const DELETE_ZONE_REQUEST = 'DELETE_ZONE_REQUEST';
export const DELETE_ZONE_SUCCESS = 'DELETE_ZONE_SUCCESS';
export const DELETE_ZONE_FAILURE = 'DELETE_ZONE_FAILURE';

export const SELECT_ZONE_WITH_CTRL = 'SELECT_ZONE_WITH_CTRL';
export const SELECT_ZONE_WITH_SHIFT = 'SELECT_ZONE_WITH_SHIFT';
export const SELECT_ZONE_WITHOUT = 'SELECT_ZONE_WITHOUT';
export const SELECT_ZONE_ALL = 'SELECT_ZONE_ALL';
export const CLEAR_ZONE_SELECTIONS = 'CLEAR_ZONE_SELECTIONS';

// validations

export const RESET_VALIDATION = 'RESET_VALIDATION';

// lines

export const LIST_LINES_REQUEST = 'LIST_LINES_REQUEST';
export const LIST_LINES_SUCCESS = 'LIST_LINES_SUCCESS';
export const LIST_LINES_FAILURE = 'LIST_LINES_FAILURE';

// import
export const IMPORT_ACTIVITY_REQUEST = 'IMPORT_ACTIVITY_REQUEST';
export const IMPORT_ACTIVITY_SUCCESS = 'IMPORT_ACTIVITY_SUCCESS';
export const IMPORT_ACTIVITY_FAILURE = 'IMPORT_ACTIVITY_FAILURE';

// filters
export const LIST_FILTERS_REQUEST = 'LIST_FILTERS_REQUEST';
export const LIST_FILTERS_SUCCESS = 'LIST_FILTERS_SUCCESS';
export const LIST_FILTERS_FAILURE = 'LIST_FILTERS_FAILURE';

export const SAVE_FILTER_REQUEST = 'SAVE_FILTER_REQUEST';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_FAILURE = 'SAVE_FILTER_FAILURE';

export const DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_FAILURE = 'DELETE_FILTER_FAILURE';

export const UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE';

export const REPLACE_FILTER_REQUEST = 'REPLACE_FILTER_REQUEST';
export const REPLACE_FILTER_SUCCESS = 'REPLACE_FILTER_SUCCESS';
export const REPLACE_FILTER_FAILURE = 'REPLACE_FILTER_FAILURE';

export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
