import {
  LIST_INDUSTRIES_FAILURE,
  LIST_INDUSTRIES_REQUEST,
  LIST_INDUSTRIES_SUCCESS,
} from '../constants/action-types';
import { IIndustry } from '../types/api/industry';

export const listIndustriesRequest = () => ({
  type: LIST_INDUSTRIES_REQUEST,
});

export const listIndustriesSuccess = (industries: IIndustry[]) => ({
  industries,
  type: LIST_INDUSTRIES_SUCCESS,
});

export const listIndustriesFailure = (error: unknown) => ({
  error,
  type: LIST_INDUSTRIES_FAILURE,
});
