import { RootActions } from '../actions';
import * as locationsActions from '../actions/locations';
import {
  CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  LIST_ALL_LOCATIONS_FAILURE,
  LIST_ALL_LOCATIONS_REQUEST,
  LIST_ALL_LOCATIONS_SUCCESS,
  LIST_LOCATIONS_FAILURE,
  LIST_LOCATIONS_REQUEST,
  LIST_LOCATIONS_SUCCESS,
  RESET_TOKEN,
  SELECT_ALL_LOCATIONS,
  SELECT_LOCATION_WITH_CTRL,
  SELECT_LOCATION_WITH_SHIFT,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/action-types';
import { ILocation } from '../types/api/locations';

export type LocationsState = {
  allLocations: ILocation[];
  count: number;
  creating: boolean;
  deletingLocation: boolean;
  filteredLocations: ILocation['id'][];
  lastSelected: ILocation['id'] | undefined;
  listing: boolean;
  listingAll: boolean;
  locations: ILocation[];
  selectedLocations: ILocation['id'][];
  updatingLocation: boolean;
  updatingLocations: boolean;
};

export const initialState: LocationsState = {
  allLocations: [],
  count: 0,
  creating: false,
  deletingLocation: false,
  filteredLocations: [],
  lastSelected: undefined,
  listing: false,
  listingAll: false,
  locations: [],
  selectedLocations: [],
  updatingLocation: false,
  updatingLocations: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case LIST_ALL_LOCATIONS_REQUEST:
      return {
        ...state,
        listingAll: true,
      };
    case LIST_ALL_LOCATIONS_SUCCESS: {
      const { locations } = action as ReturnType<
        typeof locationsActions.listAllLocationsSuccess
      >;

      return {
        ...state,
        allLocations: locations,
        listingAll: false,
      };
    }
    case LIST_ALL_LOCATIONS_FAILURE:
      return {
        ...state,
        listingAll: false,
      };
    case LIST_LOCATIONS_REQUEST:
      return {
        ...state,
        listing: true,
        selectedLocations: [],
      };
    case LIST_LOCATIONS_SUCCESS: {
      const { count, locations } = action as ReturnType<
        typeof locationsActions.listLocationsSuccess
      >;

      return {
        ...state,
        count,
        listing: false,
        locations,
      };
    }
    case LIST_LOCATIONS_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case CREATE_LOCATION_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }
    case CREATE_LOCATION_SUCCESS: {
      const { location } = action as ReturnType<
        typeof locationsActions.createLocationSuccess
      >;

      return {
        ...state,
        allLocations: [location, ...state.allLocations],
        creating: false,
        locations: [location, ...state.locations],
      };
    }
    case CREATE_LOCATION_FAILURE: {
      return {
        ...state,
        creating: false,
      };
    }
    case UPDATE_LOCATION_REQUEST: {
      return {
        ...state,
        updatingLocation: true,
      };
    }
    case UPDATE_LOCATION_SUCCESS: {
      const { location } = action as ReturnType<
        typeof locationsActions.updateLocationSuccess
      >;

      return {
        ...state,
        allLocations: state.allLocations.map((l) => {
          if (l.id === location.id) {
            return location;
          }
          return l;
        }),
        locations: state.locations.map((l) => {
          if (l.id === location.id) {
            return location;
          }
          return l;
        }),
        updatingLocation: false,
      };
    }
    case UPDATE_LOCATION_FAILURE: {
      return {
        ...state,
        updatingLocation: false,
      };
    }
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        deletingLocation: true,
      };
    case DELETE_LOCATION_SUCCESS: {
      const { id } = action as ReturnType<
        typeof locationsActions.deleteLocationSuccess
      >;

      return {
        ...state,
        deletingLocation: false,
        locations: state.locations.filter((location) => location.id !== id),
        selectedLocations: state.selectedLocations.filter(
          (locationId) => locationId !== id
        ),
      };
    }
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        deletingLocations: false,
      };
    case SELECT_ALL_LOCATIONS: {
      let selectedLocations = [];

      if (!state.selectedLocations.length) {
        selectedLocations = state.locations.map(({ id }) => id);
      }

      return {
        ...state,
        selectedLocations,
      };
    }
    case SELECT_LOCATION_WITH_SHIFT: {
      const { filteredLocations, lastSelected } = state;
      const { id } = action as ReturnType<
        typeof locationsActions.selectLocationWithShift
      >;

      let { locations } = state;
      if (filteredLocations.length) {
        locations = locations.filter((location) =>
          filteredLocations.includes(location.id)
        );
      }

      if (!lastSelected || id === lastSelected) {
        return {
          ...state,
          lastSelected: id,
          selectedLocations: [id],
        };
      }

      const index = locations.findIndex((u) => id === u.id);
      const indexLastSelected = locations.findIndex(
        (u) => lastSelected === u.id
      );

      let selectedLocations: ILocation['id'][];
      if (index > indexLastSelected) {
        selectedLocations = locations
          .slice(indexLastSelected, index + 1)
          .map((u) => u.id);
      } else {
        selectedLocations = locations
          .slice(index, indexLastSelected + 1)
          .map((u) => u.id);
      }

      return {
        ...state,
        selectedLocations,
      };
    }
    case SELECT_LOCATION_WITH_CTRL: {
      const { selectedLocations } = state;
      const { id } = action as ReturnType<
        typeof locationsActions.selectLocationWithCtrl
      >;

      const index = state.selectedLocations.indexOf(id);

      if (index === -1) {
        const newSelected = [...selectedLocations, id];

        return {
          ...state,
          lastSelected: id,
          selectedLocations: newSelected,
        };
      }

      const newSelected = [
        ...selectedLocations.slice(0, index),
        ...selectedLocations.slice(index + 1),
      ];
      let previousSelection = 0;
      if (newSelected.length) {
        previousSelection = newSelected[newSelected.length - 1];
      }

      return {
        ...state,
        lastSelected: previousSelection,
        selectedLocations: newSelected,
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
