import { RootActions } from '../actions';
import * as categoriesActions from '../actions/categories';
import {
  LIST_CATEGORIES_FAILURE,
  LIST_CATEGORIES_REQUEST,
  LIST_CATEGORIES_SUCCESS,
  RESET_TOKEN,
} from '../constants/action-types';
import { ICategory } from '../types/api/categories';

export type CategoriesState = {
  categories: ICategory[];
  listing: boolean;
};

export const initialState: CategoriesState = {
  categories: [],
  listing: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case LIST_CATEGORIES_REQUEST:
      return {
        ...state,
        listing: true,
      };
    case LIST_CATEGORIES_SUCCESS: {
      const { categories } = action as ReturnType<
        typeof categoriesActions.listCategoriesSuccess
      >;

      return {
        ...state,
        categories,
        listing: false,
      };
    }
    case LIST_CATEGORIES_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
