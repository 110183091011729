export const generateGenders = (t) => [
  {
    label: t('gender.female'),
    value: 1,
  },
  {
    label: t('gender.male'),
    value: 2,
  },
];

export const generateAges = (t) => [
  {
    label: t('age.18-24'),
    value: 3,
  },
  {
    label: t('age.25-34'),
    value: 4,
  },
  {
    label: t('age.35-44'),
    value: 5,
  },
  {
    label: t('age.45-54'),
    value: 6,
  },
  {
    label: t('age.55-64'),
    value: 7,
  },
  {
    label: t('age.65+'),
    value: 8,
  },
];

export const dates = [
  {
    label: 'Custom date',
    value: 'custom',
  },
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'This Week',
    value: 'thisWeek',
  },
  {
    label: 'Last Week',
    value: 'lastWeek',
  },
  {
    label: 'This Month',
    value: 'thisMonth',
  },
  {
    label: 'Last Month',
    value: 'lastMonth',
  },
  {
    label: 'This Year',
    value: 'thisYear',
  },
  {
    label: 'Last Year',
    value: 'lastYear',
  },
];

export const secondVariables = [
  {
    label: '1 minute interval',
    value: '1m',
  },
  {
    label: '10 minutes interval',
    value: '10m',
  },
  {
    label: '30 minutes interval',
    value: '30m',
  },
  {
    label: '1 hour interval',
    value: '1h',
  },
  {
    label: '1 day interval',
    value: '1d',
  },
  {
    label: '1 week interval',
    value: '1w',
  },
  {
    label: '1 month interval',
    value: '1mo',
  },
];
