import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { hideModal } from '../../actions/modals';
import { SvgCross } from '../../components/svg';
import styles from './header.module.css';

type HeaderProps = {
  children?: JSX.Element;
  icon?: JSX.Element;
  title?: string;
};

function Header(props: HeaderProps) {
  const { children, icon, title } = props;

  const dispatch = useDispatch();

  const handleCloseClick = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return (
    <div className={styles.header}>
      <div className={styles.top}>
        <SvgCross
          className={styles.close}
          height={16}
          onClick={handleCloseClick}
          width={16}
        />
      </div>
      <div className={styles.bottom}>
        {icon && title && (
          <div className={styles.label}>
            {icon}
            <span className={styles.title}>{title}</span>
          </div>
        )}
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
}

export default Header;
