import { call, put, select, takeLatest } from 'redux-saga/effects';

import { hideFoldout } from '../actions/foldouts';
import { hideModal } from '../actions/modals';
import {
  createZoneFailure,
  createZoneRequest,
  createZoneSuccess,
  deleteZoneFailure,
  deleteZoneRequest,
  deleteZoneSuccess,
  listZonesByOrganizationFailure,
  listZonesByOrganizationSuccess,
  listZonesFailure,
  listZonesSuccess,
  updateZoneFailure,
  updateZoneRequest,
  updateZoneSuccess,
} from '../actions/zones';
import { Zones } from '../api';
import {
  CREATE_ZONE_REQUEST,
  DELETE_ZONE_REQUEST,
  LIST_ZONES_BY_ORGANIZATION_REQUEST,
  LIST_ZONES_REQUEST,
  UPDATE_ZONE_REQUEST,
} from '../constants/action-types';
import { activeOrganizationSelector } from '../selectors/organizations';
import { parseError } from '../utils/error';

export function* listZones() {
  try {
    const { body: zones } = yield call(Zones.List);
    yield put(listZonesSuccess(zones));
  } catch (e) {
    yield put(listZonesFailure(e));
  }
}

export function* listZonesByOrganization() {
  try {
    const activeOrganization = yield select(activeOrganizationSelector);
    const { body: zones } = yield call(
      Zones.ListByOrganizationId,
      activeOrganization.id
    );
    yield put(listZonesByOrganizationSuccess(zones));
  } catch (e) {
    yield put(listZonesByOrganizationFailure(e));
  }
}

export function* updateZone({
  id,
  zone: payload,
}: ReturnType<typeof updateZoneRequest>) {
  try {
    const { body: zone } = yield call(Zones.Update, id, payload);
    yield put(updateZoneSuccess(zone));
    yield put(hideFoldout());
  } catch (e) {
    yield put(updateZoneFailure(e, parseError(e)));
  }
}

export function* createZone({
  zone: payload,
}: ReturnType<typeof createZoneRequest>) {
  try {
    const activeOrganization = yield select(activeOrganizationSelector);
    const { body: zone } = yield call(Zones.Create, {
      ...payload,
      organization: activeOrganization.id,
    });
    yield put(createZoneSuccess(zone));
    yield put(hideFoldout());
  } catch (e) {
    yield put(createZoneFailure(e, parseError(e)));
  }
}

export function* deleteZone({ id }: ReturnType<typeof deleteZoneRequest>) {
  try {
    yield call(Zones.Delete, id);
    yield put(deleteZoneSuccess(id));
    yield put(hideFoldout());
    yield put(hideModal());
  } catch (e) {
    yield put(deleteZoneFailure(e));
  }
}

export function* watchZones() {
  yield takeLatest(CREATE_ZONE_REQUEST, createZone);
  yield takeLatest(DELETE_ZONE_REQUEST, deleteZone);
  yield takeLatest(LIST_ZONES_BY_ORGANIZATION_REQUEST, listZonesByOrganization);
  yield takeLatest(LIST_ZONES_REQUEST, listZones);
  yield takeLatest(UPDATE_ZONE_REQUEST, updateZone);
}
