import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listOrganizationsRequest } from '../../../../../../actions/organizations';
import { setSelectedOrganization } from '../../../../../../actions/segmentation';
import { Checkbox, Label, Select } from '../../../../../../components/form';
import { listingOrganizationsSelector } from '../../../../../../selectors/organizations';
import {
  latestResetTimeSelector,
  organizationFilterOptionsSelector,
  selectedOrganizationSelector,
} from '../../../../../../selectors/segmentation';

type OrganizationFilterProps = {
  label: string;
  placeholder?: string;
};

function OrganizationFilter(props: OrganizationFilterProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const latestReset = useSelector(latestResetTimeSelector);
  const selected = useSelector(selectedOrganizationSelector);
  const loading = useSelector(listingOrganizationsSelector);
  const options = useSelector(organizationFilterOptionsSelector);

  const [filterEnabled, setFilterEnabled] = useState(!!selected);

  const handleChange = useCallback(
    (option: Option) => {
      dispatch(setSelectedOrganization(option));
    },
    [dispatch]
  );

  const handleToggleFilter = useCallback(() => {
    if (filterEnabled) {
      dispatch(setSelectedOrganization(null));
    }

    setFilterEnabled(!filterEnabled);
  }, [dispatch, filterEnabled]);

  useEffect(() => {
    dispatch(listOrganizationsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!!selected) {
      setFilterEnabled(!!selected);
    }
  }, [selected]);

  useEffect(() => {
    if (latestReset) {
      dispatch(setSelectedOrganization(options[0]));
    }
  }, [latestReset]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Checkbox
        borderColor="black"
        checked={filterEnabled}
        label={label}
        onClick={handleToggleFilter}
      />
      {filterEnabled && (
        <Label>
          <Select
            loading={loading}
            options={options}
            placeholder={placeholder}
            selected={selected}
            setSelected={handleChange}
            zIndex={7}
          />
        </Label>
      )}
    </>
  );
}

export default OrganizationFilter;
