import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Placement } from '@floating-ui/react';

import styles from './four-step-progress.module.css';
import Tooltip from './tooltip';
import { SvgTip } from '../svg';
import { StandardTooltip } from '../tooltip';
import { TStrength } from '../../utils/helper';
import { useWindowSize } from '../../hooks';

type FourStepProgressProps = HTMLAttributes<HTMLDivElement> & {
  strength: [number, TStrength];
  tooltip: JSX.Element;
  showTooltip: boolean;
  placement?: Placement;
  onTipOver?: () => void;
  onTipOut?: () => void;
};

function FourStepProgress(props: FourStepProgressProps) {
  const {
    strength,
    className,
    tooltip,
    showTooltip,
    placement,
    onTipOver,
    onTipOut,
  } = props;

  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={clsx(styles.progress, styles[strength[1]], className)}>
        <div
          className={clsx(styles.step, { [styles.active]: strength[0] > 0 })}
        />
        <div
          className={clsx(styles.step, { [styles.active]: strength[0] > 1 })}
        />
        <div
          className={clsx(styles.step, { [styles.active]: strength[0] > 2 })}
        />
        <div
          className={clsx(styles.step, { [styles.active]: strength[0] > 3 })}
        />
      </div>
      <div className={clsx(styles.strength, styles[strength[1]])}>
        {strength[1]}
        <Tooltip
          placement={placement || width > 1040 ? 'right' : 'left-start'}
          open={showTooltip}
        >
          <Tooltip.Trigger asChild>
            <div
              className={styles.tip}
              role="link"
              tabIndex={0}
              onMouseOver={onTipOver}
              onMouseOut={onTipOut}
            >
              <SvgTip width={18} height={18} />
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <StandardTooltip>{tooltip}</StandardTooltip>
          </Tooltip.Content>
        </Tooltip>
      </div>
    </div>
  );
}

export default FourStepProgress;
