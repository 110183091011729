import { tokenSelector } from './selectors/auth';
import storeRegistry from './store/store-registry';
import { ICamera } from './types/api/cameras';
import { IFilter } from './types/api/filters';
import { ILineWithZoneIds } from './types/api/lines';
import requestCreator from './utils/request';

const Request = requestCreator(tokenSelector, storeRegistry);

export class Google {
  static Timezone(
    latitude: number,
    longitude: number,
    timestamp: number = new Date().getTime()
  ) {
    return new Request()
      .Get(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude}%2C${longitude}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`
      )
      .End();
  }
}

export class Auth {
  static Login(email: string, password: string) {
    return new Request()
      .Post('/api/v1/login/')
      .Set('authorization')
      .Field('email', email)
      .Field('password', password)
      .End();
  }

  static Logout() {
    return new Request().Post('/api/v1/logout/').End();
  }

  static Register(email: string, password: string) {
    return new Request()
      .Post('/api/v1/register/')
      .Field('email', email)
      .Field('password', password)
      .End();
  }

  static ResetPassword(pre: string, post: string, password: string) {
    return new Request()
      .Post(`/api/v1/reset_password/${pre}/${post}/`)
      .Set('Authorization')
      .Field('password', password)
      .End();
  }

  static SendResetPassword(email: string) {
    return new Request()
      .Post('/api/v1/users/send_reset_password/')
      .Set('authorization')
      .Field('email', email)
      .End();
  }
}

export class Permissions {
  static List() {
    return new Request().Get('/api/v1/permissions/').End();
  }
}

export class Users {
  static ActivateInvitedUser(pre, post, password) {
    return new Request()
      .Post(`/api/v1/activate_invited_user/${pre}/${post}/`)
      .Field('password', password)
      .End();
  }

  static BatchUpdate(ids: number[], fieldName: string, value: string) {
    return new Request()
      .Patch('/api/v1/users/batch/')
      .Send({
        field_name: fieldName,
        ids,
        value,
      })
      .End();
  }

  static Create(payload) {
    return new Request().Post('/api/v1/users/').Send(payload).End();
  }

  static DeclineInvitation(ids) {
    return new Request()
      .Post('/api/v1/users/invite/decline/')
      .Send({ ids })
      .End();
  }

  static DeleteUser(id: number) {
    return new Request()
      .Post(`/api/v1/users/${id}/delete-user/`)
      .Send({ id })
      .End();
  }

  static Get(uid) {
    return new Request().Get(`/api/v1/users/${uid}/`).End();
  }

  static Invite(
    inviteEmail,
    locations,
    startAt,
    expireAt,
    systemRole,
    retrieveLink = false
  ) {
    const req: {
      expire_at?: string;
      invite_email: string;
      locations: number[];
      start_at: string;
      system_role: string;
    } = {
      invite_email: inviteEmail,
      locations,
      start_at: startAt,
      system_role: systemRole,
    };

    if (expireAt) req.expire_at = expireAt;

    return new Request()
      .Post(`/api/v1/users/invite/?retrieve_link=${retrieveLink}`)
      .Send(req)
      .End();
  }

  static Invitees() {
    return new Request().Get('/api/v1/users/invite/').End();
  }

  static List(limit: number, offset: number) {
    return new Request()
      .Get(`/api/v1/users/?limit=${limit}&offset=${offset}`)
      .End();
  }

  static ListOrganizations(uid) {
    return new Request().Get(`/api/v1/users/${uid}/organizations/`).End();
  }

  static ListUserGroups(uid) {
    return new Request().Get(`/api/v1/users/${uid}/groups/`).End();
  }

  static PatchUserInfo(uid, { firstName, lastName, password, workRole }) {
    return new Request()
      .Patch(`/api/v1/users/${uid}/`)
      .Send(
        password
          ? {
              first_name: firstName,
              last_name: lastName,
              password,
              work_role: workRole,
            }
          : {
              first_name: firstName,
              last_name: lastName,
              work_role: workRole,
            }
      )
      .End();
  }

  static PatchUsers(payload) {
    return new Request().Patch('/api/v1/users/batch/').Send(payload).End();
  }

  static RemoveUser(uid) {
    return new Request().Delete(`/api/v1/users/${uid}/`).End();
  }

  static SendInvitation(ids) {
    return new Request().Post('/api/v1/users/invite/send/').Send({ ids }).End();
  }

  static Update(id: number, payload) {
    return new Request().Patch(`/api/v1/users/${id}/`).Send(payload).End();
  }
}

export class Cameras {
  static Activate(id: ICamera['id']) {
    return new Request().Patch(`/api/v1/cameras/${id}/activate/`).End();
  }

  static BatchUpdate(ids: ICamera['id'][], fieldName: string, value: string) {
    return new Request()
      .Patch('/api/v1/cameras/batch/')
      .Send({
        field_name: fieldName,
        ids,
        value,
      })
      .End();
  }

  static Create(
    payload: Partial<Omit<ICamera, 'zones'>> & {
      zones?: ILineWithZoneIds[];
    }
  ) {
    return new Request().Post('/api/v1/cameras/').Send(payload).End();
  }

  static Deactivate(id: ICamera['id']) {
    return new Request().Patch(`/api/v1/cameras/${id}/deactivate/`).End();
  }

  static Delete(id: ICamera['id']) {
    return new Request().Delete(`/api/v1/cameras/${id}/`).End();
  }

  static Get(id: ICamera['id']) {
    return new Request().Get(`/api/v1/cameras/${id}/`).End();
  }

  static List(limit: number, offset: number, order = '', search = '') {
    return new Request()
      .Get(
        `/api/v1/cameras/?limit=${limit}&offset=${offset}&search=${search}&ordering=${order}`
      )
      .End();
  }

  static Update(
    id: ICamera['id'],
    payload: Partial<Omit<ICamera, 'zones'>> & {
      zones: ILineWithZoneIds[];
    }
  ) {
    return new Request().Patch(`/api/v1/cameras/${id}/`).Send(payload).End();
  }
}

export class Groups {
  static List() {
    return new Request().Get('/api/v1/groups/').End();
  }
}

export class Locations {
  static Create(payload) {
    return new Request().Post('/api/v1/locations/').Send(payload).End();
  }

  static DeleteLocation(id) {
    return new Request().Delete(`/api/v1/locations/${id}/`).End();
  }

  static List(limit: number, offset: number) {
    return new Request()
      .Get(`/api/v1/locations/?limit=${limit}&offset=${offset}`)
      .End();
  }

  static ListAll() {
    return new Request().Get('/api/v1/locations/').End();
  }

  static PatchLocations(payload) {
    return new Request().Patch('/api/v1/locations/batch/').Send(payload).End();
  }

  static Update(id: number, payload) {
    return new Request().Patch(`/api/v1/locations/${id}/`).Send(payload).End();
  }
}

export class Organizations {
  static Cancel(id) {
    return new Request()
      .Post(`/api/v1/organizations/${id}/cancel-subscription/`)
      .End();
  }

  static Create(payload) {
    return new Request().Post('/api/v1/organizations/').Send(payload).End();
  }

  static Delete(id) {
    return new Request().Delete(`/api/v1/organizations/${id}/`).End();
  }

  static List() {
    return new Request().Get('/api/v1/organizations/').End();
  }

  static Patch(id, payload) {
    return new Request()
      .Patch(`/api/v1/organizations/${id}/`)
      .Send(payload)
      .End();
  }
}

export class Lines {
  static List(params: {
    category?: string;
    floor?: string;
    location?: string;
    organization?: string;
    zone?: string;
  }) {
    const queryString = new URLSearchParams(params);

    return new Request().Get(`/api/v1/lines/?${queryString}`).End();
  }
}

export class Categories {
  static Create(payload) {
    return new Request()
      .Post('/api/v1/lines/organization-category/')
      .Send(payload)
      .End();
  }

  static Delete(id: number) {
    return new Request()
      .Delete(`/api/v1/lines/organization-category/${id}/`)
      .End();
  }

  static List() {
    return new Request().Get('/api/v1/lines/organization-category/').End();
  }

  static Update(id: number, payload) {
    return new Request()
      .Patch(`/api/v1/lines/organization-category/${id}/`)
      .Send(payload)
      .End();
  }
}

export class Zones {
  static Create(payload) {
    return new Request()
      .Post('/api/v1/lines/organization-zone/')
      .Send(payload)
      .End();
  }

  static Delete(id: number) {
    return new Request().Delete(`/api/v1/lines/organization-zone/${id}/`).End();
  }

  static List() {
    return new Request().Get('/api/v1/lines/organization-zone/').End();
  }

  static ListByOrganizationId(organizationId: string) {
    return new Request()
      .Get(`/api/v1/organizations/${organizationId}/zones/`)
      .End();
  }

  static Update(id: number, payload) {
    return new Request()
      .Patch(`/api/v1/lines/organization-zone/${id}/`)
      .Send(payload)
      .End();
  }
}

export class Insights {
  static AgeDistribution(startDate, endDate, locationId) {
    return new Request()
      .Post('/insights/v1/age_distribution/')
      .Field('start_date', startDate)
      .Field('end_date', endDate)
      .Field('location_id', locationId)
      .End();
  }

  static GenderDistribution(startDate, endDate, locationId) {
    return new Request()
      .Post('/insights/v1/gender_distribution/')
      .Field('start_date', startDate)
      .Field('end_date', endDate)
      .Field('location_id', locationId)
      .End();
  }

  static MostPopularTime(startDate, endDate, locationId, agg) {
    return new Request()
      .Post('/insights/v1/most_popular_time_distribution/')
      .Field('start_date', startDate)
      .Field('end_date', endDate)
      .Field('location_id', locationId)
      .Field('agg', agg)
      .End();
  }

  static TotalVisits(params: {
    aggregate_by: string;
    chunk?: string;
    end_date: string;
    floor?: string;
    line_ids?: string;
    location?: string;
    organization: string;
    start_date: string;
    type?: string;
    zone?: string;
  }) {
    const queryString = new URLSearchParams(params);

    return new Request()
      .Get(`/api/v1/rnd/insights/line_crosses/?${queryString}`)
      .End();
  }

  static TotalVisitsDistribution(startDate, endDate, locationId, agg) {
    return new Request()
      .Post('/insights/v1/total_visits_distribution/')
      .Field('start_date', startDate)
      .Field('end_date', endDate)
      .Field('location_id', locationId)
      .Field('agg', agg)
      .End();
  }

  static VisitorJourney(params: {
    depth: string;
    end_date: string;
    location?: string;
    start_date: string;
  }) {
    return new Request()
      .Get(`/api/v1/rnd/insights/flow/?${new URLSearchParams(params)}`)
      .End();
  }
}

export class Industries {
  static List() {
    return new Request().Get('/api/v1/industries/').End();
  }
}

export class Imports {
  static ImportActivity(formData: FormData) {
    return new Request().Post('/api/v1/import-data/').Send(formData).End();
  }
}

export class Filters {
  static Delete(id: number) {
    return new Request().Delete(`/api/v1/filters-settings/${id}/`).End();
  }

  static List() {
    return new Request().Get('/api/v1/filters-settings/').End();
  }

  static Save(payload: Partial<IFilter>) {
    return new Request().Post('/api/v1/filters-settings/').Send(payload).End();
  }

  static Update(id: number, payload: Partial<IFilter>) {
    return new Request()
      .Patch(`/api/v1/filters-settings/${id}/`)
      .Send(payload)
      .End();
  }
}
