import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { hideFoldout } from '../../../actions/foldouts';
import { hideModal } from '../../../actions/modals';
import { Button } from '../../../components/form';
import { SvgTrash } from '../../../components/svg';
import { deletingUserSelector } from '../../../selectors/users';
import Header from '../header';
import styles from './index.module.css';

type UnsavedChangesModalProps = {
  close: () => void;
};

function UnsavedChangesModal(props: UnsavedChangesModalProps) {
  const { close } = props;

  const [t] = useTranslation('modals', {
    keyPrefix: 'unsavedChanges',
  });

  const dispatch = useDispatch();

  const deleting = useSelector(deletingUserSelector);

  const handleApproveClick = useCallback(() => {
    dispatch(hideFoldout());
    dispatch(hideModal());
  }, [dispatch]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgTrash color="#fff" height={17} width={19} />}
        title={t('title')}
      />
      <div className={styles.description}>{t('description')}</div>
      <div className={styles.buttonGroup}>
        <Button color="primaryNegative" onClick={close} type="button">
          {t('decline')}
        </Button>
        <Button
          className={styles.submit}
          color="negative"
          containerClassName={styles.submitContainer}
          disabled={false}
          loading={deleting}
          onClick={handleApproveClick}
          type="button"
        >
          {t('approve')}
        </Button>
      </div>
    </form>
  );
}

export default UnsavedChangesModal;
