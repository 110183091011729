import { RootActions } from '../actions';
import * as modalToastsActions from '../actions/modal-toasts';
import {
  DECLINE_INVITATION_REQUEST,
  HIDE_FOLDOUT,
  HIDE_MODAL,
  HIDE_MODAL_TOAST,
  RESET_TOKEN,
  SEND_INVITATION_REQUEST,
  SHOW_MODAL_TOAST,
} from '../constants/action-types';

export type ModalLevel = 'failure' | 'success' | 'warning';

export type ModalToastsState = {
  id: string;
  level: ModalLevel;
  message: string;
  reloadable: boolean;
  timeout: number;
};

export const initialState: ModalToastsState = null;

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case SHOW_MODAL_TOAST: {
      const { id, level, message, reloadable, timeout } = action as ReturnType<
        typeof modalToastsActions.showModalToast
      >;

      return {
        id,
        level,
        message,
        reloadable,
        timeout,
      };
    }
    case SEND_INVITATION_REQUEST:
    case DECLINE_INVITATION_REQUEST:
    case HIDE_MODAL:
    case HIDE_MODAL_TOAST:
    case HIDE_FOLDOUT:
      return null;
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
