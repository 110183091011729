import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import Nav from '../nav';
import NavWrapper from '../nav-wrapper';

function Settings() {
  const [t] = useTranslation('sidebar', { keyPrefix: 'settings' });

  return (
    <>
      <NavWrapper>
        <Nav to="/settings/organizations">{t('organizations')}</Nav>
        <Nav to="/settings/people-counters">{t('peopleCounters')}</Nav>
        <Nav to="/settings/locations">{t('locations')}</Nav>
        <Nav to="/settings/users">{t('users')}</Nav>
        <Nav to="/settings/data-import">{t('importData')}</Nav>
      </NavWrapper>
      <Outlet />
    </>
  );
}

export default Settings;
