import {
  LIST_CATEGORIES_FAILURE,
  LIST_CATEGORIES_REQUEST,
  LIST_CATEGORIES_SUCCESS,
} from '../constants/action-types';
import { ICategory } from '../types/api/categories';

export const listCategoriesRequest = () => ({
  type: LIST_CATEGORIES_REQUEST,
});

export const listCategoriesSuccess = (categories: ICategory[]) => ({
  categories,
  type: LIST_CATEGORIES_SUCCESS,
});

export const listCategoriesFailure = (error: unknown) => ({
  error,
  type: LIST_CATEGORIES_FAILURE,
});
