import clsx from 'clsx';
import styles from './title.module.css';

type TitleProps = {
  children: string;
  className?: string;
};

function Title(props: TitleProps) {
  const { children, className, ...rest } = props;

  return (
    <h1 className={clsx([styles.title, className])} {...rest}>
      {children}
    </h1>
  );
}

export default Title;
