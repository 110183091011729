import React, { useEffect, useMemo } from 'react';
import { Layer, Rect, Stage } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';

import { getCameraRequest } from '../../../../../../actions/cameras';
import { CircleSpinner } from '../../../../../../components/loader';
import { SvgFallbackImage } from '../../../../../../components/svg';
import { useImage } from '../../../../../../hooks';
import { RootState } from '../../../../../../reducers';
import {
  listingSingleCameraSelector,
  singleCameraSelector,
} from '../../../../../../selectors/cameras';
import { ICamera } from '../../../../../../types/api/cameras';
import { ILine } from '../../../../../../types/api/lines';
import Mark from '../../../../../foldouts/edit-camera/mark';
import styles from './virtual-canvas.module.css';

type VirtualCanvasProps = {
  camera: ReturnType<typeof singleCameraSelector>;
  lineId: ILine['id'];
  peopleCounterId: ICamera['id'];
  visible: boolean;
};

export const FULL_WIDTH = 780 / 2;
export const FULL_HEIGHT = 438.75 / 2;

function VirtualCanvas(props: VirtualCanvasProps) {
  const { camera, lineId, peopleCounterId, visible } = props;

  const dispatch = useDispatch();

  const loading = useSelector((state: RootState) =>
    listingSingleCameraSelector(state, peopleCounterId)
  );

  const cameraImage = useMemo(
    () =>
      `${process.env.REACT_APP_API_URL}${
        camera ? camera.last_image_url : null
      }`,
    [camera]
  );

  const [image, status] = useImage(cameraImage);

  const proportion = useMemo(() => {
    const { width = FULL_WIDTH } = (image as HTMLImageElement) || {};

    return FULL_WIDTH / width;
  }, [image]);

  const mark = useMemo(() => {
    if (camera) {
      const raw = camera.zones.find((l) => l.id == lineId);

      return {
        ...raw,
        parentId: null,
        points: raw.points.map((p) => p * 0.5),
        x: raw.x * 0.5,
        y: raw.y * 0.5,
      };
    }

    return null;
  }, [camera, lineId]);

  useEffect(() => {
    if (visible && !camera) {
      dispatch(getCameraRequest(peopleCounterId));
    }
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div style={{ height: 0, overflow: 'hidden', width: 0 }}>
        <img src={cameraImage} />
      </div>
      <div className={styles.container}>
        {loading || status === 'loading' ? (
          <div className={styles.spinnerContainer}>
            <CircleSpinner className={styles.spinner} />
          </div>
        ) : (
          <div className={styles.canvas}>
            {status === 'failed' ? (
              <SvgFallbackImage height={FULL_HEIGHT} width={FULL_WIDTH} />
            ) : (
              <Stage height={FULL_HEIGHT} width={FULL_WIDTH}>
                <Layer>
                  <Rect
                    fillPatternImage={image as HTMLImageElement}
                    fillPatternScaleX={proportion}
                    fillPatternScaleY={proportion}
                    height={FULL_HEIGHT}
                    listening={false}
                    width={FULL_WIDTH}
                  />
                  <Mark isSelected shapeProps={mark} />
                </Layer>
              </Stage>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(VirtualCanvas);
