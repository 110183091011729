import {
  HTMLAttributes,
  KeyboardEvent,
  RefAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useSpring, animated } from 'react-spring';
import styles from './multi-select.module.css';
import Checkbox from './checkbox';
import { SvgCross2, SvgSelectArrow } from '../svg';
import { useIsMount } from '../../hooks';
import { noop } from '../../utils/helper';
import { CircleSpinner } from '../loader';
import { PopupScrollContext } from '../../contexts';
import { resetValidation } from '../../actions/validations';

const MAX_LENGTH = 30;

type MultiSelectProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    blackDisabled?: boolean;
    dashed?: boolean;
    disabled?: boolean;
    info?: string;
    loading?: boolean;
    name?: string;
    onBlur?: () => void;
    options: Option[];
    placeholder?: string;
    selected?: any;
    setSelected: (o: Option) => void;
    validation?: Validation[];
    validationKey?: string;
    zIndex?: number;
  };

function MultiSelect(props: MultiSelectProps) {
  const {
    blackDisabled = false,
    dashed = true,
    disabled = false,
    info = '',
    loading = false,
    name = null,
    onBlur = noop,
    options,
    placeholder = '',
    selected = null,
    setSelected,
    validation = [],
    validationKey = null,
    zIndex = 0,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const ref = useRef(null);

  const optionsRef = useRef(null);

  const scrollTo = useContext(PopupScrollContext);

  const [expanded, setExpand] = useState(false);

  const [filter, setFilter] = useState('');

  const [cursor, setCursor] = useState(-1);

  const val: Validation = useMemo(() => {
    if (validation.length === 0) {
      return { field: '', message: '' };
    }

    const found = validation.find((v) => v.field === name);

    return found || { field: '', message: '' };
  }, [name, validation]);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) => {
        if (filter) {
          const label = option.label.toLowerCase();

          return label.includes(filter);
        }
        return true;
      }),
    [filter, options]
  );

  const [arrowAnim, setArrow] = useSpring(() => ({
    transform: 'rotateX(0deg)',
  }));

  const [inputAnim, setInput] = useSpring(() => ({
    height: '33px',
  }));

  const optionsAnim = useSpring({
    immediate: !optionsRef.current,
    config: { duration: 200 },
    to: async (next) => {
      if (expanded) {
        await next({ display: 'block' });
        await next({ opacity: 1 });
      } else {
        await next({ opacity: 0 });
        await next({ display: 'none' });
      }
    },
    from: {
      opacity: expanded ? 0 : 1,
      display: expanded ? 'none' : 'block',
    },
  });

  useEffect(() => {
    if (expanded) {
      const height = (filteredOptions.length + 1) * 33 + 2;

      setInput({
        config: { duration: 80 },
        to: async (next) => {
          await next({ height: `${height + 4}px` });
          await next({ height: `${height}px` });
        },
      });
    } else {
      setInput({
        config: { duration: 80 },
        to: async (next) => {
          await next({ height: '29px' });
          await next({ height: '33px' });
        },
      });
    }

    setCursor(-1);
  }, [expanded, filteredOptions.length, setInput]);

  const handleClick = useCallback(() => {
    if (expanded) {
      setArrow({ transform: 'rotateX(0deg)' });
    } else {
      setArrow({ transform: 'rotateX(180deg)' });
    }

    setExpand(!expanded);
  }, [expanded, setArrow]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      if (!optionsRef.current.contains(document.activeElement)) {
        setExpand(false);
      }
      setArrow({ transform: 'rotateX(0deg)' });
    }, 1);
  }, [setArrow]);

  const handleOptionClick = useCallback(
    (e, option: Option) => {
      setSelected(option);
      setFilter('');
      setExpand(true);

      if (validationKey) {
        dispatch(resetValidation(validationKey));
      }
    },
    [dispatch, setSelected, validationKey]
  );

  const handleTagClick = useCallback(
    (e, option: Option) => {
      setSelected(option);

      if (validationKey) {
        dispatch(resetValidation(validationKey));
      }
    },
    [dispatch, setSelected, validationKey]
  );

  const handleInput = useCallback(() => {
    const { textContent: value } = ref.current;

    if (selected) {
      setSelected(null);

      if (value.length > 0) {
        ref.current.textContent = value.slice(0, MAX_LENGTH);

        const range = document.createRange();
        const sel = window.getSelection();

        range.setStart(ref.current, 1);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else {
      setFilter(value.toLowerCase().trim());
    }

    if (value.length >= MAX_LENGTH) {
      ref.current.textContent = value.slice(0, MAX_LENGTH);

      const range = document.createRange();
      const sel = window.getSelection();

      range.setStart(ref.current, 1);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    }

    if (validationKey) {
      dispatch(resetValidation(validationKey));
    }
  }, [dispatch, selected, setSelected, validationKey]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Escape':
          (e.target as HTMLDivElement).blur();
          break;
        case 'Enter':
          e.preventDefault();

          if (cursor > -1) {
            setSelected(filteredOptions[cursor]);
          }
          setFilter('');

          break;
        case 'ArrowUp': {
          e.preventDefault();

          if (cursor > 0) {
            const next = cursor - 1;
            setCursor(next);
          }
          break;
        }
        case 'ArrowDown': {
          const lastIndex = filteredOptions.length - 1;

          if (cursor < lastIndex) {
            const next = cursor + 1;
            setCursor(next);
          }

          break;
        }
        default:
      }
    },
    [cursor, filteredOptions, setSelected]
  );

  const isMount = useIsMount();

  useEffect(
    () => {
      if (!isMount && !expanded) {
        onBlur();
      }
    },
    [expanded] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (val.field === name && scrollTo) {
      if (ref.current) {
        scrollTo(0, ref.current.getBoundingClientRect().top);
        ref.current.focus({ preventScroll: true });
      }
    }
  }, [val.field]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={styles.filler}>
        <div
          className={clsx([
            styles.select,
            { [styles.expanded]: expanded, [styles.disabled]: disabled },
          ])}
          style={{ zIndex }}
        >
          {loading ? (
            <div className={styles.spinnerContainer}>
              <CircleSpinner className={styles.spinner} strokeWidth={3} r={8} />
            </div>
          ) : (
            <SvgSelectArrow
              className={styles.arrow}
              width={16}
              height={8}
              style={{ ...arrowAnim, zIndex } as any}
            />
          )}
          <animated.div
            onBlur={handleBlur}
            onClick={handleClick}
            ref={ref}
            className={clsx([
              styles.input,
              {
                [styles.dashed]: dashed,
                [styles.disabled]: disabled,
                [styles.blackDisabled]: blackDisabled,
                [styles.invalid]: !!val.message,
              },
            ])}
            role="textbox"
            tabIndex={0}
            placeholder={placeholder}
            {...rest}
            style={inputAnim}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
          >
            {selected.map((s) => s.label).join(', ')}
          </animated.div>
          <animated.ul
            className={styles.options}
            style={optionsAnim}
            ref={optionsRef}
            tabIndex={0}
            onBlur={() => {
              setTimeout(() => {
                if (!ref.current.contains(document.activeElement)) {
                  setExpand(false);
                }
              }, 1);
            }}
          >
            {filteredOptions.map((option, i) => {
              const { label, value, children } = option;

              return (
                <li
                  key={value}
                  className={clsx([
                    styles.option,
                    {
                      [styles.selected]: selected?.value === value,
                      [styles.odd]: i % 2 === 1,
                      [styles.active]: cursor === i,
                    },
                  ])}
                  onMouseDown={(e) => handleOptionClick(e, option)}
                  onMouseEnter={() => setCursor(i)}
                >
                  <div>
                    <Checkbox
                      checked={selected.some((s) => s.value === value)}
                    />
                  </div>
                  <div className={styles.label}>{label}</div>
                  {children}
                </li>
              );
            })}
          </animated.ul>
        </div>
      </div>
      {!!val.message ? (
        <div className={styles.validation}>{val.message}</div>
      ) : (
        info && <div className={styles.info}>{info}</div>
      )}
      <div className={styles.tags}>
        {selected.map((option: Option) => (
          <div key={option.value} className={styles.tag}>
            {option.label}
            {!disabled && (
              <SvgCross2
                onClick={(e) => handleTagClick(e, option)}
                height={12}
                width={12}
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default MultiSelect;
