import { RootActions } from '../actions';
import * as filtersActions from '../actions/filters';
import {
  DELETE_FILTER_FAILURE,
  DELETE_FILTER_REQUEST,
  DELETE_FILTER_SUCCESS,
  LIST_FILTERS_FAILURE,
  LIST_FILTERS_REQUEST,
  LIST_FILTERS_SUCCESS,
  REPLACE_FILTER_FAILURE,
  REPLACE_FILTER_REQUEST,
  REPLACE_FILTER_SUCCESS,
  RESET_TOKEN,
  SAVE_FILTER_FAILURE,
  SAVE_FILTER_REQUEST,
  SAVE_FILTER_SUCCESS,
  SET_SELECTED_FILTER,
  UPDATE_FILTER_FAILURE,
  UPDATE_FILTER_REQUEST,
  UPDATE_FILTER_SUCCESS,
} from '../constants/action-types';
import { IFilter } from '../types/api/filters';

export type FiltersState = {
  deleting: boolean;
  filters: IFilter[];
  listing: boolean;
  replacing: boolean;
  saving: boolean;
  selected: number;
  updating: boolean;
};

export const initialState: FiltersState = {
  deleting: false,
  filters: [],
  listing: false,
  replacing: false,
  saving: false,
  selected: null,
  updating: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case REPLACE_FILTER_REQUEST:
      return {
        ...state,
        replacing: true,
      };
    case REPLACE_FILTER_SUCCESS: {
      const { filter } = action as ReturnType<
        typeof filtersActions.replaceFilterSuccess
      >;

      return {
        ...state,
        filters: state.filters.map((f) => (f.id === filter.id ? filter : f)),
        replacing: false,
      };
    }
    case REPLACE_FILTER_FAILURE:
      return {
        ...state,
        replacing: false,
      };
    case UPDATE_FILTER_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_FILTER_SUCCESS: {
      const { filter } = action as ReturnType<
        typeof filtersActions.updateFilterSuccess
      >;

      return {
        ...state,
        filters: state.filters.map((f) => (f.id === filter.id ? filter : f)),
        updating: false,
      };
    }
    case UPDATE_FILTER_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case DELETE_FILTER_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_FILTER_SUCCESS: {
      const { id } = action as ReturnType<
        typeof filtersActions.deleteFilterSuccess
      >;

      return {
        ...state,
        deleting: false,
        filters: state.filters.filter((filter) => filter.id !== id),
        selected: state.selected === id ? null : state.selected,
      };
    }
    case DELETE_FILTER_FAILURE:
      return {
        ...state,
        deleting: false,
      };
    case SET_SELECTED_FILTER: {
      const { id } = action as ReturnType<
        typeof filtersActions.setSelectedFilter
      >;

      return {
        ...state,
        selected: id,
      };
    }
    case SAVE_FILTER_REQUEST:
      return {
        ...state,
        saving: true,
      };
    case SAVE_FILTER_SUCCESS: {
      const { filter } = action as ReturnType<
        typeof filtersActions.saveFilterSuccess
      >;

      return {
        ...state,
        filters: [...state.filters, filter],
        saving: false,
        selected: filter.id,
      };
    }
    case SAVE_FILTER_FAILURE:
      return {
        ...state,
        saving: false,
      };
    case LIST_FILTERS_REQUEST:
      return {
        ...state,
        listing: true,
      };
    case LIST_FILTERS_SUCCESS: {
      const { filters } = action as ReturnType<
        typeof filtersActions.listFiltersSuccess
      >;

      return {
        ...state,
        filters,
        listing: false,
      };
    }
    case LIST_FILTERS_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
