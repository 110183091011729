import { RootActions } from '../actions';
import * as organizationsActions from '../actions/organizations';
import {
  CANCEL_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CREATE_ORGANIZATION_FAILURE,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  LIST_ORGANIZATIONS_FAILURE,
  LIST_ORGANIZATIONS_REQUEST,
  LIST_ORGANIZATIONS_SUCCESS,
  RESET_TOKEN,
  SET_ACTIVE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from '../constants/action-types';
import { IOrganization } from '../types/api/organization';

export type OrganizationsState = {
  activeOrganization: Nullable<IOrganization>;
  cancellingSubscription: boolean;
  creatingOrganization: boolean;
  deletingOrganization: boolean;
  listingOrganizations: boolean;
  organizations: IOrganization[];
  updatingOrganization: boolean;
};

export const initialState: OrganizationsState = {
  activeOrganization: null,
  cancellingSubscription: false,
  creatingOrganization: false,
  deletingOrganization: false,
  listingOrganizations: false,
  organizations: [],
  updatingOrganization: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case SET_ACTIVE_ORGANIZATION: {
      const { organization } = action as ReturnType<
        typeof organizationsActions.setActiveOrganization
      >;

      return {
        ...state,
        activeOrganization: organization,
      };
    }
    case CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        creatingOrganization: true,
      };
    case CREATE_ORGANIZATION_SUCCESS: {
      const { organizations } = state;
      const { organization } = action as ReturnType<
        typeof organizationsActions.createOrganizationSuccess
      >;

      return {
        ...state,
        creatingOrganization: false,
        organizations: [...organizations, organization],
      };
    }
    case CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        creatingOrganization: false,
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancellingSubscription: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancellingSubscription: false,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        cancellingSubscription: false,
      };
    case DELETE_ORGANIZATION_REQUEST:
      return {
        ...state,
        deletingOrganization: true,
      };
    case DELETE_ORGANIZATION_SUCCESS: {
      const { organizations } = state;
      const { id } = action as ReturnType<
        typeof organizationsActions.deleteOrganizationSuccess
      >;

      return {
        ...state,
        deletingOrganization: false,
        organizations: organizations.filter(
          (organization) => organization.id !== id
        ),
      };
    }
    case DELETE_ORGANIZATION_FAILURE:
      return {
        ...state,
        deletingOrganization: false,
      };
    case UPDATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        updatingOrganization: true,
      };
    case UPDATE_ORGANIZATION_SUCCESS: {
      const { activeOrganization, organizations } = state;
      const { id, payload } = action as ReturnType<
        typeof organizationsActions.updateOrganizationSuccess
      >;

      const index = organizations.findIndex((o) => o.id === id);

      return {
        ...state,
        activeOrganization:
          activeOrganization.id === id
            ? {
                ...activeOrganization,
                ...payload,
              }
            : activeOrganization,
        organizations: [
          ...organizations.slice(0, index),
          {
            ...organizations[index],
            ...payload,
          },
          ...organizations.slice(index + 1),
        ],
        updatingOrganization: false,
      };
    }
    case UPDATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        updatingOrganization: false,
      };
    case LIST_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        listingOrganizations: true,
      };
    case LIST_ORGANIZATIONS_SUCCESS: {
      const { organizations } = action as ReturnType<
        typeof organizationsActions.listOrganizationsSuccess
      >;

      return {
        ...state,
        listingOrganizations: false,
        organizations,
      };
    }
    case LIST_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        listingOrganizations: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
