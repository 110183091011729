import styles from './title-with-entity-count.module.css';
import Title from './title';
import { HollowDotsSpinner } from '../loader';

type TitleWithEntityCountProps = {
  title: string;
  entityText: string;
  loading?: boolean;
};

function TitleWithEntityCount(props: TitleWithEntityCountProps) {
  const { title, entityText, loading = false } = props;

  return (
    <div className={styles.titleWithBreadcrumb}>
      <Title className={styles.title}>{title}</Title>
      <div className={styles.entityCount}>
        {loading ? (
          <HollowDotsSpinner size={5} className={styles.spinner} />
        ) : (
          entityText
        )}
      </div>
    </div>
  );
}

export default TitleWithEntityCount;
