export const EDIT_USER_FOLDOUT = 'EDIT_USER_FOLDOUT';
export const EDIT_PARTNER_FOLDOUT = 'EDIT_PARTNER_FOLDOUT';
export const EDIT_LOCATION_FOLDOUT = 'EDIT_LOCATION_FOLDOUT';
export const EDIT_CAMERA_FOLDOUT = 'EDIT_CAMERA_FOLDOUT';
export const CREATE_CAMERA_FOLDOUT = 'CREATE_CAMERA_FOLDOUT';
export const CREATE_ORGANIZATION_FOLDOUT = 'CREATE_ORGANIZATION_FOLDOUT';
export const CREATE_LOCATION_FOLDOUT = 'CREATE_LOCATION_FOLDOUT';
export const CREATE_ZONE_FOLDOUT = 'CREATE_ZONE_FOLDOUT';
export const EDIT_ZONE_FOLDOUT = 'EDIT_ZONE_FOLDOUT';
export const CREATE_USER_FOLDOUT = 'CREATE_USER_FOLDOUT';
