import {
  RESET_TOKEN,
  SET_LAST_BEHAVIOR,
  SET_LAST_ORGANIZATION,
  SET_LAST_PROFILE,
  SET_LAST_SETTINGS,
} from '../constants/action-types';

export const initialState = {
  lastBehavior: '',
  lastOrganization: 'information',
  lastProfile: '',
  lastSettings: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_ORGANIZATION:
      return {
        ...state,
        lastOrganization: action.path,
      };
    case SET_LAST_SETTINGS:
      return {
        ...state,
        lastSettings: action.path,
      };
    case SET_LAST_BEHAVIOR:
      return {
        ...state,
        lastBehavior: action.path,
      };
    case SET_LAST_PROFILE:
      return {
        ...state,
        lastProfile: action.path,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
