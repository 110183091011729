import { call, put, takeLatest } from 'redux-saga/effects';

import {
  listIndustriesFailure,
  listIndustriesSuccess,
} from '../actions/industries';
import { Industries } from '../api';
import { LIST_INDUSTRIES_REQUEST } from '../constants/action-types';

export function* listIndustries() {
  try {
    const { body: industries } = yield call(Industries.List);
    yield put(listIndustriesSuccess(industries));
  } catch (e) {
    yield put(listIndustriesFailure(e));
  }
}

export function* watchIndustries() {
  yield takeLatest(LIST_INDUSTRIES_REQUEST, listIndustries);
}
