import {
  CREATE_LOCATION_FAILURE,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  LIST_ALL_LOCATIONS_FAILURE,
  LIST_ALL_LOCATIONS_REQUEST,
  LIST_ALL_LOCATIONS_SUCCESS,
  LIST_LOCATIONS_FAILURE,
  LIST_LOCATIONS_REQUEST,
  LIST_LOCATIONS_SUCCESS,
  SELECT_ALL_LOCATIONS,
  SELECT_LOCATION_WITH_CTRL,
  SELECT_LOCATION_WITH_SHIFT,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_SUCCESS,
} from '../constants/action-types';
import { ILocation, ILocationPayload } from '../types/api/locations';

export const listAllLocationsRequest = () => ({
  type: LIST_ALL_LOCATIONS_REQUEST,
});

export const listAllLocationsSuccess = (locations: ILocation[]) => ({
  locations,
  type: LIST_ALL_LOCATIONS_SUCCESS,
});

export const listAllLocationsFailure = (error: unknown) => ({
  error,
  type: LIST_ALL_LOCATIONS_FAILURE,
});

export const listLocationsRequest = (limit: number, offset: number) => ({
  limit,
  offset,
  type: LIST_LOCATIONS_REQUEST,
});

export const listLocationsSuccess = (
  locations: ILocation[],
  count: number
) => ({
  count,
  locations,
  type: LIST_LOCATIONS_SUCCESS,
});

export const listLocationsFailure = (error: unknown) => ({
  error,
  type: LIST_LOCATIONS_FAILURE,
});

export const createLocationRequest = (location: Partial<ILocationPayload>) => ({
  location,
  type: CREATE_LOCATION_REQUEST,
});

export const createLocationSuccess = (location: ILocation) => ({
  location,
  type: CREATE_LOCATION_SUCCESS,
});

export const createLocationFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: CREATE_LOCATION_FAILURE,
  validation,
});

export const updateLocationRequest = (
  id: ILocation['id'],
  location: Partial<ILocationPayload>
) => ({
  id,
  location,
  type: UPDATE_LOCATION_REQUEST,
});

export const updateLocationSuccess = (location: ILocation) => ({
  location,
  type: UPDATE_LOCATION_SUCCESS,
});

export const updateLocationFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: UPDATE_LOCATION_FAILURE,
  validation,
});

export const selectAllLocations = () => ({
  type: SELECT_ALL_LOCATIONS,
});

export const selectLocationWithCtrl = (id: ILocation['id']) => ({
  id,
  type: SELECT_LOCATION_WITH_CTRL,
});

export const selectLocationWithShift = (id: ILocation['id']) => ({
  id,
  type: SELECT_LOCATION_WITH_SHIFT,
});

export const deleteLocationRequest = (id: ILocation['id']) => ({
  id,
  type: DELETE_LOCATION_REQUEST,
});

export const deleteLocationSuccess = (id: ILocation['id']) => ({
  id,
  type: DELETE_LOCATION_SUCCESS,
});

export const deleteLocationFailure = (error: unknown) => ({
  error,
  type: DELETE_LOCATION_FAILURE,
});
