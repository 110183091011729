function SvgGitMerge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={21}
      viewBox="0 0 21 21"
      width={21}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-2.5 -2.5)"
      >
        <circle
          cx={3.333}
          cy={3.333}
          r={3.333}
          transform="translate(16.333 16.333)"
        />
        <circle cx={3.333} cy={3.333} r={3.333} transform="translate(3 3)" />
        <path d="M6.333 23V9.667a10 10 0 0 0 10 10" />
      </g>
    </svg>
  );
}

export default SvgGitMerge;
