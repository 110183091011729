import { spawn } from 'redux-saga/effects';

import { watchAuth } from './auth';
import { watchCameras } from './cameras';
import { watchCategories } from './categories';
import { watchFailure } from './failure';
import { watchFilters } from './filters';
import { watchFoldouts } from './foldouts';
import { watchImports } from './imports';
import { watchIndustries } from './industries';
import { watchInsights } from './insights';
import { watchLines } from './lines';
import { watchLocations } from './locations';
import { watchModalToasts } from './modal-toasts';
import { watchModals } from './modals';
import { watchOrganizations } from './organizations';
import { watchPermissions } from './permissions';
import { watchSegmentation } from './segmentation';
import { watchToasts } from './toasts';
import { watchUsers } from './users';
import { watchVisitorJourney } from './visitor-journey';
import { watchZones } from './zones';

const sagas = [
  watchAuth,
  watchCameras,
  watchCategories,
  watchFailure,
  watchFilters,
  watchFoldouts,
  watchImports,
  watchIndustries,
  watchInsights,
  watchLines,
  watchLocations,
  watchModalToasts,
  watchModals,
  watchOrganizations,
  watchPermissions,
  watchSegmentation,
  watchToasts,
  watchUsers,
  watchZones,
  watchVisitorJourney,
];

export default function* rootSaga() {
  for (const saga of sagas) {
    yield spawn(saga);
  }
}
