import { Store } from 'redux';
import { Persistor } from 'redux-persist';

import { RootState } from '../reducers';

interface StoreRegistry {
  persistor: Persistor | null;
  registry: {
    persistor: Persistor | null;
    store: Store<RootState> | null;
  };
  readonly state: RootState | null;
  store: Store<RootState> | null;
}

const storeRegistry: StoreRegistry = {
  set persistor(persistor) {
    this.registry.persistor = persistor;
  },

  get persistor() {
    return this.registry.persistor;
  },

  registry: {
    persistor: null,
    store: null,
  },

  get state() {
    if (this.registry.store) {
      return this.registry.store.getState();
    }
    return null;
  },

  set store(store: Store<RootState> | null) {
    this.registry.store = store;
  },

  get store(): Store<RootState> | null {
    return this.registry.store;
  },
};

export default storeRegistry;
