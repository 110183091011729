const SvgPieChart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.512}
    height={21.505}
    viewBox="0 0 21.512 21.505"
    {...props}
  >
    <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path d="M20.202 14.74A10.252 10.252 0 1 1 6.659 1.351" />
      <path d="M21.011 10.752A10.252 10.252 0 0 0 10.759.5v10.252Z" />
    </g>
  </svg>
);

export default SvgPieChart;
