import { RootActions } from '../actions';
import * as linesActions from '../actions/lines';
import * as segmentationActions from '../actions/segmentation';
import {
  LIST_LINES_SUCCESS,
  RESET_FILTERS,
  RESET_TOKEN,
  SET_ALL_SELECTED,
  SET_SELECTED_DATE,
  SET_SELECTED_DISPLAY_LEVEL,
  SET_SELECTED_END_DATE,
  SET_SELECTED_FLOOR,
  SET_SELECTED_LINE,
  SET_SELECTED_LINE_TYPE,
  SET_SELECTED_LOCATION,
  SET_SELECTED_ORGANIZATION,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_START_DATE,
  SET_SELECTED_ZONE_FROM,
  SET_SELECTED_ZONE_TO,
} from '../constants/action-types';

export type SegmentationState = {
  date: Option;
  displayLevel: Option;
  endDate: Nullable<Date | string>;
  floor: Option[];
  latestResetTime: Nullable<EpochTimeStamp>;
  latestUpdateTime: Nullable<EpochTimeStamp>;
  line: Option[];
  lineType: Option[];
  location: Nullable<Option>;
  organization: Nullable<Option>;
  property: Option;
  startDate: Nullable<Date | string>;
  zoneFrom: Option[];
  zoneTo: Option[];
};

export const initialState: SegmentationState = {
  date: { label: 'Today', value: 'today' },
  displayLevel: { label: '2', value: '2' },
  endDate: null,
  floor: [],
  latestResetTime: null, // update this only if organization option [0] selection is required
  latestUpdateTime: null,
  line: [],
  lineType: [],
  location: null,
  organization: null,
  property: { label: '1 hour interval', value: '1h' },
  startDate: null,
  zoneFrom: [],
  zoneTo: [],
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case LIST_LINES_SUCCESS: {
      const { lines } = action as ReturnType<
        typeof linesActions.listLinesSuccess
      >;

      const lineIds = new Set(lines.map((line) => line.id.toString()));

      return {
        ...state,
        line: state.line.filter((line) => lineIds.has(line.value)),
      };
    }
    case SET_ALL_SELECTED: {
      const { selectedFilters } = action as ReturnType<
        typeof segmentationActions.setAllSelected
      >;

      return {
        ...state,
        ...selectedFilters,
        latestResetTime:
          selectedFilters.organization == null
            ? new Date().getTime()
            : state.latestResetTime,
        latestUpdateTime: new Date().getTime(),
      };
    }
    case SET_SELECTED_DISPLAY_LEVEL: {
      const { displayLevel } = action as ReturnType<
        typeof segmentationActions.setSelectedDisplayLevel
      >;

      return {
        ...state,
        displayLevel,
      };
    }
    case SET_SELECTED_PROPERTY: {
      const { property } = action as ReturnType<
        typeof segmentationActions.setSelectedProperty
      >;

      return {
        ...state,
        date: null,
        endDate: null,
        property,
        startDate: null,
      };
    }
    case RESET_FILTERS: {
      return {
        ...structuredClone(initialState),
        latestResetTime: new Date().getTime(),
      };
    }
    case SET_SELECTED_LINE: {
      const { line: prevLine } = state;
      const { line } = action as ReturnType<
        typeof segmentationActions.setSelectedLine
      >;

      if (!line) {
        return {
          ...state,
          line: prevLine.length === 0 ? prevLine : [],
        };
      }

      const index = prevLine.findIndex((f) => f.value === line.value);

      return {
        ...state,
        line:
          index === -1
            ? [...prevLine, line]
            : [...prevLine.slice(0, index), ...prevLine.slice(index + 1)],
      };
    }
    case SET_SELECTED_LINE_TYPE: {
      const { lineType: prevLineType } = state;
      const { lineType } = action as ReturnType<
        typeof segmentationActions.setSelectedLineType
      >;

      if (!lineType) {
        return {
          ...state,
          lineType: prevLineType.length === 0 ? prevLineType : [],
        };
      }

      const index = prevLineType.findIndex((f) => f.value === lineType.value);

      return {
        ...state,
        lineType:
          index === -1
            ? [...prevLineType, lineType]
            : [
                ...prevLineType.slice(0, index),
                ...prevLineType.slice(index + 1),
              ],
      };
    }
    case SET_SELECTED_ZONE_FROM: {
      const { zoneFrom: prevZoneFrom } = state;
      const { zoneFrom } = action as ReturnType<
        typeof segmentationActions.setSelectedZoneFrom
      >;

      if (!zoneFrom) {
        return {
          ...state,
          zoneFrom: prevZoneFrom.length === 0 ? prevZoneFrom : [],
        };
      }

      const index = prevZoneFrom.findIndex((f) => f.value === zoneFrom.value);

      return {
        ...state,
        zoneFrom:
          index === -1
            ? [...prevZoneFrom, zoneFrom]
            : [
                ...prevZoneFrom.slice(0, index),
                ...prevZoneFrom.slice(index + 1),
              ],
      };
    }
    case SET_SELECTED_ZONE_TO: {
      const { zoneTo: prevZoneTo } = state;
      const { zoneTo } = action as ReturnType<
        typeof segmentationActions.setSelectedZoneTo
      >;

      if (!zoneTo) {
        return {
          ...state,
          zoneTo: prevZoneTo.length === 0 ? prevZoneTo : [],
        };
      }

      const index = prevZoneTo.findIndex((f) => f.value === zoneTo.value);

      return {
        ...state,
        zoneTo:
          index === -1
            ? [...prevZoneTo, zoneTo]
            : [...prevZoneTo.slice(0, index), ...prevZoneTo.slice(index + 1)],
      };
    }
    case SET_SELECTED_FLOOR: {
      const { floor: prevFloor } = state;
      const { floor } = action as ReturnType<
        typeof segmentationActions.setSelectedFloor
      >;

      if (!floor) {
        return {
          ...state,
          floor: prevFloor.length === 0 ? prevFloor : [],
        };
      }

      const index = prevFloor.findIndex((f) => f.value === floor.value);

      return {
        ...state,
        floor:
          index === -1
            ? [...prevFloor, floor]
            : [...prevFloor.slice(0, index), ...prevFloor.slice(index + 1)],
      };
    }
    case SET_SELECTED_START_DATE: {
      const { startDate } = action as ReturnType<
        typeof segmentationActions.setSelectedStartDate
      >;

      return {
        ...state,
        endDate: null,
        startDate,
      };
    }
    case SET_SELECTED_END_DATE: {
      const { endDate } = action as ReturnType<
        typeof segmentationActions.setSelectedEndDate
      >;

      return {
        ...state,
        endDate,
      };
    }
    case SET_SELECTED_DATE: {
      const { date } = action as ReturnType<
        typeof segmentationActions.setSelectedDate
      >;

      return {
        ...state,
        date,
      };
    }
    case SET_SELECTED_ORGANIZATION: {
      const { organization } = action as ReturnType<
        typeof segmentationActions.setSelectedOrganization
      >;

      return {
        ...state,
        location:
          state.organization != null &&
          organization?.value !== state.organization?.value
            ? null
            : state.location,
        organization,
      };
    }
    case SET_SELECTED_LOCATION: {
      const { location } = action as ReturnType<
        typeof segmentationActions.setSelectedLocation
      >;

      return {
        ...state,
        location,
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
