import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/form';
import { SvgArrowUpCircle } from '../../../components/svg';
import Header from '../header';
import styles from './index.module.css';
import SvgVisitorJourney from './visitor-journey.svg';

type UpgradeWarningModalProps = {
  close: () => void;
};

function UpgradeWarningModal(props: UpgradeWarningModalProps) {
  const { close } = props;

  const [t] = useTranslation('modals', { keyPrefix: 'upgradeWarning' });

  const handleTalkToSalesClick = useCallback(() => {
    window.open(
      'https://www.indivd.com/contact-us#modal-9fd6c140-a2c2-4936-8910-5944b2035ea9',
      '_blank',
      'noopener,noreferrer'
    );
  }, []);

  const handleViewPricingClick = useCallback(() => {
    window.open(
      'https://www.indivd.com/pricing',
      '_blank',
      'noopener,noreferrer'
    );
  }, []);

  return (
    <form className={styles.container}>
      <Header />
      <div className={styles.columns}>
        <div className={styles.content}>
          <div className={styles.title}>
            <SvgArrowUpCircle />
            {t('title')}
          </div>
          <div className={styles.h1}>{t('warning.h1')}</div>
          <ul className={styles.ul}>
            <li>{t('warning.li1')}</li>
            <li>{t('warning.li2')}</li>
            <li>{t('warning.li3')}</li>
          </ul>
          <div className={styles.buttonGroup}>
            <Button
              color="secondary"
              onClick={handleViewPricingClick}
              type="button"
            >
              {t('viewPricing')}
            </Button>
            <Button onClick={handleTalkToSalesClick} type="button">
              {t('talkToSales')}
            </Button>
          </div>
        </div>
        <div className={styles.image}>
          <img alt="Visitor Journey" src={SvgVisitorJourney} width="100%" />
        </div>
      </div>
    </form>
  );
}

export default UpgradeWarningModal;
