import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './standard.module.css';

type StandardTooltipProps = HTMLAttributes<HTMLDivElement>;

function StandardTooltip(props: StandardTooltipProps) {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(styles.tooltip, className)} {...rest}>
      {children}
    </div>
  );
}

export default StandardTooltip;
