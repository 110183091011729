import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  activateCameraFailure,
  activateCameraRequest,
  activateCameraSuccess,
  batchUpdateCamerasFailure,
  batchUpdateCamerasRequest,
  batchUpdateCamerasSuccess,
  createCameraFailure,
  createCameraRequest,
  createCameraSuccess,
  deactivateCameraFailure,
  deactivateCameraRequest,
  deactivateCameraSuccess,
  deleteCameraFailure,
  deleteCameraRequest,
  deleteCameraSuccess,
  getCameraFailure,
  getCameraRequest,
  getCameraSuccess,
  listCamerasFailure,
  listCamerasRequest,
  listCamerasSuccess,
  updateCameraFailure,
  updateCameraRequest,
  updateCameraSuccess,
} from '../actions/cameras';
import { hideFoldout } from '../actions/foldouts';
import { hideModal } from '../actions/modals';
import { setLastSettings } from '../actions/routes';
import { Cameras } from '../api';
import {
  ACTIVATE_CAMERA_REQUEST,
  BATCH_UPDATE_CAMERAS_REQUEST,
  CREATE_CAMERA_REQUEST,
  DEACTIVATE_CAMERA_REQUEST,
  DELETE_CAMERA_REQUEST,
  GET_CAMERA_REQUEST,
  LIST_CAMERAS_REQUEST,
  UPDATE_CAMERA_REQUEST,
} from '../constants/action-types';
import { categoriesSelector } from '../selectors/categories';
import { zonesSelector } from '../selectors/zones';
import { ICamera } from '../types/api/cameras';
import { ILine, ILineWithZoneIds } from '../types/api/lines';
import { IZone } from '../types/api/zones';
import { parseError } from '../utils/error';
import {
  validateCreateCamera,
  validateUpdateCamera,
} from '../validations/cameras';

export function* listCameras({
  limit,
  offset,
  order,
  search,
}: ReturnType<typeof listCamerasRequest>) {
  try {
    const {
      body: { count, results },
    } = yield call(Cameras.List, limit, offset, order, search);
    yield put(listCamerasSuccess(results, count));

    const url = new URL(window.location.href);

    if (url.pathname.startsWith('/settings/people-counters')) {
      window.history.replaceState(
        null,
        '',
        `/settings/people-counters/${limit}/${offset}`
      );

      yield put(
        setLastSettings(`/settings/people-counters/${limit}/${offset}`)
      );
    }
  } catch (e) {
    yield put(listCamerasFailure(e));
  }
}

export function* activateCamera({
  id,
}: ReturnType<typeof activateCameraRequest>) {
  try {
    yield call(Cameras.Activate, id);
    yield put(activateCameraSuccess(id));
    yield put(hideModal());
  } catch (e) {
    yield put(activateCameraFailure(e));
  }
}

export function* deactivateCamera({
  id,
}: ReturnType<typeof deactivateCameraRequest>) {
  try {
    yield call(Cameras.Deactivate, id);
    yield put(deactivateCameraSuccess(id));
    yield put(hideModal());
  } catch (e) {
    yield put(deactivateCameraFailure(e));
  }
}

export function* updateCamera({
  camera,
  id,
}: ReturnType<typeof updateCameraRequest>) {
  try {
    validateUpdateCamera(camera);

    const zones: IZone[] = yield select(zonesSelector);
    const categories = yield select(categoriesSelector);

    const forRequest: Partial<Omit<ICamera, 'zones'>> & {
      zones: ILineWithZoneIds[];
    } = {
      ...camera,
      zones: camera.zones.map((z) => {
        const {
          camera: skip,
          id: lineId,
          marker_id: markerId,
          ...rest
        } = z as ILineWithZoneIds;

        return lineId
          ? {
              id: lineId,
              ...rest,
            }
          : {
              marker_id: markerId,
              ...rest,
            };
      }),
    };

    const { body: data } = yield call(Cameras.Update, id, forRequest);
    yield put(
      updateCameraSuccess(id, {
        ...camera,
        zones: data.zones.map((z) => {
          const {
            category: categoryId,
            line_zones: lineZones,
            zone_from: zoneFromId,
            zone_to: zoneToId,
            ...rest
          } = z;

          const lineZonesFilled: ILine['line_zones'][] = lineZones.map(
            (lineZone) => {
              const fromZone = zones.find(
                (zone) => zone.id === lineZone.FromZone
              );
              const toZone = zones.find((zone) => zone.id === lineZone.ToZone);

              return {
                FromZone: fromZone,
                ToZone: toZone,
              };
            }
          );

          return {
            ...rest,
            category: categories.find((category) => category.id === categoryId),
            line_zones: lineZonesFilled,
            zone_from: zones.find((zone) => zone.id === zoneFromId),
            zone_to: zones.find((zone) => zone.id === zoneToId),
          };
        }),
      })
    );
    yield put(hideFoldout());
  } catch (e) {
    yield put(updateCameraFailure(e, parseError(e, 'patch')));
  }
}

export function* batchUpdateCameras({
  fieldName,
  ids,
  value,
}: ReturnType<typeof batchUpdateCamerasRequest>) {
  try {
    yield call(Cameras.BatchUpdate, ids, fieldName, value);
    yield put(batchUpdateCamerasSuccess(ids, fieldName, value));
    yield put(hideModal());
  } catch (e) {
    yield put(batchUpdateCamerasFailure(e, parseError(e, 'update')));
  }
}

export function* getCamera({ id }: ReturnType<typeof getCameraRequest>) {
  try {
    const { body: camera } = yield call(Cameras.Get, id);
    yield put(getCameraSuccess(camera));
  } catch (e) {
    yield put(getCameraFailure(id, e));
  }
}

export function* createCamera({
  payload,
}: ReturnType<typeof createCameraRequest>) {
  try {
    validateCreateCamera(payload);

    const { body: camera } = yield call(Cameras.Create, payload);
    yield put(createCameraSuccess({ ...camera, zones: [] }));
    yield put(hideFoldout());
  } catch (e) {
    yield put(createCameraFailure(e, parseError(e, 'create')));
  }
}

export function* deleteCamera({ id }: ReturnType<typeof deleteCameraRequest>) {
  try {
    yield call(Cameras.Delete, id);
    yield put(hideFoldout());
    yield put(hideModal());
    yield put(deleteCameraSuccess(id));
  } catch (e) {
    yield put(deleteCameraFailure(e));
  }
}

export function* watchCameras() {
  yield takeLatest(ACTIVATE_CAMERA_REQUEST, activateCamera);
  yield takeLatest(BATCH_UPDATE_CAMERAS_REQUEST, batchUpdateCameras);
  yield takeLatest(CREATE_CAMERA_REQUEST, createCamera);
  yield takeLatest(DEACTIVATE_CAMERA_REQUEST, deactivateCamera);
  yield takeLatest(DELETE_CAMERA_REQUEST, deleteCamera);
  yield takeLatest(GET_CAMERA_REQUEST, getCamera);
  yield takeLatest(LIST_CAMERAS_REQUEST, listCameras);
  yield takeLatest(UPDATE_CAMERA_REQUEST, updateCamera);
}
