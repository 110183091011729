import {
  ACTIVATE_INVITED_USER_FAILURE,
  ACTIVATE_INVITED_USER_REQUEST,
  ACTIVATE_INVITED_USER_SUCCESS,
  BATCH_UPDATE_USERS_FAILURE,
  BATCH_UPDATE_USERS_REQUEST,
  BATCH_UPDATE_USERS_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DECLINE_INVITATION_FAILURE,
  DECLINE_INVITATION_REQUEST,
  DECLINE_INVITATION_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_INVITEES_FAILURE,
  GET_INVITEES_REQUEST,
  GET_INVITEES_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  INVITE_BYLINK_FAILURE,
  INVITE_BYLINK_REQUEST,
  INVITE_BYLINK_SUCCESS,
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  LIST_USER_GROUPS_FAILURE,
  LIST_USER_GROUPS_REQUEST,
  LIST_USER_GROUPS_SUCCESS,
  LIST_USERS_FAILURE,
  LIST_USERS_REQUEST,
  LIST_USERS_SUCCESS,
  LOCATION_TREE_FAILURE,
  LOCATION_TREE_REQUEST,
  LOCATION_TREE_SUCCESS,
  PATCH_USER_INFO_FAILURE,
  PATCH_USER_INFO_REQUEST,
  PATCH_USER_INFO_SUCCESS,
  REMOVE_USER_FAILURE,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  SELECT_USER_ALL,
  SELECT_USER_WITH_CTRL,
  SELECT_USER_WITH_SHIFT,
  SELECT_USER_WITHOUT,
  SEND_INVITATION_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../constants/action-types';

export const locationTreeRequest = () => ({
  type: LOCATION_TREE_REQUEST,
});

export const locationTreeSuccess = (locationTree) => ({
  locationTree,
  type: LOCATION_TREE_SUCCESS,
});

export const locationTreeFailure = (error) => ({
  error,
  type: LOCATION_TREE_FAILURE,
});

export const listUsersRequest = (limit: number, offset: number) => ({
  limit,
  offset,
  type: LIST_USERS_REQUEST,
});

export const listUsersSuccess = (users, count: number) => ({
  count,
  type: LIST_USERS_SUCCESS,
  users,
});

export const listUsersFailure = (error) => ({
  error,
  type: LIST_USERS_FAILURE,
});

export const listUserGroupsRequest = () => ({
  type: LIST_USER_GROUPS_REQUEST,
});

export const listUserGroupsSuccess = (groups) => ({
  groups,
  type: LIST_USER_GROUPS_SUCCESS,
});

export const listUserGroupsFailure = (error) => ({
  error,
  type: LIST_USER_GROUPS_FAILURE,
});

export const patchUserInfoRequest = (payload) => ({
  payload,
  type: PATCH_USER_INFO_REQUEST,
});

export const patchUserInfoSuccess = (user) => ({
  type: PATCH_USER_INFO_SUCCESS,
  user,
});

export const patchUserInfoFailure = (error, validation) => ({
  error,
  type: PATCH_USER_INFO_FAILURE,
  validation,
});

export const getUserRequest = () => ({
  type: GET_USER_REQUEST,
});

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  user,
});

export const getUserFailure = (error) => ({
  error,
  type: GET_USER_FAILURE,
});

export const deleteUserRequest = (id) => ({
  id,
  type: DELETE_USER_REQUEST,
});

export const deleteUserSuccess = (id) => ({
  id,
  type: DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  error,
  type: DELETE_USER_FAILURE,
});

export const activateInvitedUserRequest = (pre, post, password, password2) => ({
  password,
  password2,
  post,
  pre,
  type: ACTIVATE_INVITED_USER_REQUEST,
});

export const activateInvitedUserSuccess = () => ({
  type: ACTIVATE_INVITED_USER_SUCCESS,
});

export const activateInvitedUserFailure = (error, validation) => ({
  error,
  type: ACTIVATE_INVITED_USER_FAILURE,
  validation,
});

export const inviteUserRequest = ({
  expireAt,
  inviteEmail,
  locations,
  startAt,
  systemRole,
}) => ({
  payload: {
    expireAt,
    inviteEmail,
    locations,
    startAt,
    systemRole,
  },
  type: INVITE_USER_REQUEST,
});

export const inviteUserSuccess = (invitee) => ({
  invitee,
  type: INVITE_USER_SUCCESS,
});

export const inviteUserFailure = (error, validation) => ({
  error,
  type: INVITE_USER_FAILURE,
  validation,
});

export const inviteBylinkRequest = ({
  expireAt,
  inviteEmail,
  locations,
  startAt,
  systemRole,
}) => ({
  payload: {
    expireAt,
    inviteEmail,
    locations,
    startAt,
    systemRole,
  },
  type: INVITE_BYLINK_REQUEST,
});

export const inviteBylinkSuccess = (invitee) => ({
  invitee,
  type: INVITE_BYLINK_SUCCESS,
});

export const inviteBylinkFailure = (error, validation) => ({
  error,
  type: INVITE_BYLINK_FAILURE,
  validation,
});

export const removeUserRequest = (uid) => ({
  type: REMOVE_USER_REQUEST,
  uid,
});

export const removeUserSuccess = (uid, email) => ({
  email,
  type: REMOVE_USER_SUCCESS,
  uid,
});

export const removeUserFailure = (error) => ({
  error,
  type: REMOVE_USER_FAILURE,
});

export const selectUserWithCtrl = (id) => ({
  id,
  type: SELECT_USER_WITH_CTRL,
});

export const selectUserWithShift = (id) => ({
  id,
  type: SELECT_USER_WITH_SHIFT,
});

export const selectUserWithout = (id) => ({
  id,
  type: SELECT_USER_WITHOUT,
});

export const selectUserAll = () => ({
  type: SELECT_USER_ALL,
});

export const getInviteesRequest = () => ({
  type: GET_INVITEES_REQUEST,
});

export const getInviteesSuccess = (invitees) => ({
  invitees,
  type: GET_INVITEES_SUCCESS,
});

export const getInviteesFailure = (error) => ({
  error,
  type: GET_INVITEES_FAILURE,
});

export const sendInvitationRequest = (ids) => ({
  ids,
  type: SEND_INVITATION_REQUEST,
});

export const sendInvitationSuccess = (ids) => ({
  ids,
  type: SEND_INVITATION_SUCCESS,
});

export const sendInvitationFailure = (error) => ({
  error,
  type: SEND_INVITATION_FAILURE,
});

export const declineInvitationRequest = (ids) => ({
  ids,
  type: DECLINE_INVITATION_REQUEST,
});

export const declineInvitationSuccess = (ids) => ({
  ids,
  type: DECLINE_INVITATION_SUCCESS,
});

export const declineInvitationFailure = (error) => ({
  error,
  type: DECLINE_INVITATION_FAILURE,
});

export const batchUpdateUsersRequest = (
  ids: number[],
  fieldName: string,
  value: string
) => ({
  fieldName,
  ids,
  type: BATCH_UPDATE_USERS_REQUEST,
  value,
});

export const batchUpdateUsersSuccess = (
  ids: number[],
  fieldName: string,
  value: string
) => ({
  fieldName,
  ids,
  type: BATCH_UPDATE_USERS_SUCCESS,
  value,
});

export const batchUpdateUsersFailure = (error, validation) => ({
  error,
  type: BATCH_UPDATE_USERS_FAILURE,
  validation,
});

export const updateUserRequest = (id: number, user) => ({
  id,
  type: UPDATE_USER_REQUEST,
  user,
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  user,
});

export const updateUserFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: UPDATE_USER_FAILURE,
  validation,
});

export const createUserRequest = (user) => ({
  type: CREATE_USER_REQUEST,
  user,
});

export const createUserSuccess = (user) => ({
  type: CREATE_USER_SUCCESS,
  user,
});

export const createUserFailure = (
  error: unknown,
  validation: Validation[]
) => ({
  error,
  type: CREATE_USER_FAILURE,
  validation,
});
