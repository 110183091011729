import { RootActions } from '../actions';
import * as authActions from '../actions/auth';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_TOKEN,
  SEND_RESET_PASSWORD_FAILURE,
  SEND_RESET_PASSWORD_REQUEST,
  SEND_RESET_PASSWORD_SUCCESS,
} from '../constants/action-types';
import { IUserGroup } from '../types/api/user-groups';
import { IUser } from '../types/api/users';

export type AuthState = {
  loggingIn: boolean;
  loggingOut: boolean;
  registering: boolean;
  resetting: boolean;
  sendingReset: boolean;
  sentResetEmail: string;
  token: string;
  user: IUser;
  usergroups: IUserGroup[];
};

export const initialState: AuthState = {
  loggingIn: false,
  loggingOut: false,
  registering: false,
  resetting: false,
  sendingReset: false,
  sentResetEmail: '',
  token: '',
  user: {} as IUser,
  usergroups: [],
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        registering: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetting: true,
      };
    case RESET_PASSWORD_SUCCESS: {
      const { token, user, usergroups } = action as ReturnType<
        typeof authActions.resetPasswordSuccess
      >;

      return {
        ...state,
        resetting: false,
        token,
        user,
        usergroups,
      };
    }
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetting: false,
      };
    case SEND_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        sendingReset: true,
      };
    case SEND_RESET_PASSWORD_SUCCESS: {
      const { email } = action as ReturnType<
        typeof authActions.sendResetPasswordSuccess
      >;

      return {
        ...state,
        sendingReset: false,
        sentResetEmail: email,
      };
    }
    case SEND_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        sendingReset: false,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loggingOut: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGIN_SUCCESS: {
      const { email, token, user, usergroups } = action as ReturnType<
        typeof authActions.loginSuccess
      >;

      return {
        ...state,
        email,
        loggingIn: false,
        token,
        user,
        usergroups,
      };
    }
    case LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
      };
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
