import clsx from 'clsx';
import dayjs from 'dayjs';
import i18n from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';

import { noop, sameDay } from '../../utils/helper';
import CustomHeader from '../date/custom-header';
import CustomTimeInput from '../date/custom-time-input';
import { Button, Checkbox, Separator } from '../form';
import { SvgSelectArrow } from '../svg';
import DateRangePicker from './date-range-picker';
import styles from './datepicker-select.module.css';

type DatePickerSelectProps = {
  dashed?: boolean;
  disabled?: boolean;
  endDate?: any;
  handleTodayAndForwardChange?: () => void;
  onBlur?: () => void;
  options?: {
    label: string;
    value: number | string;
  }[];
  placeholder?: string;
  setEndDate: (date: any) => void;
  setStartDate: (date: any) => void;
  startDate: any;
  zIndex?: number;
};

const DatePickerSelect = React.forwardRef((props: DatePickerSelectProps, _) => {
  const {
    dashed = true,
    disabled = false,
    endDate = null,
    handleTodayAndForwardChange = noop,
    onBlur = noop,
    setEndDate,
    setStartDate,
    startDate,
    zIndex = 0,
    ...rest
  } = props;

  const { t } = useTranslation('header');

  const ref = useRef(null);

  const optionsRef = useRef(null);

  const [expanded, setExpand] = useState(false);

  const [arrowAnim, setArrow] = useSpring(() => ({
    transform: 'rotateX(0deg)',
  }));

  const [inputAnim, setInput] = useSpring(() => ({
    height: '33px',
  }));

  const optionsAnim = useSpring({
    config: { duration: 200 },
    from: {
      display: expanded ? 'none' : 'block',
      opacity: expanded ? 0 : 1,
    },
    immediate: !optionsRef.current,
    to: async (next, cancel) => {
      if (expanded) {
        await next({ display: 'block' });
        await next({ opacity: 1 });
      } else {
        await next({ opacity: 0 });
        await next({ display: 'none' });
      }
    },
  });

  const filterStart = useCallback(
    (date) => {
      if (endDate) {
        return date.getTime() < endDate.getTime();
      }

      return true;
    },
    [endDate]
  );

  const filterEnd = useCallback(
    (date) => {
      if (startDate) {
        return date.getTime() > startDate.getTime();
      }
      return true;
    },
    [startDate]
  );

  const handleResetClick = useCallback(() => {
    handleTodayAndForwardChange();
  }, [handleTodayAndForwardChange]);

  useEffect(() => {
    if (expanded) {
      const height = startDate ? 387 : 418;

      setInput({
        config: { duration: 80 },
        to: async (next, cancel) => {
          await next({ height: `${height + 4}px` });
          await next({ height: `${height}px` });
        },
      });
    } else {
      setInput({
        config: { duration: 80 },
        to: async (next, cancel) => {
          await next({ height: '29px' });
          await next({ height: '33px' });
        },
      });
    }
  }, [expanded, setInput, startDate]);

  const handleClick = useCallback(() => {
    if (expanded) {
      setArrow({ transform: 'rotateX(0deg)' });
    } else {
      setArrow({ transform: 'rotateX(180deg)' });
    }

    setExpand(!expanded);
  }, [expanded, setArrow]);

  const handleBlur = useCallback(
    (e) => {
      setTimeout(() => {
        if (!optionsRef.current?.contains(document.activeElement)) {
          setExpand(false);
        }
        setArrow({ transform: 'rotateX(0deg)' });
      }, 1);
    },
    [setArrow]
  );

  const handleApplyClick = useCallback(() => {
    setExpand(false);
  }, []);

  return (
    <div
      className={clsx([styles.select, { [styles.expanded]: expanded }])}
      style={{ zIndex }}
    >
      <SvgSelectArrow
        className={styles.arrow}
        height={8}
        style={arrowAnim as any}
        width={16}
      />
      <animated.div
        className={clsx([
          styles.input,
          { [styles.dashed]: dashed, [styles.disabled]: disabled },
        ])}
        onBlur={handleBlur}
        onClick={handleClick}
        ref={ref}
        role="textbox"
        tabIndex={0}
        {...rest}
        style={inputAnim}
      >
        {dayjs(startDate).format('DD MMM YYYY')}
        {endDate && ` – ${dayjs(endDate).format('DD MMM YYYY')}`}
      </animated.div>
      <animated.ul
        className={styles.options}
        onBlur={() => {
          setTimeout(() => {
            if (
              !ref.current.contains(document.activeElement) &&
              !optionsRef.current.contains(document.activeElement)
            ) {
              // eslint-disable-line max-len
              setExpand(false);
            }
          }, 1);
        }}
        ref={optionsRef}
        style={optionsAnim}
        tabIndex={0}
      >
        <div className={styles.datepickerContainer}>
          <div className={styles.picker}>
            {startDate && (
              <DateRangePicker
                customTimeInput={<CustomTimeInput label={t('date.fromTime')} />}
                disabledKeyboardNavigation
                filterDate={filterStart}
                inline
                locale={i18n.language}
                maxDate={new Date('2035-01-01')}
                minDate={new Date()}
                onChange={setStartDate}
                renderCustomHeader={(props2) => (
                  <CustomHeader label={t('date.fromDate')} {...props2} />
                )}
                selected={startDate}
                shouldCloseOnSelect={false}
                showTimeInput={false}
              />
            )}
            <DateRangePicker
              customTimeInput={<CustomTimeInput label={t('date.toTime')} />}
              disabledKeyboardNavigation
              filterDate={filterEnd}
              inline
              locale={i18n.language}
              maxDate={new Date('2035-01-01')}
              minDate={new Date()}
              onChange={setEndDate}
              renderCustomHeader={(props2) => (
                <CustomHeader label={t('date.toDate')} {...props2} />
              )}
              selected={endDate}
              shouldCloseOnSelect={false}
              showTimeInput={false}
            />
          </div>
          {!startDate && (
            <div className={clsx([styles.preset, styles.noStartDate])}>
              <Checkbox
                borderColor="black"
                checked={!endDate}
                onClick={handleTodayAndForwardChange}
              />
              <span>{t('date.preset')}</span>
            </div>
          )}
          <Separator className={styles.separator} />
          <div className={styles.actions}>
            {startDate && (
              <div className={styles.preset}>
                <Checkbox
                  borderColor="black"
                  checked={
                    startDate && !endDate && sameDay(new Date(), startDate)
                  }
                  onClick={handleTodayAndForwardChange}
                />
                <span>{t('date.preset')}</span>
              </div>
            )}
            <div
              className={clsx([
                styles.buttons,
                { [styles.withoutPreset]: !startDate },
              ])}
            >
              <Button color="primary" onClick={handleResetClick} type="button">
                {t('date.reset')}
              </Button>
              <Button
                className={styles.apply}
                color="secondary"
                disabled={!startDate}
                onClick={handleApplyClick}
                type="button"
              >
                {t('date.apply')}
              </Button>
            </div>
          </div>
        </div>
      </animated.ul>
    </div>
  );
});

export default DatePickerSelect;
