import { useMemo, MouseEvent, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import styles from './full-table.module.css';
import { Checkbox } from '../form';
import { SvgEdit, SvgSearch, SvgSortAsc, SvgSortDesc } from '../svg';
import { calculateEstimatedElementWidth, noop } from '../../utils/helper';
import { HollowDotsSpinner } from '../loader';

type FullTableProps = {
  headers: Array<string>;
  listing?: boolean;
  mode?: 'view' | 'edit';
  onCheck?: (e: MouseEvent<HTMLDivElement>, id: string | number) => void;
  onCheckAll?: () => void;
  onEditClick?: (id: string | number) => void;
  OptionsBar?: JSX.Element;
  rows: {
    id: string | number;
    isSelected: boolean;
    grayedOut: boolean;
    cells: Array<JSX.Element>;
  }[];
};

function FullTable(props: FullTableProps) {
  const {
    headers,
    listing = false,
    mode = 'edit',
    onCheck = noop,
    onCheckAll = noop,
    onEditClick,
    OptionsBar,
    rows,
  } = props;

  const [firstHeader, ...otherHeaders] = headers;

  const [t] = useTranslation();

  const table = useRef(null);

  const [sortBy, setSortBy] = useState([0, 'asc']);

  const selected = useMemo(() => rows.filter((row) => row.isSelected), [rows]);

  const handleSortClick = useCallback(
    (index: number) => {
      if (sortBy[0] === index) {
        setSortBy([index, sortBy[1] === 'asc' ? 'desc' : 'asc']);
      } else {
        setSortBy([index, 'asc']);
      }
    },
    [sortBy]
  );

  return (
    <div
      className={clsx([
        styles.tableContainer,
        { [styles.noOverflow]: listing },
      ])}
      ref={table}
    >
      <div className={clsx([styles.loader, { [styles.visible]: listing }])}>
        <HollowDotsSpinner className={styles.spinner} />
      </div>
      <table
        className={styles.table}
        rules="none"
        // cellSpacing={0}
        // cellPadding={0}
        // border={0}
      >
        <colgroup>
          <col className={styles.checkbox} />
          <col className={styles.col} />
          <col className={clsx([styles.col, styles.editCol])} />
          {otherHeaders.map((header) => (
            <col key={header} className={styles.col} />
          ))}
        </colgroup>
        <thead>
          <tr
            className={clsx([
              styles.element,
              styles.selector,
              { [styles.selected]: !!selected.length },
            ])}
          >
            <th align="center" className={styles.checkbox}>
              <Checkbox
                checked={selected.length > 0}
                borderColor="white"
                onClick={onCheckAll}
              />
            </th>
            {OptionsBar && selected.length > 0 ? (
              <th colSpan={16}>{OptionsBar}</th>
            ) : (
              <>
                <th align="left">
                  <span
                    className={styles.sortable}
                    onClick={() => handleSortClick(0)}
                  >
                    {firstHeader}
                    {sortBy[0] === 0 && (
                      <>
                        {sortBy[1] === 'asc' ? <SvgSortAsc /> : <SvgSortDesc />}
                      </>
                    )}
                  </span>
                </th>
                <th aria-label="Edit" />
                {otherHeaders.map((header, i) => (
                  <th key={header} align="left">
                    <span
                      className={styles.sortable}
                      onClick={() => handleSortClick(i + 1)}
                    >
                      {header}
                      {sortBy[0] === i + 1 && (
                        <>
                          {sortBy[1] === 'asc' ? (
                            <SvgSortAsc />
                          ) : (
                            <SvgSortDesc />
                          )}
                        </>
                      )}
                    </span>
                  </th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {rows
            .sort((a, b) => {
              const [index, order] = sortBy;

              if (order === 'asc') {
                return a.cells[index] > b.cells[index] ? 1 : -1;
              }

              return a.cells[index] < b.cells[index] ? 1 : -1;
            })
            .map((item, i) => {
              const {
                id,
                isSelected,
                grayedOut,
                cells: [firstCell, ...otherCells],
              } = item;

              return (
                <tr
                  key={id}
                  className={clsx([
                    styles.element,
                    { [styles.even]: i % 2 === 0 },
                    { [styles.grayedOut]: grayedOut },
                  ])}
                >
                  <td align="center" className={styles.checkbox}>
                    <Checkbox
                      onClick={(e) => onCheck(e, id)}
                      checked={isSelected}
                      borderColor="black"
                    />
                  </td>
                  <td>
                    <div
                      style={{
                        minWidth: calculateEstimatedElementWidth(firstHeader),
                      }}
                    >
                      {firstCell || '–'}
                    </div>
                  </td>
                  <td>
                    {onEditClick && (
                      <div
                        role="button"
                        tabIndex={0}
                        className={styles.edit}
                        onClick={() => onEditClick(id)}
                      >
                        {mode === 'edit' && (
                          <>
                            <SvgEdit color="#D7512F" width={15} height={15} />
                            {t('settings.edit')}
                          </>
                        )}
                        {mode === 'view' && (
                          <>
                            <SvgSearch color="#D7512F" width={15} height={15} />
                            {t('settings.view')}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                  {otherCells.map((cell, index) => (
                    <td key={index} align="left">
                      <div
                        className={styles.cell}
                        style={{
                          minWidth: calculateEstimatedElementWidth(
                            otherHeaders[i]
                          ),
                        }}
                      >
                        {cell?.toString() || '–'}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default FullTable;
