export function calculateArrowPos(points: number[], distance: number) {
  const sameQuadrant =
    (points[2] < 0 && points[3] < 0) || (points[2] > 0 && points[3] > 0);

  const a = points[2] - points[0];
  const b = points[3] - points[1];

  let offsetDistance = 0;
  if (sameQuadrant) {
    offsetDistance = points[3] < 0 ? distance : distance * -1;
  } else {
    offsetDistance = points[3] < 0 ? distance * -1 : distance;
  }

  const betha = Math.atan(a / b);
  const alpha = Math.atan(b / a);

  const m = offsetDistance * Math.cos(alpha);
  const n = offsetDistance * Math.cos(betha);

  const x = sameQuadrant ? a / 2 - n : a / 2 + n;
  const y = b / 2 + m;

  return [x, y];
}
