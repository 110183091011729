import dayjs from 'dayjs';
import { t } from 'i18next';
import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import { truncateSentenceEnd } from '../utils/helper';
import { categoriesSelector } from './categories';
import { linesSelector } from './lines';
import { allLocationsSelector } from './locations';
import { organizationsSelector } from './organizations';
import { zonesSelector } from './zones';

export const segmentationSelector = (state: RootState) => state.segmentation;

export const locationSelector = (state: RootState) =>
  state.segmentation.location;

export const selectedDateSelector = (state: RootState) =>
  state.segmentation.date;

export const latestUpdateTimeSelector = (state: RootState) =>
  state.segmentation.latestUpdateTime;

export const latestResetTimeSelector = (state: RootState) =>
  state.segmentation.latestResetTime;

export const selectedPropertySelector = (state: RootState) =>
  state.segmentation.property;

export const selectedStartDateSelector = (state: RootState) => {
  if (state.segmentation.startDate) {
    return dayjs(state.segmentation.startDate).toDate();
  }

  return state.segmentation.startDate;
};

export const selectedEndDateSelector = (state: RootState) => {
  if (state.segmentation.endDate) {
    return dayjs(state.segmentation.endDate).toDate();
  }

  return state.segmentation.endDate;
};

export const selectedLineSelector = (state: RootState) =>
  state.segmentation.line;

export const selectedOrganizationSelector = (state: RootState) =>
  state.segmentation.organization;

export const selectedLocationSelector = (state: RootState) =>
  state.segmentation.location;

export const selectedFloorSelector = (state: RootState) =>
  state.segmentation.floor;

export const selectedZoneToSelector = (state: RootState) =>
  state.segmentation.zoneTo;

export const selectedZoneFromSelector = (state: RootState) =>
  state.segmentation.zoneFrom;

export const selectedLineTypeSelector = (state: RootState) =>
  state.segmentation.lineType;

export const selectedDisplayLevelSelector = (state: RootState) =>
  state.segmentation.displayLevel;

export const organizationFilterOptionsSelector = createSelector(
  organizationsSelector,
  (organizations) =>
    organizations.map((organization) => ({
      label: organization.short_name,
      value: String(organization.id),
    }))
);

export const locationFilterOptionsSelector = createSelector(
  [allLocationsSelector, selectedOrganizationSelector],
  (locations, organization: Option) => {
    return locations
      .filter(
        (location) =>
          !organization ||
          location.organization.id.toString() === organization.value
      )
      .map((location) => ({
        label: `${location.organization.short_name} ${truncateSentenceEnd(
          location.legal_address
        )}`,
        value: location.id.toString(),
      }));
  }
);

export const locationFilterOptionsForVisitorJourneySelector = createSelector(
  [allLocationsSelector, selectedOrganizationSelector],
  (locations, organization: Option) => {
    return locations
      .filter(
        (location) =>
          (!organization ||
            location.organization.id.toString() === organization.value) &&
          location.customer_journey
      )
      .map((location) => ({
        label: `${location.organization.short_name} ${truncateSentenceEnd(
          location.legal_address
        )}`,
        value: location.id.toString(),
      }));
  }
);

export const zoneFilterOptionsSelector = createSelector(
  [zonesSelector, selectedOrganizationSelector],
  (zones, organization: Option) => {
    return zones
      .filter(
        (zone) =>
          !organization ||
          zone.organization === null ||
          zone.organization.toString() === organization.value
      )
      .map((zone) => ({
        label: zone.name,
        value: zone.id,
      }));
  }
);

export const categoryFilterOptionsSelector = createSelector(
  [categoriesSelector, selectedOrganizationSelector],
  (categories, organization: Option) =>
    categories
      .filter(
        (category) =>
          !organization ||
          category.organization.toString() === organization.value
      )
      .map((category) => ({
        label: `${category.name} (${t(`lineTypes.${category.type}`)})`,
        value: category.id.toString(),
      }))
);

export const lineFilterOptionsSelector = createSelector(
  [linesSelector],
  (lines) =>
    lines.map((line) => ({
      label: line.name,
      peopleCounterId: line.cameras[0],
      value: line.id,
    }))
);

export const lineFilterOptionsWithoutBufferLinesSelector = createSelector(
  [linesSelector],
  (lines) =>
    lines
      .filter((line) => line.parent_marker_id == null)
      .map((line) => ({
        label: line.name,
        peopleCounterId: line.cameras[0],
        value: line.id,
      }))
);

export const insightsRequestsEnabledSelector = createSelector(
  [
    selectedDateSelector,
    selectedStartDateSelector,
    selectedEndDateSelector,
    selectedPropertySelector,
    selectedOrganizationSelector,
  ],
  (date, startDate, endDate, property, organization) => {
    if (date && organization && property) {
      if (date?.value === 'custom') {
        if (startDate && endDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
);

export const visitorJourneyRequestsEnabledSelector = createSelector(
  [
    selectedDateSelector,
    selectedStartDateSelector,
    selectedEndDateSelector,
    selectedDisplayLevelSelector,
    selectedOrganizationSelector,
    selectedLocationSelector,
  ],
  (date, startDate, endDate, displayLevel, organization, location) => {
    if (date && organization && displayLevel && location) {
      if (date?.value === 'custom') {
        if (startDate && endDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
);

export const maxEndDateSelector = createSelector(
  [selectedStartDateSelector, selectedPropertySelector],
  (startDate, property) => {
    const defaultMax = new Date();
    let max: Date;

    if (!startDate) return defaultMax;

    switch (property?.value) {
      case '1m':
      case '10m':
      case '30m':
      case '1h':
        // Allow only the maximum of 1 day selection
        max = new Date(
          new Date(startDate).setDate(new Date(startDate).getDate())
        );

        break;
      // case '1h':
      //   // Allow only the maximum of 1 week selection
      //   max = new Date(
      //     new Date(startDate).setDate(new Date(startDate).getDate() + 7)
      //   );

      //   break;
      case '1d':
        // Allow only the maximum of 1 year selection
        max = new Date(
          new Date(startDate).setFullYear(new Date(startDate).getFullYear() + 1)
        );

        break;
      default:
        return defaultMax;
    }

    return max.getTime() > defaultMax.getTime() ? defaultMax : max;
  }
);
