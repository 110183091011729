import {
  LIST_LINES_FAILURE,
  LIST_LINES_REQUEST,
  LIST_LINES_SUCCESS,
} from '../constants/action-types';
import { ILine } from '../types/api/lines';

export const listLinesRequest = () => ({
  type: LIST_LINES_REQUEST,
});

export const listLinesSuccess = (lines: ILine[]) => ({
  lines,
  type: LIST_LINES_SUCCESS,
});

export const listLinesFailure = (error: unknown) => ({
  error,
  type: LIST_LINES_FAILURE,
});
