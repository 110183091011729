function SvgFilter(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#d7522f', ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21 19"
      {...rest}
    >
      <path
        d="M20.5.5H.5l8 9.46v6.54l4 2V9.96z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFilter;
