import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './header.module.css';
import { SvgCross } from '../../components/svg';
import { hideFoldout } from '../../actions/foldouts';
import { showModal } from '../../actions/modals';
import { foldoutChangedSelector } from '../../selectors/foldouts';
import { UNSAVED_CHANGES_MODAL } from '../../constants/modal-types';

type HeaderProps = {
  icon: JSX.Element;
  title: string;
  children?: JSX.Element | JSX.Element[];
};

function Header(props: HeaderProps) {
  const { icon, title, children } = props;

  const dispatch = useDispatch();

  const changed = useSelector(foldoutChangedSelector);

  const handleCloseClick = useCallback(() => {
    if (changed) {
      dispatch(
        showModal(UNSAVED_CHANGES_MODAL, {
          size: 'sm',
          backgroundColor: 'var(--error)',
        })
      );
    } else {
      dispatch(hideFoldout());
    }
  }, [dispatch, changed]);

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <div className={styles.label}>
          {icon}
          <span className={styles.title}>{title}</span>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.children}>{children}</div>
        <SvgCross
          onClick={handleCloseClick}
          width={16}
          height={16}
          className={styles.close}
        />
      </div>
    </header>
  );
}

export default Header;
