import { Transition, animated } from 'react-spring';
import styles from './content-loader.module.css';
import { ProgressSlider } from '../miscellaneous';

function ContentLoader() {
  return (
    <Transition
      items
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {(anim, item) =>
        item && (
          <animated.div className={styles.loader} style={anim}>
            <ProgressSlider />
            <div className={styles.squares}>
              <div className={styles.title} />
              <div className={styles.subtitle} />
              <div className={styles.content} />
              <div className={styles.twins}>
                <div className={styles.twin} />
                <div className={styles.twin} />
              </div>
            </div>
          </animated.div>
        )
      }
    </Transition>
  );
}

export default ContentLoader;
