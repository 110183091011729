import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteUserRequest } from '../../../actions/users';
import { Button } from '../../../components/form';
import { SvgTrash } from '../../../components/svg';
import { deletingUserSelector } from '../../../selectors/users';
import Header from '../header';
import styles from './index.module.css';

type DeleteUserModalProps = {
  close: () => void;
  user: {
    email: string;
    id: number;
  };
};

function DeleteUserModal(props: DeleteUserModalProps) {
  const { close, user } = props;

  const [t] = useTranslation('modals', { keyPrefix: 'deleteUser' });

  const dispatch = useDispatch();

  const deleting = useSelector(deletingUserSelector);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteUserRequest(user.id));
  }, [dispatch, user]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgTrash color="#fff" height={17} width={19} />}
        title={t('title')}
      />
      <div className={styles.description}>
        <Trans
          components={{
            div: <div />,
            p: <p />,
            u: <u />,
          }}
          i18nKey="description"
          t={t}
          values={{
            email: user.email,
          }}
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button color="primaryNegative" onClick={close} type="button">
          {t('cancel')}
        </Button>
        <Button
          className={styles.submit}
          color="negative"
          containerClassName={styles.submitContainer}
          disabled={false}
          loading={deleting}
          onClick={handleDeleteClick}
          type="button"
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default DeleteUserModal;
