import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PersistGate } from 'redux-persist/integration/react';

import './dayjs';
import './i18next';
import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css'; // eslint-disable-line import/no-extraneous-dependencies
import 'tippy.js/themes/light-border.css'; // eslint-disable-line import/no-extraneous-dependencies
import App from './containers/app';
import PageLoader from './containers/page-loader';
import reportWebVitals from './reportWebVitals';
import rootSaga from './sagas';
import configureStore from './store';
import storeRegistry from './store/store-registry';
import './styles/global.css';

const { history, persistor, store } = configureStore({
  initialState: {},
  rootSaga,
});
storeRegistry.store = store;
storeRegistry.persistor = persistor;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      <Suspense fallback={<PageLoader />}>
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
