function SvgMapPin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.273}
      height={16}
      role="img"
      viewBox="0 0 13.273 16"
      {...props}
    >
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12.773 6.636c0 4.773-6.136 8.864-6.136 8.864S.5 11.409.5 6.636a6.136 6.136 0 1 1 12.273 0Z" />
        <path d="M8.682 6.636a2.045 2.045 0 1 1-2.046-2.045 2.045 2.045 0 0 1 2.046 2.045Z" />
      </g>
    </svg>
  );
}
export default SvgMapPin;
