import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_TOKEN,
  SEND_RESET_PASSWORD_FAILURE,
  SEND_RESET_PASSWORD_REQUEST,
  SEND_RESET_PASSWORD_SUCCESS,
} from '../constants/action-types';
import { IUserGroup } from '../types/api/user-groups';
import { IUser } from '../types/api/users';

export const loginRequest = (email: string, password: string) => ({
  email,
  password,
  type: LOGIN_REQUEST,
});

export const loginSuccess = (
  email: string,
  token: string,
  user: IUser,
  usergroups: IUserGroup
) => ({
  email,
  token,
  type: LOGIN_SUCCESS,
  user,
  usergroups,
});

export const loginFailure = (error: unknown, validation: Validation[]) => ({
  error,
  type: LOGIN_FAILURE,
  validation,
});

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  error,
  type: LOGOUT_FAILURE,
});

export const resetToken = () => ({
  type: RESET_TOKEN,
});

export const sendResetPasswordRequest = (email: string) => ({
  email,
  type: SEND_RESET_PASSWORD_REQUEST,
});

export const sendResetPasswordSuccess = (email: string) => ({
  email,
  type: SEND_RESET_PASSWORD_SUCCESS,
});

export const sendResetPasswordFailure = (error, validation: any) => ({
  error,
  type: SEND_RESET_PASSWORD_FAILURE,
  validation,
});

export const resetPasswordRequest = (
  pre: string,
  post: string,
  password: string
) => ({
  password,
  post,
  pre,
  type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = (token: string, user, usergroups) => ({
  token,
  type: RESET_PASSWORD_SUCCESS,
  user,
  usergroups,
});

export const resetPasswordFailure = (error, validation: Validation[]) => ({
  error,
  type: RESET_PASSWORD_FAILURE,
  validation,
});

export const registerRequest = (email, password) => ({
  email,
  password,
  type: REGISTER_REQUEST,
});

export const registerSuccess = (email, password) => ({
  email,
  password,
  type: REGISTER_SUCCESS,
});

export const registerFailure = (error, validation: any) => ({
  error,
  type: REGISTER_FAILURE,
  validation,
});
