import clsx from 'clsx';
import styles from './foldout-action.module.css';
import { HTMLAttributes } from 'react';

type FoldoutActionProps = HTMLAttributes<HTMLDivElement> & {
  text: string;
  icon: JSX.Element;
};

function FoldoutAction(props: FoldoutActionProps) {
  const { className, text, icon, ...rest } = props;

  return (
    <div className={clsx(styles.action, className)} {...rest}>
      {icon}
      {text}
    </div>
  );
}

export default FoldoutAction;
