import { NavLink } from 'react-router-dom';

import { SvgGear } from '../../components/svg';
import styles from './settings.module.css';

function Settings() {
  return (
    <NavLink className={styles.container} to="/settings">
      {/* <div className={styles.container}> */}
      <SvgGear />
      {/* </div> */}
    </NavLink>
  );
}

export default Settings;
