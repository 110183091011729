import { HTMLAttributes, useCallback, useRef } from 'react';

import { PopupScrollContext } from '../../contexts';
import styles from './main.module.css';

function Main(props: HTMLAttributes<HTMLDivElement>) {
  const { children, ...rest } = props;

  const contentRef = useRef<HTMLDivElement | null>(null);

  const scrollTo = useCallback((x: number, y: number) => {
    // TODO: Make these lines dynamic, for example if the container
    //       is a modal it should get header height dynamically.
    contentRef.current.scrollTo({
      // 94 (47 height + 47 margin) is foldout header height
      behavior: 'smooth',
      left: x,
      // 24 is label + offset for better ux
      top: y + contentRef.current.scrollTop - 94 - 24,
    });
  }, []);

  return (
    <main ref={contentRef} {...rest} className={styles.main}>
      <PopupScrollContext.Provider value={scrollTo}>
        {children}
      </PopupScrollContext.Provider>
    </main>
  );
}

export default Main;
