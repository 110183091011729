import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import clsx from 'clsx';
import { noop } from 'lodash';
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { foldoutStateChanged } from '../../../actions/foldouts';
import { updateLocationRequest } from '../../../actions/locations';
import { showModal } from '../../../actions/modals';
import { listOrganizationsRequest } from '../../../actions/organizations';
import {
  Button,
  ButtonGroup,
  Input,
  Label,
  Select,
  Toggle,
} from '../../../components/form';
import { FoldoutAction } from '../../../components/miscellaneous';
import { SvgEdit, SvgMapPin, SvgTrashLight } from '../../../components/svg';
import iso3166 from '../../../constants/iso3166-1.alpha2';
import {
  LOCATION_PLACEMENTS,
  LOCATION_SIZES,
  LOCATION_TYPES,
  LOCATION_WEEKDAYS,
  Weekdays,
} from '../../../constants/location';
import { DELETE_LOCATION_MODAL } from '../../../constants/modal-types';
import { ADMIN, SUPERUSER, VIEWER } from '../../../constants/roles';
import timezones from '../../../constants/timezones.json';
import { updatingLocationSelector } from '../../../selectors/locations';
import { organizationsSelector } from '../../../selectors/organizations';
import { userRoleSelector } from '../../../selectors/users';
import { updateLocationValidationSelector } from '../../../selectors/validations';
import { ILocation } from '../../../types/api/locations';
import { weekdayByName, weekdayByNumber } from '../../../utils/helper';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

declare type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[];

const libraries: Libraries = ['places'];

type EditLocationProps = {
  close: () => void;
  location: ILocation;
};

function EditLocation(props: EditLocationProps) {
  const { close, location } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'editLocation' });

  const [t2] = useTranslation();

  const autocomplete = useRef(null);
  const autocompleteCity = useRef(null);
  const addressRef = useRef<HTMLInputElement>(null);

  const role = useSelector(userRoleSelector);

  const organizations = useSelector(organizationsSelector);
  const validation = useSelector(updateLocationValidationSelector);
  const submitting = useSelector(updatingLocationSelector);

  const [selectedType, setSelectedType] = useState(
    location.type
      ? {
          label: t2(`locationTypes.${location.type}`),
          value: location.type,
        }
      : null
  );
  const [selectedOrganization, setSelectedOrganization] = useState({
    label: location.organization.short_name,
    value: location.organization.id.toString(),
  });
  const [selectedPlacement, setSelectedPlacement] = useState({
    label: t2(`locationPlacements.${location.placement}`),
    value: location.placement,
  });
  const [country, setCountry] = useState({
    label: iso3166[location.country],
    value: location.country,
  });
  const [postcode, setPostcode] = useState(location.postcode || '');
  const [address, setAddress] = useState(location.legal_address || '');
  const [city, setCity] = useState(location.city || '');
  const [size, setSize] = useState({
    label: LOCATION_SIZES[location.size],
    value: location.size.toString(),
  });
  const [openingYear, setOpeningYear] = useState<
    ILocation['opening_year'] | null
  >(location.opening_year || null);
  const [employees, setEmployees] = useState(
    location.employees.toString() || ''
  );
  const [timezone, setTimezone] = useState(
    location.timezone_id
      ? {
          label: location.timezone_id,
          value: location.timezone_id,
        }
      : null
  );
  const [activeWeekdays, setActiveWeekdays] = useState<
    Record<Weekdays, boolean>
  >(
    location.weekdays.reduce((acc, day) => {
      acc[weekdayByNumber(day.weekday)] = day.is_open;
      return acc;
    }, {} as Record<Weekdays, boolean>)
  );
  const [openingTime, setOpeningTime] = useState<Record<Weekdays, string>>(
    location.weekdays.reduce((acc, day) => {
      const [hh, mm] = day.opens_at.split(':');
      acc[weekdayByNumber(day.weekday)] = `${hh}:${mm}`;
      return acc;
    }, {} as Record<Weekdays, string>)
  );
  const [closingTime, setClosingTime] = useState<Record<Weekdays, string>>(
    location.weekdays.reduce((acc, day) => {
      const [hh, mm] = day.closes_at.split(':');
      acc[weekdayByNumber(day.weekday)] = `${hh}:${mm}`;
      return acc;
    }, {} as Record<Weekdays, string>)
  );

  const locationIds = useMemo(() => {
    const ids = new Map();
    location.weekdays.forEach((day) => {
      ids.set(weekdayByNumber(day.weekday), day.id);
    });
    return ids;
  }, [location]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries,
  });

  const onLoad = (_autocomplete) => {
    autocomplete.current = _autocomplete;
  };

  const onLoadCity = (_autocomplete) => {
    autocompleteCity.current = _autocomplete;
  };

  const onPlaceChanged = useCallback(() => {
    if (autocomplete.current !== null) {
      const { address_components: comps } = autocomplete.current.getPlace();

      let countryIndex = 0;
      let placeCountry = '';
      let placeCity = '';
      let placePostcode = '';
      comps.forEach((comp, i) => {
        const type = comp.types.join(';');
        if (type === 'country;political') {
          placeCountry = comp.short_name;
          countryIndex = i;
        }
        if (type === 'postal_code') {
          placePostcode = comp.short_name;
        }
        if (type === 'postal_town') {
          placeCity = comp.short_name;
        }
        if (!placeCity) {
          if (type === 'locality;political') {
            placeCity = comp.short_name;
          }
          if (type === 'administrative_area_level_1;political') {
            placeCity = comp.short_name;
          }
        }
      });

      let streetAddress = '';
      for (let i = 0; i < countryIndex; i += 1) {
        if (!streetAddress) {
          streetAddress += comps[i].short_name;
        } else {
          streetAddress = `${streetAddress}, ${comps[i].short_name}`;
        }
      }

      if (placeCountry) {
        setCountry({
          label: iso3166[placeCountry],
          value: placeCountry,
        });
      }

      if (placeCity) {
        setCity(placeCity);
      }

      if (placePostcode) {
        setPostcode(placePostcode);
      }

      if (streetAddress) {
        setAddress(streetAddress);
      }
    }
  }, []);

  const onCityChange = useCallback(() => {
    if (autocompleteCity.current !== null) {
      const { address_components: comps } = autocompleteCity.current.getPlace();

      let placeCity = '';
      comps.forEach((comp) => {
        const type = comp.types.join(';');
        if (type === 'locality;political') {
          placeCity = comp.short_name;
        }
        if (!placeCity && type === 'administrative_area_level_1;political') {
          placeCity = comp.short_name;
        }
      });

      if (placeCity) {
        setCity(placeCity);
      }
    }
  }, []);

  const handleAddressChange = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleCityChange = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handlePostcodeChange = useCallback((e) => {
    setPostcode(e.target.value);
  }, []);

  const handleOpeningYearChange = useCallback((e) => {
    setOpeningYear(e.target.value);
  }, []);

  const handleEmployeesChange = useCallback((e) => {
    setEmployees(e.target.value);
  }, []);

  const handleActiveDayChange = useCallback(
    (day: Weekdays) => {
      setActiveWeekdays({
        ...activeWeekdays,
        [day]: !activeWeekdays[day],
      });
    },
    [activeWeekdays]
  );

  const handleOpeningTimeChange = useCallback(
    (day: Weekdays, e: ChangeEvent<HTMLInputElement>) => {
      setOpeningTime({
        ...openingTime,
        [day]: e.target.value,
      });
    },
    [openingTime]
  );

  const handleClosingTimeChange = useCallback(
    (day: Weekdays, e: ChangeEvent<HTMLInputElement>) => {
      setClosingTime({
        ...closingTime,
        [day]: e.target.value,
      });
    },
    [closingTime]
  );

  const handleDeleteClick = useCallback(() => {
    dispatch(
      showModal(DELETE_LOCATION_MODAL, {
        backgroundColor: 'var(--error)',
        location,
        size: 'sm',
      })
    );
  }, [dispatch, location]);

  const handleSubmit = useCallback(() => {
    dispatch(
      updateLocationRequest(location.id, {
        city,
        country: country?.value,
        employees: Number(employees),
        legal_address: address,
        opening_year: openingYear,
        organization: selectedOrganization
          ? Number(selectedOrganization.value)
          : null,
        placement: selectedPlacement?.value,
        postcode,
        size: Number(size?.value),
        timezone_id: timezone?.value,
        type: selectedType?.value,
        weekdays: LOCATION_WEEKDAYS.map((day) => ({
          closes_at: closingTime[day] + ':00',
          id: locationIds.get(day),
          is_open: activeWeekdays[day],
          opens_at: openingTime[day] + ':00',
          weekday: weekdayByName(day),
        })),
      })
    );
  }, [
    activeWeekdays,
    address,
    city,
    closingTime,
    country,
    dispatch,
    employees,
    location,
    locationIds,
    openingTime,
    openingYear,
    postcode,
    selectedOrganization,
    selectedPlacement,
    selectedType,
    size,
    timezone,
  ]);

  const isUpdated = useMemo(
    () =>
      selectedType?.value != location.type ||
      selectedOrganization?.value !== location.organization.id.toString() ||
      selectedPlacement?.value !== location.placement ||
      country?.value !== location.country ||
      postcode !== location.postcode ||
      address !== (location.legal_address || '') ||
      city !== location.city ||
      size?.value !== location.size.toString() ||
      openingYear !== (location.opening_year || '') ||
      employees !== location.employees.toString() ||
      timezone?.value !== location.timezone_id,
    [
      address,
      city,
      country,
      employees,
      location,
      openingYear,
      postcode,
      selectedOrganization,
      selectedPlacement,
      selectedType,
      size,
      timezone,
    ]
  );

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  useEffect(() => {
    if (organizations.length === 0) {
      dispatch(listOrganizationsRequest());
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isLoaded || loadError) return null;

  return (
    <>
      {role === VIEWER ? (
        <Header
          icon={<SvgMapPin height={20} width={20} />}
          title={t('title', { context: 'view' })}
        />
      ) : (
        <Header icon={<SvgEdit height={20} width={20} />} title={t('title')} />
      )}
      <Main>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.type')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="type"
              options={LOCATION_TYPES.map((key) => ({
                label: t2(`locationTypes.${key}`),
                value: key,
              }))}
              placeholder={t('placeholder.type')}
              selected={selectedType}
              setSelected={setSelectedType}
              validation={validation}
              validationKey="updateLocation"
              zIndex={10}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.organization')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="organization"
              options={organizations.map((o) => ({
                label: o.short_name,
                value: o.id.toString(),
              }))}
              placeholder={t('placeholder.organization')}
              selected={selectedOrganization}
              setSelected={setSelectedOrganization}
              validation={validation}
              validationKey="updateLocation"
              zIndex={9}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.placement')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="placement"
              options={LOCATION_PLACEMENTS.map((key) => ({
                label: t2(`locationPlacements.${key}`),
                value: key,
              }))}
              placeholder={t('placeholder.placement')}
              selected={selectedPlacement}
              setSelected={setSelectedPlacement}
              validation={validation}
              validationKey="updateLocation"
              zIndex={8}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.address')}
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <Input
                blackDisabled
                dashed={false}
                disabled={role === VIEWER}
                name="legal_address"
                onChange={handleAddressChange}
                placeholder={t('placeholder.address')}
                ref={addressRef}
                type="text"
                validation={validation}
                validationKey="updateLocation"
                value={address}
              />
            </Autocomplete>
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.city')}
            <Autocomplete
              onLoad={onLoadCity}
              onPlaceChanged={onCityChange}
              options={{ types: ['(cities)'] }}
            >
              <Input
                blackDisabled
                dashed={false}
                disabled={role === VIEWER}
                name="city"
                onChange={handleCityChange}
                placeholder={t('placeholder.city')}
                type="text"
                validation={validation}
                validationKey="updateLocation"
                value={city}
              />
            </Autocomplete>
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.country')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="country"
              options={Object.entries(iso3166).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              placeholder={t('placeholder.country')}
              selected={country}
              setSelected={setCountry}
              validation={validation}
              validationKey="updateLocation"
              zIndex={7}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.postcode')}
            <Input
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="postcode"
              onChange={handlePostcodeChange}
              placeholder={t('placeholder.postcode')}
              type="text"
              validation={validation}
              validationKey="updateLocation"
              value={postcode}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.timezone')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="timezone_id"
              options={Object.entries(timezones).map(([, value]) => ({
                label: value,
                value: value,
              }))}
              placeholder={t('placeholder.timezone')}
              selected={timezone}
              setSelected={setTimezone}
              validation={validation}
              validationKey="updateLocation"
              zIndex={7}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.size')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="size"
              options={LOCATION_SIZES.map((key, i) => ({
                label: key,
                value: i.toString(),
              }))}
              placeholder={t('placeholder.size')}
              selected={size}
              setSelected={setSize}
              validation={validation}
              validationKey="updateLocation"
              zIndex={6}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.openingYear')}
            <Input
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="opening_year"
              onChange={handleOpeningYearChange}
              placeholder={t('placeholder.openingYear')}
              type="text"
              validation={validation}
              validationKey="updateLocation"
              value={openingYear}
            />
          </Label>
          <Label className={styles.label}>
            {t('label.employees')}
            <Input
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="employees"
              onChange={handleEmployeesChange}
              placeholder={t('placeholder.employees')}
              type="text"
              validation={validation}
              validationKey="updateLocation"
              value={employees}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <div className={styles.openingHours}>{t('times')}</div>
        </div>
        {LOCATION_WEEKDAYS.map((day) => (
          <Fragment key={day}>
            <div className={styles.row}>
              <Label className={clsx([styles.label, styles.inline])}>
                <div className={styles.status}>{t(`label.weekday.${day}`)}</div>
                <Toggle
                  checked={activeWeekdays[day]}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ignoreid="true"
                  onChange={() =>
                    role === VIEWER ? noop : handleActiveDayChange(day)
                  }
                  parentClass={styles.toggle}
                  prefix={{
                    off: t('deactivated'),
                    on: t('activated'),
                  }}
                />
              </Label>
            </div>
            {activeWeekdays[day] && (
              <div className={styles.row}>
                <Label className={styles.label}>
                  {t('label.openingTime')}
                  <Input
                    blackDisabled
                    dashed={false}
                    disabled={role === VIEWER}
                    name={`opening_time_${weekdayByName(day)}`}
                    onChange={(e) => handleOpeningTimeChange(day, e)}
                    placeholder={t('placeholder.openingTime')}
                    type="text"
                    validation={validation}
                    validationKey="updateLocation"
                    value={openingTime[day]}
                  />
                </Label>
                <Label className={styles.label}>
                  {t('label.closingTime')}
                  <Input
                    blackDisabled
                    dashed={false}
                    disabled={role === VIEWER}
                    name={`closing_time_${weekdayByName(day)}`}
                    onChange={(e) => handleClosingTimeChange(day, e)}
                    placeholder={t('placeholder.closingTime')}
                    type="text"
                    validation={validation}
                    validationKey="updateLocation"
                    value={closingTime[day]}
                  />
                </Label>
              </div>
            )}
          </Fragment>
        ))}
        {(role === ADMIN || role === SUPERUSER) && (
          <div className={styles.row}>
            <FoldoutAction
              icon={<SvgTrashLight height={16} width={16} />}
              onClick={handleDeleteClick}
              text={t('delete')}
            />
          </div>
        )}
      </Main>
      <Footer>
        <ButtonGroup>
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            disabled={role === VIEWER}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default EditLocation;
