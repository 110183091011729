function SvgExcel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      style={{
        verticalAlign: 'middle',
        fill: 'currentColor',
        overflow: 'hidden',
      }}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      role="img"
      {...props}
    >
      <path d="M563.2 1006.933s-3.413 0 0 0l-549.547-102.4C6.827 901.12 0 894.293 0 887.467v-716.8c0-6.827 6.827-13.654 13.653-17.067L559.787 17.067c3.413 0 10.24 0 13.653 3.413 3.413 3.413 6.827 6.827 6.827 13.653v955.734c0 3.413-3.414 10.24-6.827 13.653-3.413 3.413-6.827 3.413-10.24 3.413zM34.133 873.813l512 95.574V54.613l-512 129.707v689.493z" />
      <path d="M1006.933 938.667H563.2c-10.24 0-17.067-6.827-17.067-17.067s6.827-17.067 17.067-17.067h426.667V119.467h-409.6c-10.24 0-17.067-6.827-17.067-17.067s6.827-17.067 17.067-17.067h426.666c10.24 0 17.067 6.827 17.067 17.067v819.2c0 10.24-6.827 17.067-17.067 17.067zm-614.4-238.934c-6.826 0-10.24-3.413-13.653-6.826l-204.8-341.334c-6.827-10.24-3.413-20.48 3.413-23.893s17.067-3.413 23.894 6.827l204.8 341.333c3.413 6.827 3.413 17.067-6.827 23.893h-6.827z" />
      <path d="M187.733 699.733c-3.413 0-6.826 0-10.24-3.413-6.826-3.413-10.24-13.653-3.413-23.893l204.8-341.334c3.413-6.826 13.653-10.24 23.893-6.826 6.827 3.413 10.24 13.653 6.827 23.893L204.8 689.493c-6.827 6.827-10.24 10.24-17.067 10.24zm546.134 238.934c-10.24 0-17.067-6.827-17.067-17.067V119.467c0-10.24 6.827-17.067 17.067-17.067s17.066 6.827 17.066 17.067V921.6c0 10.24-6.826 17.067-17.066 17.067z" />
      <path d="M989.867 802.133H563.2c-10.24 0-17.067-6.826-17.067-17.066S552.96 768 563.2 768h426.667c10.24 0 17.066 6.827 17.066 17.067s-6.826 17.066-17.066 17.066zm17.066-136.533H563.2c-10.24 0-17.067-6.827-17.067-17.067s6.827-17.066 17.067-17.066h443.733c10.24 0 17.067 6.826 17.067 17.066s-6.827 17.067-17.067 17.067zm0-136.533H580.267c-10.24 0-17.067-6.827-17.067-17.067s6.827-17.067 17.067-17.067h426.666c10.24 0 17.067 6.827 17.067 17.067s-6.827 17.067-17.067 17.067zm-6.826-136.534H573.44c-10.24 0-17.067-6.826-17.067-17.066S563.2 358.4 573.44 358.4h426.667c10.24 0 17.066 6.827 17.066 17.067s-10.24 17.066-17.066 17.066zM1006.933 256H563.2c-10.24 0-17.067-6.827-17.067-17.067s6.827-17.066 17.067-17.066h443.733c10.24 0 17.067 6.826 17.067 17.066S1017.173 256 1006.933 256z" />
    </svg>
  );
}

export default SvgExcel;
