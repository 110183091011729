export const ORGANIZATION_SIZES = Object.freeze({
  0: '1 - 4',
  1: '5 - 9',
  2: '10 - 49',
  3: '50 - 99',
  4: '100 - 249',
  5: '249+',
});

export const ORGANIZATION_SIZE_VALUES = Object.freeze({
  '1 - 4': 0,
  '5 - 9': 1,
  '10 - 49': 2,
  '50 - 99': 3,
  '100 - 249': 4,
  '249+': 5,
});

export const INDUSTRIES = Object.freeze({
  0: 'Flourist',
  1: 'Random',
});
