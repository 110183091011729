import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ScrollContext } from '../../contexts';
import {
  calculateEstimatedElementWidth,
  noop,
  trimStartDash,
} from '../../utils/helper';
import { Checkbox } from '../form';
import { HollowDotsSpinner } from '../loader';
import {
  SvgEdit,
  SvgSearch,
  SvgSortAsc,
  SvgSortDesc,
  SvgTickDown,
  SvgTickLeft,
  SvgTickRight,
} from '../svg';
import styles from './table.module.css';

const DEFAULT_PAGE_ITEM_COUNT = 25;

const PAGE_LIMIT_OPTIONS = [25, 50, 100];

type TableProps = {
  OptionsBar?: JSX.Element;
  headers: {
    key: string;
    label: string;
    sortable?: boolean;
  }[];
  listing?: boolean;
  mode?: 'edit' | 'view';
  onCheck?: (e: MouseEvent<HTMLDivElement>, id: number) => void;
  onCheckAll?: () => void;
  onEditClick?: (id: number) => void;
  onSortClick?: (order: string) => void;
  order?: string;
  pageLimit?: number;
  pageOffset?: number;
  rows: {
    cells: Array<ReactNode>;
    grayedOut?: boolean;
    id: number;
    isSelected: boolean;
  }[];
  search?: string;
  setLimit: Dispatch<SetStateAction<number>>;
  setOffset: Dispatch<SetStateAction<number>>;
  totalCount?: number;
};

function Table(props: TableProps) {
  const {
    OptionsBar,
    headers,
    listing = false,
    mode = 'edit',
    onCheck = noop,
    onCheckAll = noop,
    onEditClick,
    onSortClick = noop,
    order,
    pageLimit = DEFAULT_PAGE_ITEM_COUNT,
    pageOffset = 0,
    rows,
    search = '',
    setLimit,
    setOffset,
    totalCount = 0,
  } = props;

  const [firstHeader, ...otherHeaders] = headers;

  const [t] = useTranslation();

  const table = useRef(null);
  const loader = useRef(null);
  const hollow = useRef(null);

  const scrollTo = useContext(ScrollContext);

  const [visible, setVisible] = useState<boolean | false>(false);

  const selected = useMemo(() => rows.filter((row) => row.isSelected), [rows]);

  const toggleVisibility = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const totalPageAmount = useMemo(() => {
    if (totalCount > pageLimit) {
      return Math.ceil(totalCount / pageLimit);
    }
    return 1;
  }, [totalCount, pageLimit]);

  const currentPage = useMemo(
    () => pageOffset / pageLimit + 1,
    [pageLimit, pageOffset]
  );

  const resetScroll = useCallback(() => {
    table.current.scrollTo(0, 0);
    scrollTo(0, 0);
  }, [scrollTo]);

  const handlePreviousClick = useCallback(() => {
    setOffset(pageLimit * (currentPage - 2));
    resetScroll();
  }, [currentPage, pageLimit, resetScroll, setOffset]);

  const handleNextClick = useCallback(() => {
    setOffset(pageLimit * currentPage);
    resetScroll();
  }, [currentPage, pageLimit, resetScroll, setOffset]);

  const handlePageNumberClick = useCallback(
    (number: number) => {
      setOffset(pageLimit * (number - 1));
      resetScroll();
    },
    [pageLimit, resetScroll, setOffset]
  );

  const handlePerPageChange = useCallback(
    (limit: number) => {
      setLimit(limit);
      setOffset(0);
      toggleVisibility();
      resetScroll();
    },
    [resetScroll, setLimit, setOffset, toggleVisibility]
  );

  // useEffect to add scroll listener to the "table" ref
  useEffect(() => {
    const tableRef = table.current;

    const handleScroll = () => {
      const { scrollLeft } = table.current;

      loader.current.style.transform = 'translateX(' + scrollLeft + 'px)';
    };

    tableRef.addEventListener('scroll', handleScroll);

    return () => {
      tableRef.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;

      hollow.current.style.transform = 'translateY(' + scrollTop + 'px)';
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={clsx([
          styles.tableContainer,
          { [styles.noOverflow]: listing },
        ])}
        ref={table}
      >
        <div
          className={clsx([styles.loader, { [styles.visible]: listing }])}
          ref={loader}
        >
          <HollowDotsSpinner className={styles.spinner} ref={hollow} />
        </div>
        <table
          className={styles.table}
          rules="none"
          // cellSpacing={0}
          // cellPadding={0}
          // border={0}
        >
          <colgroup>
            <col className={styles.checkbox} />
            <col className={styles.col} />
            <col className={clsx([styles.col, styles.editCol])} />
            {otherHeaders.map((h) => (
              <col className={styles.col} key={h.key} />
            ))}
          </colgroup>
          <thead>
            <tr
              className={clsx([
                styles.element,
                styles.selector,
                { [styles.selected]: !!selected.length },
              ])}
            >
              <th align="center" className={styles.checkbox}>
                <Checkbox
                  borderColor="white"
                  checked={selected.length > 0}
                  onClick={onCheckAll}
                />
              </th>
              {OptionsBar && selected.length > 0 ? (
                <th colSpan={16}>{OptionsBar}</th>
              ) : (
                <>
                  <th align="left">
                    <span
                      className={clsx({
                        [styles.sortable]: firstHeader.sortable,
                      })}
                      onClick={() => onSortClick(firstHeader.key)}
                    >
                      {firstHeader.label}
                      {trimStartDash(order) === firstHeader.key && (
                        <>
                          {order.startsWith('-') ? (
                            <SvgSortDesc />
                          ) : (
                            <SvgSortAsc />
                          )}
                        </>
                      )}
                    </span>
                  </th>
                  <th aria-label="Edit" />
                  {otherHeaders.map((h) => (
                    <th align="left" key={h.key}>
                      <span
                        className={clsx(styles.header, {
                          [styles.sortable]: h.sortable,
                        })}
                        onClick={() => onSortClick(h.key)}
                      >
                        {h.label}
                        {trimStartDash(order) === h.key && (
                          <>
                            {order.startsWith('-') ? (
                              <SvgSortDesc />
                            ) : (
                              <SvgSortAsc />
                            )}
                          </>
                        )}
                      </span>
                    </th>
                  ))}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 && (
              <tr className={styles.element}>
                <td align="left" colSpan={16}>
                  <div className={styles.noResults}>
                    <div>
                      {listing
                        ? t('empty.loading')
                        : t('empty.cameras', {
                            context: search ? 'search' : 'none',
                            search,
                          })}
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {rows.map((item, i) => {
              const {
                cells: [firstCell, ...otherCells],
                grayedOut,
                id,
                isSelected,
              } = item;

              return (
                <tr
                  className={clsx([
                    styles.element,
                    { [styles.even]: i % 2 === 0 },
                    { [styles.grayedOut]: grayedOut },
                  ])}
                  key={id}
                >
                  <td align="center" className={styles.checkbox}>
                    <Checkbox
                      borderColor="black"
                      checked={isSelected}
                      onClick={(e) => onCheck(e, id)}
                    />
                  </td>
                  <td>
                    <div
                      style={{
                        minWidth: calculateEstimatedElementWidth(
                          firstHeader.label,
                          firstHeader.sortable
                        ),
                      }}
                    >
                      {firstCell || '–'}
                    </div>
                  </td>
                  <td>
                    {onEditClick && (
                      <div
                        className={styles.edit}
                        onClick={() => onEditClick(id)}
                        role="button"
                        tabIndex={0}
                      >
                        {mode === 'edit' && (
                          <>
                            <SvgEdit color="#D7512F" height={15} width={15} />
                            {t('settings.edit')}
                          </>
                        )}
                        {mode === 'view' && (
                          <>
                            <SvgSearch color="#D7512F" height={15} width={15} />
                            {t('settings.view')}
                          </>
                        )}
                      </div>
                    )}
                  </td>
                  {otherCells.map((cell, index) => {
                    return (
                      <td align="left" key={index}>
                        <div
                          className={styles.cell}
                          style={{
                            minWidth: calculateEstimatedElementWidth(
                              otherHeaders[index].label,
                              otherHeaders[index].sortable
                            ),
                          }}
                        >
                          {cell?.toString() || '–'}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {totalPageAmount && (
        <div className={styles.pagination}>
          <div
            className={clsx([
              styles.prev,
              { [styles.disabled]: currentPage === 1 },
            ])}
            onClick={handlePreviousClick}
          >
            <SvgTickLeft height={16} width={16} />
            {t('table.prev')}
          </div>
          <div className={styles.pageNumbers}>
            {[...Array(totalPageAmount).keys()].map((_, i) => (
              <div
                className={clsx(styles.pageNumber, {
                  [styles.current]: currentPage === i + 1,
                })}
                key={i}
                onClick={() => handlePageNumberClick(i + 1)}
              >
                {i + 1}
              </div>
            ))}
          </div>
          <div
            className={clsx([
              styles.next,
              { [styles.disabled]: currentPage === totalPageAmount },
            ])}
            onClick={handleNextClick}
          >
            {t('table.next')}
            <SvgTickRight height={16} width={16} />
          </div>
          <Tippy
            appendTo={document.body}
            arrow={false}
            content={
              <div className={styles.dropdown}>
                {PAGE_LIMIT_OPTIONS.map((limit) => (
                  <div
                    className={clsx([
                      styles.item,
                      { [styles.active]: pageLimit === limit },
                    ])}
                    key={limit}
                    onClick={() => handlePerPageChange(limit)}
                  >
                    {t('table.pageLimit', { pageLimit: limit })}
                  </div>
                ))}
              </div>
            }
            interactive
            maxWidth={160}
            onClickOutside={() => setVisible(false)}
            placement="top"
            theme="light-border"
            visible={visible}
          >
            <div
              className={clsx([
                styles.perpageCount,
                { [styles.visible]: visible },
              ])}
              onClick={toggleVisibility}
            >
              {t('table.pageLimit', { pageLimit })}
              <SvgTickDown height={16} width={16} />
            </div>
          </Tippy>
        </div>
      )}
    </>
  );
}

export default Table;
