import { call, put, select, takeLatest } from 'redux-saga/effects';

import { listLinesFailure, listLinesSuccess } from '../actions/lines';
import { Lines } from '../api';
import { LIST_LINES_REQUEST } from '../constants/action-types';
import lineTypes from '../constants/line-types';

export function* listLines() {
  try {
    const { floor, lineType, location, organization, zoneFrom, zoneTo } =
      yield select((state) => state.segmentation);

    const params = {
      // category: category.map(({ value }) => value).join(','),
      floor: floor.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      type: lineType.map(({ value }) => lineTypes[value]).join(','),
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: lines } = yield call(Lines.List, params);

    yield put(listLinesSuccess(lines));
  } catch (e) {
    yield put(listLinesFailure(e));
  }
}

export function* watchLines() {
  yield takeLatest(LIST_LINES_REQUEST, listLines);
}
