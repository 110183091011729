import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './foldout-line.module.css';

type FoldoutLineProps = HTMLAttributes<HTMLDivElement>;

function FoldoutLine(props: FoldoutLineProps) {
  const { className, ...rest } = props;

  return (
    <div className={clsx(styles.lineWrapper, className)} {...rest}>
      <div className={styles.line} />
    </div>
  );
}

export default FoldoutLine;
