import { RootActions } from '../actions';
import * as camerasActions from '../actions/cameras';
import {
  ACTIVATE_CAMERA_FAILURE,
  ACTIVATE_CAMERA_REQUEST,
  ACTIVATE_CAMERA_SUCCESS,
  BATCH_UPDATE_CAMERAS_FAILURE,
  BATCH_UPDATE_CAMERAS_REQUEST,
  BATCH_UPDATE_CAMERAS_SUCCESS,
  CLEAR_CAMERA_SELECTIONS,
  CREATE_CAMERA_FAILURE,
  CREATE_CAMERA_REQUEST,
  CREATE_CAMERA_SUCCESS,
  DEACTIVATE_CAMERA_FAILURE,
  DEACTIVATE_CAMERA_REQUEST,
  DEACTIVATE_CAMERA_SUCCESS,
  DELETE_CAMERA_FAILURE,
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_SUCCESS,
  GET_CAMERA_FAILURE,
  GET_CAMERA_REQUEST,
  GET_CAMERA_SUCCESS,
  LIST_CAMERAS_FAILURE,
  LIST_CAMERAS_REQUEST,
  LIST_CAMERAS_SUCCESS,
  RESET_TOKEN,
  SELECT_CAMERA_ALL,
  SELECT_CAMERA_WITH_CTRL,
  SELECT_CAMERA_WITH_SHIFT,
  SELECT_CAMERA_WITHOUT,
  UPDATE_CAMERA_FAILURE,
  UPDATE_CAMERA_REQUEST,
  UPDATE_CAMERA_SUCCESS,
} from '../constants/action-types';
import { ICamera } from '../types/api/cameras';

export type CamerasState = {
  activating: boolean;
  addingChangelog: boolean;
  batchUpdating: boolean;
  cameraCount: number;
  cameras: ICamera[];
  creating: boolean;
  deactivating: boolean;
  deleting: boolean;
  lastSelected: number;
  listing: boolean;
  listingSingleCameras: Record<string, boolean>;
  selectedCameras: number[];
  singleCameras: Record<string, ICamera>;
  updating: boolean;
};

export const initialState: CamerasState = {
  activating: false,
  addingChangelog: false,
  batchUpdating: false,
  cameraCount: 0,
  cameras: [],
  creating: false,
  deactivating: false,
  deleting: false,
  lastSelected: null,
  listing: false,
  listingSingleCameras: {},
  selectedCameras: [],
  singleCameras: {},
  updating: false,
};

export default (state = initialState, action: RootActions) => {
  switch (action.type) {
    case DELETE_CAMERA_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_CAMERA_SUCCESS: {
      const { id } = action as ReturnType<
        typeof camerasActions.deleteCameraSuccess
      >;

      return {
        ...state,
        cameras: state.cameras.map((c) =>
          c.id === id ? { ...c, deleted_at: new Date().toJSON() } : c
        ),
        deleting: false,
      };
    }
    case DELETE_CAMERA_FAILURE:
      return {
        ...state,
        deleting: false,
      };
    case CREATE_CAMERA_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_CAMERA_SUCCESS: {
      const { camera } = action as ReturnType<
        typeof camerasActions.createCameraSuccess
      >;

      return {
        ...state,
        cameras: [camera, ...state.cameras],
        creating: false,
      };
    }
    case CREATE_CAMERA_FAILURE:
      return {
        ...state,
        creating: false,
      };
    case GET_CAMERA_REQUEST: {
      const { listingSingleCameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.getCameraRequest
      >;

      return {
        ...state,
        listingSingleCameras: {
          ...listingSingleCameras,
          [id]: true,
        },
      };
    }
    case GET_CAMERA_SUCCESS: {
      const { listingSingleCameras, singleCameras } = state;
      const { camera } = action as ReturnType<
        typeof camerasActions.getCameraSuccess
      >;

      return {
        ...state,
        listingSingleCameras: {
          ...listingSingleCameras,
          [camera.id]: false,
        },
        singleCameras: {
          ...singleCameras,
          [camera.id]: camera,
        },
      };
    }
    case GET_CAMERA_FAILURE: {
      const { listingSingleCameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.getCameraFailure
      >;

      return {
        ...state,
        listingSingleCameras: {
          ...listingSingleCameras,
          [id]: false,
        },
      };
    }
    case UPDATE_CAMERA_REQUEST:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_CAMERA_SUCCESS: {
      const { cameras } = state;
      const { camera, id } = action as ReturnType<
        typeof camerasActions.updateCameraSuccess
      >;

      const index = cameras.findIndex((c) => c.id === id);

      return {
        ...state,
        cameras: [
          ...cameras.slice(0, index),
          {
            ...cameras[index],
            ...camera,
          },
          ...cameras.slice(index + 1),
        ],
        updating: false,
      };
    }
    case UPDATE_CAMERA_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case BATCH_UPDATE_CAMERAS_REQUEST:
      return {
        ...state,
        batchUpdating: true,
      };
    case BATCH_UPDATE_CAMERAS_SUCCESS: {
      const { cameras } = state;
      const { fieldName, ids, value } = action as ReturnType<
        typeof camerasActions.batchUpdateCamerasSuccess
      >;

      return {
        ...state,
        batchUpdating: false,
        cameras: cameras.map((camera) => {
          if (ids.includes(camera.id)) {
            return {
              ...camera,
              [fieldName]: value,
            };
          }
          return camera;
        }),
      };
    }
    case BATCH_UPDATE_CAMERAS_FAILURE:
      return {
        ...state,
        batchUpdating: false,
      };
    case ACTIVATE_CAMERA_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case ACTIVATE_CAMERA_SUCCESS: {
      const { cameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.activateCameraSuccess
      >;

      const index = cameras.findIndex((c) => c.id === id);

      return {
        ...state,
        activating: false,
        camera: [
          ...cameras.slice(0, index),
          {
            ...cameras[index],
            is_active: true,
          },
          ...cameras.slice(index + 1),
        ],
      };
    }
    case ACTIVATE_CAMERA_FAILURE:
      return {
        ...state,
        activating: false,
      };
    case DEACTIVATE_CAMERA_REQUEST:
      return {
        ...state,
        deactivating: true,
      };
    case DEACTIVATE_CAMERA_SUCCESS: {
      const { cameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.deactivateCameraSuccess
      >;

      const index = cameras.findIndex((c) => c.id === id);

      return {
        ...state,
        camera: [
          ...cameras.slice(0, index),
          {
            ...cameras[index],
            is_active: false,
          },
          ...cameras.slice(index + 1),
        ],
        deactivating: false,
      };
    }
    case DEACTIVATE_CAMERA_FAILURE:
      return {
        ...state,
        deactivating: false,
      };
    case LIST_CAMERAS_REQUEST:
      return {
        ...state,
        listing: true,
        selectedCameras: [],
      };
    case LIST_CAMERAS_SUCCESS: {
      const { cameras, count } = action as ReturnType<
        typeof camerasActions.listCamerasSuccess
      >;

      return {
        ...state,
        cameraCount: count,
        cameras,
        listing: false,
      };
    }
    case LIST_CAMERAS_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case CLEAR_CAMERA_SELECTIONS:
      return {
        ...state,
        cameras: [],
      };
    case SELECT_CAMERA_WITH_SHIFT: {
      const { cameras, lastSelected } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.selectCameraWithShift
      >;

      if (!lastSelected || id === lastSelected) {
        return {
          ...state,
          lastSelected: id,
          selectedCameras: [id],
        };
      }

      const index = cameras.findIndex((u) => id === u.id);
      const indexLastSelected = cameras.findIndex((u) => lastSelected === u.id);

      let selectedCameras;
      if (index > indexLastSelected) {
        selectedCameras = cameras
          .slice(indexLastSelected, index + 1)
          .map((u) => u.id);
      } else {
        selectedCameras = cameras
          .slice(index, indexLastSelected + 1)
          .map((u) => u.id);
      }

      return {
        ...state,
        selectedCameras,
      };
    }
    case SELECT_CAMERA_WITH_CTRL: {
      const { selectedCameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.selectCameraWithCtrl
      >;

      const index = selectedCameras.indexOf(id);

      if (index === -1) {
        const newSelected = [...selectedCameras, id];

        return {
          ...state,
          lastSelected: id,
          selectedCameras: newSelected,
        };
      }

      const newSelected = [
        ...selectedCameras.slice(0, index),
        ...selectedCameras.slice(index + 1),
      ];
      let previousSelection = 0;
      if (newSelected.length) {
        previousSelection = newSelected[newSelected.length - 1];
      }

      return {
        ...state,
        lastSelected: previousSelection,
        selectedCameras: newSelected,
      };
    }
    case SELECT_CAMERA_WITHOUT: {
      const { selectedCameras } = state;
      const { id } = action as ReturnType<
        typeof camerasActions.selectCameraWithout
      >;

      const isThisOne = selectedCameras[0] === id;
      const isOnlyOne = selectedCameras.length === 1;

      const newSelected = isOnlyOne && isThisOne ? [] : [id];

      return {
        ...state,
        lastSelected: isOnlyOne && isThisOne ? 0 : id,
        selectedCameras: newSelected,
      };
    }
    case SELECT_CAMERA_ALL: {
      const { cameras, selectedCameras } = state;

      return {
        ...state,
        selectedCameras:
          selectedCameras.length > 0 ? [] : cameras.map((u) => u.id),
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
