import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  declineInvitationRequest,
  getInviteesRequest,
  sendInvitationRequest,
} from '../../../actions/users';
import { Button, Checkbox } from '../../../components/form';
import { CircleSpinner } from '../../../components/loader';
import { SvgEdit } from '../../../components/svg';
import { EntityCount } from '../../../components/text';
import { systemRoleIds as sr } from '../../../constants/system-role';
import {
  decliningInvitationSelector,
  gettingInviteesSelector,
  inviteesSelector,
  sendingInvitationSelector,
} from '../../../selectors/users';
import Header from '../header';
import styles from './index.module.css';

function Requests() {
  const [t] = useTranslation('modals', { keyPrefix: 'requests' });

  const [t2] = useTranslation();

  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const invitees = useSelector(inviteesSelector);

  const approving = useSelector(sendingInvitationSelector);

  const declining = useSelector(decliningInvitationSelector);

  const loading = useSelector(gettingInviteesSelector);

  const disabled = useMemo(
    () => selected.length === 0 || approving || declining,
    [approving, declining, selected]
  );

  const handleCheckboxClick = useCallback(
    (id) => {
      const index = selected.findIndex((selectedId) => selectedId === id);

      if (index === -1) {
        setSelected([...selected, id]);
      } else {
        setSelected([
          ...selected.slice(0, index),
          ...selected.slice(index + 1),
        ]);
      }
    },
    [selected]
  );

  const handleApproveClick = useCallback(() => {
    dispatch(sendInvitationRequest(selected));
  }, [dispatch, selected]);

  const handleDeclineClick = useCallback(() => {
    dispatch(declineInvitationRequest(selected));
  }, [dispatch, selected]);

  useEffect(() => {
    dispatch(getInviteesRequest());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Header icon={<SvgEdit height={19} width={19} />} title={t('title')}>
        <EntityCount>
          {!loading && (
            <Trans
              components={{
                bold: <b />,
              }}
              i18nKey="total"
              t={t}
              values={{
                count: invitees.length,
              }}
            />
          )}
        </EntityCount>
      </Header>
      <div className={styles.gap} />

      <div className={styles.list}>
        {loading ? (
          <div className={styles.spinnerContainer}>
            <CircleSpinner className={styles.spinner} />
          </div>
        ) : (
          <>
            {invitees.map((invitee, i) => {
              const {
                id,
                invite_email: email,
                locations,
                system_role: role,
              } = invitee;

              return (
                <div
                  className={clsx([
                    styles.item,
                    { [styles.even]: i % 2 === 0 },
                  ])}
                  key={id}
                >
                  <Checkbox
                    borderColor="white"
                    checked={selected.includes(id)}
                    onClick={() => handleCheckboxClick(id)}
                  />
                  <span className={styles.meta}>
                    <span className={styles.email}>{email}</span>
                    <span className={styles.location}>
                      {locations.map((l) => l.name).join(' ')}
                    </span>
                    <span className={styles.organization}>
                      {locations
                        .map((l) => l.organization.short_name)
                        .join(' ')}
                    </span>
                    <span className={styles.role}>
                      {t2(`systemrole.${sr[role]}`)}
                    </span>
                  </span>
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.selectedCount}>
          <Trans
            components={{ b: <b /> }}
            i18nKey="selected"
            t={t}
            values={{ count: selected.length }}
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            className={styles.submit}
            disabled={disabled}
            loading={declining}
            onClick={handleDeclineClick}
            type="button"
          >
            {t('decline')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.approveContainer}
            disabled={disabled}
            loading={approving}
            onClick={handleApproveClick}
            type="button"
          >
            {t('approve')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Requests;
