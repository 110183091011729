import { HTMLProps, MouseEvent, useId, useMemo } from 'react';
import { Transition } from 'react-spring';
import clsx from 'clsx';
import styles from './checkbox.module.css';
import { SvgChecked, SvgUnchecked } from '../svg';
import { noop } from '../../utils/helper';

type Size = 'original' | 'big';

export type CheckboxProps = {
  borderColor?: 'black' | 'white';
  checked: boolean;
  className?: string;
  labelClass?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent<SVGSVGElement | HTMLLabelElement>) => void;
  size?: Size;
  label?: string;
};

function Checkbox(props: HTMLProps<HTMLDivElement> & CheckboxProps) {
  const {
    checked,
    borderColor,
    disabled = false,
    size = 'original',
    className,
    labelClass,
    onClick = noop,
    label,
    ...rest
  } = props;

  const id = useId();

  const { checkedSize, uncheckedSize, unchecked } = useMemo(() => {
    switch (size as Size) {
      case 'big':
        return {
          checkedSize: [30, 24],
          uncheckedSize: [24, 24],
          unchecked: styles.uncheckedBig,
        };
      case 'original':
      default:
        return {
          checkedSize: [27, 20],
          uncheckedSize: [20, 20],
          unchecked: styles.unchecked,
        };
    }
  }, [size]);

  return (
    <div
      className={clsx([
        'checkbox', // used for some wrappers like "filter-wrapper"
        styles.checkbox,
        className,
        { [styles.checked]: checked, [styles.disabled]: disabled },
      ])}
      {...rest}
    >
      <div style={{ width: checkedSize[0] }} />
      <Transition
        config={{ duration: 100 }}
        items={checked}
        from={{
          opacity: 0,
          transform: 'scale(0.5)',
          // transformOrigin: '50% 80%',
        }}
        enter={{
          opacity: 1,
          transform: 'scale(1)',
        }}
        leave={{
          opacity: 0,
          transform: 'scale(0.5)',
          position: 'absolute',
        }}
      >
        {(style, item) =>
          item ? (
            <SvgChecked
              style={style}
              width={checkedSize[0]}
              height={checkedSize[1]}
              disabled={disabled}
              onClick={onClick}
              id={id}
            />
          ) : (
            <SvgUnchecked
              style={style}
              width={uncheckedSize[0]}
              height={uncheckedSize[1]}
              className={clsx([
                unchecked,
                { [styles[borderColor]]: borderColor },
              ])}
              disabled={disabled}
              onClick={onClick}
              id={id}
            />
          )
        }
      </Transition>
      {label && (
        <label
          htmlFor={id}
          className={clsx(styles.label, labelClass, {
            [styles.disabled]: disabled,
          })}
          onClick={onClick}
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default Checkbox;
