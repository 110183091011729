import { useSelector } from 'react-redux';

import { Accordion } from '../../../../../components/form';
import {
  filtersSelectedSelector,
  filtersSelector,
} from '../../../../../selectors/filters';
import Filter from './filter';
import styles from './index.module.css';

type SavedFiltersProps = {
  label: string;
};

function SavedFilters(props: SavedFiltersProps) {
  const { label } = props;

  const filters = useSelector(filtersSelector);
  const open = useSelector(filtersSelectedSelector);

  return (
    <Accordion initiallyOpen={!!open} title={label}>
      <div className={styles.filters}>
        {filters.map((f) => (
          <Filter filter={f} key={f.id} />
        ))}
      </div>
    </Accordion>
  );
}

export default SavedFilters;
