import { RefAttributes } from 'react';
import { animated } from 'react-spring';

type SvgCheckedProps = {
  disabled?: boolean;
};

function SvgChecked(
  props: React.SVGProps<SVGSVGElement> &
    RefAttributes<SVGSVGElement> &
    SvgCheckedProps
) {
  const { disabled = false, ...rest } = props;

  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 34.688 26"
      {...rest}
    >
      <path d="M0 0h26v26H0z" fill={disabled ? '#CCCCCC' : '#d7512f'} />
      <path
        d="M3.998 12.331L13.068 21l20.93-20"
        fill="none"
        stroke={disabled ? '#999999' : '#fff'}
        strokeWidth={2}
      />
    </animated.svg>
  );
}

export default SvgChecked;
