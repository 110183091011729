import clsx from 'clsx';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { resetValidation } from '../../actions/validations';
import { noop, truncateSentenceEnd } from '../../utils/helper';
import { SvgSelectArrow } from '../svg';
import styles from './categorized-multi-select.module.css';
import Checkbox from './checkbox';

const MAX_LENGTH = 30;

type CategorizedMultiSelectProps = {
  dashed?: boolean;
  disabled?: boolean;
  info?: string;
  initialOptions?: {
    indent: number;
    value: number[] | string[];
  }[];
  initialSelected?: number[];
  name?: string;
  onBlur?: () => void;
  options?: any[];
  placeholder?: string;
  selected?: any;
  setSelected: (selected: any) => void;
  validation?: Validation[];
  validationKey?: string;
  zIndex?: number;
};

const CategorizedMultiSelect = React.forwardRef(
  (props: CategorizedMultiSelectProps, _) => {
    const {
      dashed = true,
      disabled = false,
      info = '',
      initialOptions = [],
      initialSelected = [],
      name = null,
      onBlur = noop,
      options,
      selected = null,
      setSelected,
      validation = [],
      validationKey = null,
      zIndex = 0,
      ...rest
    } = props;

    const dispatch = useDispatch();

    const ref = useRef(null);

    const optionsRef = useRef(null);

    const [expanded, setExpand] = useState(false);

    const filteredOptions = useMemo(() => {
      const ops = [];

      for (const o of options) {
        ops.push({
          indent: 0,
          index: ops.length,
          label: o.fields.short_name,
          value: o.pk,
        });

        for (const country of o.fields.countries) {
          ops.push({
            indent: 1,
            index: ops.length,
            label: country.name,
            value: country.name,
          });

          for (const city of country.cities) {
            ops.push({
              indent: 2,
              index: ops.length,
              label: city.name,
              value: city.name,
            });

            for (const location of city.locations) {
              ops.push({
                indent: 3,
                index: ops.length,
                label: `${
                  location.fields.organization.short_name
                } ${truncateSentenceEnd(location.fields.legal_address)}`,
                value: location.pk,
              });
            }
          }
        }
      }

      return ops;
    }, [options]);

    const [cursor, setCursor] = useState(-1);

    const val: Validation = useMemo(() => {
      if (validation.length === 0) {
        return { field: '', message: '' };
      }

      const found = validation.find((v) => v.field === name);

      return found || { field: '', message: '' };
    }, [name, validation]);

    const [arrowAnim, setArrow] = useSpring(() => ({
      transform: 'rotateX(0deg)',
    }));

    const [inputAnim, setInput] = useSpring(() => ({
      height: '33px',
    }));

    const optionsAnim = useSpring({
      config: { duration: 200 },
      from: {
        display: expanded ? 'none' : 'block',
        opacity: expanded ? 0 : 1,
      },
      immediate: !optionsRef.current,
      to: async (next) => {
        if (expanded) {
          await next({ display: 'block' });
          await next({ opacity: 1 });
        } else {
          await next({ opacity: 0 });
          await next({ display: 'none' });
        }
      },
    });

    const parentsToBeRemoved = (
      noParentCleanYet,
      mainIndex,
      indent,
      toBeRemoved = []
    ) => {
      if (mainIndex > 0) {
        let prevIndent = filteredOptions[mainIndex - 1].indent;
        let i = mainIndex - 1;
        let exist = false;

        while (prevIndent >= indent && i > 0) {
          const curr = filteredOptions[i];
          exist =
            noParentCleanYet.some((s) => shallowEqual(s, curr)) &&
            !toBeRemoved.includes(i);

          if (exist) break;

          i -= 1;

          prevIndent = filteredOptions[i]?.indent;
        }

        if (!exist) {
          if (mainIndex < filteredOptions.length - 1) {
            let nextIndent = filteredOptions[mainIndex + 1].indent;
            let j = mainIndex + 1;

            while (nextIndent >= indent && j < filteredOptions.length) {
              const curr = filteredOptions[j];
              exist =
                noParentCleanYet.some((s) => shallowEqual(s, curr)) &&
                !toBeRemoved.includes(j);

              if (exist) break;

              j += 1;

              nextIndent = filteredOptions[j]?.indent;
            }
          }

          if (!exist) {
            if (
              noParentCleanYet.some((s) => shallowEqual(s, filteredOptions[i]))
            ) {
              toBeRemoved = [i, ...toBeRemoved]; // eslint-disable-line no-param-reassign

              toBeRemoved = parentsToBeRemoved(
                noParentCleanYet,
                i,
                filteredOptions[i].indent,
                toBeRemoved
              ); /* eslint-disable-line max-len, no-param-reassign */

              return toBeRemoved;
            }
          }

          return toBeRemoved;
        }
      }

      return toBeRemoved;
    };

    const handleSelect = useCallback(
      (option) => {
        const index = selected.findIndex(
          (l) =>
            l.indent === option.indent &&
            l.value === option.value &&
            l.label === option.label &&
            l.index === option.index // eslint-disable-line max-len
        );

        if (index === -1) {
          // Selected location absent

          const mainIndex = filteredOptions.findIndex(
            (fo) =>
              fo.indent === option.indent &&
              fo.value === option.value &&
              fo.label === option.label &&
              fo.index === option.index // eslint-disable-line max-len
          );

          // Check option and its children
          const parentAndChildren = [option];
          let nextIndent =
            filteredOptions.length > mainIndex + 1
              ? filteredOptions[mainIndex + 1].indent
              : 0;

          let i = mainIndex + 1;
          while (nextIndent > option.indent && i < filteredOptions.length) {
            const curr = filteredOptions[i];
            const exist = selected.some((pac) => shallowEqual(pac, curr));

            if (!exist) {
              parentAndChildren.push(filteredOptions[i]);
            }

            i += 1;

            nextIndent = filteredOptions[i]?.indent;
          }

          setSelected([...selected, ...parentAndChildren]);
        } else {
          // Selected location already exist

          const mainIndex = filteredOptions.findIndex(
            (fo) =>
              fo.indent === option.indent &&
              fo.value === option.value &&
              fo.label === option.label &&
              fo.index === option.index // eslint-disable-line max-len
          );

          // Check option and its children
          const parentAndChildren = [mainIndex];
          let nextIndent =
            filteredOptions.length > mainIndex + 1
              ? filteredOptions[mainIndex + 1].indent
              : 0;

          let i = mainIndex + 1;
          while (nextIndent > option.indent && i < filteredOptions.length) {
            parentAndChildren.push(i);

            i += 1;

            nextIndent = filteredOptions[i]?.indent;
          }

          const noParentCleanYet = selected.filter(
            (s) => !parentAndChildren.includes(s.index)
          );

          // Clean parents:

          const toBeRemoved = parentsToBeRemoved(
            noParentCleanYet,
            mainIndex,
            option.indent,
            [mainIndex]
          ); // eslint-disable-line max-len

          setSelected(
            noParentCleanYet.filter((s) => !toBeRemoved.includes(s.index))
          );
        }
      },
      [filteredOptions, selected, setSelected] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Hacky solution for setting initial values/options.
    useEffect(
      () => {
        if (initialOptions.length > 0) {
          const initialOptionsWithLabel = [];

          for (let j = 0; j < initialOptions.length; j += 1) {
            const { indent, value } = initialOptions[j];

            for (let i = 0; i < filteredOptions.length; i += 1) {
              if (
                value === filteredOptions[i].value &&
                indent === filteredOptions[i].indent
              ) {
                initialOptionsWithLabel.push(filteredOptions[i]);
              }
            }
          }

          initialOptionsWithLabel.forEach((option) => {
            handleSelect(option);
          });
        }
      },
      [initialOptions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
      if (initialSelected.length > 0) {
        const initialSelectedOptions = filteredOptions.filter((option) =>
          initialSelected.includes(option.value)
        );

        setSelected(initialSelectedOptions);
      }
    }, [initialSelected, filteredOptions, setSelected]);

    useEffect(() => {
      if (expanded) {
        const max = (filteredOptions.length + 1) * 33 + 2;
        const dynamicHeight = 7 * 33 + 2;
        const height = max > dynamicHeight ? dynamicHeight : max;

        setInput({
          config: { duration: 80 },
          to: async (next) => {
            await next({ height: `${height + 4}px` });
            await next({ height: `${height}px` });
          },
        });
      } else {
        setInput({
          config: { duration: 80 },
          to: async (next) => {
            await next({ height: '29px' });
            await next({ height: '33px' });
          },
        });
      }

      setCursor(-1);
    }, [expanded, filteredOptions, setInput]);

    const handleClick = useCallback(() => {
      if (expanded) {
        setArrow({ transform: 'rotateX(0deg)' });
      } else {
        setArrow({ transform: 'rotateX(180deg)' });
      }

      setExpand(!expanded);
    }, [expanded, setArrow]);

    const handleBlur = useCallback(() => {
      setTimeout(() => {
        if (!optionsRef.current.contains(document.activeElement)) {
          setExpand(false);
        }
        setArrow({ transform: 'rotateX(0deg)' });
        onBlur();
      }, 1);
    }, [onBlur, setArrow]);

    const handleOptionClick = useCallback(
      (e, option) => {
        handleSelect(option);
        setExpand(true);

        if (validationKey) {
          dispatch(resetValidation(validationKey));
        }
      },
      [dispatch, handleSelect, validationKey]
    );

    const handleInput = useCallback(() => {
      const { textContent: value } = ref.current;

      if (selected) {
        setSelected(null);

        if (value.length > 0) {
          ref.current.textContent = value.slice(0, MAX_LENGTH);

          const range = document.createRange();
          const sel = window.getSelection();

          range.setStart(ref.current, 1);
          range.collapse(true);

          sel.removeAllRanges();
          sel.addRange(range);
        }
      }

      if (value.length >= MAX_LENGTH) {
        ref.current.textContent = value.slice(0, MAX_LENGTH);

        const range = document.createRange();
        const sel = window.getSelection();

        range.setStart(ref.current, 1);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }
    }, [selected, setSelected]);

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        switch (e.key) {
          case 'Escape':
            (e.target as HTMLDivElement).blur();
            break;
          case 'Enter':
            e.preventDefault();

            if (cursor > -1) {
              handleSelect(filteredOptions[cursor]);
            }

            break;
          case 'ArrowUp': {
            e.preventDefault();

            if (cursor > 0) {
              const next = cursor - 1;
              setCursor(next);
            }
            break;
          }
          case 'ArrowDown': {
            const lastIndex = filteredOptions.length - 1;

            if (cursor < lastIndex) {
              const next = cursor + 1;
              setCursor(next);
            }

            break;
          }
          default:
        }
      },
      [cursor, filteredOptions, handleSelect]
    );

    return (
      <>
        <div className={styles.filler}>
          <div
            className={clsx([styles.select, { [styles.expanded]: expanded }])}
            style={{ zIndex }}
          >
            <SvgSelectArrow
              className={styles.arrow}
              height={8}
              style={arrowAnim as any}
              width={16}
            />
            <animated.div
              className={clsx([
                styles.input,
                {
                  [styles.dashed]: dashed,
                  [styles.disabled]: disabled,
                  [styles.invalid]: !!val.message,
                },
              ])}
              onBlur={handleBlur}
              onClick={handleClick}
              ref={ref}
              role="textbox"
              tabIndex={0}
              {...rest}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              style={inputAnim}
            >
              {selected
                .filter((s) => s.indent === 3)
                .map((s) => s.label)
                .join(', ')}
            </animated.div>
            <animated.ul
              className={styles.options}
              onBlur={() => {
                setTimeout(() => {
                  if (!ref.current.contains(document.activeElement)) {
                    setExpand(false);
                  }
                }, 1);
              }}
              ref={optionsRef}
              style={optionsAnim}
              tabIndex={0}
            >
              {filteredOptions.map((option, i) => {
                const { indent, index, label, value } = option;

                return (
                  <li
                    className={clsx([
                      styles.option,
                      {
                        [styles.active]: cursor === i,
                        [styles.odd]: i % 2 === 1,
                        [styles.selected]: selected?.value === value,
                      },
                    ])}
                    key={i}
                    onMouseDown={(e) => handleOptionClick(e, option)}
                    onMouseEnter={() => setCursor(i)}
                    style={{ paddingLeft: 20 * (indent + 1) }}
                  >
                    <div className={styles.checkbox}>
                      <Checkbox
                        borderColor="black"
                        checked={selected.some(
                          (s) =>
                            s.value === value &&
                            s.indent === indent &&
                            s.label === label &&
                            s.index === index
                        )}
                      />
                    </div>
                    <div className={styles.label}>{label}</div>
                  </li>
                );
              })}
            </animated.ul>
          </div>
        </div>
        {!!val.message ? (
          <div className={styles.validation}>{val.message}</div>
        ) : (
          info && <div className={styles.info}>{info}</div>
        )}
      </>
    );
  }
);

export default CategorizedMultiSelect;
