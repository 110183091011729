import { HIDE_TOAST, RESET_TOKEN, SHOW_TOAST } from '../constants/action-types';

export const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST: {
      const { id, level, message, reloadable, timeout } = action;

      return {
        id,
        level,
        message,
        reloadable,
        timeout,
      };
    }
    case HIDE_TOAST:
      return null;
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
