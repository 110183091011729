import styles from './footer.module.css';

type FooterProps = {
  children: JSX.Element | JSX.Element[];
};

function Footer(props: FooterProps) {
  const { children } = props;

  return <footer className={styles.footer}>{children}</footer>;
}

export default Footer;
