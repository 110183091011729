import { lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import pjson from '../../../package.json';
import { setAppVersion } from '../../actions/app';
import { resetToken } from '../../actions/auth';
import { appVersionSelector } from '../../selectors/app';
import { visitorJourneyAccessSelector } from '../../selectors/users';
import storeRegistry from '../../store/store-registry';
import InsightsForwarder from '../forwarder/insights';
import ProfileForwarder from '../forwarder/profile';
import SettingsForwarder from '../forwarder/settings';
import Organization from '../routes/organization';
import ToastContainer from '../toast-container';
import ErrorBoundary from './error-boundary';
import Private from './private';

const Dashboard = lazy(() => import('../routes/dashboard'));
const FourOFour = lazy(() => import('../routes/four-o-four'));
const ImportActivity = lazy(() => import('../routes/import-activity'));
const Insights = lazy(() => import('../routes/insights'));
const Locations = lazy(() => import('../routes/locations'));
const Login = lazy(() => import('../routes/login'));
const PeopleCounter = lazy(() => import('../routes/people-counters'));
const PersonalInformation = lazy(
  () => import('../routes/personal-information')
);
const PrivacySettings = lazy(() => import('../routes/privacy-settings'));
const ResetPassword = lazy(() => import('../routes/reset-password'));
const ResetRequest = lazy(() => import('../routes/reset-request'));
const Users = lazy(() => import('../routes/users'));
const VisitorJourney = lazy(() => import('../routes/visitor-journey'));

function App() {
  const dispatch = useDispatch();

  const appVersion = useSelector(appVersionSelector);
  const accessVisitorJourney = useSelector(visitorJourneyAccessSelector);

  if (appVersion !== pjson.version) {
    storeRegistry.persistor.purge().then(() => {
      dispatch(setAppVersion(pjson.version));
      dispatch(resetToken());
    });

    return null;
  }

  return (
    <ErrorBoundary>
      <ToastContainer />
      <Routes>
        <Route element={<Login />} path="login" />
        <Route path="reset-password">
          <Route element={<ResetRequest />} index />
          <Route element={<ResetPassword />} path=":pre/:post" />
        </Route>
        <Route element={<Private />} path="/">
          <Route element={<Insights />} path="/">
            <Route element={<InsightsForwarder />} index />
            <Route element={<Dashboard />} path="dashboard" />
            {accessVisitorJourney && (
              <Route element={<VisitorJourney />} path="visitor-journey" />
            )}
          </Route>
          <Route path="settings">
            <Route element={<SettingsForwarder />} index />
            <Route element={<PeopleCounter />} path="people-counters" />
            <Route
              element={<PeopleCounter />}
              path="people-counters/:limit/:offset"
            />
            <Route element={<Locations />} path="locations" />
            <Route element={<Locations />} path="locations/:limit/:offset" />
            <Route element={<Organization />} path="organizations" />
            <Route element={<Users />} path="users" />
            <Route element={<Users />} path="users/:limit/:offset" />
            <Route path="data-import">
              <Route element={<ImportActivity />} index />
            </Route>
          </Route>
          <Route path="profile">
            <Route element={<ProfileForwarder />} index />
            <Route
              element={<PersonalInformation />}
              path="personal-information"
            />
            <Route element={<PrivacySettings />} path="privacy-settings" />
          </Route>
        </Route>
        <Route element={<FourOFour />} path="*" />
      </Routes>
    </ErrorBoundary>
  );
}

export default App;
