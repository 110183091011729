import { put, retry, takeLatest } from 'redux-saga/effects';

import {
  listPermissionsFailure,
  listPermissionsSuccess,
} from '../actions/permissions';
import { Permissions } from '../api';
import { LIST_PERMISSIONS_REQUEST } from '../constants/action-types';

export function* listPermissions() {
  try {
    const { body: raw } = yield retry(3, 5000, Permissions.List);

    const permissions = new Map();
    raw.forEach((permission) => {
      const {
        fields: { codename },
        pk,
      } = permission;

      permissions.set(codename, pk);
    });

    yield put(listPermissionsSuccess(permissions));
  } catch (e) {
    yield put(listPermissionsFailure(e));
  }
}

export function* watchPermissions() {
  yield takeLatest(LIST_PERMISSIONS_REQUEST, listPermissions);
}
