import dayjs from 'dayjs';
import i18next from 'i18next';
import { createSelector } from 'reselect';

import { TABLE_SEPARATOR } from '../constants/components';
import iso31661Alpha2 from '../constants/iso3166-1.alpha2';
import { ADMIN, SUPERUSER, VIEWER, roleIds } from '../constants/roles';
import { systemRoles } from '../constants/system-role';
import { compareValues } from '../utils/helper';
import { userSelector as authUserSelector } from './auth';

const i18n = i18next.cloneInstance({
  defaultNS: 'profile',
});

export const locationTreeSelector = (state) => state.users.locationTree;

export const selectedUsersSelector = (state) => state.users.selected;

export const userCountSelector = (state) => state.users.count;

export const creatingUserSelector = (state) => state.users.creating;

export const usersLastDeletedAtSelector = (state) => state.users.lastDeletedAt;

export const userLocations = (state) => state.users.user.locations;

export const organizationValSelector = createSelector(
  [locationTreeSelector],
  (organizations) =>
    organizations
      .map((organization) => {
        const { fields: f, pk } = organization;

        return {
          label: f.short_name,
          value: pk,
        };
      })
      .sort(compareValues('label', 'asc'))
);

export const roleListSelector = createSelector(
  [locationTreeSelector],
  (organizations) =>
    organizations.reduce((acc, organization) => {
      const roles = new Set();
      const accessDates = new Set();
      const countries = new Set();
      const cities = new Set();

      const locations = [];
      organization.fields.countries.forEach((country) => {
        country.cities.forEach((city) => {
          city.locations.forEach((location) => {
            const {
              fields: { expires_at: expiresAt, group, name: locationName },
              pk: lid,
            } = location;

            const access =
              expiresAt === 'None'
                ? i18n.t('profileSettings.forever')
                : dayjs(new Date(expiresAt)).format('L');
            accessDates.add(access);

            roles.add(roleIds[group]);

            const locationCountry = iso31661Alpha2[location.fields.country];

            if (locationCountry) {
              countries.add(locationCountry);
            }

            cities.add(location.fields.city);

            locations.push({
              access,
              city: location.fields.city,
              country: locationCountry,
              id: lid,
              name: locationName,
              role: roleIds[group],
            });
          });
        });
      });

      acc.push({
        accessDates: Array.from(accessDates).join(TABLE_SEPARATOR),
        cities: Array.from(cities).join(TABLE_SEPARATOR),
        countries: Array.from(countries).join(TABLE_SEPARATOR),
        id: organization.pk,
        locations,
        name: organization.fields.short_name,
        roles: Array.from(roles).join(TABLE_SEPARATOR),
      });

      return acc;
    }, [])
);

export const fetchingLocationTreeSelector = (state) =>
  state.users.fetchingLocationTree;

export const patchingInfoSelector = (state) => state.users.patchingInfo;

export const patchingSelector = (state) => state.users.patching;

export const removingSelector = (state) => state.users.removing;

export const userSelector = (state) => state.users.user;

export const gettingUserSelector = (state) => state.users.gettingUser;

export const deletingUserSelector = (state) => state.users.deletingUser;

export const locationsSelector = (state) => state.users.user.locations;

export const userRoleSelector = createSelector([userSelector], (user) => {
  if (user.is_superuser) {
    return SUPERUSER;
  }

  const id =
    user.accesses && user.accesses.length > 0 ? user.accesses[0].group_name : 0;
  if (id === 0) return VIEWER;
  if (id === 1) return ADMIN;
});

export const usersSelector = (state) => state.users.users;

export const listingUsersSelector = (state) => state.users.listing;

export const activeUsersSelector = createSelector(usersSelector, (users) =>
  users.filter((user) => user.is_active)
);

export const invitedUsersSelector = createSelector(usersSelector, (users) =>
  users.filter((user) => !user.is_active)
);

export const activatingInvitedUserSelector = (state) =>
  state.users.activatingInvitedUser;

export const invitingUserSelector = (state) => state.users.invitingUser;

export const gettingInviteesSelector = (state) => state.users.gettingInvitees;

export const inviteesSelector = (state) => state.users.invitees;

export const coWorkerInviteesSelector = createSelector(
  inviteesSelector,
  (invitees) =>
    invitees.filter((invitee) => invitee.system_role === systemRoles.coWorker)
);

export const partnerInviteesSelector = createSelector(
  inviteesSelector,
  (invitees) =>
    invitees.filter((invitee) => invitee.system_role === systemRoles.partner)
);

export const sendingInvitationSelector = (state) =>
  state.users.sendingInvitation;

export const decliningInvitationSelector = (state) =>
  state.users.decliningInvitation;

export const updatingUsersSelector = (state) => state.users.updating;

export const userByIdSelector = createSelector(
  [usersSelector, (_, id) => id],
  (users, id) => {
    return users.find((u) => u.id === id) || {};
  }
);

export const accessToLocationIdsSelector = createSelector(
  [locationTreeSelector, (_, accesses) => accesses],
  (locationTree, accesses) => {
    if (!accesses) return [];

    const locationIds = new Set<number>();

    accesses.forEach((access) => {
      if (!access.location && access.organization) {
        // Add all locations of the organization to locationIds
        for (let i = 0; i < locationTree.length; i++) {
          const organization = locationTree[i];
          organization.fields.countries.forEach((country) => {
            country.cities.forEach((city) => {
              city.locations.forEach((location) => {
                locationIds.add(location.pk as number);
              });
            });
          });
        }
      }
      if (!access.organization && access.location) {
        locationIds.add(access.location.id as number);
      }
    });

    return Array.from(locationIds);
  }
);

export const visitorJourneyAccessSelector = createSelector(
  [authUserSelector, userRoleSelector],
  (user, role) => {
    const { locations } = user;

    if (role === SUPERUSER) {
      return true;
    }

    return locations
      ? locations.some((location) => location.customer_journey)
      : [];
  }
);
