import clsx from 'clsx';
import {
  FocusEvent,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  RefAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { resetValidation } from '../../actions/validations';
import { PopupScrollContext } from '../../contexts';
import { noop } from '../../utils/helper';
import { CircleSpinner } from '../loader';
import { SvgSelectArrow } from '../svg';
import styles from './select.module.css';

const MAX_LENGTH = 30;

type SelectProps = HTMLAttributes<HTMLDivElement> &
  RefAttributes<HTMLDivElement> & {
    blackDisabled?: boolean;
    contentEditable?: boolean;
    dashed?: boolean;
    disabled?: boolean;
    info?: string;
    loading?: boolean;
    name?: string;
    onBlur?: (e?: FocusEvent<HTMLDivElement>) => void;
    options: Option[];
    selected?: Option | null;
    setSelected: (option: Option) => void;
    validation?: Validation[];
    validationKey?: string;
    zIndex?: number;
  };

function Select(props: SelectProps) {
  const {
    blackDisabled = false,
    contentEditable = true,
    dashed = true,
    disabled = false,
    info = '',
    loading = false,
    name = null,
    onBlur = noop,
    options,
    selected = null,
    setSelected,
    validation = [],
    validationKey = null,
    zIndex = 0,
    ...rest
  } = props;

  const dispatch = useDispatch();

  const ref = useRef(null);

  const init = useRef(false);

  const optionsRef = useRef();

  const scrollTo = useContext(PopupScrollContext);

  const [expanded, setExpand] = useState(false);

  const [filter, setFilter] = useState('');

  const [cursor, setCursor] = useState(-1);

  const val: Validation = useMemo(() => {
    if (validation.length === 0) {
      return { field: '', message: '' };
    }

    const found = validation.find((v) => v.field === name);

    return found || { field: '', message: '' };
  }, [name, validation]);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) => {
        if (filter) {
          const label = option.label.toLowerCase();

          return label.includes(filter);
        }
        return true;
      }),
    [filter, options]
  );

  const [arrowAnim, setArrow] = useSpring(() => ({
    transform: 'rotateX(0deg)',
  }));

  const [inputAnim, setInput] = useSpring(() => ({
    height: '33px',
  }));

  const optionsAnim = useSpring({
    config: { duration: 200 },
    from: {
      display: expanded ? 'none' : 'block',
      opacity: expanded ? 0 : 1,
    },
    immediate: !optionsRef.current,
    to: async (next) => {
      if (expanded) {
        await next({ display: 'block' });
        await next({ opacity: 1 });
      } else {
        await next({ opacity: 0 });
        await next({ display: 'none' });
      }
    },
  });

  useEffect(() => {
    switch (true) {
      case expanded: {
        // 2: Border width
        // 33: Input height
        const height = (filteredOptions.length + 1) * 33 + 2;

        setInput({
          config: { duration: 80 },
          to: async (next) => {
            await next({ height: `${height + 4}px` });
            await next({ height: `${height}px` });
          },
        });
        break;
      }
      case init.current:
        setInput({
          config: { duration: 80 },
          to: async (next) => {
            await next({ height: '29px' });
            await next({ height: '33px' });
          },
        });
        break;
      default: {
        init.current = true;
      }
    }

    setCursor(-1);
  }, [expanded, filteredOptions.length, setInput]);

  const handleFocus = useCallback(() => {
    if (contentEditable) {
      setArrow({ transform: 'rotateX(180deg)' });

      setExpand(true);
    }
  }, [setArrow, contentEditable]);

  const handleClick = useCallback(() => {
    if (!contentEditable) {
      if (expanded) {
        setArrow({ transform: 'rotateX(0deg)' });
      } else {
        setArrow({ transform: 'rotateX(180deg)' });
      }

      setExpand(!expanded);
    }
  }, [expanded, setArrow, contentEditable]);

  const handleBlur = useCallback(() => {
    setExpand(false);
    setArrow({ transform: 'rotateX(0deg)' });
    onBlur();
  }, [onBlur, setArrow]);

  const handleOptionClick = useCallback(
    (e: MouseEvent<HTMLLIElement>, option: Option) => {
      setSelected(option);
      setFilter('');

      if (validationKey) {
        dispatch(resetValidation(validationKey));
      }

      (e.target as HTMLLinkElement).blur();
    },
    [dispatch, setSelected, validationKey]
  );

  const handleInput = useCallback(() => {
    const { textContent: value } = ref.current;

    if (selected) {
      setSelected(null);

      if (value.length > 0) {
        ref.current.textContent = value.slice(0, MAX_LENGTH);

        const range = document.createRange();
        const sel = window.getSelection();

        range.setStart(ref.current, 1);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }
    } else {
      setFilter(value.toLowerCase().trim());
    }

    if (value.length >= MAX_LENGTH) {
      ref.current.textContent = value.slice(0, MAX_LENGTH);

      const range = document.createRange();
      const sel = window.getSelection();

      range.setStart(ref.current, 1);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    }

    if (validationKey) {
      dispatch(resetValidation(validationKey));
    }
  }, [dispatch, selected, setSelected, validationKey]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'Escape':
          (e.target as HTMLDivElement).blur();
          break;
        case 'Enter':
          e.preventDefault();

          if (cursor > -1) {
            setSelected(filteredOptions[cursor]);
          }
          setFilter('');

          (e.target as HTMLDivElement).blur();

          break;
        case 'ArrowUp': {
          e.preventDefault();

          if (cursor > 0) {
            const next = cursor - 1;
            setCursor(next);
          }
          break;
        }
        case 'ArrowDown': {
          const lastIndex = filteredOptions.length - 1;

          if (cursor < lastIndex) {
            const next = cursor + 1;
            setCursor(next);
          }

          break;
        }
        default:
      }
    },
    [cursor, filteredOptions, setSelected]
  );

  useEffect(() => {
    if (val.field === name && scrollTo) {
      // TODO: Remove commented lines if not used for 2 months (2022-10-22)
      // const input = document.querySelector(`input[name='${val.field}']`);
      // if (input) {
      //   scrollTo(0, input.getBoundingClientRect().top);
      // }

      if (ref.current) {
        scrollTo(0, ref.current.getBoundingClientRect().top);
        ref.current.focus({ preventScroll: true });
      }
    }
  }, [val.field]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className={styles.filler}>
        <div
          className={clsx([
            styles.select,
            { [styles.disabled]: disabled, [styles.expanded]: expanded },
          ])}
          style={{ zIndex }}
        >
          {loading ? (
            <div className={styles.spinnerContainer}>
              <CircleSpinner className={styles.spinner} r={8} strokeWidth={3} />
            </div>
          ) : (
            <SvgSelectArrow
              className={styles.arrow}
              height={8}
              style={{ ...arrowAnim, zIndex } as unknown}
              width={16}
            />
          )}
          <animated.div
            className={clsx([
              styles.input,
              {
                [styles.blackDisabled]: blackDisabled,
                [styles.dashed]: dashed,
                [styles.disabled]: disabled,
                [styles.invalid]: !!val.message,
              },
            ])}
            contentEditable={contentEditable}
            onBlur={handleBlur}
            onClick={handleClick}
            onFocus={handleFocus}
            ref={ref}
            role="textbox"
            suppressContentEditableWarning
            tabIndex={0}
            {...rest}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            style={inputAnim}
          >
            {selected?.label}
          </animated.div>
          <animated.ul className={styles.options} style={optionsAnim}>
            {filteredOptions.map((option, i) => {
              const { children, label, value } = option;

              // const childrenWithProps = React.Children.map(
              //   children,
              //   (child: React.ReactElement<{ id: number | string }>) => {
              //     if (React.isValidElement(child)) {
              //       return React.cloneElement(child, { id: value });
              //     }
              //     return child;
              //   }
              // );

              return (
                <li
                  className={clsx([
                    styles.option,
                    {
                      [styles.active]: cursor === i,
                      [styles.odd]: i % 2 === 1,
                      [styles.selected]: selected?.value === value,
                    },
                  ])}
                  key={value}
                  onMouseDown={(e) => handleOptionClick(e, option)}
                  onMouseEnter={() => setCursor(i)}
                  ref={optionsRef}
                  title={label}
                >
                  <div className={styles.label}>{label}</div>
                  {children}
                </li>
              );
            })}
          </animated.ul>
        </div>
      </div>
      {!!val.message ? (
        <div className={styles.validation}>{val.message}</div>
      ) : (
        info && <div className={styles.info}>{info}</div>
      )}
    </div>
  );
}

export default Select;
