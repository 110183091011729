import dayjs from 'dayjs';
import download from 'downloadjs';
import { toPng } from 'html-to-image';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  cameraLineMultilineFailure,
  cameraLineMultilineSuccess,
  exportChartAsImageFailure,
  exportChartAsImageRequest,
  lastEnteringsFailure,
  lastEnteringsSuccess,
  lineTypeMultilineFailure,
  lineTypeMultilineSuccess,
  locationMultilineFailure,
  locationMultilineSuccess,
  totalEnteringsFailure,
  totalEnteringsRequest,
  totalEnteringsSuccess,
  totalVisitorsFailure,
  totalVisitorsSuccess,
  totalVisitsFailure,
  totalVisitsPreviousFailure,
  totalVisitsPreviousRequest,
  totalVisitsPreviousSuccess,
  totalVisitsSuccess,
  zoneFromMultilineFailure,
  zoneFromMultilineSuccess,
  zoneToMultilineFailure,
  zoneToMultilineSuccess,
} from '../actions/insights';
import { Insights } from '../api';
import {
  CAMERA_LINE_MULTILINE_REQUEST,
  EXPORT_CHART_AS_IMAGE_REQUEST,
  LAST_ENTERINGS_REQUEST,
  LINE_TYPE_MULTILINE_REQUEST,
  LOCATION_MULTILINE_REQUEST,
  TOTAL_ENTERINGS_REQUEST,
  TOTAL_VISITORS_REQUEST,
  TOTAL_VISITS_PREVIOUS_REQUEST,
  TOTAL_VISITS_REQUEST,
  ZONE_FROM_MULTILINE_REQUEST,
  ZONE_TO_MULTILINE_REQUEST,
} from '../constants/action-types';
import lineTypes from '../constants/line-types';
import {
  selectedEndDateSelector,
  selectedPropertySelector,
  selectedStartDateSelector,
} from '../selectors/segmentation';
import {
  segmentationDateToPreviousRndDate,
  segmentationDateToRndDate,
  segmentationPropertyToAggregatedBy,
} from '../utils/helper';

export function* totalVisitsPrevious({
  params,
}: ReturnType<typeof totalVisitsPreviousRequest>) {
  try {
    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(totalVisitsPreviousSuccess(data));
  } catch (e) {
    yield put(totalVisitsPreviousFailure(e));
  }
}

export function* lastEnterings() {
  try {
    const { location, organization } = yield select(
      (state) => state.segmentation
    );

    const date = dayjs().tz('Europe/Stockholm', false).format('YYYY-MM-DD');

    const params = {
      aggregate_by: 'minute,hour,dayofmonth,month,year',
      chunk: '1',
      end_date: date,
      location: location?.value,
      organization: organization?.value,
      start_date: date,
      type: lineTypes.ENTRANCE.toString(),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    yield put(totalEnteringsRequest());

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(lastEnteringsSuccess(data));
  } catch (e) {
    yield put(lastEnteringsFailure(e));
  }
}

export function* totalEnterings() {
  try {
    const { location, organization } = yield select(
      (state) => state.segmentation
    );

    const date = dayjs().tz('Europe/Stockholm', false).format('YYYY-MM-DD');

    const params = {
      aggregate_by: 'line',
      end_date: date,
      location: location?.value,
      organization: organization?.value,
      start_date: date,
      type: lineTypes.ENTRANCE.toString(),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(totalEnteringsSuccess(data));
  } catch (e) {
    yield put(totalEnteringsFailure(e));
  }
}

export function* totalVisitors() {
  try {
    const { location, organization } = yield select(
      (state) => state.segmentation
    );

    const today = dayjs().tz('Europe/Stockholm', false).format('YYYY-MM-DD');
    const yesterday = dayjs()
      .tz('Europe/Stockholm', false)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');

    const params = {
      aggregate_by: 'line,minute,hour,dayofmonth,month,year',
      location: location?.value,
      organization: organization?.value,
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: entranceDataToday } = yield call(Insights.TotalVisits, {
      ...params,
      end_date: today,
      start_date: today,
      type: lineTypes.ENTRANCE.toString(),
    });
    const { body: exitDataToday } = yield call(Insights.TotalVisits, {
      ...params,
      end_date: today,
      start_date: today,
      type: lineTypes.EXIT.toString(),
    });

    const { body: entranceDataYesterday } = yield call(Insights.TotalVisits, {
      ...params,
      end_date: yesterday,
      start_date: yesterday,
      type: lineTypes.ENTRANCE.toString(),
    });
    const { body: exitDataYesterday } = yield call(Insights.TotalVisits, {
      ...params,
      end_date: yesterday,
      start_date: yesterday,
      type: lineTypes.EXIT.toString(),
    });

    const thisHour = parseInt(
      dayjs().tz('Europe/Stockholm', false).format('HH'),
      10
    );

    const thisMinute = parseInt(
      dayjs().tz('Europe/Stockholm', false).format('mm'),
      10
    );

    const entranceTodayTotal = entranceDataToday.reduce(
      (acc, curr) => acc + curr.count,
      0
    );

    const exitTodayTotal = exitDataToday.reduce(
      (acc, curr) => acc + curr.count,
      0
    );

    const entranceYesterdayIndex = entranceDataYesterday.findIndex(
      (d) => d.hour === thisHour && d.minute === thisMinute
    );
    const exitYesterdayIndex = exitDataYesterday.findIndex(
      (d) => d.hour === thisHour && d.minute === thisMinute
    );

    const entranceYesterdayTotal = entranceDataYesterday
      .slice(0, entranceYesterdayIndex)
      .reduce((acc, curr) => acc + curr.count, 0);

    const exitYesterdayTotal = exitDataToday
      .slice(0, exitYesterdayIndex)
      .reduce((acc, curr) => acc + curr.count, 0);

    const todayTotal = entranceTodayTotal - exitTodayTotal;
    const yesterdayTotal = entranceYesterdayTotal - exitYesterdayTotal;

    yield put(totalVisitorsSuccess(todayTotal, yesterdayTotal));
  } catch (e) {
    yield put(totalVisitorsFailure(e));
  }
}

export function* totalVisits() {
  try {
    const {
      date,
      floor,
      line,
      lineType,
      location,
      organization,
      zoneFrom,
      zoneTo,
    } = yield select((state) => state.segmentation);

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [prevStart, prevEnd] = segmentationDateToPreviousRndDate(
      date.value,
      startDate,
      endDate
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: aggregateBy,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      line_ids: line.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
      type: lineType.map(({ value }) => lineTypes[value].toString()).join(','),
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    yield put(
      totalVisitsPreviousRequest({
        ...params,
        end_date: prevEnd,
        start_date: prevStart,
      })
    );

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(
      totalVisitsSuccess(
        data,
        date,
        property,
        date?.value === 'custom' ? start : null,
        date?.value === 'custom' ? end : null,
        lineType,
        zoneTo,
        zoneFrom,
        organization,
        location,
        floor,
        line
      )
    );
  } catch (e) {
    yield put(totalVisitsFailure(e));
  }
}

export function* cameraLineMultiline() {
  try {
    const {
      date,
      floor,
      line,
      lineType,
      location,
      organization,
      zoneFrom,
      zoneTo,
    } = yield select((state) => state.segmentation);

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: `line,${aggregateBy}`,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      line_ids: line.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
      type: lineType.map(({ value }) => lineTypes[value]).join(','),
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(cameraLineMultilineSuccess(data));
  } catch (e) {
    yield put(cameraLineMultilineFailure(e));
  }
}

export function* lineTypeMultiline() {
  try {
    const { date, floor, lineType, location, organization, zoneFrom, zoneTo } =
      yield select((state) => state.segmentation);

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: `category,${aggregateBy}`,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
      type: lineType.map(({ value }) => lineTypes[value]).join(','),
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(lineTypeMultilineSuccess(data, date, property, start, end));
  } catch (e) {
    yield put(lineTypeMultilineFailure(e));
  }
}

export function* zoneToMultiline() {
  try {
    const { date, floor, location, organization, zoneFrom, zoneTo } =
      yield select((state) => state.segmentation);

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: `zone_to,${aggregateBy}`,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(zoneToMultilineSuccess(data));
  } catch (e) {
    yield put(zoneToMultilineFailure(e));
  }
}

export function* zoneFromMultiline() {
  try {
    const { date, floor, location, organization, zoneFrom, zoneTo } =
      yield select((state) => state.segmentation);

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: `zone_from,${aggregateBy}`,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
      zone_from: zoneFrom.map(({ value }) => value).join(','),
      zone_to: zoneTo.map(({ value }) => value).join(','),
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(zoneFromMultilineSuccess(data));
  } catch (e) {
    yield put(zoneFromMultilineFailure(e));
  }
}

export function* locationMultiline() {
  try {
    const { date, floor, location, organization } = yield select(
      (state) => state.segmentation
    );

    const property = yield select(selectedPropertySelector);
    const startDate = yield select(selectedStartDateSelector);
    const endDate = yield select(selectedEndDateSelector);

    const [aggregateBy, chunk] = segmentationPropertyToAggregatedBy(
      property.value
    );

    const [start, end] = segmentationDateToRndDate(
      date.value,
      startDate,
      endDate
    );

    const params = {
      aggregate_by: `location,${aggregateBy}`,
      chunk,
      end_date: end,
      floor: floor.map(({ value }) => value).join(','),
      location: location?.value,
      organization: organization?.value,
      start_date: start,
    };

    Object.keys(params).forEach((param) => {
      if (!params[param]) {
        delete params[param];
      }
    });

    const { body: data } = yield call(Insights.TotalVisits, params);

    yield put(locationMultilineSuccess(data));
  } catch (e) {
    yield put(locationMultilineFailure(e));
  }
}

function* exportChartAsImage({
  ref,
}: ReturnType<typeof exportChartAsImageRequest>) {
  try {
    const dataUrl = yield call(toPng, ref.current, { cacheBust: true });
    const downloadPromise = new Promise((resolve, reject) => {
      try {
        resolve(download(dataUrl, 'chart.png'));
      } catch (e) {
        reject(e);
      }
    });

    yield call(() => downloadPromise);
  } catch (e) {
    yield put(exportChartAsImageFailure(e));
  }
}

export function* watchInsights() {
  yield takeLatest(EXPORT_CHART_AS_IMAGE_REQUEST, exportChartAsImage);
  yield takeLatest(LOCATION_MULTILINE_REQUEST, locationMultiline);
  yield takeLatest(CAMERA_LINE_MULTILINE_REQUEST, cameraLineMultiline);
  yield takeLatest(LINE_TYPE_MULTILINE_REQUEST, lineTypeMultiline);
  yield takeLatest(LAST_ENTERINGS_REQUEST, lastEnterings);
  yield takeLatest(TOTAL_ENTERINGS_REQUEST, totalEnterings);
  yield takeLatest(TOTAL_VISITORS_REQUEST, totalVisitors);
  yield takeLatest(TOTAL_VISITS_PREVIOUS_REQUEST, totalVisitsPrevious);
  yield takeLatest(TOTAL_VISITS_REQUEST, totalVisits);
  yield takeLatest(ZONE_FROM_MULTILINE_REQUEST, zoneFromMultiline);
  yield takeLatest(ZONE_TO_MULTILINE_REQUEST, zoneToMultiline);
}
