import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { lastSettingsSelector } from '../../selectors/routes';

function SettingsForwarder() {
  const lastSettings = useSelector(lastSettingsSelector);

  return <Navigate to={lastSettings || '/settings/organizations'} />;
}

export default SettingsForwarder;
