function SvgUndo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21.174}
      height={19.12}
      viewBox="0 0 21.174 19.12"
      {...props}
    >
      <g fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
        <path d="M.707 1.702v5.965h5.965" />
        <path d="M3.207 12.639A8.948 8.948 0 1 0 5.32 3.333L.707 7.668" />
      </g>
    </svg>
  );
}

export default SvgUndo;
