import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { foldoutStateChanged } from '../../../actions/foldouts';
import { showModal } from '../../../actions/modals';
import { updateUserRequest } from '../../../actions/users';
import {
  Button,
  ButtonGroup,
  CategorizedMultiSelect,
  Checkbox,
  Input,
  Label,
  Select,
} from '../../../components/form';
import {
  FoldoutAction,
  FourStepProgress,
} from '../../../components/miscellaneous';
import { SvgEdit, SvgTrashLight, SvgUser } from '../../../components/svg';
import { PasswordStrengthTooltip } from '../../../components/tooltip';
import { DELETE_USER_MODAL } from '../../../constants/modal-types';
import { ADMIN, SUPERUSER, VIEWER } from '../../../constants/roles';
import wr from '../../../constants/workrole';
import {
  accessToLocationIdsSelector,
  locationTreeSelector,
  updatingUsersSelector,
  userRoleSelector,
} from '../../../selectors/users';
import { updateUserValidationSelector } from '../../../selectors/validations';
import { TStrength, calculateStrength } from '../../../utils/helper';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

type EditUserProps = {
  close: () => void;
  user: any;
};

function EditUser(props: EditUserProps) {
  const { close, user } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'editUser' });

  const [t2] = useTranslation();

  const role = useSelector(userRoleSelector);

  const submitting = useSelector(updatingUsersSelector);
  const validation = useSelector(updateUserValidationSelector);
  const organizations = useSelector(locationTreeSelector);
  const locations = useSelector((state) =>
    accessToLocationIdsSelector(state, user.accesses)
  );

  const [strength, setStrength] = useState<[number, TStrength]>([
    0,
    'Unavailable',
  ]);
  const [showPassword, setShowPassword] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const initialRole = useMemo(() => {
    return user && user.accesses.length > 0
      ? {
          label: t2(`role.${user.accesses[0].group_name}`),
          value: user.accesses[0].group_name.toString(),
        }
      : null;
  }, [t2, user]);

  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [selectedWorkRole, setSelectedWorkRole] = useState(
    user.work_role
      ? {
          label: t2(`workrole.${wr[user.work_role]}`),
          value: user.work_role.toString(),
        }
      : null
  );
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedRole, setSelectedRole] = useState(initialRole);

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.trim();
      setPassword(val);
      setStrength(calculateStrength(val));
    },
    []
  );

  const handleTipOver = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleTipOut = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const handleFirstNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFirstName(e.target.value);
    },
    []
  );

  const handleLastNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLastName(e.target.value);
    },
    []
  );

  const handleDeleteClick = useCallback(() => {
    dispatch(
      showModal(DELETE_USER_MODAL, {
        backgroundColor: 'var(--error)',
        size: 'sm',
        user,
      })
    );
  }, [dispatch, user]);

  const handleSubmit = useCallback(() => {
    const payload = {
      first_name: firstName,
      group_name: selectedRole?.value,
      last_name: lastName,
      locations: selectedLocation
        .filter((l) => l.indent === 3)
        .map((l) => l.value),
      password,
      work_role: selectedWorkRole?.value,
    };
    dispatch(updateUserRequest(user.id, payload));
  }, [
    dispatch,
    firstName,
    lastName,
    password,
    selectedLocation,
    selectedWorkRole,
    selectedRole,
    user,
  ]);

  const isUpdated = useMemo(
    () =>
      password !== '' ||
      firstName !== user.first_name ||
      lastName !== user.last_name ||
      selectedWorkRole?.value != user.work_role ||
      selectedRole?.value != initialRole?.value ||
      selectedLocation.length !== locations.length,
    [
      firstName,
      initialRole,
      lastName,
      locations.length,
      password,
      selectedLocation.length,
      selectedRole,
      selectedWorkRole,
      user.first_name,
      user.last_name,
      user.work_role,
    ]
  );

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  return (
    <>
      {role === VIEWER ? (
        <Header
          icon={<SvgUser height={20} width={20} />}
          title={t('title', { context: 'view' })}
        />
      ) : (
        <Header icon={<SvgEdit height={20} width={20} />} title={t('title')} />
      )}
      <Main>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.email')}
            <Input
              autoComplete="off"
              blackDisabled
              dashed={false}
              disabled
              name="email"
              placeholder={t('placeholder.email')}
              readOnly
              type="email"
              validation={validation}
              validationKey="updateUser"
              value={user.email}
            />
          </Label>
        </div>
        {(role === ADMIN || role === SUPERUSER) && (
          <div className={styles.row}>
            <Label className={styles.label}>
              {t('label.password')}
              <Input
                autoComplete="off"
                blackDisabled
                dashed={false}
                name="password"
                onChange={handlePasswordChange}
                placeholder={t('placeholder.password')}
                type={showPassword ? 'text' : 'password'}
                validation={validation}
                validationKey="updateUser"
                value={password}
              />
              <FourStepProgress
                className={styles.fourStepProgress}
                onTipOut={handleTipOut}
                onTipOver={handleTipOver}
                showTooltip={showTooltip}
                strength={strength}
                tooltip={<PasswordStrengthTooltip password={password} />}
              />
              <Checkbox
                checked={showPassword}
                className={styles.checkbox}
                label={t('label.showPassword')}
                labelClass={styles.checkboxLabel}
                onClick={() => setShowPassword(!showPassword)}
              />
            </Label>
          </div>
        )}
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.role')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="role"
              options={['0', '1'].map((key) => ({
                label: t2(`role.${key}`),
                value: key,
              }))}
              placeholder={t('placeholder.role')}
              selected={selectedRole}
              setSelected={setSelectedRole}
              validation={validation}
              validationKey="updateUser"
              zIndex={27}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.workRole')}
            <Select
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="work_role"
              options={Object.keys(wr).map((key) => ({
                label: t2(`workrole.${wr[key]}`),
                value: key,
              }))}
              placeholder={t('placeholder.workRole')}
              selected={selectedWorkRole}
              setSelected={setSelectedWorkRole}
              validation={validation}
              validationKey="updateUser"
              zIndex={26}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.locationAccess')}
            <CategorizedMultiSelect
              dashed={false}
              disabled={role === VIEWER}
              initialSelected={locations}
              name="locations"
              options={organizations}
              placeholder={t('placeholder.locationAccess')}
              selected={selectedLocation}
              setSelected={setSelectedLocation}
              validation={validation}
              validationKey="updateUser"
              zIndex={25}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.firstName')}
            <Input
              blackDisabled={role === VIEWER}
              dashed={false}
              disabled={role === VIEWER}
              name="first_name"
              onChange={handleFirstNameChange}
              placeholder={t('placeholder.firstName')}
              type="text"
              validation={validation}
              validationKey="updateUser"
              value={firstName}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.lastName')}
            <Input
              blackDisabled={role === VIEWER}
              dashed={false}
              disabled={role === VIEWER}
              name="last_name"
              onChange={handleLastNameChange}
              placeholder={t('placeholder.lastName')}
              type="text"
              validation={validation}
              validationKey="updateUser"
              value={lastName}
            />
          </Label>
        </div>
        {(role === ADMIN || role === SUPERUSER) && (
          <div className={styles.row}>
            <FoldoutAction
              icon={<SvgTrashLight height={16} width={16} />}
              onClick={handleDeleteClick}
              text={t('delete')}
            />
          </div>
        )}
      </Main>
      <Footer>
        <ButtonGroup>
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            disabled={role === VIEWER}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default EditUser;
