import {
  CLEAR_ZONE_SELECTIONS,
  CREATE_ZONE_FAILURE,
  CREATE_ZONE_REQUEST,
  CREATE_ZONE_SUCCESS,
  DELETE_ZONE_FAILURE,
  DELETE_ZONE_REQUEST,
  DELETE_ZONE_SUCCESS,
  LIST_ZONES_BY_ORGANIZATION_FAILURE,
  LIST_ZONES_BY_ORGANIZATION_REQUEST,
  LIST_ZONES_BY_ORGANIZATION_SUCCESS,
  LIST_ZONES_FAILURE,
  LIST_ZONES_REQUEST,
  LIST_ZONES_SUCCESS,
  RESET_TOKEN,
  SELECT_ZONE_ALL,
  SELECT_ZONE_WITH_CTRL,
  SELECT_ZONE_WITH_SHIFT,
  SELECT_ZONE_WITHOUT,
  SET_ACTIVE_ORGANIZATION,
  UPDATE_ZONE_FAILURE,
  UPDATE_ZONE_REQUEST,
  UPDATE_ZONE_SUCCESS,
} from '../constants/action-types';

export const initialState = {
  creating: false,
  deleting: false,
  lastSelected: '',
  listing: false,
  selectedZones: [],
  updatingZone: false,
  zones: [],
  zonesByOrganization: [],
  zonesByOrganizationListing: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ZONE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_ZONE_SUCCESS: {
      const { id } = action;
      const { zonesByOrganization } = state;

      return {
        ...state,
        deleting: false,
        zonesByOrganization: zonesByOrganization.filter((z) => z.id !== id),
      };
    }
    case DELETE_ZONE_FAILURE:
      return {
        ...state,
        deleting: false,
      };
    case SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        zonesByOrganization: [],
      };
    case CREATE_ZONE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_ZONE_SUCCESS: {
      const { zone } = action;

      return {
        ...state,
        creating: false,
        zonesByOrganization: [zone, ...state.zones],
      };
    }
    case CREATE_ZONE_FAILURE:
      return {
        ...state,
        creating: false,
      };
    case UPDATE_ZONE_REQUEST:
      return {
        ...state,
        updatingZone: true,
      };
    case UPDATE_ZONE_SUCCESS: {
      const { zone } = action;
      const { zonesByOrganization } = state;

      return {
        ...state,
        updatingZone: false,
        zonesByOrganization: zonesByOrganization.map((z) =>
          z.id === zone.id ? zone : z
        ),
      };
    }
    case UPDATE_ZONE_FAILURE:
      return {
        ...state,
        updatingZone: false,
      };
    case LIST_ZONES_BY_ORGANIZATION_REQUEST:
      return {
        ...state,
        zonesByOrganizationListing: true,
      };
    case LIST_ZONES_BY_ORGANIZATION_SUCCESS: {
      const { zones } = action;

      return {
        ...state,
        zonesByOrganization: zones,
        zonesByOrganizationListing: false,
      };
    }
    case LIST_ZONES_BY_ORGANIZATION_FAILURE:
      return {
        ...state,
        zonesByOrganizationListing: false,
      };
    case LIST_ZONES_REQUEST:
      return {
        ...state,
        listing: true,
      };
    case LIST_ZONES_SUCCESS:
      return {
        ...state,
        listing: false,
        zones: action.zones,
      };
    case LIST_ZONES_FAILURE:
      return {
        ...state,
        listing: false,
      };
    case CLEAR_ZONE_SELECTIONS:
      return {
        ...state,
        selectedZones: [],
      };
    case SELECT_ZONE_WITH_SHIFT: {
      const { id } = action;
      const { lastSelected, zones } = state;

      if (!lastSelected || id === lastSelected) {
        return {
          ...state,
          lastSelected: id,
          selectedZones: [id],
        };
      }

      const index = zones.findIndex((u) => id === u.id);
      const indexLastSelected = zones.findIndex((u) => lastSelected === u.id);

      let selectedZones;
      if (index > indexLastSelected) {
        selectedZones = zones
          .slice(indexLastSelected, index + 1)
          .map((u) => u.id);
      } else {
        selectedZones = zones
          .slice(index, indexLastSelected + 1)
          .map((u) => u.id);
      }

      return {
        ...state,
        selectedZones,
      };
    }
    case SELECT_ZONE_WITH_CTRL: {
      const { selectedZones } = state;
      const { id } = action;
      const index = state.selectedZones.indexOf(id);

      if (index === -1) {
        const newSelected = [...selectedZones, id];

        return {
          ...state,
          lastSelected: id,
          selectedZones: newSelected,
        };
      }

      const newSelected = [
        ...selectedZones.slice(0, index),
        ...selectedZones.slice(index + 1),
      ];
      let previousSelection = 0;
      if (newSelected.length) {
        previousSelection = newSelected[newSelected.length - 1];
      }

      return {
        ...state,
        lastSelected: previousSelection,
        selectedZones: newSelected,
      };
    }
    case SELECT_ZONE_WITHOUT: {
      const { selectedZones } = state;
      const { id } = action;
      const isThisOne = selectedZones[0] === id;
      const isOnlyOne = selectedZones.length === 1;

      const newSelected = isOnlyOne && isThisOne ? [] : [action.id];

      return {
        ...state,
        lastSelected: isOnlyOne && isThisOne ? 0 : action.id,
        selectedZones: newSelected,
      };
    }
    case SELECT_ZONE_ALL: {
      const { selectedZones, zones } = state;

      return {
        ...state,
        selectedZones: selectedZones.length > 0 ? [] : zones.map((u) => u.id),
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
