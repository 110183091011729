import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';

import { logoutRequest } from '../../actions/auth';
import { SvgCross, SvgGear, SvgLogout } from '../../components/svg';
import { userSelector } from '../../selectors/auth';
import styles from './profile-dropdown.module.css';

type ProfileDropdownProps = {
  onClose(e: MouseEvent<HTMLDivElement | HTMLLIElement>): void;
};

function ProfileDropdown(props: ProfileDropdownProps) {
  const { onClose } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('header', { keyPrefix: 'profileDropdown' });

  const user = useSelector(userSelector);

  const handleLogoutClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      dispatch(logoutRequest());
      onClose(e);
    },
    [dispatch, onClose]
  );

  const handleSettingsClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      dispatch(push('/profile/personal-information'));
      onClose(e);
    },
    [dispatch, onClose]
  );

  return (
    <div className={styles.dropdown}>
      <div className={styles.title}>
        {user.first_name || user.email}
        <div className={styles.line} />
        <div className={styles.close} onClick={onClose}>
          <SvgCross color="#7F7F7F" height={11} width={11} />
        </div>
      </div>
      <ul className={styles.list}>
        <li className={styles.item} onClick={handleSettingsClick}>
          <label className={styles.label}>
            <SvgGear height={12} width={12} />
            {t('settings')}
          </label>
        </li>
        <li className={styles.item} onClick={handleLogoutClick}>
          <label className={styles.label}>
            <SvgLogout height={12} width={12} />
            {t('logout')}
          </label>
        </li>
      </ul>
    </div>
  );
}

export default ProfileDropdown;
