import { useTranslation } from 'react-i18next';

import { SvgStar } from '../../../components/svg';
import Header from '../header';
import styles from './index.module.css';
import SvgNewDashboard from './new-dashboard.png';

type NewDashboardModalProps = {
  close: () => void;
};

function NewDashboardModal(props: NewDashboardModalProps) {
  const { close } = props;

  const [t] = useTranslation('modals', { keyPrefix: 'newDashboard' });

  return (
    <form className={styles.container}>
      <Header />
      <div className={styles.columns}>
        <div className={styles.content}>
          <div className={styles.title}>
            <SvgStar />
            {t('title')}
          </div>
          <div className={styles.h1}>{t('h1')}</div>
          <p className={styles.p}>{t('description')}</p>
        </div>
        <div className={styles.image}>
          <img alt="New Dashboard" src={SvgNewDashboard} width="100%" />
        </div>
      </div>
    </form>
  );
}

export default NewDashboardModal;
