import { InputHTMLAttributes, useId } from 'react';
import styles from './radio-button.module.css';

type RadioButtonProps = {
  label?: string | JSX.Element | JSX.Element[];
  checked?: boolean;
};

function RadioButton(
  props: RadioButtonProps & InputHTMLAttributes<HTMLInputElement>
) {
  const { label, className, checked = false, ...rest } = props;

  const id = useId();

  return (
    <p className={className}>
      <input
        id={id}
        className={styles.input}
        type="radio"
        checked={checked}
        {...rest}
      />
      <label htmlFor={id}>{label}</label>
    </p>
  );
}

export default RadioButton;
