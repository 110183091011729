import { KeyboardEvent, PureComponent } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { TOAST_TIMEOUT } from '../../constants/timeouts';
import styles from './toast.module.css';
import { SvgCross } from '../svg';

type ToastProps = {
  timeout?: number;
  rate?: number;
  message: string;
  level: 'success' | 'warning' | 'failure';
  reloadable?: boolean;
  undoable?: boolean;
  reload?: () => void;
  hide?: () => void;
};

type ToastState = {
  strokeDashoffset: number;
};

class Toast extends PureComponent<ToastProps, ToastState> {
  private interval: number;

  private timer: NodeJS.Timeout;

  private ticker: NodeJS.Timer;

  constructor(props: ToastProps) {
    super(props);

    // Increase rate for higher fps.
    const { timeout = TOAST_TIMEOUT, rate = 2 } = this.props;
    this.interval = timeout / (80 * rate);

    this.state = { strokeDashoffset: 0 };
    this.handleTimeout = this.handleTimeout.bind(this);
    this.updateStrokeDashoffset = this.updateStrokeDashoffset.bind(this);
    this.handleActionClick = this.handleActionClick.bind(this);
    this.handleActionKeyDown = this.handleActionKeyDown.bind(this);
  }

  componentDidMount() {
    const { timeout = TOAST_TIMEOUT } = this.props;

    this.timer = setTimeout(this.handleTimeout, timeout);
    this.ticker = setInterval(this.updateStrokeDashoffset, this.interval);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearInterval(this.ticker);
  }

  handleTimeout() {
    const { reloadable = false, undoable = false, reload, hide } = this.props;

    switch (true) {
      case reloadable:
        reload();
        break;
      case undoable:
      default:
        hide();
    }
  }

  handleActionClick() {
    const { reloadable, reload } = this.props;

    switch (true) {
      case reloadable:
        reload();
        break;
      default:
    }
  }

  handleActionKeyDown(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.handleActionClick();
    }
  }

  updateStrokeDashoffset() {
    const { strokeDashoffset } = this.state;

    this.setState({ strokeDashoffset: strokeDashoffset + 0.5 });
  }

  render() {
    const { message, level } = this.props;

    return (
      <div className={clsx([styles.toast, styles[level]])}>
        <span className={styles.message}>{message}</span>
        <SvgCross
          className={styles.close}
          width={16}
          height={16}
          onClick={this.handleTimeout}
        />
      </div>
    );
  }
}

export default withTranslation('toasts')(Toast);
