import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import styles from './nav-item.module.css';

type NavItemProps = {
  className?: string;
  end?: boolean;
  icon: JSX.Element;
  text: string;
  to: string;
};

function NavItem(props: NavItemProps) {
  const { className, end = false, icon, text, to } = props;

  return (
    <div className={clsx(styles.navItem, className)}>
      <NavLink end={end} to={to}>
        {icon}
        <span>{text}</span>
      </NavLink>
    </div>
  );
}

export default NavItem;
