export default {
  admin: 1,
  user: 0,
};

export const roleIds = {
  0: 'user',
  1: 'admin',
};

export const ADMIN = 'ADMIN';
export const VIEWER = 'VIEWER';
export const SUPERUSER = 'SUPERUSER';
