import {
  DELETE_FILTER_FAILURE,
  DELETE_FILTER_REQUEST,
  DELETE_FILTER_SUCCESS,
  LIST_FILTERS_FAILURE,
  LIST_FILTERS_REQUEST,
  LIST_FILTERS_SUCCESS,
  REPLACE_FILTER_FAILURE,
  REPLACE_FILTER_REQUEST,
  REPLACE_FILTER_SUCCESS,
  SAVE_FILTER_FAILURE,
  SAVE_FILTER_REQUEST,
  SAVE_FILTER_SUCCESS,
  SET_SELECTED_FILTER,
  UPDATE_FILTER_FAILURE,
  UPDATE_FILTER_REQUEST,
  UPDATE_FILTER_SUCCESS,
} from '../constants/action-types';
import { IFilter } from '../types/api/filters';

export const listFiltersRequest = () => ({
  type: LIST_FILTERS_REQUEST,
});

export const listFiltersSuccess = (filters: IFilter[]) => ({
  filters,
  type: LIST_FILTERS_SUCCESS,
});

export const listFiltersFailure = (error: unknown) => ({
  error,
  type: LIST_FILTERS_FAILURE,
});

export const saveFilterRequest = (name: IFilter['name']) => ({
  name,
  type: SAVE_FILTER_REQUEST,
});

export const saveFilterSuccess = (filter: IFilter) => ({
  filter,
  type: SAVE_FILTER_SUCCESS,
});

export const saveFilterFailure = (error: unknown) => ({
  error,
  type: SAVE_FILTER_FAILURE,
});

export const setSelectedFilter = (id: IFilter['id']) => ({
  id,
  type: SET_SELECTED_FILTER,
});

export const deleteFilterRequest = (id: IFilter['id']) => ({
  id,
  type: DELETE_FILTER_REQUEST,
});

export const deleteFilterSuccess = (id: IFilter['id']) => ({
  id,
  type: DELETE_FILTER_SUCCESS,
});

export const deleteFilterFailure = (error: unknown) => ({
  error,
  type: DELETE_FILTER_FAILURE,
});

export const updateFilterRequest = (
  id: IFilter['id'],
  payload: Partial<IFilter>
) => ({
  id,
  payload,
  type: UPDATE_FILTER_REQUEST,
});

export const updateFilterSuccess = (filter: IFilter) => ({
  filter,
  type: UPDATE_FILTER_SUCCESS,
});

export const updateFilterFailure = (error: unknown) => ({
  error,
  type: UPDATE_FILTER_FAILURE,
});

export const replaceFilterRequest = (id: IFilter['id']) => ({
  id,
  type: REPLACE_FILTER_REQUEST,
});

export const replaceFilterSuccess = (filter: IFilter) => ({
  filter,
  type: REPLACE_FILTER_SUCCESS,
});

export const replaceFilterFailure = (error: unknown) => ({
  error,
  type: REPLACE_FILTER_FAILURE,
});
