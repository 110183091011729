import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedProperty } from '../../../../../actions/segmentation';
import { Label, Select } from '../../../../../components/form';
import { secondVariables } from '../../../../../constants/segments';
import { selectedPropertySelector } from '../../../../../selectors/segmentation';

type SecondVariableProps = {
  label: string;
  placeholder?: string;
};

function SecondVariable(props: SecondVariableProps) {
  const { label, placeholder = '' } = props;

  const dispatch = useDispatch();

  const selected = useSelector(selectedPropertySelector);

  const handleChange = useCallback(
    (property: Option) => {
      dispatch(setSelectedProperty(property));
    },
    [dispatch]
  );

  return (
    <Label>
      {label}
      <Select
        contentEditable={false}
        options={secondVariables}
        placeholder={placeholder}
        selected={selected}
        setSelected={handleChange}
        zIndex={16}
      />
    </Label>
  );
}

export default SecondVariable;
