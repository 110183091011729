function SvgGdpr(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 106 40"
      {...props}
    >
      <rect width={106} height={40} rx={6} fill="#2050e0" />
      <g fill="#f8d12e">
        <path d="M22 6l.454 1.468h1.467l-1.188.907.454 1.468L22 8.935l-1.188.908.454-1.468-1.188-.907h1.468zM22 30.157l.454 1.468h1.467l-1.188.907.454 1.468L22 33.093 20.812 34l.454-1.468-1.188-.907h1.468zM28.04 8.196l.453 1.468h1.468l-1.188.908.454 1.468-1.187-.908-1.188.907.454-1.468-1.188-.907h1.468zM32.922 24l.453 1.468h1.468l-1.188.907.454 1.468-1.187-.907-1.188.907.454-1.468L31 25.468h1.468zM34.079 18.078l.453 1.468H36l-1.188.907.454 1.468-1.187-.907-1.188.907.454-1.468-1.188-.907h1.468zM9.922 18.078l.453 1.468h1.468l-1.188.907.454 1.468-1.187-.907-1.188.907.454-1.468L8 19.546h1.468zM10.922 24l.453 1.468h1.468l-1.187.907.453 1.468-1.187-.907-1.188.907.454-1.468L9 25.468h1.468zM32.922 12l.453 1.468h1.468l-1.188.907.454 1.468-1.187-.907-1.188.907.454-1.468L31 13.468h1.468zM11.422 12l.453 1.468h1.468l-1.187.907.453 1.468-1.187-.907-1.188.907.454-1.468-1.188-.907h1.468zM29.138 29.059l.453 1.468h1.468l-1.188.908.454 1.468-1.187-.908-1.188.907.454-1.468-1.188-.907h1.468zM15.961 8.196l.453 1.468h1.468l-1.188.908.454 1.468-1.187-.908-1.188.907.454-1.468-1.188-.907h1.468zM14.862 29.059l.454 1.468h1.468l-1.188.907.454 1.468-1.188-.906-1.187.906.454-1.468-1.188-.907h1.468z" />
      </g>
      <path
        d="M49.82 20.192a5.017 5.017 0 01-3.406-1.239A4.407 4.407 0 0145 15.6a4.406 4.406 0 011.414-3.361A5.017 5.017 0 0149.82 11a4.229 4.229 0 013.807 2.065L51.7 14.077a2.225 2.225 0 00-1.88-1.052 2.436 2.436 0 00-1.788.741 2.628 2.628 0 00-.7 1.83 2.63 2.63 0 00.7 1.83 2.435 2.435 0 001.788.741 2.76 2.76 0 001.68-.533v-.746h-2.04v-1.9h4.3v3.472a5.139 5.139 0 01-3.94 1.732zm25.88-.159h-2.3v-8.886h4.46a2.868 2.868 0 013.1 2.957 2.709 2.709 0 01-1.776 2.664L81 20.03h-2.63l-1.5-3.01H75.7v3.01zm0-6.954v2h1.816a1.023 1.023 0 001.108-1 1.023 1.023 0 00-1.108-1zm-8.587 6.952h-2.3v-8.884h4.46a2.868 2.868 0 013.1 2.957 2.851 2.851 0 01-3.1 2.917h-2.16v3.01zm0-6.953v2h1.856a.985.985 0 001.068-.985 1 1 0 00-1.068-1.013zm-8.252 6.953h-3.753v-8.884h3.74a5.086 5.086 0 013.462 1.184 4.219 4.219 0 011.359 3.252 4.24 4.24 0 01-1.357 3.258 5.049 5.049 0 01-3.451 1.19zM57.4 13.145v4.889h1.443a2.428 2.428 0 001.826-.734 2.485 2.485 0 00.649-1.72 2.271 2.271 0 00-2.457-2.438zM18.857 24.455a.872.872 0 01-.857-.887v-3.545a.872.872 0 01.857-.886h.429v-1.33A2.765 2.765 0 0122 15a2.765 2.765 0 012.714 2.807v1.329h.429a.872.872 0 01.857.887v3.545a.872.872 0 01-.857.887z"
        fill="#fff"
      />
      <text
        transform="translate(45 31)"
        fill="#fff"
        fontSize={8}
        fontFamily="HelveticaNeue-Bold, Helvetica Neue"
        fontWeight={700}
        letterSpacing=".025em"
      >
        <tspan x={0} y={0}>
          READY
        </tspan>
      </text>
    </svg>
  );
}

export default SvgGdpr;
