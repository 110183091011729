export const INVITE_PARTNER_MODAL = 'INVITE_PARTNER_MODAL';

export const INVITE_COWORKER_MODAL = 'INVITE_COWORKER_MODAL';

export const CAMERA_BEHAVIOR_MODAL = 'CAMERA_BEHAVIOR_MODAL';

export const SELECT_ONE_LOCATION_MODAL = 'SELECT_ONE_LOCATION_MODAL';

export const REQUESTS_MODAL = 'REQUESTS_MODAL';

export const DELETE_ORGANIZATION_MODAL = 'DELETE_ORGANIZATION_MODAL';

export const DELETE_ZONE_MODAL = 'DELETE_ZONE_MODAL';

export const DELETE_CAMERA_MODAL = 'DELETE_CAMERA_MODAL';

export const CANCEL_SUBSCRIPTION_MODAL = 'CANCEL_SUBSCRIPTION_MODAL';

export const DELETE_USER_MODAL = 'DELETE_USER_MODAL';

export const DATA_GATHERING_MODAL = 'DATA_GATHERING_MODAL';

export const BULK_EDIT_LOCATION_MODAL = 'BULK_EDIT_LOCATION_MODAL';

export const BULK_EDIT_USER_MODAL = 'BULK_EDIT_USER_MODAL';

export const BULK_EDIT_CAMERA_MODAL = 'BULK_EDIT_CAMERA_MODAL';

export const DELETE_LOCATION_MODAL = 'DELETE_LOCATION_MODAL';

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';

export const UNSAVED_CHANGES_MODAL = 'UNSAVED_CHANGES_MODAL';

export const ACTIVATE_CAMERA_MODAL = 'ACTIVATE_CAMERA_MODAL';

export const SAVE_FILTER_MODAL = 'SAVE_FILTER_MODAL';

export const DELETE_FILTER_MODAL = 'DELETE_FILTER_MODAL';

export const EDIT_FILTER_MODAL = 'EDIT_FILTER_MODAL';

export const UPGRADE_WARNING_MODAL = 'UPGRADE_WARNING_MODAL';

export const NEW_DASHBOARD_MODAL = 'NEW_DASHBOARD_MODAL';
