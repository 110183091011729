function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#999' } = props;

  return (
    <svg
      height="1em"
      viewBox="0 0 19 21"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.5 4.5h18M16.5 4.5v14a2 2 0 01-2 2h-10a2 2 0 01-2-2v-14m3 0v-2a2 2 0 012-2h4a2 2 0 012 2v2M7.5 9.5v6M11.5 9.5v6" />
      </g>
    </svg>
  );
}

export default SvgTrash;
