import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SvgLogo, SvgPieChart } from '../../components/svg';
import styles from './index.module.css';
import NavItem from './nav-item';
import Profile from './profile';
import Settings from './settings';

function Header() {
  const [t] = useTranslation('header');

  return (
    <header className={styles.header}>
      <nav>
        <div className={styles.sidebarside}>
          <Link to="/dashboard">
            <SvgLogo color="var(--black)" height={32} width={137} />
          </Link>
        </div>
        {/* <NavItem
          to="/feed"
          text={t('feed')}
          icon={<SvgGrid width={16} height={16} />}
        /> */}
        <NavItem
          className={styles.firstNav}
          end
          icon={<SvgPieChart height={18} width={18} />}
          text={t('insights')}
          to="/dashboard"
        />
        {/* <NavItem
          to="/community"
          text={t('community')}
          icon={<SvgGrid width={16} height={16} />}
        />
        <NavItem
          to="/activities"
          text={t('activities')}
          icon={<SvgGrid width={16} height={16} />}
        />
        <NavItem
          to="/reports"
          text={t('reports')}
          icon={<SvgGrid width={16} height={16} />}
        /> */}
        {/* <Create /> */}
        <Settings />
        <Profile />
      </nav>
    </header>
  );
}

export default Header;
