import { createSelector } from 'reselect';

import { RootState } from '../reducers';

export const tokenSelector = (state: RootState) => state.auth.token;

export const loggedInSelector = (state: RootState) => !!state.auth.token;

export const loggingInSelector = (state: RootState) => state.auth.loggingIn;

export const loggingOutSelector = (state: RootState) => state.auth.loggingOut;

export const registeringSelector = (state: RootState) => state.auth.registering;

export const userSelector = (state: RootState) => state.auth.user;

export const sentResetEmailSelector = (state: RootState) =>
  state.auth.sentResetEmail;

export const uidSelector = createSelector([userSelector], (user) => user?.id);

export const firstNameSelector = createSelector(
  [userSelector],
  (user) => user.first_name
);

export const lastNameSelector = createSelector(
  [userSelector],
  (user) => user.last_name
);

export const emailSelector = createSelector(
  [userSelector],
  (user) => user.email
);

export const usergroupsSelector = (state: RootState) => state.auth.usergroups;

export const sendingResetSelector = (state: RootState) =>
  state.auth.sendingReset;

export const resettingSelector = (state: RootState) => state.auth.resetting;
