import { RootState } from '../reducers';

export const foldoutPropsSelector = (state: RootState) =>
  state.foldouts.foldoutProps;

export const foldoutTypeSelector = (state: RootState) =>
  state.foldouts.foldoutType;

export const foldoutSizeSelector = (state: RootState) =>
  state.foldouts.foldoutSize;

export const foldoutShownSelector = (state: RootState) =>
  state.foldouts.foldoutShown;

export const foldoutChangedSelector = (state: RootState) =>
  state.foldouts.changed;
