import clsx from 'clsx';
import styles from './progress-slider.module.css';

function ProgressSlider() {
  return (
    <div className={styles.slider}>
      <div className={styles.line} />
      <div className={clsx([styles.subline, styles.inc])} />
      <div className={clsx([styles.subline, styles.dec])} />
    </div>
  );
}

export default ProgressSlider;
