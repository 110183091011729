function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  const { color = '#C7C7C7' } = props;

  return (
    <svg
      fill="none"
      height={25}
      viewBox="0 0 25 25"
      width={25}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12.737 2.592-1.301 5.206c-.254 1.016-.381 1.524-.646 1.937a3 3 0 0 1-.91.91c-.413.265-.92.392-1.937.646l-5.206 1.301 5.206 1.302c1.016.254 1.524.38 1.937.645.366.234.676.545.91.91.265.413.392.921.646 1.937l1.301 5.206 1.302-5.206c.254-1.016.38-1.524.645-1.937.234-.365.545-.676.91-.91.414-.264.921-.391 1.937-.645l5.206-1.302-5.206-1.301c-1.015-.254-1.523-.381-1.937-.646a3 3 0 0 1-.91-.91c-.264-.413-.391-.921-.645-1.937l-1.302-5.206Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgStar;
