import clsx from 'clsx';
import { HTMLAttributes, useId } from 'react';
import { animated, useTransition } from 'react-spring';

import styles from './toggle.module.css';

type HTMLInputWithoutPrefix = Omit<HTMLAttributes<HTMLInputElement>, 'prefix'>;

type ToggleProps = {
  checked?: boolean;
  className?: string;
  description?: string;
  disabled?: boolean;
  disabledPrefix?: boolean;
  label?: string;
  parentClass?: string;
  prefix?: {
    off: string;
    on: string;
  };
  readOnly?: boolean;
};

function Toggle(props: HTMLInputWithoutPrefix & ToggleProps) {
  const {
    checked = false,
    className,
    description = '',
    disabled = false,
    disabledPrefix = false,
    label,
    onChange,
    parentClass,
    prefix = {
      off: 'OFF',
      on: 'ON',
    },
    ...rest
  } = props;

  const id = useId();

  const transitions = useTransition(checked, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: { opacity: 0 },
    reverse: checked,
  });

  return (
    <span
      className={clsx([
        styles.toggle,
        {
          [parentClass]: !!parentClass,
        },
      ])}
    >
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        checked={checked}
        className={clsx([
          styles.tgl,
          {
            [className]: !!className,
            [styles.disabled]: disabled,
            [styles.tglLight]: !className,
          },
        ])}
        id={id}
        onChange={onChange}
        type="checkbox"
        {...rest}
      />
      <div
        className={clsx(styles.prefix, { [styles.disabled]: disabled })}
        style={{ marginTop: label ? '15px' : 0 }}
      >
        {!disabledPrefix &&
          transitions((anim, item) =>
            item ? (
              <animated.span className={styles.on} style={anim}>
                {prefix.on || 'ON'}
              </animated.span>
            ) : (
              <animated.span className={styles.off} style={anim}>
                {prefix.off || 'OFF'}
              </animated.span>
            )
          )}
        <label className={styles.tglButton} htmlFor={id} />
        <div className={styles.description}>{description}</div>
      </div>
    </span>
  );
}

export default Toggle;
