import clsx from 'clsx';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createCameraRequest } from '../../../actions/cameras';
import { foldoutStateChanged } from '../../../actions/foldouts';
import { showModal } from '../../../actions/modals';
import {
  Button,
  ButtonGroup,
  Input,
  Label,
  Select,
  Toggle,
} from '../../../components/form';
import { CounterUrl } from '../../../components/miscellaneous';
import { SvgRadio } from '../../../components/svg';
import floors from '../../../constants/floors';
import { ACTIVATE_CAMERA_MODAL } from '../../../constants/modal-types';
import { creatingCameraSelector } from '../../../selectors/cameras';
import { locationOptionsSelector } from '../../../selectors/locations';
import { fetchingLocationTreeSelector } from '../../../selectors/users';
import { createCameraValidationSelector } from '../../../selectors/validations';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

type CreateCameraProps = {
  close: () => void;
};

function CreateCamera(props: CreateCameraProps) {
  const { close } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'createCamera' });

  const [t2] = useTranslation();

  const validation = useSelector(createCameraValidationSelector);

  const locations = useSelector(locationOptionsSelector);

  const loadingLocations = useSelector(fetchingLocationTreeSelector);

  const submitting = useSelector(creatingCameraSelector);

  const [ip, setIp] = useState('');

  const [port, setPort] = useState('');

  const [name, setName] = useState('');

  const [url, setUrl] = useState('');

  const [model, setModel] = useState('');

  const [login, setLogin] = useState('');

  const [password, setPassword] = useState('');

  const [isActive, setIsActive] = useState(false);

  const [floor, setFloor] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleIpChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIp(e.target.value);
  }, []);

  const handlePortChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPort(e.target.value);
  }, []);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  const handleModelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  }, []);

  const handleLoginChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLogin(e.target.value);
  }, []);

  const handlePasswordChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  const handleIsActiveChange = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const handleChange = useCallback((option: Option) => {
    setSelectedLocation(option);
  }, []);

  const handleSubmit = useCallback(() => {
    const payload = {
      floor: floor?.value,
      ip,
      is_active: isActive,
      location: selectedLocation?.value,
      login,
      model,
      name,
      password,
      port,
      stream_location: url,
    };

    dispatch(createCameraRequest(payload));
  }, [
    dispatch,
    floor,
    ip,
    isActive,
    login,
    model,
    name,
    password,
    port,
    selectedLocation,
    url,
  ]);

  const isUpdated = useMemo(
    () =>
      selectedLocation !== null ||
      ip !== '' ||
      name !== '' ||
      url !== '' ||
      model !== '' ||
      login !== '' ||
      password !== '' ||
      isActive !== false ||
      port !== '' ||
      floor !== null,
    [
      selectedLocation,
      ip,
      name,
      url,
      model,
      login,
      password,
      isActive,
      port,
      floor,
    ]
  );

  useEffect(() => {
    if (isActive) {
      dispatch(
        showModal(ACTIVATE_CAMERA_MODAL, {
          backgroundColor: 'var(--positive)',
          deactivate: () => setIsActive(false),
          size: 'sm',
        })
      );
    }
  }, [dispatch, isActive]);

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  return (
    <>
      <Header icon={<SvgRadio height={15} width={22} />} title={t('title')} />
      <Main className={styles.foldout}>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.location')}
            <Select
              dashed={false}
              loading={loadingLocations}
              name="location"
              options={locations}
              placeholder={t('placeholder.location')}
              selected={selectedLocation}
              setSelected={handleChange}
              validation={validation}
              validationKey="createCamera"
              zIndex={1}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.name')}
            <Input
              dashed={false}
              name="name"
              onChange={handleNameChange}
              placeholder={t('placeholder.name')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={name}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.floor')}
            <Select
              dashed={false}
              name="floor"
              options={floors.map((f) => ({
                label: t2(`floors.${f}`),
                value: f.toString(),
              }))}
              placeholder={t('placeholder.floor')}
              selected={floor}
              setSelected={setFloor}
              validation={validation}
              validationKey="createCamera"
              zIndex={1}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.model')}
            <Input
              dashed={false}
              name="model"
              onChange={handleModelChange}
              placeholder={t('placeholder.model')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={model}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={clsx([styles.label, styles.inline])}>
            <div className={styles.status}>{t('label.status')}</div>
            <Toggle
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              checked={isActive}
              // @ts-ignore
              ignoreid="true"
              onChange={handleIsActiveChange}
              parentClass={styles.toggle}
              // disabled
              // readOnly
              prefix={{
                off: t('deactivated'),
                on: t('activated'),
              }}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.login')}
            <Input
              autoComplete="off"
              dashed={false}
              name="camera-login"
              onChange={handleLoginChange}
              placeholder={t('placeholder.login')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={login}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.password')}
            <Input
              autoComplete="off"
              dashed={false}
              name="camera-password"
              onChange={handlePasswordChange}
              placeholder={t('placeholder.password')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={password}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.ip')}
            <Input
              blackDisabled
              dashed={false}
              name="ip"
              onChange={handleIpChange}
              placeholder={t('placeholder.ip')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={ip}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.port')}
            <Input
              blackDisabled
              dashed={false}
              name="port"
              onChange={handlePortChange}
              placeholder={t('placeholder.port')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={port}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.url')}
            <Input
              dashed={false}
              name="stream_location"
              onChange={handleUrlChange}
              placeholder={t('placeholder.url')}
              type="text"
              validation={validation}
              validationKey="createCamera"
              value={url}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <CounterUrl
            ip={ip}
            password={password}
            port={port}
            url={url}
            username={login}
          />
        </div>
      </Main>
      <Footer>
        <ButtonGroup>
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default CreateCamera;
