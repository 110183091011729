import {
  LIST_PERMISSIONS_SUCCESS,
  RESET_TOKEN,
} from '../constants/action-types';

export const initialState = {
  // permissions: new Map(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_PERMISSIONS_SUCCESS: {
      const { permissions } = action;

      return {
        ...state,
        permissions,
      };
    }
    case RESET_TOKEN:
      return structuredClone(initialState);
    default:
      return state;
  }
};
