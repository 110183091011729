import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { resetFilters } from '../../../../actions/segmentation';
import { visitorJourneyRequest } from '../../../../actions/visitor-journey';
import { Button } from '../../../../components/form';
import { SvgLayers } from '../../../../components/svg';
import {
  selectedDateSelector,
  selectedOrganizationSelector,
  visitorJourneyRequestsEnabledSelector,
} from '../../../../selectors/segmentation';
import FilterWrapper from '../../filter-wrapper';
import CustomDateFilter from './filters/custom-date-filter';
import DateFilter from './filters/date-filter';
import LocationFilter from './filters/location-filter';
import OrganizationFilter from './filters/organization-filter';
import styles from './index.module.css';
import SecondVariable from './properties/second-variable';

function VisitorJourney() {
  const [t] = useTranslation('sidebar', { keyPrefix: 'visitorJourney' });

  const dispatch = useDispatch();

  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const selectedDate = useSelector(selectedDateSelector);
  const enabled = useSelector(visitorJourneyRequestsEnabledSelector);

  const handleClearClick = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const handleApplyClick = useCallback(() => {
    if (selectedOrganization) {
      if (selectedDate) {
        dispatch(visitorJourneyRequest());
      }
    }
  }, [dispatch, selectedDate, selectedOrganization]);

  return (
    <div>
      <FilterWrapper
        icon={<SvgLayers color="var(--black-40-)" height={16} width={16} />}
        title={t('propertiesTitle')}
      >
        <SecondVariable
          label={t('secondVariable.label')}
          placeholder={t('secondVariable.placeholder')}
        />
      </FilterWrapper>
      <FilterWrapper
        className={styles.filterWrapper}
        footer={
          <>
            <Button color="primary" onClick={handleClearClick} type="button">
              {t('clear')}
            </Button>
            <Button
              className={styles.apply}
              color="secondary"
              disabled={!enabled}
              onClick={handleApplyClick}
              type="button"
            >
              {t('apply')}
            </Button>
          </>
        }
        title={t('filterTitle')}
      >
        <DateFilter
          label={t('date.label')}
          placeholder={t('date.placeholder')}
        />

        <CustomDateFilter
          labelEndDate={t('endDate.label')}
          labelStartDate={t('startDate.label')}
          placeholderEndDate={t('endDate.placeholder')}
          placeholderStartDate={t('startDate.placeholder')}
        />

        <OrganizationFilter
          label={t('organization.label')}
          placeholder={t('organization.placeholder')}
        />

        <LocationFilter
          label={t('location.label')}
          placeholder={t('location.placeholder')}
        />
      </FilterWrapper>
    </div>
  );
}

export default VisitorJourney;
