function SvgUserPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 31 25.545"
      {...props}
    >
      <g
        transform="translate(-.5 -2.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.455 27.546v-2.727A5.455 5.455 0 0016 19.364H6.455A5.455 5.455 0 001 24.819v2.727" />
        <circle
          cx={5.455}
          cy={5.455}
          r={5.455}
          transform="translate(5.773 3)"
        />
        <path d="M26.909 9.818V18M31 13.909h-8.182" />
      </g>
    </svg>
  );
}

export default SvgUserPlus;
