import request from 'superagent';

function requestCreator(tokenSelector, storeRegistry) {
  return class Request {
    private attach;

    private field;

    private on;

    private options: {
      body?;
      credentials: boolean;
      headers;
      method: string;
      responseType?;
    };

    private query;

    private root: string;

    private token: string;

    private url: string;

    constructor(root = process.env.REACT_APP_API_URL) {
      this.url = '';
      this.root = root;
      this.query = {};
      this.attach = {};
      this.field = {};
      this.on = {};
      this.token = tokenSelector(storeRegistry.state);
      this.options = {
        credentials: false,
        headers: {
          Accept: 'application/json',
        },
        method: 'get',
      };

      if (this.token) {
        this.options.headers.Authorization = `Token ${this.token}`;
      }
    }

    Attach(key, data) {
      this.attach = { [key]: data, ...this.attach };
      this.Set('Accept', 'multipart/form-data');
      return this;
    }

    Delete(url) {
      this.url = `${this.root}${url}`;
      this.options.method = 'delete';
      return this;
    }

    End() {
      return new Promise((resolve, reject) => {
        let r = null;

        if (this.options.method === 'get') {
          r = request.get(this.url);
        } else if (this.options.method === 'post') {
          r = request.post(this.url);
        } else if (this.options.method === 'delete') {
          r = request.del(this.url);
        } else if (this.options.method === 'put') {
          r = request.put(this.url);
        } else if (this.options.method === 'head') {
          r = request.head(this.url);
        } else if (this.options.method === 'patch') {
          r = request.patch(this.url);
        }

        r = r.query(this.query);

        if (this.options.credentials) {
          r = r.withCredentials();
        }

        Object.keys(this.options.headers).forEach((key) => {
          const value = this.options.headers[key];
          r = r.set(key, value);
        });

        if (this.options.body) {
          r = r.send(this.options.body);
        }

        if (this.options.responseType) {
          r = r.responseType(this.options.responseType);
        }

        const { event, handler } = this.on;
        if (event && handler) {
          r = r.on(event, handler);
        }

        Object.keys(this.field).forEach((key) => {
          const value = this.field[key];
          r = r.field(key, value);
        });

        Object.keys(this.attach).forEach((key) => {
          const value = this.attach[key];
          r = r.attach(key, value);
        });

        r.end((err, res) => {
          if (err) {
            return reject(err);
          }

          return resolve({
            body: res.body,
            headers: res.headers,
            text: res.text,
          });
        });
      });
    }

    Field(key, data) {
      this.field = { [key]: data, ...this.field };
      return this;
    }

    Get(url: string) {
      this.url = `${this.root}${url}`;
      this.options.method = 'get';
      return this;
    }

    Head(url) {
      this.url = `${this.root}${url}`;
      this.options.method = 'head';
      return this;
    }

    On(event, handler) {
      this.on.event = event;
      this.on.handler = handler;
      return this;
    }

    Patch(url) {
      this.url = `${this.root}${url}`;
      this.options.method = 'patch';
      return this;
    }

    Post(url) {
      this.url = `${this.root}${url}`;
      this.options.method = 'post';
      return this;
    }

    Put(url) {
      this.url = `${this.root}${url}`;
      this.options.method = 'put';
      return this;
    }

    Query(query) {
      this.query = query;
      return this;
    }

    ResponseType(responseType) {
      this.options.responseType = responseType;
      return this;
    }

    Send(body) {
      this.options.body = body;
      return this;
    }

    Set(header, value?) {
      if (value) {
        this.options.headers[header] = value;
      } else {
        const { [header]: skip, ...rest } = this.options.headers;
        this.options.headers = rest;
      }
      return this;
    }

    WithCredentials() {
      this.options.credentials = true;
      return this;
    }
  };
}

export default requestCreator;
