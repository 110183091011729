import { put, take } from 'redux-saga/effects';

import pjson from '../../package.json';
import { setAppVersion } from '../actions/app';
import { resetToken } from '../actions/auth';
import {
  DELETE_USER_FAILURE,
  GET_USER_FAILURE,
  LIST_CAMERAS_FAILURE,
  LIST_CATEGORIES_FAILURE,
  LIST_LOCATIONS_FAILURE,
  LIST_PERMISSIONS_FAILURE,
  LIST_USER_GROUPS_FAILURE,
  LIST_USERS_FAILURE,
  LIST_ZONES_FAILURE,
  LOCATION_TREE_FAILURE,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  REGISTER_FAILURE,
  RESET_PASSWORD_FAILURE,
  SEND_RESET_PASSWORD_FAILURE,
  TOTAL_VISITS_FAILURE,
} from '../constants/action-types';
import storeRegistry from '../store/store-registry';

export function* watchFailure() {
  while (true) {
    const { error } = yield take([
      LOGIN_FAILURE,
      LOGOUT_FAILURE,
      SEND_RESET_PASSWORD_FAILURE,
      RESET_PASSWORD_FAILURE,
      REGISTER_FAILURE,
      LIST_PERMISSIONS_FAILURE,
      LOCATION_TREE_FAILURE,
      GET_USER_FAILURE,
      DELETE_USER_FAILURE,
      LIST_CAMERAS_FAILURE,
      LIST_USERS_FAILURE,
      LIST_USER_GROUPS_FAILURE,
      LIST_LOCATIONS_FAILURE,
      LIST_CATEGORIES_FAILURE,
      LIST_ZONES_FAILURE,
      TOTAL_VISITS_FAILURE,
    ]);

    const { status } = error;

    if (status === 401) {
      yield put(resetToken());
      storeRegistry.persistor.purge();
      yield put(setAppVersion(pjson.version));
    }

    // if (status === 400) {
    //   const { body: { code, errors }, req } = response;

    //   if (code == 1000) {
    //     switch () {
    //       case 'R000':
    //         break;
    //       default:
    //         yield put();
    //     }
    //   }
    // } else if (!error.message?.includes('the network is offline') && !error.ignore) {
    //   yield put(sendErrorFeedbackRequest(createErrorBuffer(type, error)));
    // }
  }
}
