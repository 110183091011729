import { Suspense, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { ContentLoader } from '../../components/loader';
import { ScrollContext } from '../../contexts';
import { loggedInSelector } from '../../selectors/auth';
import { visitorJourneyAccessSelector } from '../../selectors/users';
import Foldouts from '../foldouts';
import Header from '../header';
import Initialize from '../initialize';
import Modals from '../modals';
import Loader from '../page-loader';
import Sidebar from '../sidebar';
import SidebarInsights from '../sidebar/insights';
import SidebarDashboard from '../sidebar/insights/dashboard';
import SidebarVisitorJourney from '../sidebar/insights/visitor-journey';
import SidebarProfile from '../sidebar/profile';
import SidebarPersonalInformation from '../sidebar/profile/personal-information';
import SidebarPrivacySettings from '../sidebar/profile/privacy-settings';
import SidebarSettings from '../sidebar/settings';
import SidebarDataImport from '../sidebar/settings/data-import';
import SidebarLocations from '../sidebar/settings/locations';
import SidebarOrganizations from '../sidebar/settings/organizations';
import SidebarPeopleCounters from '../sidebar/settings/people-counters';
import SidebarUsers from '../sidebar/settings/users';
import styles from './private.module.css';

function Private() {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  const loggedIn = useSelector(loggedInSelector);
  const accessVisitorJourney = useSelector(visitorJourneyAccessSelector);

  const scrollTo = useCallback((x: number, y: number) => {
    contentRef.current.scrollTo(x, y);
  }, []);

  if (!loggedIn) {
    return <Navigate state={{ path: location.pathname }} to="/login" />;
  }

  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.wrapper}>
        <Routes>
          <Route element={<Sidebar />} path="/">
            <Route element={<SidebarInsights />} path="/">
              <Route element={<SidebarDashboard />} path="dashboard" />
              {accessVisitorJourney && (
                <Route
                  element={<SidebarVisitorJourney />}
                  path="visitor-journey"
                />
              )}
            </Route>
            <Route element={<SidebarProfile />} path="profile">
              <Route
                element={<SidebarPersonalInformation />}
                path="personal-information"
              />
              <Route
                element={<SidebarPrivacySettings />}
                path="privacy-settings"
              />
            </Route>
            <Route element={<SidebarSettings />} path="settings">
              <Route element={<SidebarOrganizations />} path="organizations" />
              <Route
                element={<SidebarPeopleCounters />}
                path="people-counters"
              />
              <Route
                element={<SidebarPeopleCounters />}
                path="people-counters/:limit/:offset"
              />
              <Route element={<SidebarLocations />} path="locations" />
              <Route
                element={<SidebarLocations />}
                path="locations/:limit/:offset"
              />
              <Route element={<SidebarUsers />} path="users" />
              <Route element={<SidebarUsers />} path="users/:limit/:offset" />
              <Route element={<SidebarDataImport />} path="data-import" />
            </Route>
          </Route>
        </Routes>

        <Modals />

        <Foldouts />

        <Initialize />

        <Loader />

        <div className={styles.container}>
          <Suspense fallback={<ContentLoader />}>
            <div className={styles.content} ref={contentRef}>
              <ScrollContext.Provider value={scrollTo}>
                <Outlet />
              </ScrollContext.Provider>
            </div>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default Private;
