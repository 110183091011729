function SvgFallbackImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={438.75}
      role="img"
      viewBox="0 0 780 438.75"
      width={780}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="fallback-image_svg__a">
          <path
            d="M2732.49 569.129h-19.21a9.916 9.916 0 0 1 9.255-10.147v-6.893c.483-.13.97-.257 1.455-.379v7.334a9.971 9.971 0 0 1 8.5 10.085"
            fill="none"
            transform="translate(-2713.28 -551.71)"
          />
        </clipPath>
      </defs>
      <g
        style={{
          isolation: 'isolate',
          mixBlendMode: 'luminosity',
        }}
      >
        <path d="M0 0h780v438.75H0z" fill="#fff" />
        <path d="M645 319H135V.25h510V319" fill="#fff" />
        <path
          d="m587.028 253.891-374.841-.116s-55.183-58.1-8.705-115.1c36.4-44.638 88.716-38.946 121.177-17.4 13.823 9.175 40.38 40.625 76.794-11.75 12.913-18.574 33.236-31.632 55.692-39.456l1.849-.632q4.735-1.583 9.584-2.862.723-.194 1.455-.378a164.54 164.54 0 0 1 31.316-4.806q.925-.05 1.846-.091l.191-.008c.485-.02.969-.038 1.455-.055a137.766 137.766 0 0 1 32.863 2.646c.485.1.973.208 1.455.317q2.951.663 5.772 1.475a77.35 77.35 0 0 1 1.846.549c33.842 10.463 51.19 36.91 20.312 77.585-31.692 41.75 80.44 51.585 19.934 110.085"
          fill="#f1f1f1"
        />
        <path d="M584.752 206.747H470.369v-.761h114.383v.761" fill="#ddd" />
        <path
          d="M587.086 191.863a356.11 356.11 0 0 0-2.336-2.074v-74.913a12.309 12.309 0 0 0-1.728-6.341 37.473 37.473 0 0 0 .147-3.823 14.791 14.791 0 0 1 3.916 10.164v76.988"
          fill="#e4e4e4"
        />
        <path
          d="M587.085 239.144h-2.336v-49.353c.779.687 1.561 1.381 2.336 2.074v47.279m-116.719 0h-2.337V114.876c0-7.74 5.65-14.038 12.591-14.038h93.872a11.9 11.9 0 0 1 8.676 3.873 37.541 37.541 0 0 1-.147 3.823 10.021 10.021 0 0 0-8.529-5.091H480.62c-5.655 0-10.254 5.128-10.254 11.432v124.269"
          fill="#ddd"
        />
        <path
          d="M479.956 248.01a1.927 1.927 0 0 0-.229-.048v-3.006a4.673 4.673 0 0 0-4.4-4.9h-13.353a4.673 4.673 0 0 0-4.4 4.9v2.993a1.318 1.318 0 0 1-.819-.006v-2.987c0-3.2 2.338-5.813 5.215-5.813h13.353c2.875 0 5.215 2.607 5.215 5.813v2.988h-.091a1.853 1.853 0 0 0-.5.067"
          fill="#ddd"
        />
        <path
          d="M456.74 252.607a2.346 2.346 0 0 1 0-4.665h.02a1.785 1.785 0 0 1 .479.067 2.409 2.409 0 0 0 0 4.531 1.917 1.917 0 0 1-.5.067"
          fill="#ddd"
        />
        <path
          d="M457.738 252.607a1.941 1.941 0 0 1-.5-.067 2.409 2.409 0 0 1 0-4.531 1.932 1.932 0 0 1 .341-.061c.051 0 .107-.006.159-.006a2.346 2.346 0 0 1 0 4.665M479.457 252.606a2.346 2.346 0 0 1 0-4.665 1.916 1.916 0 0 1 .271.019 1.845 1.845 0 0 1 .228.048 2.409 2.409 0 0 0 0 4.531 1.932 1.932 0 0 1-.5.067"
          fill="#ddd"
        />
        <path
          d="M480.453 252.607a1.9 1.9 0 0 1-.5-.067 2.408 2.408 0 0 1 0-4.531 1.854 1.854 0 0 1 .5-.067h.09a2.352 2.352 0 0 1-.09 4.663"
          fill="#ddd"
        />
        <path
          d="M597.102 248.009a1.815 1.815 0 0 0-.229-.048v-3a5.377 5.377 0 0 0-.275-1.708c.2-.267.394-.526.588-.79a6.306 6.306 0 0 1 .508 2.5v2.988h-.089a1.867 1.867 0 0 0-.5.067"
          fill="#e4e4e4"
        />
        <path
          d="M574.389 248.01a1.823 1.823 0 0 0-.479-.067v-2.987c0-3.2 2.339-5.813 5.214-5.813h13.356a5.211 5.211 0 0 1 4.708 3.313c-.2.264-.389.525-.587.79a4.452 4.452 0 0 0-4.12-3.19h-13.355a4.673 4.673 0 0 0-4.4 4.9v2.993a1.807 1.807 0 0 0-.341.061"
          fill="#ddd"
        />
        <path
          d="M573.89 252.607a2.346 2.346 0 0 1 0-4.665h.021a1.778 1.778 0 0 1 .479.067 2.408 2.408 0 0 0 0 4.531 1.939 1.939 0 0 1-.5.067"
          fill="#ddd"
        />
        <path
          d="M574.886 252.607a1.933 1.933 0 0 1-.5-.067 2.408 2.408 0 0 1 0-4.531 1.905 1.905 0 0 1 .342-.061c.052 0 .105-.006.16-.006a2.347 2.347 0 0 1 0 4.665"
          fill="#ddd"
        />
        <path
          d="M596.602 252.607a2.346 2.346 0 0 1 0-4.665 1.844 1.844 0 0 1 .272.019 1.749 1.749 0 0 1 .23.048 2.408 2.408 0 0 0 0 4.531 1.955 1.955 0 0 1-.5.067"
          fill="#e4e4e4"
        />
        <path
          d="M597.606 252.606a1.939 1.939 0 0 1-.5-.067 2.409 2.409 0 0 1 0-4.531 1.881 1.881 0 0 1 .5-.067h.089a2.352 2.352 0 0 1-.089 4.663"
          fill="#e4e4e4"
        />
        <path
          d="M306.555 132.976a58.091 58.091 0 0 1-4.456 4.792c-2.964 2.85-6.946 6.028-10.437 6.621a5.581 5.581 0 0 1-2.687-.133 16.054 16.054 0 0 1-5.215-3.176 54.439 54.439 0 0 1-8.784-10.283l2.5-2.829s5.585 4.149 9.079 6.375c1.389.881 2.448 1.462 2.7 1.354.868-.372 15.889-10.5 15.889-10.5l1.42 7.779"
          fill="#c3c3c3"
        />
        <path
          d="M306.555 132.976a58.142 58.142 0 0 1-4.456 4.792 113.087 113.087 0 0 1-10.438 6.621 6.215 6.215 0 0 1-2.036.835c-1.844-.109-5.805-2.6-5.805-2.6a8.808 8.808 0 0 1-.061-1.54 11.955 11.955 0 0 1 2.791-6.737c1.389.881 2.448 1.462 2.7 1.354.868-.372 15.889-10.5 15.889-10.5l1.42 7.779"
          fill="#393939"
        />
        <path
          d="M279.867 169.354c.513 3.145.864 5.432.864 5.432a34.462 34.462 0 0 1-12.578 2.506c-1.437.048-2.863.03-4.252-.032-2.165-.1-4.24-.3-6.133-.556a71.467 71.467 0 0 1-9.56-1.918 34.148 34.148 0 0 1 3.324-14.443c1.995-4.4 4.267-7.752 4.89-10.475.781-3.41-.881-11.359-2.082-16.317-.611-2.514-1.1-4.255-1.1-4.255-4.192-6.148-2.18-9.753-2.18-9.753s1.953-.814 4.4-1.559l.037-.011c.839-.255 1.733-.5 2.625-.706a21.106 21.106 0 0 1 2.709-.448c2.388-.207 2.514-5.629 2.514-5.629a8.368 8.368 0 0 1 3.307-.367 17.656 17.656 0 0 1 2.9.367s0 4.288 2.007 5.179c1.032.46 3 1.3 4.919 2.113.063.029.129.054.192.081l.635.269.822.348.4.167.636.268c1.437.607 2.478 1.042 2.478 1.042.894 2.315-2.236 7.517-2.236 7.517a5.322 5.322 0 0 1 .219 1.17c0 .063.01.129.016.195a28.582 28.582 0 0 1-.695 6.554c-.855 4.475-2.2 9.606-2.523 13.054a8.267 8.267 0 0 0 .109 2.912.756.756 0 0 0 .028.092c.133.4.28.942.438 1.593.881 3.624 2.086 10.671 2.889 15.606"
          fill="#4b4b4b"
        />
        <path
          d="m283.856 251.654-14.84-9.647a3.527 3.527 0 0 1-1.6-3l.9-74.707c0-.649-1.483-1.314-2.132-1.314s-2.581.273-2.581.922l1.728 75.588a2.435 2.435 0 0 1-1.108 2.1l-15.47 10.056a.521.521 0 0 0 .284.957l15.785-6.922a3.7 3.7 0 0 1 2.971 0l15.785 6.922a.521.521 0 0 0 .284-.957"
          fill="#4b4b4b"
        />
        <path
          d="M315.928 122.473s-4.708 5.22-10.039 3.141a33.24 33.24 0 0 1-.64-5.072c0-.252-.006-.5-.01-.727-.029-2.3-.106-3.594-.106-3.594h.006c.243-.1 8.148-3.348 8.142-3.079-.137 6.226 2.645 9.329 2.645 9.329"
          fill="#c3c3c3"
        />
        <path
          d="M312.485 114.632a12.364 12.364 0 0 1-7.247 5.188c-.029-2.3-.106-3.594-.106-3.594h.006c.219-.061 7.575-2.012 7.348-1.593"
          fill="#aaa"
        />
        <path
          d="M279.867 169.354a50.192 50.192 0 0 1-11.618-.059 35.653 35.653 0 0 1-4.552-.883c-4.7-1.254-9.259-3.654-12.165-8.07 1.995-4.4 4.267-7.754 4.89-10.476.779-3.409-.881-11.357-2.083-16.317l1.115-15.564.037-.013 1.122-.34c.416-.124 1.5-.363 1.5-.363s-.891 9.959 13.22 10.546c4.441.185 6.936.79 8.272 1.534.508 5.665-4.31 19.071-3.067 22.8.87 2.614 2.379 11.376 3.328 17.2"
          fill="#aeaeae"
        />
        <path
          d="M303.481 115.986s8.89 2.089 10.108-3.466 3.183-9.031-2.443-10.6-7.2.31-8.053 2.064-1.893 11.316.388 12"
          fill="#c3c3c3"
        />
        <path
          d="M313.283 114.034c.12.076 2.033.664 5.657-6.554a6.286 6.286 0 0 0-.369-5.748c-1.326-2.166-6.028-3.869-9.932-4.61s-4.844 1.457-5.763 3.666a11.928 11.928 0 0 1-3.755 4.228c3.37 1.88 7.425-.809 8.831-.368s1.9 2.658 2.007 3.367.268 1.3 2.036.666.8 2.5.515 3.1c0 0-.239 1.6.774 2.248"
          fill="#626262"
        />
        <path
          d="M336.187 252.613h5.384c1.128 0 2.382-1.13 2.7-3.2s2.633-3.2 3.136-4.892-2.07-4.077-2.07-4.077l-.43-.553-.448-.577-4.265 1s-.105 1.015-.171 2.308a29.446 29.446 0 0 0-.018 2.961c.125 2.258-.632 4.69-2.639 5.129s-1.176 1.9-1.176 1.9M298.709 252.612h14.271a1.375 1.375 0 0 0 .3-.087c.953-.477.655-4.125.54-5.251l-.038-.331s-5.019-4.013-5.709-.941c-.023.1-.047.19-.073.282-.871 2.877-4.766 4.183-7.014 4.547a2.45 2.45 0 0 0-2.28 1.782"
          fill="#3a3a3a"
        />
        <path
          d="M301.584 170.317s.764 9.509 1.659 20.937c1.216 15.526 2.681 34.6 2.826 38.212.25 6.273 1.254 16.309 1.254 16.309s2.382 2.385 7.151 1.378a164.169 164.169 0 0 0 5.464-29.482l.056-.75c1.13-15.429 2.884-47.922 2.884-47.922l-21.294 1.318"
          fill="#aeaeae"
        />
        <path
          d="M301.583 170.317s.764 9.509 1.659 20.937l16.7 26.416.056-.75c1.13-15.429 2.884-47.922 2.884-47.922l-21.294 1.318"
          fill="#969696"
        />
        <path
          d="M305.064 171.446s13.736 34.436 22.768 50.619 11.856 20.7 11.856 20.7 5.175-2.07 5.645-3.481c0 0-3.453-31.242-9.5-38.858-3.417-4.3-7.431-17.217-7.62-21.734s-1.224-13.454-1.224-13.454l-21.922 6.21"
          fill="#aeaeae"
        />
        <path
          d="M326.545 171.142a37.069 37.069 0 0 1-11.432 4.739c-4.883 1.061-10.85.608-12.849.859a.849.849 0 0 1-.587-.148c-.145-.092-.933-6.116-1.683-13.741-.032-.293-.068-.617-.075-.912l-.013-.107c-.081-.793-.161-1.6-.234-2.416q-.153-1.72-.291-3.477l-.2-2.73-.042-.6c-.233-3.362-.4-6.675-.455-9.616-.01-.489-.013-.967-.016-1.436v-.062c-.01-1.947.042-3.695.171-5.135.032-.369.068-.714.115-1.042a18.52 18.52 0 0 1 4.732-9.865l1.658-1.832a8.808 8.808 0 0 0 5.665-1.344c1.995-1.278 4.166-2.66 4.57-2.434 3.087 1.806 5.548 6.425 7.223 12.454a68.107 68.107 0 0 1 1.99 10.748c.161 1.476.286 2.979.369 4.5a147.982 147.982 0 0 0 2.456 19.2c.214 1.165.383 2.094.473 2.736.091.583-1.046 1.366-1.544 1.66"
          fill="#4b4b4b"
        />
        <path
          d="M312.361 132.976s-1.736 14.768-12.98 22.963l-.2-2.73-.042-.6c-.233-3.362-.4-6.675-.455-9.616-.009-.489-.013-.967-.015-1.436v-.062c.011-1.739.066-3.312.171-4.653a28.235 28.235 0 0 1 13.523-3.868"
          fill="#393939"
        />
        <path
          d="M276.466 118.486s-.316 20.11-12.136 27.44-3.425 50.613-6.862 66.574 30.856 2.939 32.591-15.075-14.976-45.78-14.831-49.224 4.366-25.464 3.919-28.581l-2.681-1.133"
          fill="#969696"
        />
        <path
          d="M297.475 150.442a24.424 24.424 0 0 1-3.474 1.893 32.366 32.366 0 0 1-8.224 1.253c-7.211.419-15.6-.048-15.6-.048-1.266.91-3.5.752-5.377-.307s-1.471-.6-2.823-1.326a.843.843 0 0 1-.376-1.295 1.911 1.911 0 0 1 1.2-.9 7.594 7.594 0 0 1 4.692-.134 1.181 1.181 0 0 1-.293-.845c.074-.271 1.9.817 2.711 1.311 2.715.386 9.109-1.506 13.88-3.117 2.874-.971 5.159-1.84 5.7-2.046a22.357 22.357 0 0 0 3.535-3.427 31.513 31.513 0 0 0 4.454 8.99"
          fill="#c3c3c3"
        />
        <path
          d="M313.636 139.533c-5.633 3.973-16.114 11.413-18.849 12.928-1.761.975-4.042 2.878-7.38 3.625-4.775-3.246-3.617-9.162-3.617-9.162 2.874-.971 5.159-1.84 5.7-2.046 1.433-.553 13.091-14.35 13.091-14.35s4.442-5.708 8.439-4.767a7.39 7.39 0 0 1 5.594 5.894 7.479 7.479 0 0 1-2.974 7.878"
          fill="#4b4b4b"
        />
        <g
          clipPath="url(#fallback-image_svg__a)"
          transform="translate(459.322 66.197)"
        >
          <path d="M0 0h19.21v17.419H0" fill="#e9e9e9" />
        </g>
        <path
          d="M513.34 83.617h-19.21c0-6 4.11-10.891 9.254-11.1v-11.22q.728-.03 1.456-.056v11.341c4.781.634 8.5 5.333 8.5 11.039M547.659 83.614h-19.207a9.918 9.918 0 0 1 9.255-10.147v-9.586q.729.152 1.456.317v9.332a9.97 9.97 0 0 1 8.5 10.085"
          fill="#e9e9e9"
        />
        <path
          d="M574.637 119.058a.123.123 0 0 1-.13.1h-31.2a.324.324 0 0 1-.325-.249.316.316 0 0 1 .146-.374 42.266 42.266 0 0 1 3.526-1.938c.214-.1.428-.207.647-.311.244-.112.488-.224.736-.333.98-.435 2.03-.849 3.123-1.212a26.943 26.943 0 0 1 3.91-.994 20.085 20.085 0 0 1 3.451-.311h.246a21.328 21.328 0 0 1 3.661.383 26.666 26.666 0 0 1 3.131.785 34.934 34.934 0 0 1 3.8 1.462c.3.134.587.27.871.408.244.117.479.235.715.353a42.593 42.593 0 0 1 3.637 2.081.122.122 0 0 1 .057.153Zm.66-1.273a42.627 42.627 0 0 0-4.877-2.693c-.052-.025-.1-.049-.157-.072-.26-.123-.525-.242-.8-.361a34.933 34.933 0 0 0-3.477-1.31 28.919 28.919 0 0 0-2.9-.76 23.051 23.051 0 0 0-3.95-.475v-5.708c0-.616-.3-1.8-1.432-1.866a1.615 1.615 0 0 1-1.135-.582 1.83 1.83 0 0 1-.454-1.277 2.007 2.007 0 0 1 2.1-1.966 1.054 1.054 0 0 1 .5.122h1.231a1.99 1.99 0 0 0-1.732-1 2.86 2.86 0 0 0-2.971 2.762 2.7 2.7 0 0 0 .664 1.928 2.48 2.48 0 0 0 1.745.884c.6.037.608.956.608.993v5.7a22.244 22.244 0 0 0-3.847.4 29.264 29.264 0 0 0-3.781 1c-1.107.371-2.162.79-3.149 1.229l-.217.1-.057.028a43.794 43.794 0 0 0-4.774 2.535 1.667 1.667 0 0 0 .871 3.087h31.2a1.466 1.466 0 0 0 .79-2.7"
          fill="#c9c9c9"
        />
        <path
          d="M560.233 103.721a.444.444 0 0 0 .39-.279h-.815a.449.449 0 0 0 .426.279"
          fill="#c9c9c9"
        />
        <path
          d="M556.232 184.936c4.3 5.074 14.982 6.636 14.982 6.636s.7-12.2 1.128-26.961c.387-13.362.543-28.831-.258-39.271-.373-4.872-.954-8.65-1.816-10.608a25.9 25.9 0 0 0-6.724-3.022l-10.012 18.77s-1.585 49.381 2.711 54.455"
          fill="#bababa"
        />
        <path
          d="M572.346 164.612c.387-13.362.543-28.831-.258-39.271-2.093 5.877-10.276 31.065.258 39.271"
          fill="#a1a1a1"
        />
        <path
          d="M570.271 114.732s4.9-2.565 7.112-.239 2.473 34.008 0 40.547c0 0-4.555 1.2-8.067-1.658l.955-38.65M561.297 184.936c-4.3 5.074-14.981 6.636-14.981 6.636s-.7-12.2-1.128-26.961c-.386-13.362-.541-28.83.256-39.271.375-4.872.953-8.65 1.818-10.608a25.889 25.889 0 0 1 6.724-3.022l10.025 18.77s1.585 49.381-2.713 54.455"
          fill="#bababa"
        />
        <path
          d="M545.191 164.612c-.387-13.362-.541-28.831.258-39.271 2.093 5.876 10.275 31.065-.258 39.271"
          fill="#a1a1a1"
        />
        <path
          d="M547.259 114.733s-4.9-2.565-7.111-.239-2.472 34.008 0 40.547c0 0 4.553 1.2 8.067-1.658l-.956-38.65"
          fill="#bababa"
        />
        <path
          d="M559.591 148.242a1.213 1.213 0 1 1 1.213 1.213 1.215 1.215 0 0 1-1.213-1.213M559.591 155.292a1.213 1.213 0 1 1 1.213 1.213 1.215 1.215 0 0 1-1.213-1.213"
          fill="#a1a1a1"
        />
        <path
          d="M562.424 252.612h-29.711a2.519 2.519 0 0 1-2.519-2.518v-31.312a2.519 2.519 0 0 1 2.519-2.519h29.711a2.518 2.518 0 0 1 2.518 2.519v31.311a2.518 2.518 0 0 1-2.518 2.518"
          fill="#969696"
        />
        <path
          d="M543.068 252.612h-30.885a1.93 1.93 0 0 1-1.929-1.932v-32.487a1.929 1.929 0 0 1 1.929-1.931h30.885a1.93 1.93 0 0 1 1.932 1.931v32.487a1.93 1.93 0 0 1-1.932 1.932"
          fill="#aeaeae"
        />
        <path
          d="M516.551 226.369a1.1 1.1 0 1 1 1.1 1.1 1.1 1.1 0 0 1-1.1-1.1M534.427 226.369a1.1 1.1 0 1 1 1.1 1.1 1.1 1.1 0 0 1-1.1-1.1M516.551 242.506a1.1 1.1 0 1 1 1.1 1.1 1.1 1.1 0 0 1-1.1-1.1M534.427 242.506a1.1 1.1 0 1 1 1.1 1.1 1.1 1.1 0 0 1-1.1-1.1"
          fill="#969696"
        />
        <path
          d="M549.96 226.367c0-.609.288-1.1.641-1.1s.643.494.643 1.1-.286 1.1-.643 1.1-.641-.494-.641-1.1M560.356 226.369c0-.608.288-1.1.644-1.1s.641.494.641 1.1-.288 1.1-.641 1.1-.644-.494-.644-1.1M549.96 242.506c0-.611.288-1.1.641-1.1s.643.494.643 1.1-.286 1.1-.643 1.1-.641-.494-.641-1.1M560.356 242.506c0-.611.288-1.1.644-1.1s.641.494.641 1.1-.288 1.1-.641 1.1-.644-.494-.644-1.1M512.181 216.262s18.476-3.033 50.241 0h-50.241"
          fill="#828282"
        />
        <path
          d="M507.009 213.653s36.272-5.994 44.947-5.053 11.541 1.373 9.443 3.967-8.354 8.663-22.7 6.618-26.1 5.8-33.77 3.243-9.489-5.337-5.773-6.929a26.911 26.911 0 0 1 7.852-1.847"
          fill="#363636"
        />
        <path
          d="M517.149 215.189s1.806-9.707 4.578-12.017 6.8.5 10.2-.8 9.24 10.963 9.24 10.963a38.3 38.3 0 0 1-24.019 1.852"
          fill="#4b4b4b"
        />
        <path
          d="M372.89 156.477c-.042-.233-.589-2.734-4.457-2.306 0 0 1.925-.049 2.373.735a3.13 3.13 0 0 0-2.438-.359c-1 .483-1.352 2.411-1.271 3.017s.948 2.471 1.639 2.539a2.021 2.021 0 0 0 .807-.116 8.874 8.874 0 0 0-.382 1c-1.08 3.376.952 6.225 2.2 5.7.8-.339 1.244 1.048 1.244 1.048l7.148-7.733s-3.868-5.724-6.865-3.52"
          fill="#3a3a3a"
        />
        <path
          d="M401.848 193.125s-1.79-1.13-2.9-.931c-.231-.082-.722-.278-.6-.391.162-.147.589-.573.544-.7s-.472.215-1.094.321-.792.235-.944.472-.691.339-.691.339a24.956 24.956 0 0 1-.754-.1c-.526-.22-1.237-.521-2.019-.866-2.249-1.005-5.072-2.354-5.691-3.04-1.428-1.6-3.141-6.941-4.75-10.471-.084-.191-.167-.374-.249-.556-.061-.133-.123-.263-.184-.392a.374.374 0 0 0-.052-.092c-.079-.145-.145-.276-.209-.4l-.026-.039a11.488 11.488 0 0 0-.905-1.357c-.355-.382-.7-.542-1.012-.41a4.109 4.109 0 0 0-.925.712c-.107.1-.216.22-.326.349a.962.962 0 0 0-.186.225 5.5 5.5 0 0 0-.978 1.945 6.812 6.812 0 0 0-.229 2.156 11.587 11.587 0 0 0 1.671 4.767 2.663 2.663 0 0 0 .131.238c1.837 3.227 4.657 6.458 6.377 7.512 1.131.7 3.972 1.129 6.365 1.371 1.012.111 1.949.185 2.646.234l.191.028a24.776 24.776 0 0 1 3.02.733c1.649.544 2.6-.568 3.3-.912s.474-.747.474-.747"
          fill="#c3c3c3"
        />
        <path
          d="m382.201 249.611-1.739.647s-7.55-10.472-8.481-19.688a13.993 13.993 0 0 1 .249-4.709l-.2-1.167-.219-1.247c-.245-1.407-.583-3.332-.969-5.522-.888-5.043-2.038-11.489-2.935-16.229l.469-.149 5.069-1.635 1.047-3.328 3.774-.993s.843 1.649 1.6 9.439c.139 1.022.264 2.148.36 3.371a66.771 66.771 0 0 1-.36 13.647 71.314 71.314 0 0 1-.661 4.188 18.546 18.546 0 0 0-.145 4.136c.379 7.532 3.145 19.239 3.145 19.239"
          fill="#464646"
        />
        <path
          d="m380.224 208.402-8.2 16.293-.219-1.247c-.245-1.407-.583-3.331-.969-5.522-.888-5.043-2.038-11.49-2.935-16.229l.469-.149 5.069-1.635 1.047-3.328 3.774-.993s.843 1.65 1.6 9.439c.139 1.022.264 2.148.36 3.371"
          fill="#363636"
        />
        <path
          d="m376.619 197.682-.19-1.627c-3.18.845-11.671-2.358-11.671-2.358s-3.079 2.706-2.674 9.194c.2 3.167.558 16.623.933 20.6.135 1.444.255 2.471.3 2.882a.569.569 0 0 1-.091.354 18.627 18.627 0 0 0-1.39 2.921c-3.695 9.846.569 21.971.569 21.971l1.108-.446s2.425-13.494 4.4-19.455a11.689 11.689 0 0 1 .95-2.281 25.739 25.739 0 0 0 2.375-6.334 155.658 155.658 0 0 0 3.194-16.8c.1 0 2.186-8.629 2.186-8.629"
          fill="#464646"
        />
        <path
          d="M379.377 177.937a9.908 9.908 0 0 1-3.5 1.373c-5.365 1.082-7.281-3.481-7.96-6.595a4.564 4.564 0 0 1 1.822-.5c.386-.035.792-.054 1.128-.061.611-.441 1.555-2.294 1.835-5.2l.249.075 4.169 1.268s-.231 1.329-.36 2.588a5.009 5.009 0 0 0 .042 2.166c.325.08.521.133.521.133a26.815 26.815 0 0 1 2.055 4.753"
          fill="#c3c3c3"
        />
        <path
          d="M377.121 168.295s-.231 1.329-.36 2.59c-2.373-.306-3.414-2.6-3.809-3.859l4.169 1.268"
          fill="#ababab"
        />
        <path
          d="M377.797 169.572s-5.641-.47-5.293-3.978-.147-5.932 3.468-5.779 4.182 1.56 4.351 2.759-1.051 7.03-2.526 7"
          fill="#c3c3c3"
        />
        <path
          d="m382.706 177.199-.183-.392a.38.38 0 0 0-.053-.092c-.078-.145-.143-.276-.209-.4l-.028-.039a11.522 11.522 0 0 0-.9-1.357 2.426 2.426 0 0 0-.623-.53 10.735 10.735 0 0 0-3.379-1.21c.056.123 1.553 3.556 1.305 4.366s-7.526-.673-7.993-1.181c-.369-.412-.727-2.844-.851-3.777a4.336 4.336 0 0 1-.054-.384 10.308 10.308 0 0 0-4 .823 11.523 11.523 0 0 1 1.168 2.5c.042.137.068.262.11.384.054.276.11.55.138.823a1.636 1.636 0 0 1-.357 1.058 8.371 8.371 0 0 1-.921 1.015c-.233.234-.494.482-.754.742.068.575.178 1.262.288 2.059a44.873 44.873 0 0 1 .521 5.081 4.877 4.877 0 0 1-.4 2.115 4.1 4.1 0 0 1-.411.755c-1.278 1.937-3.475 5.739-2.582 7.771.082.494 1.235.88 2.9 1.139 4.957.8 14.527.5 14.02-1.11 0 0 .755-12.593 1.7-15.736l.082-.344a6.643 6.643 0 0 0-.2-3.166 4.89 4.89 0 0 1 1.663-.919"
          fill="#aeaeae"
        />
        <path
          d="M380.349 160.604c-.764-1.143-1.445-.977-1.846-.7a3.955 3.955 0 0 0-5.141.3 6.733 6.733 0 0 1-2.588 3.739s.962.736 3.39.365a4.059 4.059 0 0 0 2.05-1.13 13.175 13.175 0 0 1-2.538 2.534c2.956-1.023 4.485-4.84 4.639-5.244a4.459 4.459 0 0 1 1.065 1.758c.191.922.133 2.773.675 2.765 0 0 1.44-2.681.293-4.394"
          fill="#3a3a3a"
        />
        <path
          d="M368.474 190.252c-.673 1.608-1.387 5.6-3.035 8.225-1.663-.26-2.815-.646-2.9-1.139-.893-2.032 1.3-5.834 2.582-7.771a4.1 4.1 0 0 0 .411-.755 4.877 4.877 0 0 0 .4-2.115 44.846 44.846 0 0 0-.521-5.081l.453-.947s3.681 7 2.609 9.583"
          fill="#969696"
        />
        <path
          d="M368.842 252.529h-8.756a4.8 4.8 0 0 1 .247-2.347c.439-.972 1.125-1.414.961-2.59a3.155 3.155 0 0 0 2.729 1.046 9.57 9.57 0 0 0 1.519 1.969c.572.411 3.123.264 3.3 1.922M387.642 252.525h-8.756a3.646 3.646 0 0 1 .247-2.346 1.692 1.692 0 0 0-.062-2.017 3.383 3.383 0 0 0 1.474.278 3.079 3.079 0 0 0 1.3-.458 31.234 31.234 0 0 0 2.495 2.62c.573.411 3.123.264 3.3 1.922"
          fill="#363636"
        />
        <path
          d="M376.452 167.539s.077.465 1.22.465c0 0 .96 0 .962-.5a3.082 3.082 0 0 0-2.184.038"
          fill="#fff"
        />
        <path
          d="m385.644 187.852-.948 7.852a2.477 2.477 0 0 0 2.458 2.773h8.47l4-10.625h-13.979"
          fill="#565656"
        />
        <path
          d="M388.586 188.639c0-.049.432-4.957 3.371-4.864 2.987.091 3.755 4.45 3.764 4.494l.583-.1a9.232 9.232 0 0 0-.827-2.419 4.117 4.117 0 0 0-3.5-2.569c-1.475-.044-2.63.9-3.346 2.719a10.484 10.484 0 0 0-.635 2.692l.592.047"
          fill="#565656"
        />
        <path
          d="M399.622 187.852h-12.893l1.854 8.139a3.2 3.2 0 0 0 3.117 2.486h7.837a2.522 2.522 0 0 0 2.4-3.284l-2.321-7.343"
          fill="#656565"
        />
        <path
          d="M389.612 188.641c0-.049.429-4.957 3.372-4.864 2.986.091 3.757 4.45 3.764 4.494l.584-.1a9.382 9.382 0 0 0-.828-2.419 4.117 4.117 0 0 0-3.5-2.569c-1.476-.044-2.629.9-3.346 2.719a10.432 10.432 0 0 0-.634 2.692l.59.047"
          fill="#656565"
        />
        <path
          d="M385.148 198.858c-1.965-.287-12.025-3.037-12.891-3.709-.7-.45-2.807-2.314-4.842-6.287-.282-.549-.55-1.1-.814-1.634a42.15 42.15 0 0 1-2.282-5.688 16.709 16.709 0 0 1-.668-2.748 7.466 7.466 0 0 1-.086-.784q-.016-.214-.027-.416a7.124 7.124 0 0 1 4.985-1.828c.052.086.1.173.153.263 1.057 1.907 5.287 14.35 5.511 14.842.207.453 8.892 5.035 11.073 6.016.907.415-.111 1.971-.111 1.971"
          fill="#c3c3c3"
        />
        <path
          d="M370.893 180.176a8.392 8.392 0 0 0-7.14.4c-1.3-6.987 2.815-7.9 2.815-7.9.859-.449 5.569 4.069 4.326 7.5"
          fill="#aeaeae"
        />
        <path
          d="M385.26 196.887a13.21 13.21 0 0 0 1.239-.484c.376-.193 1.158-.008 1.729-.109s.641.114.444.271-1.041.386-1.173.551 1.682.326 2.539.049.929-.134.621.486a3.981 3.981 0 0 1-2.707 1.775 17.281 17.281 0 0 1-2.8-.569l.111-1.971"
          fill="#c3c3c3"
        />
        <path
          d="M215.47 226.297s-4.209-4.91-.259-8.028c0 0 2.884-1.746 5.237-1.605 0 0-3.333 5.589-2.254 6.144s3.264-6.494 3.264-6.494 6.565-.637 8.677.378c0 0-5.612 4.594-4.43 4.866s7.243-4.233 7.243-4.233 6.11 1.039 5.784 4.767-2.431 4.877-3.356 4.778-6.338-2.165-6.456-1.407 3.14 3.237 5.422 3.353c0 0-4.308 4.749-7.171 3.379s-3.107-3.544-4.892-4.359-3.307-.813-2.571.018 3.966 1.64 4.987 3.444 1.937 3.023-1.026 2.577-7.777-2.612-7.988-5.093l-.212-2.483"
          fill="#8a8a8a"
        />
        <path
          d="M235.729 222.191s-16.595-.639-20.259 4.106a11.987 11.987 0 0 1-3.3 4.638l-.388 1.06s2.876-3.031 3.905-3.214c0 0-1.609-5.881 20.049-6.589"
          fill="#8a8a8a"
        />
        <path
          d="M212.165 230.934a10.422 10.422 0 0 0-3.809 7.343l.647.057s1.138-4.867 3.194-6.77-.031-.63-.031-.63"
          fill="#8a8a8a"
        />
        <path
          d="M200.753 220.122s6.695-3.75 3.57-8.837c0 0-2.5-3.126-5.178-3.929 0 0 1.428 7.588 0 7.766s-.981-8.569-.981-8.569-7.052-3.394-9.819-3.126c0 0 4.374 7.41 2.946 7.231s-6.339-7.678-6.339-7.678-7.23-1.339-8.391 2.947.714 6.427 1.786 6.695 7.945.178 7.766 1.071-4.821 2.32-7.41 1.517c0 0 2.857 7.052 6.607 6.695s4.91-2.678 7.23-2.856 4.017.446 2.857 1.071-5.088.2-6.963 1.8-3.392 2.576.09 3.291 9.731.268 10.98-2.411l1.249-2.677"
          fill="#a1a1a1"
        />
        <path
          d="M179.864 207.267s18.746 6.071 20.889 12.855a14.235 14.235 0 0 0 1.786 6.517v1.339s-1.964-4.553-3.035-5.178c0 0 4.2-5.892-19.639-15.533"
          fill="#a1a1a1"
        />
        <path
          d="M202.539 226.638a12.36 12.36 0 0 1 1.243 9.733l-.744-.2s.722-5.886-.79-8.847.292-.687.292-.687"
          fill="#a1a1a1"
        />
        <path
          d="M211.406 214.951s-8.826-4.943-4.707-11.648c0 0 3.294-4.118 6.825-5.178 0 0-1.883 10 0 10.238s1.294-11.3 1.294-11.3 9.3-4.472 12.944-4.12c0 0-5.766 9.768-3.884 9.533s8.355-10.121 8.355-10.121 9.531-1.764 11.061 3.884-.942 8.472-2.354 8.825-10.473.236-10.238 1.412 6.354 3.06 9.767 2c0 0-3.765 9.3-8.708 8.826s-6.473-3.53-9.531-3.766-5.3.589-3.765 1.412 6.707.271 9.179 2.372 4.472 3.395-.118 4.336-12.827.353-14.474-3.178l-1.647-3.53"
          fill="#bababa"
        />
        <path
          d="M238.941 198.006s-24.712 8-27.535 16.945a18.779 18.779 0 0 1-2.354 8.591v1.766s2.589-6 4-6.827c0 0-5.531-7.766 25.888-20.474"
          fill="#a1a1a1"
        />
        <path
          d="M209.052 223.54a16.3 16.3 0 0 0-1.639 12.832l.98-.263s-.952-7.759 1.042-11.661-.384-.907-.384-.907"
          fill="#a1a1a1"
        />
        <path
          d="M211.108 251.649a17.288 17.288 0 0 0 17.288-17.289H193.82a17.288 17.288 0 0 0 17.288 17.289"
          fill="#ababab"
        />
        <path
          d="M215.362 251.517a17.3 17.3 0 0 1-19.435-17.156h4.295a17.291 17.291 0 0 0 15.14 17.156"
          fill="#cfcfcf"
        />
        <path
          d="M228.635 252.53h-35.054a2.134 2.134 0 0 1 2.132-2.134h30.788a2.134 2.134 0 0 1 2.132 2.134M228.789 234.89h-35.538v-.531h35.538v.531"
          fill="#ababab"
        />
        <path d="M392.839 202.058h17.982v50.47h-17.982v-50.47" fill="#a1a1a1" />
        <path d="M397.255 202.058h17.981v50.47h-17.981v-50.47" fill="#bababa" />
        <path
          d="M435.013 252.604h-22.182v-21.225h22.182v21.225"
          fill="#bababa"
        />
        <path d="M429.572 231.379v21.224h-22.179v-21.224h22.179" fill="#ccc" />
        <path
          d="m411.576 220.75-.948 7.852a2.476 2.476 0 0 0 2.458 2.773h8.469l4-10.625h-13.979"
          fill="#656565"
        />
        <path
          d="M414.519 221.542c0-.049.43-4.957 3.37-4.864 2.987.09 3.756 4.45 3.764 4.494l.583-.1a9.232 9.232 0 0 0-.826-2.419 4.117 4.117 0 0 0-3.5-2.569c-1.475-.044-2.627.9-3.347 2.719a10.614 10.614 0 0 0-.635 2.691l.592.048"
          fill="#656565"
        />
        <path
          d="M425.553 220.749h-12.892l1.854 8.139a3.2 3.2 0 0 0 3.117 2.486h7.837a2.523 2.523 0 0 0 2.405-3.284l-2.32-7.343"
          fill="#aeaeae"
        />
        <path
          d="M415.544 221.539c0-.049.43-4.957 3.371-4.864 2.987.089 3.757 4.45 3.763 4.494l.585-.1a9.272 9.272 0 0 0-.829-2.419 4.111 4.111 0 0 0-3.5-2.569c-1.475-.044-2.628.9-3.345 2.719a10.47 10.47 0 0 0-.635 2.691l.593.048"
          fill="#aeaeae"
        />
        <path
          d="M482.344 132.518c-.559-.513-2 .61-2.542.952s-.638.178-.44-.195.44-1.172.22-1.317-.486.586-1.219 1.1a1.552 1.552 0 0 0-.538 1.659c.051.366-.413 1-.413 1s-6.087 6.5-9.473 9.431c-1.818 1.578-5.558 3.268-10.185 4.083a45.624 45.624 0 0 0-8.783 2.48l8.164 5.01s7.232-3.959 11.886-6.377 10.072-13.6 10.072-13.6a4.829 4.829 0 0 1 1.537-.789c.9-.268 2.269-2.927 1.712-3.44"
          fill="#c3c3c3"
        />
        <path
          d="M459.294 154.916a6.13 6.13 0 0 1-2.4 3.755 2.465 2.465 0 0 1-.2.117c-1.139.61-2.047.025-2.754-1.052a9.384 9.384 0 0 1-.917-1.891c-.854-2.307-1.572-5.067-1.572-5.067.164-.178 3.211-1.509 8.156-1.933a17.913 17.913 0 0 1-.311 6.07"
          fill="#ababab"
        />
        <path
          d="M459.297 154.918a6.132 6.132 0 0 1-2.4 3.756 2.861 2.861 0 0 1-.2.117 18.331 18.331 0 0 1-2.755-1.052c-1.182-.593-1.823-1.262-.917-1.891a3.933 3.933 0 0 1 .943-.451 9.066 9.066 0 0 1 5.33-.478"
          fill="#969696"
        />
        <path
          d="M509.679 119.052a.124.124 0 0 1-.131.1h-31.2a.321.321 0 0 1-.323-.249.312.312 0 0 1 .146-.374 41.965 41.965 0 0 1 4.913-2.582q.28-.126.57-.246a33.332 33.332 0 0 1 2.552-.965 23.6 23.6 0 0 1 7.359-1.305h.248a23.957 23.957 0 0 1 6.792 1.168 34.381 34.381 0 0 1 3.8 1.462c.224.1.449.2.665.308a42.153 42.153 0 0 1 4.557 2.532.124.124 0 0 1 .058.153Zm.658-1.273a43.137 43.137 0 0 0-4.255-2.4q-.755-.373-1.577-.73a34.557 34.557 0 0 0-3.473-1.31 25.449 25.449 0 0 0-6.856-1.235v-5.708c0-.616-.3-1.8-1.433-1.868a1.612 1.612 0 0 1-1.133-.581 1.839 1.839 0 0 1-.457-1.277 2.013 2.013 0 0 1 2.1-1.966 1.052 1.052 0 0 1 .513.129h1.22a1.989 1.989 0 0 0-1.733-1.005 2.865 2.865 0 0 0-2.976 2.762 2.7 2.7 0 0 0 .665 1.927 2.479 2.479 0 0 0 1.746.886c.6.037.609.956.609.993v5.7a25.207 25.207 0 0 0-7.628 1.408 36.28 36.28 0 0 0-3.117 1.214l-.032.013a43.482 43.482 0 0 0-4.336 2.228c-.254.149-.492.292-.717.429a1.7 1.7 0 0 0-.484.446 1.669 1.669 0 0 0 1.359 2.639h31.2a1.464 1.464 0 0 0 .79-2.7"
          fill="#c9c9c9"
        />
        <path
          d="M495.278 103.712a.445.445 0 0 0 .387-.27h-.811a.438.438 0 0 0 .423.27"
          fill="#c9c9c9"
        />
        <path
          d="M505.505 177.308c-.925-1.564 5.471-18.195 2.208-27.172a.4.4 0 0 0-.055-.1c-.561-.96-5.146-8.833-5.146-9.7 0-.918 3.977-15.1 3.977-15.1h-26.111s6.733 13.769 6.426 14.891-4.486 7.752-5.2 10c0 0-1.275 7.4 4.761 23.911a5.155 5.155 0 0 1-.178 3.964l-4.751 10.17s4.35 2.7 7.716.612c3.645-2.263 6.237 1.375 8.721 1.428 4.641.1 6.12-3.271 12.087-2.142a38.482 38.482 0 0 0-4.451-10.771"
          fill="#4b4b4b"
        />
        <path
          d="M482.009 114.171s5.916 6.488 13.464 6.182a15.826 15.826 0 0 0 11.626-6.182l8.587 9.241a8.619 8.619 0 0 1-5.935 3.468c-4.285.714-6.63 5.508-11.628 4.181s-10.352 2.6-11.983-.663-8.513-.815-8.77-3.262c-.21-2.031-5.374-2.4-5.369-5.662l10.007-7.3"
          fill="#aeaeae"
        />
        <path
          d="M460.722 252.646h-12.163a6.691 6.691 0 0 1 .342-3.26c.61-1.35 1.565-1.964 1.335-3.6a4.38 4.38 0 0 0 3.79 1.45 13.434 13.434 0 0 0 2.112 2.736c.794.57 4.34.368 4.584 2.669M485.21 252.646h-12.165a6.673 6.673 0 0 1 .344-3.26c.61-1.35 1.562-1.964 1.334-3.6a4.384 4.384 0 0 0 3.793 1.45 13.3 13.3 0 0 0 2.109 2.736c.795.57 4.34.368 4.585 2.669"
          fill="#363636"
        />
        <path
          d="M443.01 181.378s-4.12.974.269 22.644c3.257 16.085 3.005 14.545 2.388 20.938s5.388 23.218 5.388 23.218l2.961-.235s-.695-33.25 2.8-53.6c2.3-13.383-13.8-12.967-13.8-12.967"
          fill="#363636"
        />
        <path
          d="M457.561 180.535s9.5 45.332 20.955 66.7c0 0-.461.7-1.8.5a5.98 5.98 0 0 1-1.966-1.3s-15.831-16.824-15.372-23.355-27.663-34.937-14.833-43.525c8.048-5.388 13.011.979 13.011.979"
          fill="#464646"
        />
        <path
          d="M444.151 150.849c.658-.647 1.461-3.062 1.263-6.634l.313.043 5.281.765v.153c-.006.443-.018 1.782.044 3.048a6.118 6.118 0 0 0 .451 2.613c.41.038-3.4 3.129-7.349.01"
          fill="#c3c3c3"
        />
        <path
          d="M460.149 170.619c-.07 6.308-.227 13.5-1.216 14.874s-15.427.073-17.183-1.793c-1.11-1.177.014-3.668 1.259-6.511a30.254 30.254 0 0 0 1.879-5.135c.373-1.648-1.607-7.052-2.371-12.313a18.313 18.313 0 0 1 .012-6.546 4.534 4.534 0 0 1 3.825-3.816 1.42 1.42 0 0 0-.122.347s2.636 2.465 5.216 1.052c1.267.724 2.961 1.586 3.682 2.622a29.118 29.118 0 0 1 5.015 17.216"
          fill="#ababab"
        />
        <path
          d="M483.228 145.675a.974.974 0 1 1 1.218-.641.974.974 0 0 1-1.218.641Zm9.822-1.094-16.94-5.271a1.189 1.189 0 0 0-1.485.781l-7.7 24.759a1.19 1.19 0 0 0 .78 1.486l16.94 5.272a1.194 1.194 0 0 0 1.487-.78l7.7-24.76a1.19 1.19 0 0 0-.779-1.486"
          fill="#d2d2d2"
        />
        <path
          d="M483.231 145.677a.974.974 0 1 1 1.219-.641.974.974 0 0 1-1.219.641Zm7.092-1.106-11.986-3.729a3.114 3.114 0 0 0-3.888 2.043l-6.051 19.444a3.117 3.117 0 0 0 2.042 3.891l11.988 3.729a3.116 3.116 0 0 0 3.888-2.043l6.048-19.444a3.113 3.113 0 0 0-2.042-3.889"
          fill="#efefef"
        />
        <path
          d="m487.652 151.008-11.085-3.45a.405.405 0 0 1-.266-.5l.032-.1a.406.406 0 0 1 .5-.265l11.085 3.45a.4.4 0 0 1 .264.5l-.032.1a.4.4 0 0 1-.5.266M487.034 152.987l-11.086-3.449a.4.4 0 0 1-.264-.5l.03-.1a.405.405 0 0 1 .5-.265l11.085 3.449a.4.4 0 0 1 .264.5l-.032.1a.4.4 0 0 1-.5.266M486.396 155.045l-11.084-3.45a.4.4 0 0 1-.262-.5l.029-.1a.406.406 0 0 1 .506-.264l11.083 3.45a.4.4 0 0 1 .268.5l-.033.1a.406.406 0 0 1-.506.264M477.246 165.282c-.2-.062-.257-.228-.172-.5l.172-.556a3.738 3.738 0 0 1-1.1-.594c-.45-.35-.627-.685-.527-1.005a.523.523 0 0 1 .284-.343.621.621 0 0 1 .457-.014 1.6 1.6 0 0 1 .4.271c.27.21.41.32.421.331a2.656 2.656 0 0 0 .723.35 1.074 1.074 0 0 0 .827-.073 1.013 1.013 0 0 0 .538-.628q.192-.611-.535-1.218c-.453-.373-.9-.745-1.357-1.115a1.688 1.688 0 0 1-.374-1.737 1.842 1.842 0 0 1 .954-1.143 2.459 2.459 0 0 1 1.5-.2l.136-.432c.081-.262.219-.362.418-.3q.259.081.21.4c0 .007-.053.172-.155.5a3.117 3.117 0 0 1 .923.519c.35.292.481.584.388.879a.5.5 0 0 1-.276.33.609.609 0 0 1-.44.016 1.918 1.918 0 0 1-.538-.356 1.992 1.992 0 0 0-.631-.386 1.094 1.094 0 0 0-.723 0 .808.808 0 0 0-.475.537.59.59 0 0 0 .115.582 5.729 5.729 0 0 0 .648.581 8.21 8.21 0 0 1 1.286 1.177 1.785 1.785 0 0 1 .24 1.663 2.175 2.175 0 0 1-1.03 1.315 2.55 2.55 0 0 1-1.7.255l-.18.585c-.085.268-.227.369-.432.307M483.751 144.008a.772.772 0 1 0 .507.967.771.771 0 0 0-.507-.967Zm-.58 1.86a1.176 1.176 0 1 1 1.473-.773 1.176 1.176 0 0 1-1.473.773"
          fill="#d2d2d2"
        />
        <path
          d="M480.066 133.039a.8.8 0 0 0-.812.451c-.294.724.43 2.064 2.16 3.055 1.215.7 2.074 2.21 2.481 4.381a15.146 15.146 0 0 1 .232 1.916c.582-5.249-1.565-9.208-3.491-9.721a2.248 2.248 0 0 0-.569-.082Zm4.082 10.987-.191-.021c0-.056.183-5.673-2.64-7.292-1.891-1.083-2.568-2.508-2.241-3.3.21-.515.814-.7 1.609-.483 2.136.569 4.547 5.173 3.464 11.094"
          fill="#4b4b4b"
        />
        <path
          d="M451.05 148.228c-2.092-.137-4.7-2.625-4.7-2.625a10.624 10.624 0 0 1 4.652-.423c0 .443-.015 1.782.047 3.048"
          fill="#ababab"
        />
        <path
          d="M453.137 145.156s-6.292 2.908-8.042-1.037-3.742-6.228.2-8.251 5.4-.867 6.3.308 3.131 8.122 1.54 8.98"
          fill="#c3c3c3"
        />
        <path
          d="M449.949 160.833s-.216 12.556-6.939 16.357a30.222 30.222 0 0 0 1.88-5.135c.375-1.648-1.605-7.052-2.37-12.313.814-1.51 1.72-2.4 2.614-2.182 4.293 1.048 4.814 3.273 4.814 3.273"
          fill="#969696"
        />
        <path
          d="M477.442 169.365s-1.012-.446-1.515-.105c0 0-12.964.617-16.479-.555s-10.661-15.6-10.661-15.6c-4.417-3.238-5.031 3.445-5.734 5.891s7.416 13.741 14.149 15.044 20.089-3.317 20.089-3.317 1.969 1.414 2.749 1.382a13.573 13.573 0 0 0 2.92-1.023l-5.519-1.718"
          fill="#c3c3c3"
        />
        <path
          d="M453.138 159.715c.138-.139-3.652-9.427-6.336-8.328s-5.739 6.636-2.888 12.425c0 0 4.67.455 9.224-4.1"
          fill="#ababab"
        />
        <path
          d="M451.021 132.42c-.909-2.44-4.963-.78-8.01 0s-9 3.123-6.464 10.442-1.475 12.189 3.41 15.024c2.923 2.144 3.431 6.393 3.431 6.393.081-2.4 2.009-2.564 3.741-4.414s-.655-4.062-1.332-5.674.651-3.587.651-3.587c1.529-1.258-.993-3.5.632-5.728s-.319-4.427 2.283-5.264 1.361-4.325 1.361-4.325 1.2-.426.3-2.865"
          fill="#363636"
        />
        <path
          d="M253.237 129.297c-4.192-6.148-2.18-9.753-2.18-9.753 3.836 2.44 2.247 9.39 2.18 9.753"
          fill="#393939"
        />
        <path
          d="M611.057 253.709H166.984a.5.5 0 0 1-.5-.5v-.172a.5.5 0 0 1 .5-.5h444.073a.5.5 0 0 1 .506.5v.172a.5.5 0 0 1-.506.5"
          fill="#363636"
        />
        <text
          fill="#0d0d0d"
          fontFamily="HelveticaNeue-Medium, Helvetica Neue"
          fontSize={20}
          fontWeight={500}
          transform="translate(297 280)"
        >
          <tspan x={0} y={20}>
            {'No image to show'}
          </tspan>
        </text>
        <text
          fill="#707070"
          fontFamily="HelveticaNeue, Helvetica Neue"
          fontSize={14}
          transform="translate(112 319)"
        >
          <tspan x={37.286} y={13}>
            {
              'This snap shot image can not be displayed yet due to our privacy mitigations.'
            }
          </tspan>
          <tspan x={54.681} y={35}>
            {
              'Your snap shot image is set to 1 hour after closing hour for this location.'
            }
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SvgFallbackImage;
