import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { foldoutStateChanged } from '../../../actions/foldouts';
import { showModal } from '../../../actions/modals';
import { updateZoneRequest } from '../../../actions/zones';
import { Button, ButtonGroup, Input, Label } from '../../../components/form';
import { FoldoutAction } from '../../../components/miscellaneous';
import { SvgEdit, SvgTrashLight } from '../../../components/svg';
import { DELETE_ZONE_MODAL } from '../../../constants/modal-types';
import { ADMIN, SUPERUSER, VIEWER } from '../../../constants/roles';
import { userRoleSelector } from '../../../selectors/users';
import { updateZoneValidationSelector } from '../../../selectors/validations';
import {
  updatingZoneSelector,
  zoneByIdSelector,
} from '../../../selectors/zones';
import Footer from '../footer';
import Header from '../header';
import Main from '../main';
import styles from './index.module.css';

type EditZoneProps = {
  close: () => void;
  id: string;
};

function EditZone(props: EditZoneProps) {
  const { close, id } = props;

  const dispatch = useDispatch();

  const [t] = useTranslation('foldouts', { keyPrefix: 'editZone' });

  const role = useSelector(userRoleSelector);

  const zone = useSelector((state) => zoneByIdSelector(state, id));
  const submitting = useSelector(updatingZoneSelector);
  const validation = useSelector(updateZoneValidationSelector);

  const [name, setName] = useState(zone.name);

  const isUpdated = useMemo(() => name !== zone.name, [name, zone]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    const payload = {
      name,
    };

    dispatch(updateZoneRequest(zone.id, payload));
  }, [dispatch, name, zone]);

  const handleDeleteClick = useCallback(() => {
    dispatch(
      showModal(DELETE_ZONE_MODAL, {
        backgroundColor: 'var(--error)',
        size: 'sm',
        zone,
      })
    );
  }, [dispatch, zone]);

  useEffect(() => {
    dispatch(foldoutStateChanged(isUpdated));
  }, [dispatch, isUpdated]);

  if (!zone) return null;

  return (
    <>
      <Header
        icon={<SvgEdit height={20} width={20} />}
        title={t('title', { context: role === VIEWER ? 'view' : null })}
      >
        <div className={styles.id}>
          <Trans
            components={{
              bold: <b />,
            }}
            i18nKey="id"
            t={t}
            values={{ id }}
          />
        </div>
      </Header>
      <Main>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.name')}
            <Input
              blackDisabled
              dashed={false}
              disabled={role === VIEWER}
              name="name"
              onChange={handleNameChange}
              placeholder={t('placeholder.name')}
              type="text"
              validation={validation}
              validationKey="updateZone"
              value={name}
            />
          </Label>
        </div>
        <div className={styles.row}>
          <Label className={styles.label}>
            {t('label.createdAt')}
            <Input
              blackDisabled
              dashed={false}
              disabled
              name="created_at"
              onChange={handleNameChange}
              type="text"
              value={dayjs(zone.created_at).format('DD-MM-YYYY')}
            />
          </Label>
        </div>
        {(role === ADMIN || role === SUPERUSER) && (
          <div className={styles.row}>
            <FoldoutAction
              icon={<SvgTrashLight height={16} width={16} />}
              onClick={handleDeleteClick}
              text={t('delete')}
            />
          </div>
        )}
      </Main>
      <Footer>
        <ButtonGroup align="middle">
          <Button
            className={styles.submit}
            containerClassName={styles.submitContainer}
            onClick={close}
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            className={styles.submit}
            color="secondary"
            containerClassName={styles.submitContainer}
            disabled={role === VIEWER}
            loading={submitting}
            onClick={handleSubmit}
            type="button"
          >
            {t('submit')}
          </Button>
        </ButtonGroup>
      </Footer>
    </>
  );
}

export default EditZone;
