import { LinkProps, NavLink } from 'react-router-dom';

import styles from './nav.module.css';

function Nav(props: LinkProps) {
  const { children, to, ...rest } = props;

  return (
    <NavLink to={to} {...rest} className={styles.nav}>
      {children}
    </NavLink>
  );
}

export default Nav;
