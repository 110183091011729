import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import Header from '../header';
import { deleteCameraRequest } from '../../../actions/cameras';
import { Button } from '../../../components/form';
import { SvgTrash } from '../../../components/svg';
import { deletingCameraSelector } from '../../../selectors/cameras';

type DeleteCameraModalProps = {
  close: () => void;
  camera: {
    id: number;
    name: string;
  };
};

function DeleteCameraModal(props: DeleteCameraModalProps) {
  const { close, camera } = props;

  const { t } = useTranslation('modals', { keyPrefix: 'deleteCamera' });

  const dispatch = useDispatch();

  const deleting = useSelector(deletingCameraSelector);

  const handleDeleteClick = useCallback(() => {
    dispatch(deleteCameraRequest(camera.id));
  }, [dispatch, camera]);

  return (
    <form className={styles.container}>
      <Header
        icon={<SvgTrash width={19} height={17} color="#fff" />}
        title={t('title')}
      />
      <div className={styles.description}>
        <Trans
          t={t}
          i18nKey="description"
          components={{
            p: <p />,
            div: <div />,
          }}
          values={{ name: camera.name }}
        />
      </div>
      <div className={styles.buttonGroup}>
        <Button onClick={close} type="button" color="primaryNegative">
          {t('cancel')}
        </Button>
        <Button
          className={styles.submit}
          color="negative"
          containerClassName={styles.submitContainer}
          onClick={handleDeleteClick}
          type="button"
          loading={deleting}
          disabled={false}
        >
          {t('submit')}
        </Button>
      </div>
    </form>
  );
}

export default DeleteCameraModal;
