import React, { ReactNode, useId } from 'react';
import clsx from 'clsx';
import styles from './label.module.css';

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  children?: ReactNode;
  className?: string;
};

function Label(props: LabelProps) {
  const { children, className = null } = props;

  const id = useId();

  const childrenWithProps = React.Children.map(
    children as JSX.Element,
    (child: React.ReactElement<{ id: number | string; ignoreid: boolean }>) => {
      if (React.isValidElement(child)) {
        // Do not pass id if child has "ignoreid" custom attribute.
        if (child.props.ignoreid) return child;

        return React.cloneElement(child, { id });
      }
      return child;
    }
  );

  return (
    <label htmlFor={id} className={clsx([styles.label, className])}>
      {childrenWithProps}
    </label>
  );
}

export default Label;
