import { isEqual } from 'lodash';
import { createSelector } from 'reselect';

import { RootState } from '../reducers';
import {
  dataDateSelector,
  dataEndDateSelector,
  dataFloorSelector,
  dataLineSelector,
  dataLocationSelector,
  dataOrganizationSelector,
  dataPropertySelector,
  dataStartDateSelector,
  dataTypeSelector,
  dataZoneFromSelector,
  dataZoneToSelector,
} from './insights';
import {
  selectedDateSelector,
  selectedEndDateSelector,
  selectedFloorSelector,
  selectedLineSelector,
  selectedLineTypeSelector,
  selectedLocationSelector,
  selectedOrganizationSelector,
  selectedPropertySelector,
  selectedStartDateSelector,
  selectedZoneFromSelector,
  selectedZoneToSelector,
} from './segmentation';

export const filtersSelector = (state: RootState) => state.filters.filters;

export const filtersListingSelector = (state: RootState) =>
  state.filters.listing;

export const filtersReplacingSelector = (state: RootState) =>
  state.filters.replacing;

export const filtersSavingSelector = (state: RootState) => state.filters.saving;

export const filtersSelectedSelector = (state: RootState) =>
  state.filters.selected;

export const filtersDeletingSelector = (state: RootState) =>
  state.filters.deleting;

export const filtersUpdatingSelector = (state: RootState) =>
  state.filters.updating;

export const filtersAppliedSelector = createSelector(
  [
    dataTypeSelector,
    dataDateSelector,
    dataEndDateSelector,
    dataFloorSelector,
    dataLineSelector,
    dataLocationSelector,
    dataOrganizationSelector,
    dataPropertySelector,
    dataStartDateSelector,
    dataZoneFromSelector,
    dataZoneToSelector,
  ],
  (
    lineType,
    date,
    endDate,
    floor,
    line,
    location,
    organization,
    property,
    startDate,
    zoneFrom,
    zoneTo
  ) => {
    // ORDER IS IMPORTANT!
    // TODO: Make order generic by defining filtering options beforehand in an array of sorted.
    // 1. category
    // 2. date
    // 3. endDate
    // 4. floor
    // 5. line
    // 6. location
    // 7. organization
    // 8. property
    // 9. startDate
    // 10. zoneFrom
    // 11. zoneTo
    const map = new Map();
    // if (category.length > 0) {
    //   map.set(
    //     'category',
    //     category.map((c) => c.value).sort((a, b) => a - b)
    //   );
    // }
    if (lineType.length > 0) {
      map.set(
        'lineType',
        lineType.map((l) => l.value).sort((a, b) => a - b)
      );
    }
    if (date) {
      map.set('date', date.value);
    }
    if (endDate) {
      map.set('endDate', endDate);
    }
    if (floor.length > 0) {
      map.set(
        'floor',
        floor.map((f) => f.value).sort((a, b) => a - b)
      );
    }
    if (line.length > 0) {
      map.set(
        'line',
        line.map((l) => l.value).sort((a, b) => a - b)
      );
    }
    if (location) {
      map.set('location', location.value);
    }
    if (organization) {
      map.set('organization', organization.value);
    }
    if (property) {
      map.set('property', property.value);
    }
    if (startDate) {
      map.set('startDate', startDate);
    }
    if (zoneFrom.length > 0) {
      map.set(
        'zoneFrom',
        zoneFrom.map((z) => z.value).sort((a, b) => a - b)
      );
    }
    if (zoneTo.length > 0) {
      map.set(
        'zoneTo',
        zoneTo.map((z) => z.value).sort((a, b) => a - b)
      );
    }

    return Object.fromEntries(map);
  }
);

export const filtersToBeAppliedSelector = createSelector(
  [
    selectedLineTypeSelector,
    selectedDateSelector,
    selectedEndDateSelector,
    selectedFloorSelector,
    selectedLineSelector,
    selectedLocationSelector,
    selectedOrganizationSelector,
    selectedPropertySelector,
    selectedStartDateSelector,
    selectedZoneFromSelector,
    selectedZoneToSelector,
  ],
  (
    lineType,
    date,
    endDate,
    floor,
    line,
    location,
    organization,
    property,
    startDate,
    zoneFrom,
    zoneTo
  ) => {
    // ORDER IS IMPORTANT!
    // TODO: Make order generic by defining filtering options beforehand in an array of sorted.
    // 1. category
    // 2. date
    // 3. endDate
    // 4. floor
    // 5. line
    // 6. location
    // 7. organization
    // 8. property
    // 9. startDate
    // 10. zoneFrom
    // 11. zoneTo
    const map = new Map();
    // if (category.length > 0) {
    //   map.set(
    //     'category',
    //     category.map((c) => Number(c.value)).sort((a, b) => a - b)
    //   );
    // }
    if (lineType.length > 0) {
      map.set('lineType', lineType.map((l) => l.value).sort());
    }
    if (date) {
      map.set('date', date.value);
    }
    if (endDate) {
      map.set('endDate', endDate);
    }
    if (floor.length > 0) {
      map.set(
        'floor',
        floor.map((f) => Number(f.value)).sort((a, b) => a - b)
      );
    }
    if (line.length > 0) {
      map.set(
        'line',
        line.map((l) => Number(l.value)).sort((a, b) => a - b)
      );
    }
    if (location) {
      map.set('location', location.value);
    }
    if (organization) {
      map.set('organization', organization.value);
    }
    if (property) {
      map.set('property', property.value);
    }
    if (startDate) {
      map.set('startDate', startDate);
    }
    if (zoneFrom.length > 0) {
      map.set(
        'zoneFrom',
        zoneFrom.map((z) => Number(z.value)).sort((a, b) => a - b)
      );
    }
    if (zoneTo.length > 0) {
      map.set(
        'zoneTo',
        zoneTo.map((z) => Number(z.value)).sort((a, b) => a - b)
      );
    }

    return Object.fromEntries(map);
  }
);

export const filtersSaveAsEnabled = createSelector(
  [filtersSelectedSelector, filtersSelector, filtersAppliedSelector],
  (selected, filters, applied) => {
    const selectedFilter = filters.find((f) => f.id === selected);

    if (selectedFilter?.field_val) {
      return !isEqual(selectedFilter.field_val, applied);
    }

    return !selected;
  }
);

export const filtersChangedSelector = createSelector(
  [filtersAppliedSelector, filtersToBeAppliedSelector],
  (applied, toBeApplied) => !isEqual(applied, toBeApplied)
);

export const savedFiltersChangedSelector = createSelector(
  [filtersAppliedSelector, filtersSelectedSelector],
  (applied, selected) => !isEqual(applied, selected)
);
