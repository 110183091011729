import i18next from 'i18next';
import {
  ipAddressRegex,
  rtspRegex,
  subnetMaskRegex,
} from '../utils/validations';
import { isEmail } from './regex';

class ValidationErrorClass extends Error implements ValidationError {
  public validation: Validation;

  constructor(validation: Validation) {
    super(validation.message);

    this.name = 'ValidationError';
    this.validation = {
      field: validation.field || '',
      message: validation.message,
    };
  }
}

export function vt(validation: Validation) {
  throw new ValidationErrorClass(validation);
}

export function validateLogin(email: string, password: string) {
  if (!email) {
    vt({
      field: 'email',
      message: i18next.t('validations.login.C001'),
    });
  }
  if (!isEmail(email)) {
    vt({
      field: 'email',
      message: i18next.t('validations.login.C003'),
    });
  }
  if (!password) {
    vt({
      field: 'password',
      message: i18next.t('validations.login.C002'),
    });
  }
}

export function validateRegister(email?, password?) {
  if (!email) {
    vt({
      field: 'email',
      message: i18next.t('validations.register.C001'),
    });
  }
  if (!isEmail(email)) {
    vt({
      field: 'email',
      message: i18next.t('validations.register.C003'),
    });
  }
  if (!password) {
    vt({
      field: 'password',
      message: i18next.t('validations.register.C002'),
    });
  }
}

export function validateReset(email) {
  if (!email) {
    vt({
      field: 'email',
      message: i18next.t('validations.sendReset.C001'),
    });
  }
  if (!isEmail(email)) {
    vt({
      field: 'email',
      message: i18next.t('validations.sendReset.C002'),
    });
  }
}

export function validateActivateInvitedUser(password, password2) {
  if (!password) {
    vt({
      field: 'password',
      message: i18next.t('validations.activateInvitedUser.C002'),
    });
  }
  if (!password2) {
    vt({
      field: 'password2',
      message: i18next.t('validations.activateInvitedUser.C002'),
    });
  }
  if (password !== password2) {
    vt({
      field: 'password2',
      message: i18next.t('validations.activateInvitedUser.C001'),
    });
  }
}

export function validateInviteUser(email) {
  if (!email) {
    vt({
      field: 'email',
      message: i18next.t('validations.inviteUser.C001'),
    });
  }
  if (!isEmail(email)) {
    vt({
      field: 'email',
      message: i18next.t('validations.inviteUser.C002'),
    });
  }
}

export function validateInviteByLink(email) {
  if (email && !isEmail(email)) {
    vt({
      field: 'email',
      message: i18next.t('validations.inviteUser.C002'),
    });
  }
}
