import React from 'react';
import clsx from 'clsx';
import styles from './fade-loader.module.css';

type FadeLoaderProps = {
  type?: 'primary' | 'secondary';
  colorful?: boolean;
};

function FadeLoader(props: FadeLoaderProps) {
  const { type, colorful } = props;

  const loaderClass = clsx([
    {
      [styles.loader]: type === 'secondary',
    },
  ]);

  const spinClass = clsx([
    styles.lineSpinFadeLoader,
    {
      [styles.primary]: type === 'primary',
      [styles.secondary]: type === 'secondary',
      [styles.colorful]: colorful,
    },
  ]);

  return (
    <div className={loaderClass}>
      <div className={spinClass}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

FadeLoader.defaultProps = {
  type: 'primary',
  colorful: false,
};

export default FadeLoader;
