function SvgDuplicate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={16}
      {...props}
      viewBox="0 0 26 16"
    >
      <g transform="translate(.345 -.435)">
        <g transform="translate(-.345 .435)" fill="none" stroke="#fff">
          <rect width={11} height={16} rx={1} stroke="none" />
          <rect x={0.5} y={0.5} width={10} height={15} rx={0.5} />
        </g>
        <rect
          width={11}
          height={16}
          rx={1}
          transform="translate(14.655 .435)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgDuplicate;
