import clsx from 'clsx';

import styles from './button-group.module.css';

type ButtonGroupProps = {
  align?: 'left' | 'middle' | 'right';
  children: JSX.Element | JSX.Element[];
};

function ButtonGroup(props: ButtonGroupProps) {
  const { align = 'middle', children } = props;

  return (
    <div className={clsx(styles.buttonGroup, styles[align])}>{children}</div>
  );
}

export default ButtonGroup;
