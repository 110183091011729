import React, { Ref } from 'react';
import clsx from 'clsx';
import styles from './button.module.css';
import { FadeLoader } from '../loader';
import { noop } from '../../utils/helper';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  autoComplete?: string;
  className?: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'black'
    | 'positive'
    | 'primaryPositive'
    | 'primaryNegative'
    | 'negative';
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  children: JSX.Element | string | (string | JSX.Element)[];
};

const Button = React.forwardRef(
  (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
      active = false,
      children,
      className = null,
      color = 'primary',
      containerClassName = null,
      disabled = false,
      loading = false,
      onClick = noop,
      onKeyDown = noop,
      ...rest
    } = props;

    return (
      <div className={clsx([styles.container, containerClassName])}>
        <button
          className={clsx([
            styles.button,
            className,
            {
              [styles.active]: active,
              [styles.primary]: color === 'primary',
              [styles.secondary]: color === 'secondary',
              [styles.black]: color === 'black',
              [styles.positive]: color === 'positive',
              [styles.primaryNegative]: color === 'primaryNegative',
              [styles.primaryPositive]: color === 'primaryPositive',
              [styles.negative]: color === 'negative',
              [styles.disabled]: disabled || loading,
            },
          ])}
          onClick={onClick}
          onKeyDown={onKeyDown}
          ref={ref}
          role="button"
          tabIndex={0}
          {...rest}
        >
          <div
            className={clsx([styles.children, { [styles.loading]: loading }])}
          >
            {children}
          </div>
          <div className={styles.loaderContainer}>
            {loading && (
              <FadeLoader type="secondary" colorful={color === 'primary'} />
            )}
          </div>
        </button>
      </div>
    );
  }
);

export default Button;
