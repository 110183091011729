import React, { LegacyRef, TextareaHTMLAttributes } from 'react';
import styles from './hidden-text-area.module.css';

const HiddenTextArea = React.forwardRef(
  (
    props: TextareaHTMLAttributes<HTMLTextAreaElement>,
    ref: LegacyRef<HTMLTextAreaElement>
  ) => {
    const { ...rest } = props;

    return <textarea className={styles.hiddenTextArea} ref={ref} {...rest} />;
  }
);

export default HiddenTextArea;
