import { animated } from 'react-spring';

function SvgSelectArrow(
  props: React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
) {
  return (
    <animated.svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20.707 11.061"
      {...props}
    >
      <path d="M.353.353l10 10 10-10" fill="none" stroke="#999" />
    </animated.svg>
  );
}

export default SvgSelectArrow;
