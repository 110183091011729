import {
  IMPORT_ACTIVITY_FAILURE,
  IMPORT_ACTIVITY_REQUEST,
  IMPORT_ACTIVITY_SUCCESS,
} from '../constants/action-types';

export const importActivityRequest = (file: File) => ({
  file,
  type: IMPORT_ACTIVITY_REQUEST,
});

export const importActivitySuccess = () => ({
  type: IMPORT_ACTIVITY_SUCCESS,
});

export const importActivityFailure = (error: unknown) => ({
  error,
  type: IMPORT_ACTIVITY_FAILURE,
});
